import {
  query,
  setDoc,
  updateDoc,
  deleteDoc,
  doc,
  where,
  collection,
  getDocs,
  Timestamp,
  serverTimestamp,
} from "firebase/firestore";
import auth, { db } from "../config/firebase";
import jwt_decode from "jwt-decode";
import { DateFormatter } from "../helpers/DateFormatter";

export const userService = {
  createUser,
  updateUser,
  getUser,
  getAllUsers,
  getUsersbyCompanyID,
  getSubMaklers,
  getMaklers,
  getAdminUser,
  decodeToken,
  getId,
  deleteUser,
  sendEmailVerification,
  getCreator,
  deleteUserFromAuth,
  AddMakler,
  checkEmailVerified
};

const formatQuerySnapshotToArray = async (querySnapshot) => {
  const dataArray = [];

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    dataArray.push(data);
  });

  return dataArray;
};
//create user
async function createUser(user, type = null) {
  const docRef = doc(db, "xc_user", user.id);
  if (user) {
    if (type == null && user.agb_date != null) {
      user.agb_date = new Timestamp(
        user?.agb_date.seconds,
        user?.agb_date.nanoseconds
      );
    }
  }

  return await setDoc(docRef, {
    ...user,
    last_login: DateFormatter.initDate(),
    created_at: DateFormatter.initDate(),
    registration_from:"web",
    updated_at: serverTimestamp(),
  });
}
//update user
async function updateUser(user) {
  // Create a query to find the user document
  const userQuery = query(
    collection(db, "xc_user"),
    where("firebase_auth_id", "==", user.id)
  );
  const userDocs = await getDocs(userQuery);
  if (!userDocs.empty) {
    // Get the first document's reference
    const userDoc = userDocs.docs[0];
    const docRef = doc(db, "xc_user", userDoc.id);
    // Update the document with the new user data
    await updateDoc(docRef, user);
  } else {
    throw new Error("User not found");
  }
}
//get user
async function getUser(user_id) {
  let userQuerry = query(
    collection(db, "xc_user"),
    where("firebase_auth_id", "==", user_id)
  );
  const userDoc = await getDocs(userQuerry);
  return userDoc;
}

//delete user
async function deleteUser(user_id) {
  const q = query(
    collection(db, "xc_user"),
    where("firebase_auth_id", "==", user_id)
  );

  const querySnapshot = await getDocs(q);

  const deletePromises = querySnapshot.docs.map((doc) => deleteDoc(doc.ref));

  await Promise.all(deletePromises);
}
//get User by company id
async function getUsersbyCompanyID(company_id) {
  let userQuerry = query(
    collection(db, "xc_user"),
    where("company_id", "==", company_id)
  );
  const userDoc = await getDocs(userQuerry);
  const formattedArray = await formatQuerySnapshotToArray(userDoc);
  return formattedArray;
}
async function getCreator(company_id) {
  try {
    const usersRef = collection(db, "xc_user");
    const q = query(
      usersRef,
      where("company_id", "==", company_id),
      where("account_creator", "==", true)
    ); // Query for the admin user
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const adminUser = querySnapshot.docs[0].data();
      return adminUser;
    } else {
      console.log("No admin user found for the given company_id.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching admin user:", error);
    throw error;
  }
}
async function getAllUsers() {
  let userQuerry = query(collection(db, "xc_user"));
  const userDoc = await getDocs(userQuerry);
  const formattedArray = await formatQuerySnapshotToArray(userDoc);
  return formattedArray;
}

async function getSubMaklers(company_id) {
  let userQuerry = query(
    collection(db, "xc_user"),
    where("role", "==", "subMakler"),
    where("company_id", "==", company_id)
  );
  const userDoc = await getDocs(userQuerry);
  const formattedArray = await formatQuerySnapshotToArray(userDoc);
  return formattedArray;
}

async function getMaklers(company_id) {
  let userQuerry = query(
    collection(db, "xc_user"),
    where("role", "==", "makler"),
    where("company_id", "==", company_id)
  );
  const userDoc = await getDocs(userQuerry);
  const formattedArray = await formatQuerySnapshotToArray(userDoc);
  return formattedArray;
}
async function getAdminUser(company_id) {
  let userQuerry = query(
    collection(db, "xc_user"),
    where("role", "==", "admin"),
    where("company_id", "==", company_id)
  );
  const userDoc = await getDocs(userQuerry);
  const formattedArray = await formatQuerySnapshotToArray(userDoc);
  return formattedArray;
}

async function sendEmailVerification(email) {
  let baseUrl = `${process.env.REACT_APP_BASE_URL}/user/email_verification`;

  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ email: email }),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/user/email_verification`,
    requestOptions
  ).then(handleResponse);
}
function decodeToken(token) {
  var decoded = jwt_decode(token);
  return decoded;
}

function getId() {
  if (
    localStorage.getItem("switchedcustomer") &&
    localStorage.getItem("switchedcustomer") !== ""
  ) {
    return JSON.parse(localStorage.getItem("switchedcustomer"));
  } else if (localStorage.getItem("currentuser")) {
    return decodeToken(JSON.parse(localStorage.getItem("currentuser"))).user_id;
  }
}
async function deleteUserFromAuth(firebase_auth_id) {
  try {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ firebase_auth_id: firebase_auth_id }),
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/user/delete`,
      requestOptions
    ).then(handleResponse);
  } catch (error) {
    console.error("Error:", error.message);
  }
}

async function AddMakler(email,password) {
  try {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ email: email, password: password }),
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/user/add_makler`,
      requestOptions
    ).then(handleResponse);
  } catch (error) {
    console.error("Error:", error.message);
  }
}
async function checkEmailVerified(email){
  try {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ email: email}),
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/user/check_email`,
      requestOptions
    ).then(handleResponse);
  } catch (error) {
    console.error("Error:", error.message);
  }
}
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.msg) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
