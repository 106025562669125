import {
  doc,
  getDocs,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  setDoc,
  Timestamp,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../config/firebase";

export const realEstateService = {
  getRealEstate,
  getByCompany,
  getByCompanyAndZipCode,
  getByCompanyAndPlz,
  updateProperties,
  getPatternsList,
  savePattern,
  create_real_estate,
  duplicateAppartement,
  getRealEstateByAdress,
};
async function getRealEstate(realEstate_id) {
  const docRef = doc(db, "xc_real_estate", realEstate_id);
  const realEstateDoc = await getDoc(docRef);
  return realEstateDoc;
}


async function getRealEstateByAdress(
  plz,
  address,
  city,
  house_number,
  position,
  company_id
) {
  const q = query(
    collection(db, "xc_real_estate"),
    where("re_plz", "==", plz),
    where("re_street", "==", address),
    where("re_city", "==", city),
    where("re_hnr", "==", house_number),
    where("makler_id", "==", company_id)
  );

  const querySnapshot = await getDocs(q);
  const docs = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  if (docs[0]) {
    var real_estate = docs[0];
    var index = real_estate?.re_property.findIndex(
      (element) => element.property_position === position
    );
  }
  return index !== -1
    ? {
        property: real_estate?.re_property[index],
        index: index,
        realEstate: real_estate,
      }
    : { property: null, index: null, real_estate: null };
}

async function getByCompany(company_id) {
  const queryRef = query(
    collection(db, "xc_real_estate"),
    where("makler_id", "==", company_id)
  );
  const querySnapshot = await getDocs(queryRef);
  return querySnapshot;
}

async function getByCompanyAndZipCode(company_id, zip_code) {
  const queryRef = query(
    collection(db, "xc_real_estate"),
    where("makler_id", "==", company_id),
    where("re_plz", "==", zip_code)
  );
  const querySnapshot = await getDocs(queryRef);
  return querySnapshot;
}

async function getByCompanyAndPlz(company_id, plz) {
  const queryRef = query(
    collection(db, "xc_real_estate"),
    where("makler_id", "==", company_id),
    where("re_plz", "==", plz)
  );
  const querySnapshot = await getDocs(queryRef);
  const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
  return formattedArray;
}
async function getPatternsList(user_id) {
  const queryRef = query(
    collection(db, "xc_real_estate_patterns"),
    where("makler_id", "==", user_id)
  );
  const querySnapshot = await getDocs(queryRef);
  const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
  return formattedArray;
}

async function savePattern(patternObject) {
  const docRef = doc(db, "xc_real_estate_patterns", patternObject.id);
  await setDoc(docRef, patternObject);
}
async function updateProperties(real_state_id, properties) {
  //console.log(real_state_id, properties)
  const docRef = doc(db, "xc_real_estate", real_state_id);
  return await updateDoc(docRef, {...properties, last_updated_at_web: serverTimestamp(),});
}
async function duplicateAppartement(real_state_id, properties) {
  const docRef = doc(db, "xc_real_estate", real_state_id);
  return await updateDoc(docRef, properties);
}

async function create_real_estate(real_state_id, object) {
  const docRef = doc(db, "xc_real_estate", real_state_id);
  return await setDoc(docRef, object);
}
const formatQuerySnapshotToArray = async (querySnapshot) => {
  const dataArray = [];

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    dataArray.push(data);
  });

  return dataArray;
};
