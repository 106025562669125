import React, { useState, useCallback, useRef, useEffect } from "react";
import keyIcon from "../../assets/Icons/key-icon.svg";
import ValidIcon from "../../assets/Icons/valid-icon.svg";
import RaumeIcon from "../../assets/Icons/raume-icon.svg";
import CalenderIcon from "../../assets/Icons/calander-icon.svg";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import MinimizeOutlinedIcon from "@mui/icons-material/MinimizeOutlined";
import NotValidIcon from "../../assets/Icons/notvalid_icon.svg";
import HouseIcon from "@mui/icons-material/House";
import { v4 as uuidv4 } from "uuid";
import FileSaver from "file-saver";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Mangel from "../../assets/Icons/mangel.png";
import CrossIcon from "../../assets/Icons/Missing-icon.svg";
import DefectTyp from "../../assets/Icons/DeffectTyp.png";
import CommentIcon from "../../assets/Icons/CommentIcon.svg";
import Trash from "../../assets/Icons/trash.svg";
import CameraIcon from "../../assets/Icons/cameraIcon.svg";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import LineGroup from "../../assets/Icons/Group 8.png";
import Defect from "../../assets/Icons/deffectName.png";
import Config from "@mui/icons-material/Construction";
import OpenProtocol from "../../assets/Icons/deffect-icon/Menu2.svg";
import Line from "../../assets/Icons/Line.png";
import LineLong from "../../assets/Icons/Line-long.svg";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { Timestamp } from "firebase/firestore";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { RotatingLines } from "react-loader-spinner";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import WohnungsdatenUserIcon from "../../assets/Icons/user-icon.svg";

import _ from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ZumIcon from "../../assets/Icons/zum-icon.svg";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import swal from "sweetalert";
import swalAlert from "../../components/SaveButton/index";
import moment from "moment";
import ContractIcon from "../../assets/Icons/contract-icon.svg";
import Dialog from "../../components/Dialog/dialog-autocomplete";
import HideComment from "@mui/icons-material/CommentsDisabled";
import Comment from "@mui/icons-material/Comment";
import { protocolService } from "../../_services/protocol.service";
import { userService } from "../../_services/user.service.js";
import { makeStyles, Menu } from "@material-ui/core";
import { Carousel, CarouselItem, CarouselIndicators } from "reactstrap";
import { useHistory } from "react-router-dom";
import { deffectService } from "_services/deffect.service";
import { Remove } from "@mui/icons-material";
import { uploadImageService } from "_services/uploadImage.service";
const useStyles = makeStyles(() => ({
  imageContainer: {
    position: "relative",
  },
  imageText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "#fff",
    padding: "10px",
    width: "100%",
    textAlign: "justify",
    wordBreak: "break-word",
    hyphens: "auto",
  },
}));
function ProtokollOvwerView(props) {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const defffectParam = urlParams.get("deffect");
  const hasDefect = defffectParam ? defffectParam === "true" : false;
  const [state, setState] = useState();
  const [protocolToShow, setProtocolToShow] = useState();
  const [showImage, setShowImage] = useState(false);
  const [idImageShow, setIdImageShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState({ label: "", value: "" });
  const [status, setStatus] = useState(null);
  const [showstatus, setShowStatus] = useState(true);
  const [level, setLevel] = useState(0);
  const [value, setvalue] = useState("");
  const [type, setType] = useState("");
  const [index, setIndex] = useState(null);
  const [secondIndex, setSecondIndex] = useState(null);
  const [placeholder, setPlaceHolder] = useState("");
  const [CurrentPageByRoom, setCurrentPageByRoom] = useState([]);
  const [DeffectIds, setDeffectIds] = useState([]);
  const imagesPerPage = 6;
  const [selectedId, setSelectedId] = useState("");
  const [comment, setComment] = useState(false);
  const classes = useStyles();
  const [clickedOnBullet, setClickedOnBullet] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeIndexMangel, setActiveIndexMangel] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pagesByRoom, setPagesByRoom] = useState([]);
  const [show, setShow] = useState(false);
  const [realname, setRealname] = useState("");
  const toggleImage = (index) => {
    setIdImageShow(index);
    setShowImage(!showImage);
  };
  const deleteImageDefect = (index,roomIndex) => {
    setProtocol((prevProtocol) => {
      const roomImage = prevProtocol.protocol_rooms[roomIndex]?.room_image?.filter((img)=> img.id != index);
      prevProtocol.protocol_rooms[roomIndex].room_image = roomImage;
      setProtocolToShow({ ...prevProtocol, protocol_rooms: prevProtocol.protocol_rooms })
      return { ...prevProtocol, protocol_rooms: prevProtocol.protocol_rooms };
    });
  };
  const deleteImageKey = (index) => {
    const updatedImage = {
      key_image_local_path: null,
      key_image_name: null,
      key_image_remote_url: null,
      key_image_timestamp: null,
      key_image_thumb: null,
      key_image_thumb_id: null,
      id: uuidv4(),
    };
    setProtocol((prevProtocol) => {
      const updatedKeys = prevProtocol.protocol_keys.map((key) => {
        if (key.id === protocol?.protocol_keys[index]?.id) {
          return {
            ...key,
            image: updatedImage,
          };
        }
        return key;
      });

      const updatedProtocol = {
        ...prevProtocol,
        protocol_keys: updatedKeys,
      };
      setProtocolToShow(updatedProtocol)
      return updatedProtocol;
    });
  };
  let firstcounterID = generateID(36);
  let secondcounterID = generateID(36);
  let thirdcounterID = generateID(36);
  let firstkeyid = generateID(36);
  let secondkeyid = generateID(36);
  const idRoom1 = generateID(36);
  const idRoom2 = generateID(36);
  const idRoom3 = generateID(36);
  const idRoom4 = generateID(36);
  const now_date = new Date();
  const utc_time = new Date(
    now_date.getTime() + now_date.getTimezoneOffset() * 60000
  );
  const [alert, setAlert] = useState(null);
  let note = window.location.pathname.startsWith("/app/protocolcontrol")
    ? "create"
    : window.location.pathname.startsWith("/app/update_protocolcontrol") &&
      "update";

  function cancel() {
    history.push("/app/overview");
  }

  function generateID(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }
  const buttonRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(buttonRef.current);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [protocol, setProtocol] = useState(
    protocolToShow || {
      protocol_type: "",
      date_of_hand_over: null,
      id: "",
      protocol_counter: [
        {
          counter_name: null,
          counter_is_double: "singleCounter",
          counter_number: null,
          counter_value_1: null,
          market_location_number: "",
          counter_location: null,
          counter_type: "electricity",
          counter_value_2: null,
          counter_created_at: utc_time,
          counter_id_object: null,
          counter_real_estate_id: null,
          counter_updated_at: utc_time,
          property_counter_ref_id: null,
          counter_current_consumption: null,
          id: firstcounterID,
          image: {
            counter_image_local_path: null,
            counter_image_name: null,
            counter_image_remote_url: null,
            counter_image_time_stamp: null,
            counter_image_thumb: null,
            id: null,
          },
        },
        {
          counter_name: null,
          counter_number: null,
          counter_value_1: null,
          market_location_number: "",
          counter_location: null,
          counter_type: "gas",
          counter_is_double: null,
          counter_created_at: utc_time,
          counter_id_object: null,
          counter_real_estate_id: null,
          counter_updated_at: utc_time,
          property_counter_ref_id: null,
          counter_current_consumption: null,
          id: secondcounterID,
          image: {
            counter_image_local_path: null,
            counter_image_name: null,
            counter_image_remote_url: null,
            counter_image_time_stamp: null,
            counter_image_thumb: null,
            id: null,
          },
        },
        {
          counter_name: null,
          counter_location: null,
          counter_number: null,
          counter_type: "watter",
          counter_is_double: null,
          counter_value_1: null,
          counter_created_at: utc_time,
          counter_id_object: null,
          counter_real_estate_id: null,
          counter_updated_at: utc_time,
          property_counter_ref_id: null,
          counter_current_consumption: null,
          id: thirdcounterID,
          image: {
            counter_image_local_path: null,
            counter_image_name: null,
            counter_image_remote_url: null,
            counter_image_time_stamp: null,
            counter_image_thumb: null,
            id: null,
          },
        },
      ],
      protocol_keys: [
        {
          controle_comment: null,
          created_at: utc_time,
          id: firstkeyid,
          key_type: "frontDoor",
          key_name: null,
          key_notice: null,
          key_date_of_forwarding: null,
          key_number: "",
          number_keys_returned: 0,
          number_keys_returned_in_main_protocol: null,
          property_key_ref_id: null,
          updated_at: utc_time,
          image: {
            key_image_local_path: null,
            key_image_remote_url: null,
            key_image_name: null,
            key_image_thumb: null,
            key_image_timestamp: null,
            id: null,
          },
        },
        {
          controle_comment: null,
          created_at: utc_time,
          id: secondkeyid,
          key_type: "mainEntrance",
          key_name: null,
          key_notice: null,
          key_date_of_forwarding: null,
          key_number: "",
          number_keys_returned: 0,
          number_keys_returned_in_main_protocol: null,
          property_key_ref_id: null,
          updated_at: utc_time,
          image: {
            key_image_local_path: null,
            key_image_remote_url: null,
            key_image_name: null,
            key_image_thumb: null,
            key_image_timestamp: null,
            id: null,
          },
        },
      ],
      protocol_rooms: [
        {
          room_deffect: null,
          id: idRoom1,
          object_id: null,
          property_room_ref_id: "",
          real_estate_id: null,
          room_date_creation: utc_time,
          room_generale_description: null,
          room_image: null,
          room_sorting_number: null,
          room_status: null,
          room_name: "kitchen",
          room_type: "kitchen",
          room_updated_at: utc_time,
        },
        {
          room_deffect: null,
          id: idRoom2,
          object_id: null,
          property_room_ref_id: "",
          real_estate_id: null,
          room_date_creation: utc_time,
          room_generale_description: null,
          room_image: null,
          room_sorting_number: null,
          room_status: null,
          room_name: "bedroom",
          room_type: "bedroom",
          room_updated_at: utc_time,
        },
        {
          room_deffect: null,
          id: idRoom3,
          object_id: null,
          property_room_ref_id: "",
          real_estate_id: null,
          room_date_creation: utc_time,
          room_generale_description: null,
          room_image: null,
          room_sorting_number: null,
          room_status: null,
          room_name: "livingRoom",
          room_type: "livingRoom",
          room_updated_at: utc_time,
        },
        {
          room_deffect: null,
          id: idRoom4,
          object_id: null,
          property_room_ref_id: "",
          real_estate_id: null,
          room_date_creation: utc_time,
          room_generale_description: null,
          room_image: null,
          room_sorting_number: null,
          room_status: null,
          room_name: "bathroom",
          room_type: "bathroom",
          room_updated_at: utc_time,
        },
      ],
      has_corenter: false,
      renter: {
        renter_date_of_birth: null,
        renter_email: null,
        renter_first_name: null,
        renter_last_name: null,
        renter_phone: null,
        renter_mobile: null,
        renter_salutation: "noChoice",
        renter_title: "noChoice",
        address: {
          renter_address_note: null,
          renter_city: null,
          renter_house_number: null,
          renter_plz: null,
          renter_previous_new_adress: false,
          renter_srteet: null,
        },
        other_renter: null,
        signature_renter: {
          renter_refuse_signature: false,
          signature_renter: false,
          signature_renter_image_name: null,
          signature_renter_image_remote_url: null,
          signature_renter_local_path_image: null,
          signature_renter_refuse_reason: null,
          signature_renter_time_stamp: null,
          signature_renter_thumb: null,
        },
      },
      coRenter: {
        first_name: null,
        last_name: null,
        phone: null,
        mobile: null,
        salutation: "noChoice",
        signature_coRenter: {
          image_name: null,
          local_path: null,
          refuse_reason: null,
          refuse_signature: false,
          remote_url: null,
          signature: false,
          thumb: null,
          time_stamp: null,
        },
        title: "noChoice",
      },
      owner_is_makler: false,
      object: {
        object_id: "",
        object_status: null,
        object_type: null,
        real_state_id: null,
        real_state_object_number: 1,
        hasManyObject: false,
        object_date_of_renovation: null,
        address: {
          object_street: null,
          object_house_nbr: null,
          object_position: null,
          object_address_note: null,
          object_plz: null,
          object_city: null,
          object_notice: null,
        },
      },
      landlord: {
        landlord_company_name: null,
        landlord_email: null,
        landlord_first_name: null,
        landlord_last_name: null,
        landlord_phone: null,
        landlord_salutation: "noChoice",
        landlord_title: "noChoice",
        landlord_type: "noChoice",
        landlord_mobile: null,
        address: {
          landlord_address_note: null,
          landlord_city: null,
          landlord_house_number: null,
          landlord_plz: null,
          landlord_street: null,
        },
        signature_landlord: {
          signature_landlord: false,
          signature_landlord_image_name: null,
          signature_landlord_image_remote_url: null,
          signature_landlord_local_path_image: null,
          signature_landlord_time_stamp: null,
        },
      },
      previous_renter: {
        previous_renter_email: null,
        previous_renter_first_name: null,
        previous_renter_last_name: null,
        previous_renter_phone_number: null,
        previous_renter_mobile_number: null,
        previous_renter_salutation: "noChoice",
        previous_renter_title: "noChoice",
        address: {
          previous_renter_address_note: null,
          previous_renter_city: null,
          previous_renter_housnumber: null,
          previous_renter_plz: null,
          previous_renter_new_adress: false,
          previous_renter_srteet: null,
        },
        signature_previous_renter: {
          previous_renter_refuse_signature: false,
          signature_previous_renter: false,
          signature_previous_renter_image_name: null,
          signature_previous_renter_image_remote_url: null,
          signature_previous_renter_local_path_image: null,
          signature_previous_renter_refuse_reason: null,
          signature_previous_renter_time_stamp: null,
        },
      },
      owner: {
        owner_Type: "noChoice",
        owner_company_name: null,
        owner_salutation: "noChoice",
        owner_title: "noChoice",
        owner_first_name: null,
        owner_last_name: null,
        owner_email: null,
        owner_phone: null,
        owner_mobile: null,
        address: {
          owner_address_note: null,
          owner_city: null,
          owner_house_number: null,
          owner_plz: null,
          owner_street: null,
        },
      },
      log_book_id: null,
      note_taker: {
        note_taker_is_makler: false,
        note_taker_is_landlord: false,
        note_taker_first_name: null,
        note_taker_last_name: null,
        note_taker_title: null,
        note_taker_gender: null,
      },
    }
  );
  const [shrinkLabel, setShrinkLabel] = useState(false);
  const inputRefs = useRef([]);
  const handleInputClick = (e) => {
    const name = e.target.name;
    setShrinkLabel((prev) => ({ ...prev, [name]: true }));
  };

  function formatTitle(title) {
    if (
      title == "noChoice" ||
      title == null ||
      title == "Bitte wählen" ||
      title == ""
    )
      return "";
    else {
      switch (title) {
        case "dr":
          return "Dr.";
        case "noChoice":
          return "Keine Angabe";
        case "ing":
          return "Ing.";
        case "prof":
          return "Prof.";
        default:
          return title;
          break;
      }
    } //return title.charAt(0).toUpperCase() + title.slice(1);
  }
  const [loaderImage, setLoaderImage] = useState({});
    //change input value
  function handleChange(name, value, index = null,roomIndex) {
        setProtocol((prevProtocol) => {
          const roomImage = prevProtocol.protocol_rooms[roomIndex]?.room_image[index];
          const updatedRoom = { ...roomImage, [name]: value };
          prevProtocol.protocol_rooms[roomIndex].room_image[index] = updatedRoom;
          setProtocolToShow({ ...prevProtocol, protocol_rooms: prevProtocol.protocol_rooms })
          return { ...prevProtocol, protocol_rooms: prevProtocol.protocol_rooms };
        });

    }
  const handleImageUpload = async (event, imageType, mangelId = null,roomIndex) => {
    setLoaderImage((prevLoaderImage) => ({
      ...prevLoaderImage,
      [mangelId]: true,
    }));
    const file = event.target.files[0];
    if (file) {
      try {
        const { resizedDataURL, thumbnailDataURL } =
          await uploadImageService.uploadImage(
            "room_images",
            file,
            64,
            64,
            1280,
            50
          );
        const imageName = file.name;
        const seconds = Math.floor(now_date.getTime() / 1000); 
        const nanoseconds = (now_date.getTime() % 1000) * 1000000; 
        const utc_time = new Timestamp(seconds, nanoseconds);
        const thumb = await uploadImageService.addThumb({
          base64: thumbnailDataURL,
          status: 'active',
          syncedRemote: true,
          id: uuidv4(),
          company_id: protocol.company_id
        })

        const updatedImage = {
          created_at: utc_time,
          deffect_id: mangelId,
          id: uuidv4(),
          image_description: null,
          is_from_main_protocol: false,
          protocol_id: protocol.id,
          room_id: protocol?.protocol_rooms[roomIndex]?.id,
          room_image_local_path: null,
          room_image_name: imageName,
          room_image_remote_url: resizedDataURL,
          room_image_thumb: thumbnailDataURL,
          room_image_thumb_id: thumb.id,
          room_image_type: imageType,
          time_stamp: null,
          updated_at: utc_time, 
          is_from_protocol_control: true,
        };

        setProtocol((prevProtocol) => {
          const updatedRooms = prevProtocol.protocol_rooms.map((room) => {
            if (room.id === protocol?.protocol_rooms[roomIndex]?.id) {
              // Concatenate the updated image with the existing room_image array
              const updatedRoomImages =
                room.room_image !== null
                  ? room.room_image.concat(updatedImage)
                  : [updatedImage];
              setLoaderImage((prevLoaderImage) => ({
                ...prevLoaderImage,
                [mangelId]: false,
              }));
              return {
                ...room,
                room_image: updatedRoomImages,
              };
            }
            return room;
          });

          const updatedProtocol = {
            ...prevProtocol,
            protocol_rooms: updatedRooms,
          };
          setProtocolToShow(updatedProtocol)
          return updatedProtocol;
        });
      } catch (error) {
        console.error("Error uploading image:", error);
        // Handle the error as needed
        setLoaderImage((prevLoaderImage) => ({
          ...prevLoaderImage,
          [mangelId]: false,
        }));
      }
    }
  };
  const [loadPdf, setLaodPdf] = useState(false);
    //download protocol
  const handelDownloadPDFProtocol  = async (protocol_id) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/protocol/download/draftPdf/${protocol_id}`
        );
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        return await response.json(); // Assuming it returns JSON with the file path
      } catch (error) {
        console.error("Error downloading PDF:", error);
        throw error; // Re-throw the error for handling in the calling function
      }
      };
  const handleDownloadPdf = async (id) => {
           try {
                    setLaodPdf(true);
                    const pdfResult = await handelDownloadPDFProtocol(id); // Get the result from your API
                    const { file_path } = pdfResult;
                    setLaodPdf(false);
                    if (file_path) {
                      window.location.href = file_path;
                    } else {
                      console.error("No file path returned for the PDF.");
                    }
                  } catch (err) {
                    setLaodPdf(false);
                    console.error("Error creating protocol or downloading PDF:", err);
                  }
      };
  const handleImageKeyUpload = async (event, index) => {
      setLoaderImage((prevLoaderImage) => ({
        ...prevLoaderImage,
        [index]: true,
      }));
      const file = event.target.files[0];
      if (file) {
        try {
          const { resizedDataURL, thumbnailDataURL } =
            await uploadImageService.uploadImage("keys", file, 64, 64, 1280, 50);
          const reader = new FileReader();
          reader.onload =  async () => {
            const companyId = protocol.company_id;
            const imageName = file.name;
            const thumb = await uploadImageService.addThumb({
                      base64: thumbnailDataURL,
                      status: 'active',
                      syncedRemote: true,
                      id: uuidv4(),
                      company_id: companyId
                    })
            const updatedImage = {
              key_image_local_path: null,
              key_image_name: imageName,
              key_image_remote_url: resizedDataURL,
              key_image_timestamp: null,
              key_image_thumb: thumbnailDataURL,
              key_image_thumb_id: thumb.id,
              id: uuidv4(),
            };
            setProtocol((prevProtocol) => {
              const updatedKeys = prevProtocol.protocol_keys.map((key) => {
                if (key.id === protocol?.protocol_keys[index]?.id) {
                  return {
                    ...key,
                    image: updatedImage,
                  };
                }
                return key;
              });
    
              const updatedProtocol = {
                ...prevProtocol,
                protocol_keys: updatedKeys,
              };
              setProtocolToShow(updatedProtocol)
              return updatedProtocol;
            });
          };
          reader.readAsDataURL(file);
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      }
    };
  const handleCommentUploadwithoutImage = async (imageType, mangelId = null,roomIndex) => {
      try {
        const seconds = Math.floor(now_date.getTime() / 1000); 
        const nanoseconds = (now_date.getTime() % 1000) * 1000000; 
        const utc_time = new Timestamp(seconds, nanoseconds);
        const updatedImage = {
          created_at: utc_time,
          deffect_id: mangelId,
          id: uuidv4(),
          image_description: null,
          is_from_main_protocol: false,
          protocol_id: protocol.id,
          room_id: protocol?.protocol_rooms[roomIndex]?.id,
          room_image_local_path: null,
          room_image_name: null,
          room_image_remote_url: null,
          room_image_thumb: null,
          room_image_thumb_id: null,
          room_image_type: imageType,
          time_stamp: null,
          updated_at: utc_time, 
          is_from_protocol_control: true,
        };

        setProtocol((prevProtocol) => {
          const updatedRooms = prevProtocol.protocol_rooms.map((room) => {
            if (room.id === protocol?.protocol_rooms[roomIndex]?.id) {
              // Concatenate the updated image with the existing room_image array
              const updatedRoomImages =
                room.room_image !== null
                  ? room.room_image.concat(updatedImage)
                  : [updatedImage];
              return {
                ...room,
                room_image: updatedRoomImages,
              };
            }
            return room;
          });

          const updatedProtocol = {
            ...prevProtocol,
            protocol_rooms: updatedRooms,
          };
          setProtocolToShow(updatedProtocol)
          return updatedProtocol;
        });
      } catch (error) {
        console.error("Error uploading image:", error);
        // Handle the error as needed
      }

  };
  function unformatTitle(title) {
    title = title.trim();
    switch (title) {
      case "Dr.":
        return "dr";
      case "Keine Angabe":
        return "noChoice";
      case "":
        return "noChoice";
      case "Ing.":
        return "ing";
      case "Prof.":
        return "prof";
      default:
        return title;
        break;
    }
  }
  const getState = async () => {
    const storedProtocol = localStorage.getItem("state")
      ? JSON.parse(localStorage.getItem("state"))
      : false;
    if (storedProtocol) {
      try {
        getProtocolToShow(storedProtocol);
        setState(storedProtocol);
      } catch (e) {
        console.error("Error parsing stored protocol:", e);
      }
    } else {
      console.warn("No protocol found in localStorage.");
    }
  };
  //filter repaired defects
  async function getNotRepaired(protocol) {
    let defects = [];

    // Fetch defects associated with the given protocol
    defects = await deffectService.getNotRepairedDefectsByProtocolId(
      protocol.id
    );

    // Map through the rooms and filter their defects
    protocol.protocol_rooms = protocol.protocol_rooms.map((room) => {
      if (room.room_deffect) {
        room.room_deffect = room.room_deffect
          .filter((deffect) =>
            defects.some(
              (defectItem) => defectItem.defect_ref_id === deffect.id
            )
          )
          .map((deffect) => {
            const matchingDefect = defects.find(
              (defectItem) => defectItem.defect_ref_id === deffect.id
            );
            if (matchingDefect) {
              deffect.defect_ref_id = null;
              deffect.id = matchingDefect.id;
            }
            return deffect;
          });
        room.room_image = (room.room_image || [])
          .filter((deffect) =>
            defects.some(
              (defectItem) => defectItem.defect_ref_id === deffect.deffect_id
            )
          )
          .map((deffect) => {
            const matchingDefect = defects.find(
              (defectItem) => defectItem.defect_ref_id === deffect.deffect_id
            );
            if (matchingDefect) {
              deffect.deffect_id = matchingDefect.id;
            }
            return deffect;
          });
      }
      return room;
    });
    return protocol;
  }
  // get protocol
  const getProtocolToShow = async (state) => {
    if (state.hasOwnProperty("doc_type")) {
      await protocolService.getProtocol(state.id_doc).then(async (result) => {
        if (result) {
          if (window.location.pathname.startsWith("/app/protocolcontrol")) {
            let changedProtocol = await getNotRepaired(result.data());
            setProtocolToShow(changedProtocol);
            setState(changedProtocol);
          } else {
            setProtocolToShow(result.data());
            setState(result.data());
          }
        }
      });
    } else {
      setProtocolToShow(state);
    }
  };
  useEffect(() => {
    if (hasDefect) {
      getState();
    } else {
      getProtocolToShow(location.state.state);
      setState(location.state.state);
    }
  }, [hasDefect]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      for (let i = 0; i < 20; i++) {
        if (
          inputRefs.current[i] &&
          !inputRefs.current[i].contains(event.target)
        ) {
          setShrinkLabel(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [protocol]);
  useEffect(() => {
    if (protocolToShow) {
      setProtocol((prevProtocol) => ({ ...prevProtocol, ...protocolToShow }));

      if (protocolToShow.note_taker.note_taker_is_landlord)
        setSelectedValue({
          label: "Protkollant/in aus Wohnungsgeber übernehmen",
          value: "note_taker_from_landlord",
        });
      if (protocolToShow.note_taker.note_taker_is_makler)
        setSelectedValue({
          label: "Protokollant/in aus Benutzerkonto der App übernehmen",
          value: "note_taker_from_current_user",
        });
    }
  }, [protocolToShow]);
  const next = (index, type = null) => {
    if (animating || clickedOnBullet) return;
    const nextIndex =
      activeIndex === protocolToShow.protocol_rooms[index].room_image.length - 1
        ? 0
        : activeIndex + 1;
    if (type == null) setActiveIndex(nextIndex);
    else setActiveIndexMangel(nextIndex);
  };
  const previous = (index, type = null) => {
    if (animating) return;
    const prevIndex =
      activeIndex === 0
        ? protocolToShow.protocol_rooms[index].room_image.length - 1
        : activeIndex - 1;
    if (type == null) setActiveIndex(prevIndex);
    else setActiveIndexMangel(prevIndex);
  };
  const goToIndexMangel = (newIndex) => {
    if (animating) return;
    setClickedOnBullet(true);
    setActiveIndexMangel(newIndex);
  };
  const handleExiting = () => {
    setAnimating(true);
  };
  // Function to update selected index to true
  const handleCurrentPageByRoom = (page, i) => {
    setCurrentPage(page);
    // Updating the array based on condition index === i
    setCurrentPageByRoom((prev) => {
      const updatedCurrentPageByRoom = CurrentPageByRoom.map((value, index) => {
        return index === i ? page : value;
      });
      return updatedCurrentPageByRoom;
    });
  };
  // Function to handle pagination for the selected room
  const paginate = (pageNumber, id) => {
    handleCurrentPageByRoom(pageNumber, id);
  };
  function handleNoteTakerChange(event) {
    const { name, value } = event.target;

    setProtocol((prevProtocol) => ({
      ...prevProtocol,
      note_taker: {
        ...prevProtocol.note_taker,
        [name]: name == "note_taker_title" ? unformatTitle(value) : value,
      },
    }));
  }

  async function getNoteTakeInfo() {
    try {
      const userDoc = await userService.getUser(userService.getId());

      if (userDoc.empty) {
        console.error("User not found");
        return;
      }

      const promises = userDoc.docs.map(async (user_doc) => {
        const note_taker = {
          note_taker_first_name: user_doc.data().first_name,
          note_taker_last_name: user_doc.data().last_name,
          note_taker_gender:
            user_doc.data().gender?.toLowerCase() || "noChoice",
          note_taker_title: user_doc.data()?.title || "noChoice",
        };

        return {
          note_taker,
        };
      });

      const results = await Promise.all(promises);

      results.forEach(({ note_taker }) => {
        setProtocol((prevProtocol) => ({
          ...prevProtocol,

          note_taker: {
            ...prevProtocol.note_taker,
            note_taker_is_makler: true,
            note_taker_is_landlord: false,
            note_taker_first_name: note_taker.note_taker_first_name,
            note_taker_last_name: note_taker.note_taker_last_name,
            note_taker_gender: note_taker.note_taker_gender,
            note_taker_title: note_taker.note_taker_title,
          },
        }));
      });
    } catch (err) {
      console.error(err);
    }
  }
  const [selectedOption, setSelectedOption] = useState({
    label: "Protokollant/in neu aufnehmen",
    value: "new_note_taker",
  });
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setSelectedValue(option);
    if (option.value == "note_taker_from_landlord") {
      const requiredOwnerFields = [
        "landlord_salutation",
        "landlord_first_name",
        "landlord_last_name",
      ];
      const hasMissingField = requiredOwnerFields.some((field) => {
        return (
          !protocol.landlord ||
          !protocol.landlord[field] ||
          protocol.landlord[field] === ""
        );
      });
      if (hasMissingField) {
        swal({
          title: "HINWEIS",
          text: "Bitte füllen Sie vorher die Daten des Wohnungsgebers aus um diesen als Protokollanten zu übernehmen.",
          button: "OK",
        }).then((value) => {
          if (value) {
            window.scrollTo(0, 0);
          }
        });
      } else {
        setProtocol((prevProtocol) => ({
          ...prevProtocol,

          note_taker: {
            ...prevProtocol.note_taker,
            note_taker_is_landlord: true,
            note_taker_is_makler: false,
            note_taker_first_name: protocol.landlord.landlord_first_name,
            note_taker_last_name: protocol.landlord.landlord_last_name,
            note_taker_gender: protocol.landlord.landlord_salutation,
            note_taker_title: protocol.landlord.landlord_title,
          },
        }));
      }
    }
    if (option.value == "note_taker_from_current_user") {
      getNoteTakeInfo();
    }
    if (option.value == "new_note_taker") {
      setProtocol((prevProtocol) => ({
        ...prevProtocol,

        note_taker: {
          note_taker_is_landlord: false,
          note_taker_is_makler: false,
          note_taker_first_name: null,
          note_taker_last_name: null,
          note_taker_gender: null,
          note_taker_title: null,
        },
      }));
    }

    handleClose();
  };
  const CustomDropdown = ({ options }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(options[0]);

    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
      setSelectedOption(option);
      setSelectedValue(option);
      if (option.value == "note_taker_from_landlord") {
        const requiredOwnerFields = [
          "landlord_salutation",
          "landlord_first_name",
          "landlord_last_name",
        ];
        const hasMissingField = requiredOwnerFields.some((field) => {
          return (
            !protocol.landlord ||
            !protocol.landlord[field] ||
            protocol.landlord[field] === ""
          );
        });
        if (hasMissingField) {
          swal({
            title: "HINWEIS",
            text: "Bitte füllen Sie vorher die Daten des Wohnungsgebers aus um diesen als Protokollanten zu übernehmen.",
            button: "OK",
          }).then((value) => {
            if (value) {
              window.scrollTo(0, 0);
            }
          });
        } else {
          setProtocol((prevProtocol) => ({
            ...prevProtocol,

            note_taker: {
              ...prevProtocol.note_taker,
              note_taker_is_landlord: true,
              note_taker_is_makler: false,
              note_taker_first_name: protocol.landlord.landlord_first_name,
              note_taker_last_name: protocol.landlord.landlord_last_name,
              note_taker_gender: protocol.landlord.landlord_salutation,
              note_taker_title: protocol.landlord.landlord_title,
            },
          }));
        }
      }
      if (option.value == "note_taker_from_current_user") {
        getNoteTakeInfo();
      }
      if (option.value == "new_note_taker") {
        setProtocol((prevProtocol) => ({
          ...prevProtocol,

          note_taker: {
            note_taker_is_landlord: false,
            note_taker_is_makler: false,
            note_taker_first_name: null,
            note_taker_last_name: null,
            note_taker_gender: null,
            note_taker_title: null,
          },
        }));
      }

      setIsOpen(false);
    };
    return (
      <>
      {loadPdf == true && (
        <div className="overlay">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            id="loadBox"
          >
            <RotatingLines
              width={50}
              height={50}
              strokeColor="#fff"
              visible={true}
            />
            <div id="loaderDiv">
              <div className="overlay-message">
                Bitte bleiben Sie auf dieser Seite und warten Sie, bis der
                Prozess beendet wird!
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="custom-dropdown">
        
        <div className="selected-option" onClick={toggleDropdown}>
          {selectedValue.label !== ""
            ? selectedValue.label
            : selectedOption.label}
          <KeyboardArrowDownIcon />
        </div>
        
        {isOpen && (
          <div className="options">
            {options.map((option, index) => (
              <div
                key={option.value}
                className="option"
                value={
                  option.value === selectedValue.value
                    ? option.value
                    : selectedOption.value
                }
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
      </>
    );
  };
  const correctDefect = (defect) => {
    setDeffectIds((prevDeffectIds) => {
      if (prevDeffectIds) {
        // Check if deffect exists
        const existingDefectIndex = prevDeffectIds.findIndex(
          (item) => item.defect.id === defect.id
        );
        if (existingDefectIndex > -1) {
          prevDeffectIds[existingDefectIndex].action = "corrected";
          return [...prevDeffectIds];
        } else {
          return [...prevDeffectIds, { defect, action: "corrected" }];
        }
      } else {
        return [{ defect, action: "corrected" }];
      }
    });
  };
  const handleOk = useCallback(
    (e, index, i) => {
      setStatus(true);
      setShowStatus(false);
      const roomDeffect = state?.protocol_rooms[index].room_deffect;
      const newDefect = roomDeffect[i];
      correctDefect(newDefect);
      const now_date = new Date();
      setProtocol((prevProtocol) => {
        const roomImage = prevProtocol.protocol_rooms[index].room_deffect[i];
        const updatedRoom = {
          ...roomImage,
          ["controle_corrected"]: true,
          updated_at: {
            seconds: Math.floor(now_date.getTime() / 1000),
            nanoseconds: (now_date.getTime() % 1000) * 1e6,
          },
        };
        prevProtocol.protocol_rooms[index].room_deffect[i] = updatedRoom;
        return {
          ...prevProtocol,
          protocol_rooms: prevProtocol.protocol_rooms,
        };
      });
    },
    [state]
  );
  const incorrectDefect = (defect) => {
    setDeffectIds((prevDeffectIds) => {
      if (prevDeffectIds) {
        // Check if deffect exists
        const existingDefectIndex = prevDeffectIds.findIndex(
          (item) => item.defect.id === defect.id
        );
        if (existingDefectIndex > -1) {
          prevDeffectIds[existingDefectIndex].action = "incorrected";
          return [...prevDeffectIds];
        } else {
          return [...prevDeffectIds, { defect, action: "incorrected" }];
        }
      } else {
        return [{ defect, action: "incorrected" }];
      }
    });
  };

  const handleMangel = useCallback(
    (e, index, i) => {
      setStatus(false);
      setShowStatus(false);
      const roomDeffect = state.protocol_rooms[index].room_deffect;
      incorrectDefect(roomDeffect[i]);
      setProtocol((prevProtocol) => {
        const roomImage = prevProtocol.protocol_rooms[index].room_deffect[i];
        const updatedRoom = {
          ...roomImage,
          ["controle_corrected"]: false,
          updated_at: {
            seconds: Math.floor(now_date.getTime() / 1000),
            nanoseconds: (now_date.getTime() % 1000) * 1e6,
          },
        };
        prevProtocol.protocol_rooms[index].room_deffect[i] = updatedRoom;
        return {
          ...prevProtocol,
          protocol_rooms: prevProtocol.protocol_rooms,
        };
      });
    },
    [state]
  );

  function handleChangeComment(name, value, index, i = null) {
    if (i !== null) {
      setProtocol((prevProtocol) => {
        const roomDeffect = prevProtocol.protocol_rooms[index].room_deffect[i];
        const updatedRoom = {
          ...roomDeffect,
          [name]: value,
        };
        prevProtocol.protocol_rooms[index].room_deffect[i] = updatedRoom;
        return {
          ...prevProtocol,
          protocol_rooms: prevProtocol.protocol_rooms,
        };
      });
    } else {
      setProtocol((prevProtocol) => {
        const room = prevProtocol.protocol_rooms[index];
        const updatedRoom = { ...room, [name]: value };
        prevProtocol.protocol_rooms[index] = updatedRoom;
        return {
          ...prevProtocol,
          protocol_rooms: prevProtocol.protocol_rooms,
        };
      });
    }
  }

  const [counters, setCounters] = useState(
    Array(protocol?.protocol_keys?.length).fill(0)
  );

  const CompteuraddKey = useCallback(
    (e, s) => {
      let myBasicInt;
      if (
        protocolToShow.protocol_keys[s].key_number !== "" &&
        protocolToShow.protocol_keys[s].key_number !== null
      ) {
        myBasicInt = parseInt(protocolToShow.protocol_keys[s].key_number, 10);
        const numberKeysReturned =
          protocolToShow.protocol_keys[s].number_keys_returned_in_main_protocol;
        const myInt =
          numberKeysReturned !== null ? parseInt(numberKeysReturned, 10) : 0;
        let newINT = myInt + 1;
        // let max = myInt + counters[s];
        let max =
          myInt +
          (protocolToShow.protocol_keys[s].number_keys_returned !== NaN
            ? protocolToShow.protocol_keys[s].number_keys_returned
            : 0);

        if (max != myBasicInt) {
          setCounters((prevCounters) => {
            const updatedCounters = [...prevCounters];
            updatedCounters[s] = updatedCounters[s] + 1;
            return updatedCounters;
          });
          if (
            window.location.pathname.startsWith("/app/update_protocolcontrol")
          ) {
            let ReturnedKey =
              protocol.protocol_keys[s].number_keys_returned + 1;
            setProtocol((prevProtocol) => {
              const counterKey = prevProtocol.protocol_keys[s];
              const updatedKey = {
                ...counterKey,
                ["number_keys_returned"]: ReturnedKey,
              };
              prevProtocol.protocol_keys[s] = updatedKey;
              return {
                ...prevProtocol,
                protocol_keys: prevProtocol.protocol_keys,
              };
            });
          } else {
            setProtocol((prevProtocol) => {
              const counterKey = prevProtocol.protocol_keys[s];
              const updatedKey = {
                ...counterKey,
                ["number_keys_returned"]: counters[s] + 1,
              };
              prevProtocol.protocol_keys[s] = updatedKey;
              return {
                ...prevProtocol,
                protocol_keys: prevProtocol.protocol_keys,
              };
            });
          }
        }
      }
    },
    [counters, protocolToShow]
  );

  const CompteurRemoveKey = useCallback(
    (e, s) => {
      if (
        window.location.pathname.startsWith("/app/protocolcontrol") ||
        window.location.pathname.startsWith("/app/update_protocolcontrol")
      ) {
        const myInt = parseInt(
          protocolToShow?.protocol_keys[s]
            ?.number_keys_returned_in_main_protocol,
          10
        );
        let newINT = myInt - 1;
        if (protocol.protocol_keys[s].number_keys_returned > 0) {
          setCounters((prevCounters) => {
            const updatedCounters = [...prevCounters];
            updatedCounters[s] = updatedCounters[s] - 1;
            return updatedCounters;
          });
          if (
            window.location.pathname.startsWith("/app/update_protocolcontrol")
          ) {
            let ReturnedKey =
              protocol.protocol_keys[s].number_keys_returned - 1;
            setProtocol((prevProtocol) => {
              const counterKey = prevProtocol.protocol_keys[s];
              const updatedKey = {
                ...counterKey,
                ["number_keys_returned"]: ReturnedKey,
              };
              prevProtocol.protocol_keys[s] = updatedKey;
              return {
                ...prevProtocol,
                protocol_keys: prevProtocol.protocol_keys,
              };
            });
          } else {
            setProtocol((prevProtocol) => {
              const counterKey = prevProtocol.protocol_keys[s];
              const updatedKey = {
                ...counterKey,
                ["number_keys_returned"]: counters[s] - 1,
              };
              prevProtocol.protocol_keys[s] = updatedKey;
              return {
                ...prevProtocol,
                protocol_keys: prevProtocol.protocol_keys,
              };
            });
          }
        }
      }
    },
    [counters, protocolToShow]
  );

  function handleChangeKey(name, value, index) {
    setProtocol((prevProtocol) => {
      const myKey = prevProtocol.protocol_keys[index];
      const updatedKey = { ...myKey, [name]: value };
      prevProtocol.protocol_keys[index] = updatedKey;
      return {
        ...prevProtocol,
        protocol_keys: prevProtocol.protocol_keys,
      };
    });
  }

  const onSubmit = () => {
    const alert = swalAlert(handleCancel, handleThirdButton, handleConfirm);
    setAlert(alert);
  };

  const handleCancel = () => {
    setAlert(null);
  };

  const handleThirdButton = () => {
    setAlert(null);
    history.push("/app/overview?type=protocol");
  };
  const updateDeffectV2 = async () => {
    for (const { defect, action } of DeffectIds) {
      try {
        // Check if the defect has been corrected
        if (action === "incorrected") {
          // Remove the Deffect Correction
          const result = await deffectService.Deffect_InCorrected(
            defect.id,
            true
          );
          if (result) {
            console.log(`Defect with ID ${defect.id} has been marked as incorrect.`);
          } else {
            console.log(
              `Failed to mark defect with ID ${defect.id} as incorrect.`
            );
          }
        } else if (action === "corrected") {
          // Correct the Deffect
          const result = await deffectService.Deffect_Corrected(
            defect.id,
            true
          );
          if (result) {
            console.log(`Defect with ID ${defect.id} has been corrected.`);
          } else {
            console.log(`Failed to correct defect with ID ${defect.id}.`);
          }
        } else {
          console.log(
            `Failed to update defect with ID ${defect.id} action didn't found.`
          );
        }
      } catch (error) {
        console.error(`Error updating defect with ID ${defect.id}:`, error);
      }
    }
  };
  const handleConfirm = async () => {
    setAlert(null);
    if (DeffectIds && DeffectIds.length > 0) {
      await updateDeffectV2();
    }
    let newProtocol;
    if (window.location.pathname.startsWith("/app/protocolcontrol")) {
      newProtocol = protocolService.initProtocolControl(
        protocol,
        protocolToShow.company_id
      );
    } else if (
      window.location.pathname.startsWith("/app/update_protocolcontrol")
    ) {
      newProtocol = protocolService.updateProtocolControl(
        protocol,
        protocolToShow.company_id
      );
    }
    // Mise à jour des protocol_rooms avec les id_protocol et id_room
    const updatedProtocolRooms = {
      ...newProtocol,
      protocol_rooms: protocol.protocol_rooms.map((room) => ({
        ...room,
        room_deffect: room.room_deffect
          ? room.room_deffect.map((deffect) => ({
              ...deffect,
              id_protocol: newProtocol.id,
              id_room: room.id,
            }))
          : [],
        room_image: room.room_image
          ? room.room_image.map((image) => ({
              ...image,
              protocol_id: newProtocol.id,
              room_id: room.id,
              id: uuidv4(),
            }))
          : [],
      })),
    };
    try {
      protocolService.createProtocol(updatedProtocolRooms).then((result) => {
        swal({
          title: "HINWEIS",
          text: "Ihr Dokument wurde gespeichert und kann zur Weiterverarbeitung in der App aufgerufen werden. ",
          button: "OK",
        }).then((result) => {
          if (result) {
            history.push("/app/protocol/list");
          }
        });
      });
    } catch (err) {
      console.error(err);
    }
  };

  function formatReason(reason) {
    if (reason == "notPresent") return "Nicht anwesend";
    else if (reason == "refuseToSign") return "Verweigert Unterschrift";
    else return "Keine Unterschrift";
  }

  const handleClickTextfield = (
    type,
    value,
    level,
    index = null,
    placeholder,
    i,
    realname
  ) => {
    setPlaceHolder(placeholder);
    setIndex(index);

    setLevel(level);
    setvalue(value);
    setType(type);
    setShow(true);
    setSecondIndex(i);
    if (realname) setRealname(realname);
  };
  return (
    <>
      <div className="layoutstepsbar" style={{ height: "70px" }}>
        <div className="row layoutcontent full-height mr-0 ml-0">
          <div className="einzug width">
            <HouseIcon
              className="RaumDetailsIcon"
              style={{
                width: "40px",
                height: "40px",
              }}
            />
            <h3>Protokoll Nachbegehung</h3>
          </div>
          <div className="Weiter align-end-button">
            <button
              onClick={() => cancel()}
              className="WeiterButton"
              type="submit"
            >
              Zurück
              <ArrowForwardIosIcon
                className="icon-registrieren"
                sx={{ fontSize: 16 }}
              />
            </button>
          </div>
        </div>
      </div>
      <div className="outer-container paading">
        <div className="card-style">
          <div className="SonCard">
            <div className="neuer withoutBorderRadius">
              <img className="NeuerIcon" src={ValidIcon} alt="abschluss Icon" />
              <h3 className="Header">
                {" "}
                {protocol?.protocol_type == null && "Kein Angabe"}
                {protocol?.protocol_type == "move" && "Einzug"}
                {protocol?.protocol_type == "moveIn" && "Auszug"}
                {protocol?.protocol_type == "moveInAndExit" &&
                  "Ein- und Auszug"}
                protokoll
              </h3>
            </div>
            <div
              className="card-shadow NewOverviewDocument"
              style={{ marginBottom: "2rem" }}
            >
              <div className="after-cards">
                <div className="formAbschlussOvwerview">
                  <div className="LigneSchlüssel Adresse-RenviernungsdatumOvwerview w-100">
                    <div className="float-left">
                      <div className="LigneWohnungsdatenOvwerview protocol-Type">
                        <span>Protokollant:</span>
                        <span className="Header-content">
                          {protocolToShow?.note_taker?.note_taker_gender ==
                            null && "Anrede fehlt "}
                          {protocolToShow?.note_taker?.note_taker_gender ==
                            "noChoice" && "Keine Angabe "}
                          {protocolToShow?.note_taker?.note_taker_gender ==
                            "male" && "Herr "}
                          {protocolToShow?.note_taker?.note_taker_gender ==
                            "female" && "Frau "}
                          {protocolToShow?.note_taker?.note_taker_title ==
                            "noChoice" && "Keine Angabe "}
                          {protocolToShow?.note_taker?.note_taker_title ==
                            "dr" && "Dr. "}
                          {protocolToShow?.note_taker?.note_taker_title ==
                            "prof" && "Prof. "}
                          {protocolToShow?.note_taker?.note_taker_title ==
                            "ing" && "Ing. "}
                          {protocolToShow?.note_taker?.note_taker_first_name ==
                            null &&
                            protocolToShow?.note_taker?.note_taker_last_name ==
                              null && <span>Name und Vorname fehlt</span>}
                          {(protocolToShow?.note_taker?.note_taker_first_name ==
                            null ||
                            protocolToShow?.note_taker?.note_taker_first_name ==
                              "") &&
                            protocolToShow?.note_taker?.note_taker_last_name !=
                              null && (
                              <>
                                {protocolToShow?.note_taker
                                  ?.note_taker_first_name
                                  ? protocolToShow?.note_taker
                                      ?.note_taker_first_name
                                  : " Vorname fehlt "}
                              </>
                            )}
                          {protocolToShow?.note_taker?.note_taker_first_name !=
                            null &&
                            (protocolToShow?.note_taker?.note_taker_last_name ==
                              null ||
                              protocolToShow?.note_taker
                                ?.note_taker_last_name == "") && (
                              <>
                                {protocolToShow?.note_taker
                                  ?.note_taker_last_name
                                  ? protocolToShow?.note_taker
                                      ?.note_taker_last_name
                                  : " Nachname fehlt "}
                              </>
                            )}
                          {protocolToShow?.note_taker?.note_taker_first_name !=
                            null &&
                            protocolToShow?.note_taker?.note_taker_last_name !=
                              null && (
                              <>
                                {
                                  protocolToShow?.note_taker
                                    ?.note_taker_first_name
                                }{" "}
                                {
                                  protocolToShow?.note_taker
                                    ?.note_taker_last_name
                                }
                              </>
                            )}
                        </span>
                      </div>
                    </div>
                    <div className="float-right">
                      <span>Datum der Übergabe</span>
                      <div className="text-style">
                        <img
                          className="auftrageCalendarIcon"
                          src={CalenderIcon}
                          alt="Auftrage Calendar Icon"
                          style={{
                            marginRight: "5%",
                            verticalAlign: "text-bottom",
                          }}
                        />
                        <span>
                          {protocol?.date_of_hand_over
                            ? protocol?.date_of_hand_over
                            : "Angabe fehlt"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="hrOvwerview" />
                <div className="formAbschlussOvwerview">
                  <div className="LigneWohnungsdatenOvwerview">
                    <span>Wohnungsdaten</span>
                  </div>
                  <div className="LigneSchlüssel Adresse-RenviernungsdatumOvwerview w-100">
                    <div className="float-left">
                      <span>Adresse</span>
                      <div className="mb-2 text-style">
                        <span>
                          {protocolToShow?.object?.address?.object_street
                            ? protocolToShow?.object?.address?.object_street
                            : "Straße fehlt"}
                          ,{" "}
                          {protocolToShow?.object?.address?.object_house_nbr
                            ? protocolToShow?.object?.address?.object_house_nbr
                            : "Hausnummer fehlt"}
                        </span>
                        <br />
                        <span>
                          {protocolToShow?.object?.address?.object_plz
                            ? protocolToShow?.object?.address?.object_plz
                            : "PLZ fehlt"}
                          ,{" "}
                          {protocolToShow?.object?.address?.object_city
                            ? protocolToShow?.object?.address?.object_city
                            : "Ort fehlt"}
                        </span>
                      </div>
                      {protocolToShow?.object?.object_date_of_renovation && (
                        <div className=" clearLeft">
                          <span>Renovierungsdatum</span>
                          <div className="mb-2 text-style">
                            <span>
                              {protocolToShow?.object?.object_date_of_renovation
                                ? protocolToShow?.object
                                    ?.object_date_of_renovation
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {protocolToShow?.object?.address.object_address_note && (
                    <div className="LigneAdresszustazOvwerview w-100">
                      <div className="float-left">
                        <span>Adresszusatz/Lage oder Wohnungsnummer</span>
                        <div className="mb-2 text-style">
                          <span>
                            {protocolToShow?.object?.address.object_address_note
                              ? protocolToShow?.object?.address
                                  .object_address_note
                              : "Angabe fehlt"}
                            ,{" "}
                            {protocolToShow?.object?.address?.object_position
                              ? protocolToShow?.object?.address?.object_position
                              : "Angabe fehlt"}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <hr className="hrOvwerview" />
                <div className="formAbschlussOvwerview">
                  <div className="LigneWohnungsdatenOvwerview">
                    <span>Eigentümer</span>
                  </div>
                  {protocolToShow?.owner_is_landlord == true ? (
                    <>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview mb-2">
                        <span
                          className="Header-content"
                          style={{ fontSize: "18px" }}
                        >
                          {protocolToShow?.landlord?.landlord_company_name
                            ? protocolToShow?.landlord?.landlord_company_name
                            : "Unternehmensname fehlt"}
                        </span>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <div className="mb-2 text-style">
                          <span>
                            {(protocolToShow?.landlord?.landlord_salutation ==
                              null &&
                              protocolToShow?.landlord?.landlord_salutation ==
                                "") ||
                              (protocolToShow?.landlord?.landlord_salutation ==
                                null &&
                                "Anrede fehlt ")}
                            {protocolToShow?.landlord?.landlord_salutation ==
                              "noChoice" && "Keine Angabe "}
                            {protocolToShow?.landlord?.landlord_salutation ==
                              "male" && "Herr "}
                            {protocolToShow?.landlord?.landlord_salutation ==
                              "female" && "Frau "}
                            {protocolToShow?.landlord?.landlord_title ==
                              "noChoice" && "Keine Angabe "}
                            {protocolToShow?.landlord?.landlord_title == "dr" &&
                              "Dr. "}
                            {protocolToShow?.landlord?.landlord_title ==
                              "prof" && "Prof. "}
                            {protocolToShow?.landlord?.landlord_title ==
                              "ing" && "Ing. "}
                            {protocolToShow?.landlord?.landlord_first_name ==
                              null &&
                              protocolToShow?.landlord?.landlord_last_name ==
                                null && <span>Name und Vorname fehlt</span>}
                            {(protocolToShow?.landlord?.landlord_first_name ==
                              null ||
                              protocolToShow?.landlord?.landlord_first_name ==
                                "") &&
                              protocolToShow?.landlord?.landlord_last_name !=
                                null &&
                              " Vorname fehlt "}
                            {protocolToShow?.landlord?.landlord_first_name !=
                              null &&
                              (protocolToShow?.landlord?.landlord_last_name ==
                                null ||
                                protocolToShow?.landlord?.landlord_last_name ==
                                  "") &&
                              " Nachname fehlt "}
                            {protocolToShow?.landlord?.landlord_first_name !=
                              null &&
                              protocolToShow?.landlord?.landlord_last_name !=
                                null && (
                                <>
                                  {
                                    protocolToShow?.landlord
                                      ?.landlord_first_name
                                  }{" "}
                                  {protocolToShow?.landlord?.landlord_last_name}
                                </>
                              )}
                          </span>
                          <span>
                            {protocolToShow?.landlord?.address?.landlord_street
                              ? protocolToShow?.landlord?.address
                                  ?.landlord_street
                              : "Straße fehlt"}
                            ,{" "}
                            {protocolToShow?.landlord?.address
                              ?.landlord_house_number
                              ? protocolToShow?.landlord?.address
                                  ?.landlord_house_number
                              : "Hausnummer fehlt"}
                          </span>
                          <span>
                            {protocolToShow?.landlord?.address?.landlord_plz
                              ? protocolToShow?.landlord?.address?.landlord_plz
                              : "PLZ fehlt"}
                            ,{" "}
                            {protocolToShow?.landlord?.address?.landlord_city
                              ? protocolToShow?.landlord?.address?.landlord_city
                              : "Ort fehlt"}
                          </span>
                        </div>
                      </div>
                      <div className="Adresse-RenviernungsdatumOvwerview">
                        <span style={{ fontSize: "16px" }}>
                          Art des Wohnungsgebers
                        </span>
                        <div className="mb-2">
                          {(protocolToShow?.landlord?.landlord_type == null ||
                            protocolToShow?.landlord?.landlord_type == "") && (
                            <span>Angabe fehlt</span>
                          )}
                          {protocolToShow?.landlord?.landlord_type ==
                            "noChoice" && (
                            <span style={{ color: "#000000" }}>
                              Keine Angabe
                            </span>
                          )}
                          {protocolToShow?.landlord?.landlord_type ==
                            "company" && (
                            <span style={{ color: "#000000" }}>
                              Gesellschaft
                            </span>
                          )}
                          {protocolToShow?.landlord?.landlord_type ==
                            "private" && (
                            <span style={{ color: "#000000" }}>Privat</span>
                          )}
                        </div>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span style={{ fontSize: "16px" }}>E-mail:</span>
                        <div className="mb-2 text-style">
                          <span>
                            {" "}
                            {protocolToShow?.landlord?.landlord_email
                              ? protocolToShow?.landlord?.landlord_email
                              : "Angabe fehlt"}
                          </span>
                        </div>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span style={{ fontSize: "16px" }}>Festnetz:</span>
                        <div className="mb-2 text-style">
                          <span>
                            {" "}
                            {protocolToShow?.landlord?.landlord_phone
                              ? protocolToShow?.landlord?.landlord_phone
                              : "Angabe fehlt"}
                          </span>
                        </div>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span style={{ fontSize: "16px" }}>Mobilnummer:</span>
                        <div className="mb-2 text-style">
                          <span>
                            {" "}
                            {protocolToShow?.landlord?.landlord_mobile
                              ? protocolToShow?.landlord?.landlord_mobile
                              : "Angabe fehlt"}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span
                          className="Header-content"
                          style={{ textTransform: "capitalize" }}
                        >
                          {protocolToShow?.owner?.owner_company_name
                            ? protocolToShow?.owner?.owner_company_name
                            : "Unternehmensname fehlt"}
                        </span>
                      </div>
                      <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                        <div>
                          <span>
                            {protocolToShow?.owner?.owner_salutation == null &&
                              "Anrede fehlt "}
                            {protocolToShow?.owner?.owner_salutation ==
                              "noChoice" && "Keine Angabe "}
                            {protocolToShow?.owner?.owner_salutation ==
                              "male" && "Herr "}
                            {protocolToShow?.owner?.owner_salutation ==
                              "female" && "Frau "}
                            {protocolToShow?.owner?.owner_salutation ==
                              "association" && "WEG "}
                            {protocolToShow?.owner?.owner_title == "noChoice" &&
                              "Keine Angabe "}
                            {protocolToShow?.owner?.owner_title == "dr" &&
                              "Dr. "}
                            {protocolToShow?.owner?.owner_title == "prof" &&
                              "Prof. "}
                            {protocolToShow?.owner?.owner_title == "ing" &&
                              "Ing. "}
                            {protocolToShow?.owner?.owner_first_name == null &&
                              protocolToShow?.owner?.owner_last_name ==
                                null && <span>Name und Vorname fehlt</span>}
                            {(protocolToShow?.owner?.owner_first_name == null ||
                              protocolToShow?.owner?.owner_first_name == "") &&
                              protocolToShow?.owner?.owner_last_name != null &&
                              " Vorname fehlt "}
                            {protocolToShow?.owner?.owner_first_name != null &&
                              (protocolToShow?.owner?.owner_last_name == null ||
                                protocolToShow?.owner?.owner_last_name == "") &&
                              " Nachname fehlt "}
                            {protocolToShow?.owner?.owner_first_name != null &&
                              protocolToShow?.owner?.owner_last_name !=
                                null && (
                                <>
                                  {protocolToShow?.owner?.owner_first_name}{" "}
                                  {protocolToShow?.owner?.owner_last_name}
                                </>
                              )}
                          </span>
                          <br />
                          <span>
                            {protocolToShow?.owner?.address?.owner_street
                              ? protocolToShow?.owner?.address?.owner_street
                              : "Straße fehlt"}
                            ,
                            {protocolToShow?.owner?.address?.owner_house_number
                              ? protocolToShow?.owner?.address
                                  ?.owner_house_number
                              : "Hausnummer fehlt"}
                          </span>
                          <br />
                          <span>
                            {protocolToShow?.owner?.address?.owner_plz
                              ? protocolToShow?.owner?.address?.owner_plz
                              : "PLZ fehlt"}
                            ,{" "}
                            {protocolToShow?.owner?.address?.owner_city
                              ? protocolToShow?.owner?.address?.owner_city
                              : "Ort fehlt"}
                          </span>
                        </div>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span style={{ fontSize: "16px" }}>
                          Art des Eigentümers
                        </span>
                        <div className="mb-2 text-style">
                          {protocolToShow?.owner?.owner_Type == null && (
                            <span>Angabe fehlt</span>
                          )}
                          {protocolToShow?.owner?.owner_Type == "noChoice" && (
                            <span style={{ color: "#000000" }}>
                              Keine Angabe
                            </span>
                          )}
                          {protocolToShow?.owner?.owner_Type == "company" && (
                            <span style={{ color: "#000000" }}>
                              Gesellschaft
                            </span>
                          )}
                          {protocolToShow?.owner?.owner_Type == "private" && (
                            <span style={{ color: "#000000" }}>Privat</span>
                          )}
                        </div>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span style={{ fontSize: "16px" }}>E-mail:</span>
                        <div className="mb-2 text-style">
                          <span>
                            {" "}
                            {protocolToShow?.owner?.owner_email
                              ? protocolToShow?.owner?.owner_email
                              : "Angabe fehlt"}
                          </span>
                        </div>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span style={{ fontSize: "16px" }}>Festnetz:</span>
                        <div className="mb-2 text-style">
                          <span>
                            {" "}
                            {protocolToShow?.owner?.owner_phone
                              ? protocolToShow?.owner?.owner_phone
                              : "Angabe fehlt"}
                          </span>
                        </div>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span style={{ fontSize: "16px" }}>Mobilnummer:</span>
                        <div className="mb-2 text-style">
                          <span>
                            {" "}
                            {protocolToShow?.owner?.owner_mobile
                              ? protocolToShow?.owner?.owner_mobile
                              : "Angabe fehlt"}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <hr className="hrOvwerview" />
                <div className="formAbschlussOvwerview">
                  <div className="LigneWohnungsdatenOvwerview">
                    <span>Wohnungsabnehmer</span>
                  </div>
                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                    <span
                      className="Header-content"
                      style={{ textTransform: "capitalize" }}
                    >
                      {protocolToShow?.landlord?.landlord_company_name
                        ? protocolToShow?.landlord?.landlord_company_name
                        : "Unternehmensname fehlt"}
                    </span>
                  </div>
                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                    <div className="mb-2 text-style">
                      <span>
                        {protocolToShow?.landlord?.landlord_salutation ==
                          null && "Anrede fehlt "}
                        {protocolToShow?.landlord?.landlord_salutation ==
                          "noChoice" && "Keine Angabe "}
                        {protocolToShow?.landlord?.landlord_salutation ==
                          "male" && "Herr "}
                        {protocolToShow?.landlord?.landlord_salutation ==
                          "female" && "Frau "}
                        {protocolToShow?.landlord?.landlord_title ==
                          "noChoice" && "Keine Angabe "}
                        {protocolToShow?.landlord?.landlord_title == "dr" &&
                          "Dr. "}
                        {protocolToShow?.landlord?.landlord_title == "prof" &&
                          "Prof. "}
                        {protocolToShow?.landlord?.landlord_title == "ing" &&
                          "Ing. "}
                        {protocolToShow?.landlord?.landlord_first_name ==
                          null &&
                          protocolToShow?.landlord?.landlord_last_name ==
                            null && <span>Name und Vorname fehlt</span>}
                        {(protocolToShow?.landlord?.landlord_first_name ==
                          null ||
                          protocolToShow?.landlord?.landlord_first_name ==
                            "") &&
                          protocolToShow?.landlord?.landlord_last_name !=
                            null && (
                            <>
                              {protocolToShow?.landlord?.landlord_first_name
                                ? protocolToShow?.landlord?.landlord_first_name
                                : " Vorname fehlt "}
                            </>
                          )}
                        {protocolToShow?.landlord?.landlord_first_name !=
                          null &&
                          (protocolToShow?.landlord?.landlord_last_name ==
                            null ||
                            protocolToShow?.landlord?.landlord_last_name ==
                              "") && (
                            <>
                              {protocolToShow?.landlord?.landlord_last_name
                                ? protocolToShow?.landlord?.landlord_last_name
                                : " Nachname fehlt "}
                            </>
                          )}
                        {protocolToShow?.landlord?.landlord_first_name !=
                          null &&
                          protocolToShow?.landlord?.landlord_last_name !=
                            null && (
                            <>
                              {protocolToShow?.landlord?.landlord_first_name}{" "}
                              {protocolToShow?.landlord?.landlord_last_name}
                            </>
                          )}
                      </span>
                      <span>
                        {protocolToShow?.landlord?.address?.landlord_street
                          ? protocolToShow?.landlord?.address?.landlord_street
                          : "Straße fehlt"}
                        ,{" "}
                        {protocolToShow?.landlord?.address
                          ?.landlord_house_number
                          ? protocolToShow?.landlord?.address
                              ?.landlord_house_number
                          : "Hausnummer fehlt"}
                      </span>
                      <span>
                        {protocolToShow?.landlord?.address?.landlord_plz
                          ? protocolToShow?.landlord?.address?.landlord_plz
                          : "PLZ fehlt"}
                        ,{" "}
                        {protocolToShow?.landlord?.address?.landlord_city
                          ? protocolToShow?.landlord?.address?.landlord_city
                          : "Ort fehlt"}
                      </span>
                    </div>
                  </div>
                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                    <span style={{ fontSize: "16px" }}>
                      Art des Eigentümers
                    </span>
                    <div className="mb-2 text-style">
                      {(protocolToShow?.landlord?.landlord_type == null ||
                        protocolToShow?.landlord?.landlord_type == "") && (
                        <span>Angabe fehlt</span>
                      )}
                      {protocolToShow?.landlord?.landlord_type ==
                        "noChoice" && (
                        <span style={{ color: "#000000" }}>Keine Angabe</span>
                      )}
                      {protocolToShow?.landlord?.landlord_type == "company" && (
                        <span style={{ color: "#000000" }}>Gesellschaft</span>
                      )}
                      {protocolToShow?.landlord?.landlord_type == "private" && (
                        <span style={{ color: "#000000" }}>Privat</span>
                      )}
                    </div>
                  </div>
                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                    <span style={{ fontSize: "16px" }}>E-mail:</span>
                    <div className="mb-2 text-style">
                      <span>
                        {" "}
                        {protocolToShow?.landlord?.landlord_email
                          ? protocolToShow?.landlord?.landlord_email
                          : "Angabe fehlt"}
                      </span>
                    </div>
                  </div>
                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                    <span style={{ fontSize: "16px" }}>Festnetz:</span>
                    <div className="mb-2 text-style">
                      <span>
                        {" "}
                        {protocolToShow?.landlord?.landlord_phone
                          ? protocolToShow?.landlord?.landlord_phone
                          : "Angabe fehlt"}
                      </span>
                    </div>
                  </div>
                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                    <span style={{ fontSize: "16px" }}>Mobilnummer:</span>
                    {protocolToShow?.landlord?.landlord_mobile && (
                      <div className="mb-2 text-style">
                        <span>
                          {" "}
                          {protocolToShow?.landlord?.landlord_mobile
                            ? protocolToShow?.landlord?.landlord_mobile
                            : "Angabe fehlt"}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <hr className="hrOvwerview" />
                {/* mieter  renter*/}
                <div className="formAbschlussOvwerview">
                  <div className="LigneWohnungsdatenOvwerview">
                    <span>Mieter</span>
                  </div>
                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                    <span style={{ fontWeight: "bold", color: "#000000" }}>
                      {protocolToShow?.renter?.renter_salutation == null &&
                        "Anrede fehlt "}
                      {protocolToShow?.renter?.renter_salutation ==
                        "noChoice" && "Keine Angabe "}
                      {protocolToShow?.renter?.renter_salutation == "male" &&
                        "Herr "}
                      {protocolToShow?.renter?.renter_salutation == "female" &&
                        "Frau "}
                      {formatTitle(protocolToShow?.renter?.renter_title)}
                      {protocolToShow?.renter?.renter_first_name == null &&
                        protocolToShow?.renter?.renter_last_name == null && (
                          <span>Name und Vorname fehlt</span>
                        )}
                      {(protocolToShow?.renter?.renter_first_name == null ||
                        protocolToShow?.renter?.renter_first_name == "") &&
                        protocolToShow?.renter?.renter_last_name != null && (
                          <>
                            {protocolToShow?.renter?.renter_first_name
                              ? protocolToShow?.renter?.renter_first_name
                              : " Vorname fehlt "}
                          </>
                        )}
                      {protocolToShow?.renter?.renter_first_name != null &&
                        (protocolToShow?.renter?.renter_last_name == null ||
                          protocolToShow?.renter?.renter_last_name == "") && (
                          <>
                            {protocolToShow?.renter?.renter_last_name
                              ? protocolToShow?.renter?.renter_last_name
                              : " Nachname fehlt "}
                          </>
                        )}
                      {protocolToShow?.renter?.renter_first_name != null &&
                        protocolToShow?.renter?.renter_last_name != null && (
                          <>
                            {protocolToShow?.renter?.renter_first_name}{" "}
                            {protocolToShow?.renter?.renter_last_name}
                          </>
                        )}
                    </span>
                    <span className="uppercase" style={{ fontSize: "16px" }}>
                      Geburtsdatum
                    </span>
                    <div className="mb-2 text-style">
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span style={{ color: "#000000" }}>
                          {protocolToShow?.renter?.renter_date_of_birth
                            ? protocolToShow?.renter?.renter_date_of_birth
                            : "Angabe fehlt"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                    <span style={{ fontSize: "16px" }}>E-mail:</span>
                    <div className="mb-2 text-style">
                      <span>
                        {" "}
                        {protocolToShow?.renter?.renter_email
                          ? protocolToShow?.renter?.renter_email
                          : "Angabe fehlt"}
                      </span>
                    </div>
                  </div>
                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                    <span style={{ fontSize: "16px" }}>Festnetz:</span>
                    <div className="mb-2 text-style">
                      <span>
                        {" "}
                        {protocolToShow?.renter?.renter_phone
                          ? protocolToShow?.renter?.renter_phone
                          : "Angabe fehlt"}
                      </span>
                    </div>
                  </div>
                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                    <span style={{ fontSize: "16px" }}>Mobilnummer:</span>
                    <div className="mb-2 text-style">
                      <span>
                        {" "}
                        {protocolToShow?.renter?.renter_mobile
                          ? protocolToShow?.renter?.renter_mobile
                          : "Angabe fehlt"}
                      </span>
                    </div>
                  </div>
                </div>
                {/* Vorherige Adresse */}
                {protocolToShow?.renter?.address
                  ?.renter_previous_new_adress && (
                  <div className="aftercardOvwerview pt-0 pb-0">
                    <div className="formAbschlussOvwerview">
                      <div className="LigneWohnungsdatenOvwerview">
                        <span>Vorherige Adresse</span>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span style={{ color: "#000000" }}>
                          {protocolToShow?.renter?.address?.renter_srteet
                            ? protocolToShow?.renter?.address?.renter_srteet
                            : "Straße fehlt"}
                          ,{" "}
                          {protocolToShow?.renter?.address?.renter_house_number
                            ? protocolToShow?.renter?.address
                                ?.renter_house_number
                            : "Hausnummer  fehlt"}
                        </span>
                        <div className="mb-2 text-style">
                          <span>
                            {protocolToShow?.renter?.address?.renter_plz
                              ? protocolToShow?.renter?.address?.renter_plz
                              : "PLZ fehlt"}
                            ,{" "}
                            {protocolToShow?.renter?.address?.renter_city
                              ? protocolToShow?.renter?.address?.renter_city
                              : "Ort fehlt"}
                          </span>
                        </div>
                      </div>
                      {protocolToShow?.renter?.address?.renter_address_note && (
                        <div className="LigneAdresse-RenviernungsdatumOvwerview">
                          <span style={{ fontSize: "16px" }}>Adresszusatz</span>
                          <div className="mb-2 text-style">
                            <span style={{ fontWeight: "bold" }}>
                              {protocolToShow?.renter?.address
                                ?.renter_address_note
                                ? protocolToShow?.renter?.address
                                    ?.renter_address_note
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <hr className="hrOvwerview" />
                  </div>
                )}
                {/* Weitere Mieter corenter */}
                {protocolToShow?.has_corenter && (
                  <>
                    <div className="aftercardOvwerview pt-0 pb-0">
                      <div className="formAbschlussOvwerview">
                        <div className="LigneWohnungsdatenOvwerview">
                          <span>Weitere Mieter</span>
                        </div>
                        <div className="LigneAdresse-RenviernungsdatumOvwerview">
                          <span
                            style={{ fontWeight: "bold", color: "#000000" }}
                          >
                            {protocolToShow?.coRenter?.salutaion == null &&
                              "Anrede fehlt "}
                            {protocolToShow?.coRenter?.salutaion ==
                              "noChoice" && "Keine Angabe "}
                            {protocolToShow?.coRenter?.salutaion == "male" &&
                              "Herr "}
                            {protocolToShow?.coRenter?.salutaion == "female" &&
                              "Frau "}
                            {formatTitle(protocolToShow?.coRenter?.title)}
                            {(protocolToShow?.coRenter?.first_name == null ||
                              protocolToShow?.coRenter?.first_name == "") &&
                              protocolToShow?.coRenter?.last_name != null && (
                                <>
                                  {protocolToShow?.coRenter?.first_name
                                    ? protocolToShow?.coRenter?.first_name
                                    : " Vorname fehlt "}
                                </>
                              )}
                            {protocolToShow?.coRenter?.first_name == null &&
                              protocolToShow?.coRenter?.last_name == null && (
                                <>{"Name und Vorname fehlt"}</>
                              )}
                            {protocolToShow?.coRenter?.first_name != null &&
                              (protocolToShow?.coRenter?.last_name == null ||
                                protocolToShow?.coRenter?.last_name == "") && (
                                <>
                                  {protocolToShow?.coRenter?.last_name
                                    ? protocolToShow?.coRenter?.last_name
                                    : " Nachname fehlt "}
                                </>
                              )}
                            {protocolToShow?.coRenter?.first_name != null &&
                              protocolToShow?.coRenter?.last_name != null && (
                                <>
                                  {protocolToShow?.coRenter?.first_name}{" "}
                                  {protocolToShow?.coRenter?.last_name}
                                </>
                              )}
                          </span>
                        </div>
                        <div className="LigneAdresse-RenviernungsdatumOvwerview">
                          <span style={{ fontSize: "16px" }}>E-mail:</span>
                          <div className="mb-2 text-style">
                            <span>
                              {" "}
                              {protocolToShow?.coRenter?.email
                                ? protocolToShow?.coRenter?.email
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        </div>
                        <div className="LigneAdresse-RenviernungsdatumOvwerview">
                          <span style={{ fontSize: "16px" }}>Festnetz:</span>
                          <div className="mb-2 text-style">
                            <span>
                              {" "}
                              {protocolToShow?.coRenter?.phone
                                ? protocolToShow?.coRenter?.phone
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        </div>
                        <div className="LigneAdresse-RenviernungsdatumOvwerview">
                          <span style={{ fontSize: "16px" }}>Mobilnummer:</span>
                          <div className="mb-2 text-style">
                            <span>
                              {" "}
                              {protocolToShow?.coRenter?.mobile
                                ? protocolToShow?.coRenter?.mobile
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {protocolToShow?.roommates &&
                  protocolToShow?.roommates.length > 0 && (
                    <>
                      {protocolToShow?.roommates.map((element, index) => {
                        return (
                          <div className="aftercardOvwerview pt-0 pb-0">
                            <div className="formAbschlussOvwerview">
                              <div className="LigneWohnungsdatenOvwerview">
                                <span className="uppercase">
                                  {index + 1}. Bewohner
                                </span>
                              </div>
                              <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#000000",
                                  }}
                                >
                                  {element.first_name} {element.last_name}
                                </span>
                              </div>
                            </div>
                            <hr className="hrOvwerview" />
                          </div>
                        );
                      })}
                    </>
                  )}
                {protocolToShow?.protocol_type == "moveInAndExit" && (
                  <>
                    <div className="aftercardOvwerview pt-0 pb-0">
                      <hr className="hrOvwerview" />
                      <div className="formAbschlussOvwerview">
                        <div className="LigneWohnungsdatenOvwerview">
                          <span>Vormieter</span>
                        </div>
                        <div className="LigneAdresse-RenviernungsdatumOvwerview">
                          <span
                            style={{ fontWeight: "bold", color: "#000000" }}
                            className=" mb-2"
                          >
                            {protocolToShow?.previous_renter
                              ?.previous_renter_salutation == null &&
                              "Anrede fehlt "}
                            {protocolToShow?.previous_renter
                              ?.previous_renter_salutation == "noChoice" &&
                              "Keine Angabe "}
                            {protocolToShow?.previous_renter
                              ?.previous_renter_salutation == "male" && "Herr "}
                            {protocolToShow?.previous_renter
                              ?.previous_renter_salutation == "female" &&
                              "Frau "}
                            {protocolToShow?.previous_renter
                              ?.previous_renter_title == "noChoice" &&
                              "Keine Angabe "}
                            {protocolToShow?.previous_renter
                              ?.previous_renter_title == "dr" && "Dr. "}
                            {protocolToShow?.previous_renter
                              ?.previous_renter_title == "prof" && "Prof. "}
                            {protocolToShow?.previous_renter
                              ?.previous_renter_title == "ing" && "Ing. "}
                            {protocolToShow?.previous_renter
                              ?.previous_renter_first_name
                              ? protocolToShow?.previous_renter
                                  ?.previous_renter_first_name + " "
                              : "Vorname fehlt"}
                            {protocolToShow?.previous_renter
                              ?.previous_renter_last_name
                              ? protocolToShow?.previous_renter
                                  ?.previous_renter_last_name
                              : "Nachname fehlt"}
                          </span>
                        </div>
                        <div className="LigneAdresse-RenviernungsdatumOvwerview">
                          <span>E-mail:</span>
                          <div className="mb-2 text-style">
                            <span>
                              {protocolToShow?.previous_renter
                                ?.previous_renter_email
                                ? protocolToShow?.previous_renter
                                    ?.previous_renter_email
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        </div>
                        <div className="LigneAdresse-RenviernungsdatumOvwerview">
                          <span className="uppercase">Festnetz:</span>
                          <div className="mb-2 text-style">
                            <span>
                              {" "}
                              {protocolToShow?.previous_renter
                                ?.previous_renter_phone_number
                                ? protocolToShow?.previous_renter
                                    ?.previous_renter_phone_number
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        </div>
                        <div className="LigneAdresse-RenviernungsdatumOvwerview">
                          <span style={{ fontSize: "16px" }}>Mobilnummer:</span>
                          <div className="mb-2 text-style">
                            <span>
                              {" "}
                              {protocolToShow?.previous_renter
                                ?.previous_renter_mobile_number
                                ? protocolToShow?.previous_renter
                                    ?.previous_renter_mobile_number
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {protocolToShow?.previous_renter?.address
                      ?.previous_renter_new_adress && (
                      <div className="aftercardOvwerview pt-0 pb-0">
                        <hr className="hrOvwerview" />
                        <div className="formAbschlussOvwerview">
                          <div className="LigneWohnungsdatenOvwerview">
                            <span>Verzugs-Adresse</span>
                          </div>
                          <div className="LigneAdresse-RenviernungsdatumOvwerview">
                            <span style={{ color: "#000000" }}>
                              {protocolToShow?.previous_renter.address
                                .previous_renter_srteet
                                ? protocolToShow?.previous_renter.address
                                    ?.previous_renter_srteet
                                : "Straße fehlt"}
                              ,{" "}
                              {protocolToShow?.previous_renter?.address
                                ?.previous_renter_housnumber
                                ? protocolToShow?.previous_renter?.address
                                    ?.previous_renter_housnumber
                                : "Hausnummer fehlt"}
                            </span>
                            <span>Adresszusatz</span>
                          </div>
                          <div className="LigneAdresse-RenviernungsdatumOvwerview">
                            <div className="mb-2 text-style">
                              <span>
                                {protocolToShow?.previous_renter?.address
                                  ?.previous_renter_plz
                                  ? protocolToShow?.previous_renter?.address
                                      ?.previous_renter_plz
                                  : "PLZ fehlt"}
                                ,{" "}
                                {protocolToShow?.previous_renter?.address
                                  ?.previous_renter_city
                                  ? protocolToShow?.previous_renter?.address
                                      ?.previous_renter_city
                                  : "Ort fehlt"}
                              </span>
                            </div>
                            {protocolToShow?.previous_renter?.address
                              ?.previous_renter_address_note && (
                              <div className="mb-2 text-style">
                                <span style={{ fontWeight: "bold" }}>
                                  {protocolToShow?.previous_renter?.address
                                    ?.previous_renter_address_note
                                    ? protocolToShow?.previous_renter?.address
                                        ?.previous_renter_address_note
                                    : "Angabe fehlt"}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            className="SonCard mt-3 pt-0 pb-0 protocol-kontrol"
            style={{ backgroundColor: "#fff" }}
          >
            <div className="neuer  withoutBorderRadius">
              <img className="NeuerIcon" src={RaumeIcon} alt="Räume Icon" />
              <h3 className="uppercase"> Räume und Zimmer </h3>
            </div>

            {(window.location.pathname.startsWith(
              "/app/update_protocolcontrol"
            ) ||
              window.location.pathname.startsWith(
                "/app/show_protocolcontrol"
              ) ||
              window.location.pathname.startsWith("/app/protocolcontrol")) &&
            protocolToShow?.protocol_rooms &&
            protocolToShow.protocol_rooms.length > 0 ? (
              <>
                {protocolToShow?.protocol_rooms.map((room, index) => (
                  <div className="Room-Block-Defect">
                    <div className="VerteilerContainer">
                      <div>
                        <img
                          className="line-Image"
                          src={Line}
                          alt="notvalidateSclusselOvwerview Icon"
                          style={{ float: "left" }}
                        />
                        <div className="LigneWohnungsdatenOvwerview float-left">
                          <span>
                            {protocolToShow?.protocol_rooms[index]?.room_type ==
                              "livingRoom" && <>Wohnzimmer</>}
                            {protocolToShow?.protocol_rooms[index]?.room_type ==
                              "bedroom" && <>Schlafzimmer</>}
                            {protocolToShow?.protocol_rooms[index]?.room_type ==
                              "kitchen" && <>Küche</>}
                            {protocolToShow?.protocol_rooms[index]?.room_type ==
                              "bathroom" && <>Badezimmer</>}
                            {protocolToShow?.protocol_rooms[index]?.room_type ==
                              "basement" && <>Keller</>}
                            {protocolToShow?.protocol_rooms[index]?.room_type ==
                              "corridor" && <>Flur</>}
                            {protocolToShow?.protocol_rooms[index]?.room_type ==
                              "secondbedroom" && <>Zweites Schlafzimmer</>}
                            {protocolToShow?.protocol_rooms[index]?.room_type ==
                              "secondbathroom" && <>Zweites Badezimmer</>}
                            {protocolToShow?.protocol_rooms[index]?.room_type ==
                              "attic" && <>Dachboden</>}
                            {protocolToShow?.protocol_rooms[index]?.room_type ==
                              "garage" && <>Garage</>}
                            {protocolToShow?.protocol_rooms[index]?.room_type ==
                              "balcony" && <>Balkon</>}
                            {protocolToShow?.protocol_rooms[index]?.room_type ==
                              "shed" && <>Schuppen</>}
                            {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "guest_room" && <>Gästezimmer</>}
                                    {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "guest_toilet" && <>Gäste-WC</>}
                            {protocolToShow?.protocol_rooms[index]?.room_type ==
                              "garden" && <>Garten</>}
                            {protocolToShow?.protocol_rooms[index]?.room_type ==
                              "nursery" && <>Kinderzimmer</>}
                            {protocolToShow?.protocol_rooms[index]?.room_type ==
                              "other" && (
                              <>
                                {
                                  protocolToShow?.protocol_rooms[index]
                                    ?.room_name
                                }
                              </>
                            )}
                          </span>
                          <span className="RoomNameComment">
                            Es fehlen diverse Teile.
                          </span>
                        </div>
                        <div className="LigneZustandPROTOKOLLTYPE Room-Status float-right">
                          {protocolToShow?.protocol_rooms[index].room_status ==
                            null && (
                            <>
                              <div className="LigneRaumeundZimmerAddIcon">
                                <MinimizeOutlinedIcon
                                  sx={{
                                    fontSize: "2rem",
                                    paddingBottom: "12px",
                                    color: "rgb(141 133 133)",
                                  }}
                                />

                                <span
                                  style={{
                                    color: "rgb(141 133 133)",
                                    fontSize: "1.3rem",
                                  }}
                                >
                                  Angabe fehlt
                                </span>
                              </div>
                            </>
                          )}
                          {protocolToShow?.protocol_rooms[index].room_status ==
                            0 && (
                            <>
                              <CheckIcon
                                className="icon-AngabeButton"
                                sx={{ fontSize: 40, color: "#62AB12" }}
                              />
                              <span
                                style={{
                                  color: "#62AB12",
                                  fontSize: "1.3rem",
                                }}
                              >
                                IN ORDNUNG
                              </span>
                            </>
                          )}
                          {protocolToShow?.protocol_rooms[index].room_status ==
                            1 && (
                            <>
                              <img
                                className="PenIcon"
                                src={Mangel}
                                alt="Baskets Icon"
                                style={{
                                  filter: "none",
                                  marginRight: "10px",
                                }}
                              />

                              <span
                                style={{
                                  color: "#D00000",
                                  fontSize: "1.3rem",
                                }}
                              >
                                Mangel vorhanden
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {protocolToShow?.protocol_rooms[index]
                      ?.room_generale_description != null && (
                      <div className="RoomGeneralContant row">
                        <div className="LigneAdresse-RenviernungsdatumOvwerview col-md-9">
                        <TextField
                                            type="text"
                                            placeholder="Allgemeine Beschreibung"
                                            label={"Allgemeine Beschreibung"}
                                            className="valueselected-deffect"
                                            id="room_generale_description"
                                            value={protocolToShow?.protocol_rooms[index]
                                              ?.room_generale_description != null
                                              ? protocolToShow.protocol_rooms[index]
                                                  ?.room_generale_description
                                              : "KEIN KOMMENTAR"}
                                            multiline={true}
                                            fullWidth
                                            rows="3"
                                            InputProps={{ readOnly: true }}
                                            InputLabelProps={{
                                              shrink: true,
                                          }}
                                          />
                        </div>
                        <>
                          {protocolToShow.protocol_rooms[index].room_image &&
                          protocolToShow.protocol_rooms[index].room_image
                            .length > 0 ? (
                              <>
                              {protocolToShow.protocol_rooms[index].room_image
                                ?.filter((Rimage) => Rimage?.deffect_id == null)
                                .map((imageSet, rimg) => (
                                  <div
                                    className="col-md-3  pr-0"
                                    style={{
                                      height:"250px",
                                      marginBottom: "30px",
                                    }}
                                  >
                                    <div
                                      className="image--container image-right"
                                      style={{ flexDirection: "column",height:"250px" }}
                                      key={rimg}
                                    >
                                      {showImage &&
                                        protocolToShow?.protocol_rooms[index]
                                          ?.room_image[rimg]?.id ==
                                          idImageShow && (
                                          <div
                                            className="image-overlay"
                                            onClick={() =>
                                              toggleImage(
                                                protocolToShow?.protocol_rooms[
                                                  index
                                                ]?.room_image[rimg]?.id
                                              )
                                            }
                                          >
                                            <div
                                              className={classes.imageContainer}
                                            >
                                              <img
                                                src={
                                                  protocolToShow
                                                    ?.protocol_rooms[index]
                                                    ?.room_image[rimg]
                                                    ?.room_image_remote_url
                                                }
                                                alt={
                                                  protocolToShow
                                                    ?.protocol_rooms[index]
                                                    ?.room_image[rimg]
                                                    ?.room_image_name
                                                }
                                                className="image"
                                              />
                                            </div>
                                          </div>
                                        )}
                                      {!showImage && (
                                        <div
                                          className="icon overview"
                                          onClick={() =>
                                            toggleImage(
                                              protocolToShow?.protocol_rooms[
                                                index
                                              ]?.room_image[rimg]?.id
                                            )
                                          }
                                        >
                                          {/* Add your icon here */}
                                          <i className="fas fa-search-plus"></i>
                                        </div>
                                      )}
                                      <img
                                        src={
                                          protocolToShow?.protocol_rooms[index]
                                            ?.room_image[rimg]
                                            ?.room_image_remote_url
                                        }
                                        alt={
                                          protocolToShow?.protocol_rooms[index]
                                            ?.room_image[rimg]?.room_image_name
                                        }
                                        className="image-Room"
                                      />
                                      {protocolToShow?.protocol_rooms[index]
                                        ?.room_image[rimg]
                                        ?.image_description && (
                                        <>
                                          <div className="deffect-filter-comment-title">
                                            Kommentar
                                          </div>
                                          <div className="description-contain">
                                            <span
                                              style={{
                                                //backgroundColor: "#fff",
                                                color: "rgba(0, 0, 0, 1)",
                                              }}
                                            >
                                              {
                                                protocolToShow?.protocol_rooms[
                                                  index
                                                ]?.room_image[rimg]
                                                  ?.image_description
                                              }
                                            </span>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                ))}
                                </>
                          ) : null}
                        </>
                      </div>
                    )}

                    {protocolToShow.protocol_rooms[index].room_deffect &&
                    protocolToShow.protocol_rooms[index].room_deffect.length >
                      0 ? (
                      <>
                        {protocolToShow.protocol_rooms[index].room_deffect.map(
                          (deffect, i) =>
                            protocolToShow.protocol_rooms[index].room_deffect[i]
                              .room_deffect_who_fix != "accepted" && (
                              <>
                              <div className="Defect-Block">
                                <div className="first-part-formverteilerOvwerview w-100 pt-4 ">
                                  <div className="row w-100 m-0">
                                    <div className="col-md-9">
                                    <div className="row m-0 line-Mangel">
                                    <img
                                      className="line-Image"
                                      src={Line}
                                      alt="notvalidateSclusselOvwerview Icon"
                                    />
                                    <div
                                      className={`LigneZustandPROTOKOLLTYPE  ${
                                        protocolToShow.protocol_rooms[index]
                                          .room_deffect[i].controle_corrected ==
                                        null
                                          ? ""
                                          : protocolToShow.protocol_rooms[index]
                                              .room_deffect[i]
                                              .controle_corrected == true
                                          ? "OK mt-0"
                                          : "BAD"
                                      }`}
                                      style={{ marginTop: "14px" }}
                                    >
                                      {protocolToShow.protocol_rooms[index]
                                        .room_deffect[i].controle_corrected ==
                                      true ? (
                                        <CheckIcon
                                          className="icon-AngabeButton"
                                          sx={{
                                            fontSize: 40,
                                            color: "#62AB12",
                                          }}
                                        />
                                      ) : (
                                        <img
                                        className="BeschreibungIcon"
                                        src={NotValidIcon}
                                        alt="notvalidateSclusselOvwerview Icon"
                                        style={{
                                          height: "1.3rem",
                                          padding: "0rem",
                                          marginRight: "0rem",
                                          marginTop: "0",
                                          filter:
                                            "invert(50%) sepia(99%) saturate(9999%) hue-rotate(390deg) brightness(95%) contrast(119%)",
                                        }}
                                      />
                                      )}
                                      <h2
                                        style={{
                                          fontSize: "1.3rem",
                                          marginBottom: "0",
                                          lineHeight: "0",
                                        }}
                                      >
                                        Mangel {i + 1} von{" "}
                                        {
                                          protocolToShow.protocol_rooms[index]
                                            .room_deffect.length
                                        }
                                      </h2>
                                    </div>
                                  </div>
                                      <div className="DefectName-Line pl-3">
                                        <img
                                          src={Defect}
                                          alt="notvalidateSclusselOvwerview Icon"
                                          style={{
                                            float: "left",
                                            padding: "10px",
                                          }}
                                        />
                                        <div className=" mangel-deffect mangel-deffect-title" style={{ paddingLeft: "20px",paddingRight:"0" }}>
                                          <TextField
                                            type="text"
                                            label={"Objekt"}
                                            className="valueselected-deffect"
                                            placeholder="Objekt"
                                            id="deffect_name"
                                            name="deffect_name"
                                            value={
                                              protocolToShow.protocol_rooms[
                                                index
                                              ].room_deffect[i].deffect_name !=
                                              null
                                                ? protocolToShow.protocol_rooms[
                                                    index
                                                  ].room_deffect[i].deffect_name
                                                : "keine angabe"
                                            }
                                            index={i}
                                            InputProps={{ readOnly: true }}
                                            fullWidth
                                            InputLabelProps={{
                                              shrink: true,
                                          }}/>
                                        </div>
                                      </div>
                                      <div
                                        className=" float-left w-100 pl-3"
                                        style={{
                                          paddingTop: "20px",
                                          clear: "inline-start",
                                        }}
                                      >
                                        <img
                                            style={{
                                              height: "3rem",
                                              width: "3rem",
                                              float: "inline-start",
                                            }}
                                            className="PenIcon"
                                            src={DefectTyp}
                                            alt="Baskets Icon"
                                          />
                                        
                                        <div className="DefectTyp-Line  d-flex align-items-center" style={{ paddingLeft: "20px",paddingRight:"0" }}>
                                        <img
                                          className=""
                                          src={LineLong}
                                          alt="notvalidateSclusselOvwerview Icon"
                                          style={{ marginTop: "-45px"}}
                                          
                                        />
                                          <div className=" mangel-deffect mangel-deffect-title w-100 pr-0">
                                            <TextField
                                              type="text"
                                              label={"Art des Mangels"}
                                              placeholder="Art des Mangels"
                                              className="valueselected-deffect"
                                              id="deffect_type"
                                              value={
                                                protocolToShow.protocol_rooms[
                                                  index
                                                ].room_deffect[i]
                                                  .deffect_type != null
                                                  ? protocolToShow
                                                      .protocol_rooms[index]
                                                      .room_deffect[i]
                                                      .deffect_type
                                                  : "keine angabe"
                                              }
                                              index={i}
                                              multiline={false}
                                              fullWidth
                                              InputProps={{ readOnly: true }}
                                              InputLabelProps={{
                                                shrink: true,
                                            }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3"></div>
                                  </div>
                                  <div className="Line-UnderDeffect">
                                  <div
                                    className="LigneDataAdresse-RenviernungsdatumOvwerview text-Kommontar"
                                    style={{
                                      marginBottom: "10px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div style={{ width: "100%" }}>
                                      {window.location.pathname.startsWith(
                                        "/app/show_protocolcontrol"
                                      ) ? (
                                        <div
                                          className="image-right"
                                          style={{ width: "100%" }}
                                        >
                                          <TextField
                                            InputLabelProps={{
                                              style: {
                                                textTransform: "uppercase",
                                                padding: "0 5px 0 0",
                                                backgroundColor: "white",
                                                shrink: true,
                                              },
                                            }}
                                            name="controle_comment"
                                            value={
                                              protocolToShow?.protocol_rooms[
                                                index
                                              ]?.room_deffect[i]
                                                ?.controle_comment || "KEIN KOMMENTAR"
                                            }
                                            id="controle_comment"
                                            type="text"
                                            label={"Bemerkung"}
                                            placeholder={"BEMERKUNG"}
                                            variant="outlined"
                                            rows="3"
                                            multiline={true}
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                          />
                                        </div>
                                      ) : (
                                        <div
                                          className="image-right"
                                          style={{ width: "100%" }}
                                        >
                                          <TextField
                                            InputLabelProps={{
                                              style: {
                                                textTransform: "uppercase",
                                                padding: "0 5px 0 0",
                                                backgroundColor: "white",
                                                shrink: protocolToShow?.protocol_rooms[
                                                  index
                                                ]?.room_deffect[i]
                                                  ?.controle_comment ? true : false,
                                              },
                                            }}
                                            name="controle_comment"
                                            value={
                                              protocolToShow?.protocol_rooms[
                                                index
                                              ]?.room_deffect[i]
                                                ?.controle_comment
                                            }
                                            onChange={(e) =>
                                              handleChangeComment(
                                                e.target.name,
                                                e.target.value,
                                                index,
                                                i
                                              )
                                            }
                                            id="controle_comment"
                                            type="text"
                                            placeholder={"BEMERKUNG"}
                                            label={"Bemerkung"}
                                            variant="outlined"
                                            rows="3"
                                            multiline={true}
                                            fullWidth
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  </div>
                                  <div className="Line-UnderDeffect">
                                    <div
                                      className="Block-Mangel"
                                      style={{ backgroundColor: "#f3f3f3" }}
                                    >
                                      <div className=" float-left w-100">
                                        <Config
                                          className=" float-left"
                                          sx={{
                                            fontSize: 33,
                                            color: "#009fe3",
                                          }}
                                          style={{ margin: "5px" }}
                                        />
                                        <div className="mangel-deffect mangel-deffect-title">
                                          <TextField
                                            type="text"
                                            placeholder="Maßnahme"
                                            label={"Maßnahme"}
                                            className="valueselected-deffect"
                                            id="room_deffect_who_fix"
                                            value={protocolToShow.protocol_rooms[
                                              index
                                            ].room_deffect[i]
                                              .room_deffect_who_fix != null
                                              ? protocolToShow.protocol_rooms[
                                                  index
                                                ].room_deffect[i]
                                                  .room_deffect_who_fix ==
                                                "accepted"
                                                ? "KEINE BEHEBUNG NÖTIG"
                                                : protocolToShow.protocol_rooms[
                                                    index
                                                  ].room_deffect[i]
                                                    .room_deffect_who_fix
                                              : "keine angabe"}
                                            index={i}
                                            multiline={false}
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            InputLabelProps={{
                                              shrink: true,
                                          }}
                                          />
                                        </div>
                                      </div>
                                      <div className=" float-right">
                                        <div className="mangel-deffect mangel-deffect-title">
                                          <div className="d-flex align-items-center">
                                            <TextField
                                            type="text"
                                            placeholder="Zu erledigen bis"
                                            label={"Zu erledigen bis"}
                                            id="room_deffect_date_of_fix"
                                            value={protocolToShow.protocol_rooms[
                                              index
                                            ].room_deffect[i]
                                              .room_deffect_date_of_fix
                                              ? protocolToShow.protocol_rooms[
                                                  index
                                                ].room_deffect[i]
                                                  .room_deffect_date_of_fix
                                              : "K.A"}
                                            index={i}
                                            multiline={false}
                                            fullWidth
                                            InputProps={{ readOnly: true,
                                              startAdornment: (
                                              <InputAdornment position="start">
                                                <img
                                                  className="auftrageCalendarIcon"
                                                  src={CalenderIcon}
                                                  alt="Auftrage Calendar Icon"
                                                />
                                              </InputAdornment>
                                            ), }}
                                            InputLabelProps={{
                                              shrink: true,
                                          }}
                                          />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="Line-UnderDeffect position-relative mb-4 mt-3">
                                    <img src={LineGroup} alt="" 
                                         className={`Line ${
                                          protocolToShow.protocol_rooms[index]
                                            .room_deffect[i].controle_corrected ==
                                          null
                                            ? "nothing"
                                            : protocolToShow.protocol_rooms[index]
                                                .room_deffect[i]
                                                .controle_corrected == true
                                            ? "corrected"
                                            : "defected"
                                        }`}
                                    />
                                    <span className="Label-Line">NEUER ZUSTAND</span>
                                    <span className="Header-Line-title">Status bei der Nachbesichtigung am{" "}
                                      {moment
                                          .unix(protocolToShow.protocol_rooms[index].room_updated_at?.seconds)
                                          .add(protocolToShow.protocol_rooms[index].room_updated_at?.nanoseconds / 1000000)
                                          .format("DD.MM.YYYY")
                                                  }</span>
                                  </div>
                                  <div className="Line-UnderDeffect">
                                    {protocolToShow.protocol_rooms[index]
                                      ?.room_image &&
                                    protocolToShow.protocol_rooms[index]
                                      ?.room_image?.length > 0
                                      ? protocolToShow.protocol_rooms[
                                          index
                                        ]?.room_image
                                          ?.filter(
                                            (Rimage) =>
                                              Rimage?.deffect_id !== null &&
                                              Rimage?.deffect_id ==
                                                protocolToShow?.protocol_rooms[
                                                  index
                                                ]?.room_deffect[i]?.id
                                          )
                                          .map((Rimage, rimg) => (
                                            <>
                                            <div className="row deffect-filter-comment-container">
                                            <div
                                              className="col-md-2 pl-0 pr-0"
                                              key={rimg}
                                            >
                                              <div className="image--container image-right">
                                                {showImage &&
                                                  Rimage?.id == idImageShow && (
                                                    <div
                                                      className="image-overlay"
                                                      onClick={() =>
                                                        toggleImage(
                                                          protocolToShow
                                                            ?.protocol_rooms[
                                                            index
                                                          ]?.room_image[rimg]
                                                            ?.id
                                                        )
                                                      }
                                                    >
                                                      <div
                                                        className={
                                                          classes.imageContainer
                                                        }
                                                      >
                                                        {protocolToShow
                                                              ?.protocol_rooms[
                                                              index
                                                            ]?.room_image[rimg]
                                                              ?.room_image_remote_url == null?
                                                        <img
                                                          src={CommentIcon}
                                                          alt={"withoutImage"}
                                                          className="image"
                                                        />:
                                                        <img
                                                          src={
                                                            protocolToShow
                                                              ?.protocol_rooms[
                                                              index
                                                            ]?.room_image[rimg]
                                                              ?.room_image_remote_url
                                                          }
                                                          alt={
                                                            protocolToShow
                                                              ?.protocol_rooms[
                                                              index
                                                            ]?.room_image[rimg]
                                                              ?.room_image_name
                                                          }
                                                          className="image"
                                                        />
                                                        }
                                                      </div>
                                                    </div>
                                                  )}
                                                {!showImage && (
                                                  <div
                                                    className="icon overview"
                                                    onClick={() =>
                                                      toggleImage(Rimage?.id)
                                                    }
                                                  >
                                                    <i className="fas fa-search-plus"></i>
                                                  </div>
                                                )}
                                                {(!showImage && 
                                                (window.location.pathname.startsWith("/app/protocolcontrol") ||
                                                window.location.pathname.startsWith("/app/update_protocolcontrol"))) && (
                                              <div
                                                className="icon overview Delete"
                                                onClick={() =>
                                                  deleteImageDefect(Rimage?.id,index)
                                                }
                                              >
                                                 <img src={Trash} alt="" />
                                              </div>
                                            )}
                                                <div className="defect-contain ">
                                                {protocolToShow
                                                              ?.protocol_rooms[
                                                              index
                                                            ]?.room_image[rimg]
                                                              ?.room_image_remote_url == null?
                                                        <img
                                                          src={CommentIcon}
                                                          alt={"withoutImage"}
                                                          className="w-full"
                                                          style={{
                                                          width: "100%",
                                                          height: "90px !important",
                                                           }}
                                                        />:
                                                        <img
                                                        src={
                                                          Rimage?.room_image_remote_url
                                                        }
                                                        alt={
                                                          Rimage?.room_image_name
                                                        }
                                                        className="w-full"
                                                        style={{
                                                          width: "100%",
                                                          height: "90px !important",
                                                           }}
                                                      />
                                                        }
                                                 
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-10 pr-0">
                                            {Rimage.is_from_main_protocol === true ?
                                            <TextField
                                            type="text"
                                            placeholder="Kommentar"
                                            label={"Kommentar"}
                                            className="valueselected-deffect"
                                            id="image_description"
                                            value={
                                              protocolToShow
                                                ?.protocol_rooms[
                                                index
                                              ]?.room_image[rimg]
                                                ?.image_description ||  "KEIN KOMMENTAR"
                                            }
                                            multiline={true}
                                            fullWidth
                                            rows="3"
                                            InputProps={{ readOnly: true }}
                                            InputLabelProps={{
                                              shrink: true,
                                          }}
                                          />
                                          :
                                          <TextField
                                            type="text"
                                            placeholder="Kommentar"
                                            label={"Kommentar"}
                                            className="valueselected-deffect"
                                            id="image_description"
                                            name="image_description"
                                            value={
                                              protocolToShow
                                                ?.protocol_rooms[
                                                index
                                              ]?.room_image[rimg]
                                                ?.image_description
                                            }
                                            onChange={(e) =>
                                              handleChange(
                                                e.target.name,
                                                e.target.value,
                                                rimg,
                                                index
                                              )
                                            }
                                            multiline={true}
                                            fullWidth
                                            rows="3"
                                            InputLabelProps={{
                                              shrink: true,
                                          }}
                                          />
                                          }
                                          </div>
                                          </div>
                                          </>
                                          ))
                                      : null}
                                  
                                  </div>
                                  {(window.location.pathname.startsWith("/app/protocolcontrol") ||
                                  window.location.pathname.startsWith("/app/update_protocolcontrol"))&&
                                  <div className="Line-UnderDeffect Action-Line-Add-Image-Comment pr-0"
                                  style={{ paddingBottom:"20px" }}
                                  >
                                  <div  style={{marginRight:"50px",}}>
                                    <img src={CameraIcon} alt="" />
                                    <button className="Weiter" onClick={() => document.getElementById(`defectImageRoom${i}+Room${index}`).click()}>
                                      Foto hinzufügen</button>
                                      <input
                                          type="file"
                                          accept="image/*"
                                          onChange={(e) => handleImageUpload(e, "defect", deffect.id,index) }
                                          style={{ display: "none", }}
                                          id={`defectImageRoom${i}+Room${index}`}
                                      />
                                  </div>
                                  <div>
                                    <img src={CommentIcon} alt="" />
                                    <button className="Weiter" onClick={() =>handleCommentUploadwithoutImage("defect", deffect.id,index)}>
                                      Kommentar hinzufügen</button>
                                  </div>
                                </div>}
                                {window.location.pathname.startsWith(
                                    "/app/show_protocolcontrol"
                                  ) ? (
                                    <div className="row mangelButtonsProtocolControl m-0 col-md-12"
                                         style={{ paddingg: "0 0 0 50px" }}
                                    >
                                    <div
                                      className={`LigneZustandPROTOKOLLTYPE float-right ${
                                        protocolToShow.protocol_rooms[index]
                                          .room_deffect[i].controle_corrected ==
                                        null
                                          ? "div-emotion-button mb-5"
                                          : protocolToShow.protocol_rooms[index]
                                              .room_deffect[i]
                                              .controle_corrected == true
                                          ? "div-emotion-button OK mb-5"
                                          : "div-emotion-button BAD mb-5"
                                      }`}
                                    >
                                      {protocolToShow.protocol_rooms[index]
                                        .room_deffect[i].controle_corrected ==
                                      true ? (
                                        <CheckIcon
                                          className="icon-AngabeButton"
                                          sx={{
                                            fontSize: 40,
                                            color: "#62AB12",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          className="PenIcon"
                                          src={Mangel}
                                          alt="Baskets Icon"
                                          style={{
                                            filter: "none",
                                            marginRight: "10px",
                                          }}
                                        />
                                      )}

                                      <span
                                        style={{
                                          color: `${
                                            protocolToShow.protocol_rooms[index]
                                              .room_deffect[i]
                                              .controle_corrected == true
                                              ? "#62AB12"
                                              : "#D00000"
                                          }`,
                                          fontSize: "1.3rem",
                                        }}
                                      >
                                        Mangel vorhanden
                                      </span>
                                    </div>
                                    </div>
                                  ) : (
                                    <>
                                    <div className="row mangelButtonsProtocolControl m-0 col-md-12"
                                         style={{ padding: "0 0 40px 50px" }}
                                     >
                                      <div className="float-right">
                                        <div
                                          className=" Deffect-Status  SwitchMangelProtocol-Control"
                                          style={{
                                            backgroundColor:
                                              protocolToShow.protocol_rooms[
                                                index
                                              ].room_deffect[i]
                                                .controle_corrected == true
                                                ? "rgb(98, 171, 18)"
                                                : "rgb(208, 0, 0)",
                                          }}
                                        >
                                          <div
                                            className="Deffect-Status-Switch"
                                            style={{
                                              float: "left",
                                              color:
                                                protocolToShow.protocol_rooms[
                                                  index
                                                ].room_deffect[i]
                                                  .controle_corrected == false
                                                  ? "rgb(208, 0, 0)"
                                                  : "",
                                            }}
                                            onClick={(e) =>
                                              handleMangel(e, index, i)
                                            }
                                          >
                                            <span>NICHT BEHOBEN</span>
                                          </div>
                                          <div
                                            className="Deffect-Status-Switch"
                                            style={{
                                              float: "right",
                                              marginLeft: "20px",
                                              color:
                                                protocolToShow.protocol_rooms[
                                                  index
                                                ].room_deffect[i]
                                                  .controle_corrected == true
                                                  ? "rgb(98, 171, 18)"
                                                  : "",
                                            }}
                                            onClick={(e) =>
                                              handleOk(e, index, i)
                                            }
                                          >
                                            {" "}
                                            <span>BEHOBEN</span>
                                            {protocolToShow.protocol_rooms[
                                              index
                                            ].room_deffect[i]
                                              .controle_corrected == true && (
                                              <CheckIcon
                                                className="icon-AngabeButton"
                                                sx={{
                                                  fontSize: 30,
                                                  color: "#62AB12",
                                                  padding: "0px",
                                                }}
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                                </div>
                              </>
                            )
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <></>
            )}
          </div>

          {window.location.pathname.startsWith("/app/protocolcontrol") ||
          window.location.pathname.startsWith("/app/update_protocolcontrol") ? (
            <>
              <div
                className={` SonCard m-3 pt-0 pb-0 ${protocolToShow?.protocol_keys
                  ?.filter((key, s) => 
                    parseInt(key.key_number) > key.number_keys_returned_in_main_protocol
                  )?.length > 0 ?"protocol-kontrol" :""}`}
                style={{ backgroundColor: "#fff" }}
              >
                <div className="neuer  withoutBorderRadius mt-0">
                  <img
                    className="NeuerIcon"
                    src={keyIcon}
                    alt="Schlüssel Icon"
                  />
                  <h3 style={{ textTransform: "uppercase" }}> Schlüssel </h3>
                </div>
                {protocolToShow?.protocol_keys &&
                protocolToShow?.protocol_keys.length > 0 ? (
                  <>
                    {protocolToShow?.protocol_keys.map((key, s) =>
                      parseInt(protocolToShow?.protocol_keys[s]?.key_number) <=
                      protocolToShow?.protocol_keys[s]
                        ?.number_keys_returned_in_main_protocol ? (
                        <></>
                      ) : (
                        protocolToShow?.protocol_keys[s]?.key_number != 0 &&
                        protocolToShow?.protocol_keys[s]?.key_number != "" && (
                          <>
                            <div className="card-shadow Keys-Block">
                              <div className="after-cards ">
                                <div className="formAbschlussOvwerview  Padding-NewForm">
                                  <div className="LigneSclusselDataOvwerview">
                                    <div
                                      className="blocks-SclusselOvwerview Keys-overview"
                                      style={{ alignItems: "center" }}
                                    >
                                      <div className="col-md-12 pr-0 pl-0">
                                        <img
                                          className="line-Image"
                                          src={Line}
                                          alt="notvalidateSclusselOvwerview Icon"
                                          style={{ float: "left" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            alignItems: "baseline",
                                            marginTop: parseInt(
                                              protocolToShow?.protocol_keys[s]
                                                ?.key_number
                                            ) >
                                            protocolToShow?.protocol_keys[s]
                                              ?.number_keys_returned_in_main_protocol ? "10px" : "",
                                          }}
                                        >
                                          {parseInt(
                                            protocolToShow?.protocol_keys[s]
                                              ?.key_number
                                          ) >
                                          protocolToShow?.protocol_keys[s]
                                            ?.number_keys_returned_in_main_protocol ? (
                                            <img
                                              className="notvalidateSclusselOvwerviewIcon"
                                              src={NotValidIcon}
                                              alt="notvalidateSclusselOvwerview Icon"
                                              style={{
                                                paddingTop: "0",
                                                height: "20px",
                                              }}
                                            />
                                          ) : (
                                            <img
                                              className="validateSclusselOvwerviewIcon"
                                              src={ValidIcon}
                                              alt="validateSclusselOvwerview Icon"
                                              style={{
                                                paddingTop: "0",
                                                height: "20px",
                                              }}
                                            />
                                          )}
                                          <p
                                            style={{
                                              marginTop: "0px",
                                              marginBottom: "3px",
                                              fontSize: "1.15rem",
                                            }}
                                          >
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "mainEntrance" && (
                                              <span> Eingangstür </span>
                                            )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "apartmentKey" && (
                                              <span> Wohnungsschlüssel </span>
                                            )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "basement" && (
                                              <span> Keller </span>
                                            )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "attic" && (
                                              <span> Dachboden </span>
                                            )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "garage" && (
                                              <span> Garage </span>
                                            )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "shed" && (
                                              <span> Schuppen </span>
                                            )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "outerGate" && (
                                              <span> Aussentor </span>
                                            )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "goal" && (
                                              <span> Tor </span>
                                            )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "gazebo" && (
                                              <span> Gartenlaube </span>
                                            )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "letterBox" && (
                                              <span> Briefkasten </span>
                                            )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "frontDoor" && (
                                              <span> Haustür </span>
                                            )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_type === "other" && (
                                              <span>
                                                {
                                                  protocolToShow?.protocol_keys[
                                                    s
                                                  ]?.key_name
                                                }
                                              </span>
                                            )}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.key_number &&
                                            protocolToShow?.protocol_keys[s]
                                              ?.key_number.length > 0 ? (
                                              <span>
                                                {"schlüssel"}:{" "}{
                                                  protocolToShow?.protocol_keys[
                                                    s
                                                  ]?.key_number
                                                }
                                              </span>
                                            ) : (
                                              <span>: 0 </span>
                                            )}
                                          </p>
                                          <p>
                                          <span >
                                              erhalten :{" "}
                                              
                                            </span>
                                          </p>
                                          {protocolToShow?.protocol_keys[s]?.number_keys_returned_in_main_protocol !=null &&
                                      protocolToShow?.protocol_keys[s]?.number_keys_returned_in_main_protocol !="" ? (
                                        <div className="counterKey-Action">
                                          <div className="icon-minus-counter">
                                          <RemoveIcon
                                            sx={
                                              parseInt(
                                                protocolToShow?.protocol_keys[s]
                                                  ?.key_number
                                              ) ==
                                                parseInt(
                                                  protocolToShow?.protocol_keys[s]
                                                    ?.number_keys_returned_in_main_protocol
                                                ) +
                                                protocolToShow.protocol_keys[s]
                                                  .number_keys_returned
                                                ? {
                                                  fontSize: 22,
                                                  color: "#fff",
                                                }
                                                : {
                                                  fontSize: 22,
                                                  color: "#fff",
                                                }
                                            }
                                            onClick={(e) => {
                                              CompteurRemoveKey(e, s);
                                            }}
                                          />
                                          </div>
                                          <div className="counterBox">
                                            {protocolToShow.protocol_keys[s].number_keys_returned + parseInt( protocolToShow?.protocol_keys[s]
                                            ?.number_keys_returned_in_main_protocol, 10 )
                                             }
                                          </div>
                                          <div className="icon-plus-counter" >
                                          <AddIcon
                                            sx={
                                              protocol.protocol_keys[s]
                                                .number_keys_returned == 0
                                                ? {
                                                  fontSize: 22,
                                                  color: "#fff",
                                                }
                                                : {
                                                  fontSize: 22,
                                                  color: "#fff",
                                                }
                                            }
                                            onClick={(e) => {
                                              CompteuraddKey(e, s);
                                            }}
                                          />
                                           </div>
                                        </div>
                                      )
                                     : (
                                      <div className="counterKey-Action"> 
                                      <div className="icon-minus-counter">
                                      <RemoveIcon
                                          sx={{
                                            fontSize: 22,
                                            color: "#fff",
                                          }}
                                          onClick={(e) => {
                                            CompteurRemoveKey(e, s);
                                          }}
                                        />
                                        </div>
                                        <div className="counterBox">
                                          {"0"}
                                        </div>
                                        <div className="icon-plus-counter" >
                                        <AddIcon
                                          sx={{
                                            fontSize: 22,
                                            color: "#fff",
                                          }}
                                          onClick={(e) => {
                                            CompteuraddKey(e, s);
                                          }}
                                        />
                                        </div>
                                       
                                      </div>
                                    )}
                                    <p className={`StatusKeyLabel ${parseInt(
                                                   protocolToShow?.protocol_keys[
                                                   s
                                                   ]?.key_number
                                                 ) >
                                                 protocolToShow?.protocol_keys[s]
                                                   ?.number_keys_returned_in_main_protocol
                                                   ? "Missing"
                                                   :  "correct" }`}
                                                   >
                                          Status: {parseInt(
                                                   protocolToShow?.protocol_keys[
                                                   s
                                                   ]?.key_number
                                                 ) >
                                                 protocolToShow?.protocol_keys[s]
                                                   ?.number_keys_returned_in_main_protocol
                                                   ? "Fehlend"
                                                   :  "Vollständig" }
                                          </p>
                                        </div>
                                      </div>
                                      </div>
                                      </div>
                                      <div className="row w-100 mt-1 mb-1 mr-0 ml-0">

                                      <div className="col-md-3">
                                      <TextField
                                            type="text"
                                            placeholder="Übergabedatum"
                                            label={"Übergabedatum"}
                                            id="key_date_of_forwarding"
                                            value={protocolToShow?.protocol_keys[s]
                                              ?.key_date_of_forwarding
                                              ? protocolToShow.protocol_keys[s]
                                                  ?.key_date_of_forwarding
                                              : "K.A"}
                                            multiline={false}
                                            fullWidth
                                            InputProps={{ readOnly: true,
                                              startAdornment: (
                                              <InputAdornment position="start">
                                                <img
                                                  className="auftrageCalendarIcon"
                                                  src={CalenderIcon}
                                                  alt="Auftrage Calendar Icon"
                                                />
                                              </InputAdornment>
                                            ), }}
                                            InputLabelProps={{
                                              shrink: true,
                                          }}
                                          />
                                      </div>
                                      <div className="col-md-9 pr-0"
                                        style={{ alignItems: "center" }}
                                      >
                                          <TextField
                                            type="text"
                                            placeholder="Information"
                                            label={"Information"}
                                            id="key_notice"
                                            value={protocolToShow?.protocol_keys[s]
                                              ?.key_notice
                                              ? protocolToShow?.protocol_keys[s]
                                                  ?.key_notice
                                              : "Kein Kommentar"}
                                            multiline={false}
                                            fullWidth
                                            InputProps={{ readOnly: true,}}
                                            InputLabelProps={{
                                              shrink: true,
                                          }}
                                          />
                                      </div>
                                      </div>
                                      <div className="row w-100 keys-protocol-control mr-0 ml-0">
                                      <div className="col-md-2"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {protocolToShow?.protocol_keys[s]
                                          .image &&
                                        protocolToShow?.protocol_keys[s]?.image
                                          ?.key_image_remote_url != null ? (
                                          <div className="image--container" >
                                            {showImage &&
                                              protocolToShow?.protocol_keys[s]
                                                ?.image?.id == idImageShow && (
                                                <div
                                                  className="image-overlay"
                                                  onClick={() =>
                                                    toggleImage(
                                                      protocolToShow
                                                        ?.protocol_keys[s]
                                                        ?.image?.id
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src={
                                                      protocolToShow
                                                        ?.protocol_keys[s]
                                                        ?.image
                                                        ?.key_image_remote_url
                                                    }
                                                    alt={
                                                      protocolToShow
                                                        ?.protocol_keys[s]
                                                        ?.image?.key_image_name
                                                    }
                                                    className="image"
                                                  />
                                                </div>
                                              )}
                                            {!showImage && (
                                              <div
                                                className="icon overview"
                                                onClick={() =>
                                                  toggleImage(
                                                    protocolToShow
                                                      ?.protocol_keys[s]?.image
                                                      ?.id
                                                  )
                                                }
                                              >
                                                 <i className="fas fa-search-plus"></i>
                                              </div>
                                            )}
                                            {!showImage && (
                                              <div
                                                className="icon overview Delete"
                                                onClick={() =>
                                                  deleteImageKey(s)
                                                }
                                              >
                                                 <img src={Trash} alt="" />
                                              </div>
                                            )}
                                            <img
                                              src={
                                                protocolToShow?.protocol_keys[s]
                                                  ?.image?.key_image_remote_url
                                              }
                                              alt={
                                                protocolToShow?.protocol_keys[s]
                                                  ?.image?.key_image_name
                                              }
                                              style={{
                                                objectFit: "cover",
                                                width: "7.8rem",
                                                height: "100px",
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          <div className="image-container">
                                            <PhotoLibraryIcon
                                              className="defaultImageIcon"
                                              sx={{
                                                width: "7.8rem",
                                                height: "100px",
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-md-10 w-100 pr-0">
                                      {window.location.pathname.startsWith(
                                        "/app/show_protocolcontrol"
                                      ) && (
                                        <TextField
                                          InputLabelProps={{
                                            style: {
                                              textTransform: "uppercase",
                                              padding: "0 5px 0 0",
                                              backgroundColor: "white",
                                            },
                                          }}
                                          name="controle_comment"
                                          value={
                                            protocolToShow?.protocol_keys[s]
                                              ?.controle_comment
                                          }
                                          id="controle_comment"
                                          type="text"
                                          placeholder={"KOMMENTAR"}
                                          variant="outlined"
                                          rows="3"
                                          InputProps={{ readOnly: true,}}
                                          multiline={true}
                                          fullWidth
                                        />
                                      )}
                                      {(window.location.pathname.startsWith(
                                        "/app/protocolcontrol"
                                      ) ||
                                        window.location.pathname.startsWith(
                                          "/app/update_protocolcontrol"
                                        )) && (
                                          <TextField
                                            InputLabelProps={{
                                              style: {
                                                textTransform: "uppercase",
                                                padding: "0 5px 0 0",
                                                backgroundColor: "white",
                                              },
                                            }}
                                            name="controle_comment"
                                            value={
                                              protocolToShow?.protocol_keys[s]
                                                ?.controle_comment
                                            }
                                            onChange={(e) =>
                                              handleChangeKey(
                                                e.target.name,
                                                e.target.value,
                                                s
                                              )
                                            }
                                            id="controle_comment"
                                            type="text"
                                            placeholder={"KOMMENTAR"}
                                            variant="outlined"
                                            rows="3"
                                            multiline={true}
                                            fullWidth
                                          />
                                        )}
                                    </div>
                                  </div>
                                  {(protocol?.protocol_keys[s]
                                          .image &&
                                        protocol?.protocol_keys[s]?.image
                                          ?.key_image_remote_url == null )&&
                                    <div className="Line-UnderDeffect Action-Line-Add-Image-Comment w-100 ml-0 pr-0"
                                       style={{marginTop:"30px"}}
                                    >
                                    <div>
                                     <img src={CameraIcon} alt="" />
                                     <button className="Weiter mr-0" onClick={() => document.getElementById(`imageKey_${s}`).click()}>
                                     Foto mit Kommentar hinzufügen</button>
                                      <input
                                          type="file"
                                          accept="image/*"
                                          onChange={(e) => handleImageKeyUpload(e, s) }
                                          style={{ display: "none", }}
                                          id={`imageKey_${s}`}
                                      />
                                  </div>
                                </div>}
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      )
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : null}

          {window.location.pathname.startsWith("/app/show_protocolcontrol") ? (
            <>
            <div
              className={` SonCard m-3 pt-0 pb-0 ${protocolToShow?.protocol_keys?.filter((key, s) => 
                  parseInt(key.key_number) > key.number_keys_returned_in_main_protocol
                )?.length > 0 ?"protocol-kontrol" :""}`}
              style={{ backgroundColor: "#fff" }}
            >
              <div className="neuer  withoutBorderRadius mt-0">
                <img
                  className="NeuerIcon"
                  src={keyIcon}
                  alt="Schlüssel Icon"
                />
                <h3 style={{ textTransform: "uppercase" }}> Schlüssel </h3>
              </div>
              {protocolToShow?.protocol_keys &&
              protocolToShow?.protocol_keys.length > 0 ? (
                <>
                  {protocolToShow?.protocol_keys.map((key, s) =>
                    parseInt(protocolToShow?.protocol_keys[s]?.key_number) <=
                    protocolToShow?.protocol_keys[s]
                      ?.number_keys_returned_in_main_protocol ? (
                      <></>
                    ) : (
                      protocolToShow?.protocol_keys[s]?.key_number != 0 &&
                      protocolToShow?.protocol_keys[s]?.key_number != "" && (
                        <>
                          <div className="card-shadow Keys-Block">
                            <div className="after-cards ">
                              <div className="formAbschlussOvwerview  Padding-NewForm">
                                <div className="LigneSclusselDataOvwerview">
                                  <div
                                    className="blocks-SclusselOvwerview Keys-overview"
                                    style={{ alignItems: "center" }}
                                  >
                                    <div className="col-md-12 pr-0 pl-0">
                                      <img
                                        className="line-Image"
                                        src={Line}
                                        alt="notvalidateSclusselOvwerview Icon"
                                        style={{ float: "left" }}
                                      />
                                      <div
                                        style={{
                                          display: "flex",
                                          flexWrap: "nowrap",
                                          alignItems: "baseline",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {parseInt(
                                          protocolToShow?.protocol_keys[s]
                                            ?.key_number
                                        ) >
                                        protocolToShow?.protocol_keys[s]
                                          ?.number_keys_returned_in_main_protocol ? (
                                          <img
                                            className="notvalidateSclusselOvwerviewIcon"
                                            src={NotValidIcon}
                                            alt="notvalidateSclusselOvwerview Icon"
                                            style={{
                                              paddingTop: "0",
                                              height: "20px",
                                              width:"20px"
                                            }}
                                          />
                                        ) : (
                                          <img
                                            className="validateSclusselOvwerviewIcon"
                                            src={ValidIcon}
                                            alt="validateSclusselOvwerview Icon"
                                            style={{
                                              paddingTop: "0",
                                              height: "20px",
                                            }}
                                          />
                                        )}
                                        <p
                                          style={{
                                            marginTop: "0px",
                                            marginBottom: "3px",
                                            fontSize: "1.15rem",
                                          }}
                                        >
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type === "mainEntrance" && (
                                            <span> Eingangstür </span>
                                          )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type === "apartmentKey" && (
                                            <span> Wohnungsschlüssel </span>
                                          )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type === "basement" && (
                                            <span> Keller </span>
                                          )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type === "attic" && (
                                            <span> Dachboden </span>
                                          )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type === "garage" && (
                                            <span> Garage </span>
                                          )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type === "shed" && (
                                            <span> Schuppen </span>
                                          )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type === "outerGate" && (
                                            <span> Aussentor </span>
                                          )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type === "goal" && (
                                            <span> Tor </span>
                                          )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type === "gazebo" && (
                                            <span> Gartenlaube </span>
                                          )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type === "letterBox" && (
                                            <span> Briefkasten </span>
                                          )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type === "frontDoor" && (
                                            <span> Haustür </span>
                                          )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_type === "other" && (
                                            <span>
                                              {
                                                protocolToShow?.protocol_keys[
                                                  s
                                                ]?.key_name
                                              }
                                            </span>
                                          )}
                                          {protocolToShow?.protocol_keys[s]
                                            ?.key_number &&
                                          protocolToShow?.protocol_keys[s]
                                            ?.key_number.length > 0 ? (
                                            <span>
                                              {"schlüssel"}:{" "}{
                                                protocolToShow?.protocol_keys[
                                                  s
                                                ]?.key_number
                                              }
                                            </span>
                                          ) : (
                                            <span>: 0 </span>
                                          )}
                                        </p>
                                        <p>
                                        <span >
                                            erhalten :{" "}
                                            {protocolToShow?.protocol_keys[s]
                                              ?.number_keys_returned_in_main_protocol
                                              ? protocolToShow?.protocol_keys[
                                                  s
                                                ]
                                                  ?.number_keys_returned_in_main_protocol
                                              : 0}{" "}
                                            
                                          </span>
                                        </p>
                                        <p>
                                        <span >
                                        nachgereicht: {
                                                protocolToShow.protocol_keys[s]
                                                  .number_keys_returned
                                              }
                                          </span>
                                        </p>
                                        <p className={`StatusKeyLabel ${parseInt(
                                                 protocolToShow?.protocol_keys[
                                                 s
                                                 ]?.key_number
                                               ) >
                                               protocolToShow?.protocol_keys[s]
                                                 ?.number_keys_returned_in_main_protocol
                                                 ? "Missing"
                                                 :  "correct" }`}>
                                        Status: {parseInt(
                                                 protocolToShow?.protocol_keys[
                                                 s
                                                 ]?.key_number
                                               ) >
                                               protocolToShow?.protocol_keys[s]
                                                 ?.number_keys_returned_in_main_protocol
                                                 ? "Fehlend"
                                                 :  "Vollständig" }
                                        </p>
                                      </div>
                                    </div>
                                    </div>
                                    </div>
                                    <div className="row w-100 mt-1 mb-1">

                                    <div className="col-md-3">
                                    <TextField
                                          type="text"
                                          placeholder="Übergabedatum"
                                          label={"Übergabedatum"}
                                          id="key_date_of_forwarding"
                                          value={protocolToShow?.protocol_keys[s]
                                            ?.key_date_of_forwarding
                                            ? protocolToShow.protocol_keys[s]
                                                ?.key_date_of_forwarding
                                            : "K.A"}
                                          multiline={false}
                                          fullWidth
                                          InputProps={{ readOnly: true,
                                            startAdornment: (
                                            <InputAdornment position="start">
                                              <img
                                                className="auftrageCalendarIcon"
                                                src={CalenderIcon}
                                                alt="Auftrage Calendar Icon"
                                              />
                                            </InputAdornment>
                                          ), }}
                                          InputLabelProps={{
                                            shrink: true,
                                        }}
                                        />
                                    </div>
                                    <div className="col-md-9"
                                      style={{ alignItems: "center" }}
                                    >
                                        <TextField
                                          type="text"
                                          placeholder="Information"
                                          label={"Information"}
                                          id="key_notice"
                                          value={protocolToShow?.protocol_keys[s]
                                            ?.key_notice
                                            ? protocolToShow?.protocol_keys[s]
                                                ?.key_notice
                                            : "Kein Kommentar"}
                                          multiline={false}
                                          fullWidth
                                          InputProps={{ readOnly: true,}}
                                          InputLabelProps={{
                                            shrink: true,
                                        }}
                                        />
                                    </div>
                                    </div>
                                    <div className="row w-100 keys-protocol-control">
                                    <div className="col-md-2"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {protocolToShow?.protocol_keys[s]
                                        .image &&
                                      protocolToShow?.protocol_keys[s]?.image
                                        ?.key_image_remote_url != null ? (
                                        <div className="image--container" >
                                          {showImage &&
                                            protocolToShow?.protocol_keys[s]
                                              ?.image?.id == idImageShow && (
                                              <div
                                                className="image-overlay"
                                                onClick={() =>
                                                  toggleImage(
                                                    protocolToShow
                                                      ?.protocol_keys[s]
                                                      ?.image?.id
                                                  )
                                                }
                                              >
                                                <img
                                                  src={
                                                    protocolToShow
                                                      ?.protocol_keys[s]
                                                      ?.image
                                                      ?.key_image_remote_url
                                                  }
                                                  alt={
                                                    protocolToShow
                                                      ?.protocol_keys[s]
                                                      ?.image?.key_image_name
                                                  }
                                                  className="image"
                                                />
                                              </div>
                                            )}
                                          {!showImage && (
                                            <div
                                              className="icon overview"
                                              onClick={() =>
                                                toggleImage(
                                                  protocolToShow
                                                    ?.protocol_keys[s]?.image
                                                    ?.id
                                                )
                                              }
                                            >
                                               <i className="fas fa-search-plus"></i>
                                            </div>
                                          )}
                                          <img
                                            src={
                                              protocolToShow?.protocol_keys[s]
                                                ?.image?.key_image_remote_url
                                            }
                                            alt={
                                              protocolToShow?.protocol_keys[s]
                                                ?.image?.key_image_name
                                            }
                                            style={{
                                              objectFit: "cover",
                                              width: "7.8rem",
                                              height: "100px",
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div className="image-container">
                                          <PhotoLibraryIcon
                                            className="defaultImageIcon"
                                            sx={{
                                              width: "7.8rem",
                                              height: "100px",
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-md-10 w-100">
                                    {window.location.pathname.startsWith(
                                      "/app/show_protocolcontrol"
                                    ) && (
                                      <TextField
                                        InputLabelProps={{
                                          style: {
                                            textTransform: "uppercase",
                                            padding: "0 5px 0 0",
                                            backgroundColor: "white",
                                          },
                                        }}
                                        name="controle_comment"
                                        value={
                                          protocolToShow?.protocol_keys[s]
                                            ?.controle_comment
                                        }
                                        id="controle_comment"
                                        type="text"
                                        placeholder={"KOMMENTAR"}
                                        variant="outlined"
                                        rows="3"
                                        InputProps={{ readOnly: true,}}
                                        multiline={true}
                                        fullWidth
                                      />
                                    )}
                                    </div>
                                    </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    )
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </>
          ) : null}
          {(window.location.pathname.startsWith(
            "/app/update_protocolcontrol"
          ) ||
            window.location.pathname.startsWith("/app/protocolcontrol")) ? (
            <>
              <div className="SonCard protocol-kontrol p-0">
                <div className="wohnungsdaten withoutBorderRadius">
                  <div className="first_wohnungsdaten w-100 withoutBorderRadius">
                    <div className="padding-center-withoutLeftandRght w-100 h-full">
                      <div className="float-left">
                        <div className="d-flex align-items-center">
                          <img
                            className="WohnungsdatenIcon"
                            src={WohnungsdatenUserIcon}
                            alt="eigentumer Icon"
                          />
                          <h3>Protokollant</h3>
                        </div>
                      </div>
                      <div
                        className="float-right"
                        onClick={handleClick}
                        ref={buttonRef}
                      >
                        <div className="d-flex align-items-center">
                          <h3>Protokollant/in neu aufnehmen</h3>
                          <AddOutlinedIcon
                            className="icon-bewohnerButton"
                            sx={{ fontSize: 27 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() =>
                        handleOptionClick({
                          label: "Protokollant/in neu aufnehmen",
                          value: "new_note_taker",
                        })
                      }
                    >
                      <span className="w-100">
                        {" "}
                        Protokollant/in neu aufnehmen
                      </span>
                      <AddOutlinedIcon
                        className="icon-bewohnerButton"
                        sx={{ fontSize: 27, color: "rgb(95, 95, 95)" }}
                      />
                    </MenuItem>
                    <hr className="hr-deffect" />
                    <MenuItem
                      onClick={() =>
                        handleOptionClick({
                          label: "Protkollant/in aus Wohnungsgeber übernehmen",
                          value: "note_taker_from_landlord",
                        })
                      }
                    >
                      <span className="w-100">
                        {" "}
                        Protkollant/in aus Wohnungsgeber übernehmen
                      </span>
                      <img
                        className="MenuActiontIcon filter-black"
                        src={OpenProtocol}
                        alt="Aufträge Icon"
                      />
                    </MenuItem>
                    <hr className="hr-deffect" />
                    <MenuItem
                      onClick={() =>
                        handleOptionClick({
                          label:
                            "Protokollant/in aus Benutzerkonto der App übernehmen",
                          value: "note_taker_from_current_user",
                        })
                      }
                    >
                      <span className="w-100">
                        {" "}
                        Protokollant/in aus Benutzerkonto der App übernehmen
                      </span>{" "}
                      <img
                        className="MenuActiontIcon  filter-black"
                        src={OpenProtocol}
                        alt="Aufträge Icon"
                      />
                    </MenuItem>
                  </Menu>
                </div>
                  <div className="after-cards">
                    <div className="textfieldsdateplzObOw">
                      <div style={{ padding: "10px 20px" }}>
                        <img
                          className="line-Image mt-1 pr-2"
                          src={Line}
                          alt="notvalidateSclusselOvwerview Icon"
                          style={{ float: "left" }}
                        />
                        <div className="row mt-2 mb-2">
                          <div
                            className="col-md-1 "
                            style={{ padding: "5px 5px 5px 0px" }}
                          >
                            <FormControl style={{ width: "100%" }}>
                              <InputLabel
                                onClick={() => handleInputClick}
                                inputRef={(el) => (inputRefs.current[16] = el)}
                                id="note_taker_gender"
                                style={{
                                  backgroundColor: "white",
                                  padding: "0 5px 0 0",
                                }}
                                shrink={
                                  (shrinkLabel.note_taker_gender &&
                                    protocol &&
                                    protocol.note_taker &&
                                    protocol.note_taker.note_taker_gender) ||
                                  (!shrinkLabel.note_taker_gender &&
                                    protocol &&
                                    protocol.note_taker &&
                                    protocol.note_taker.note_taker_gender) ||
                                  (shrinkLabel.note_taker_gender &&
                                    protocol &&
                                    protocol.note_taker &&
                                    (protocol.note_taker.note_taker_gender ===
                                      null ||
                                      protocol.note_taker.note_taker_gender ===
                                        ""))
                                }
                              >
                                ANREDE WÄHLEN *
                              </InputLabel>

                              <Select
                                labelId="note_taker_gender"
                                id="note_taker_gender"
                                value={protocol?.note_taker?.note_taker_gender}
                                label="ANREDE WÄHLEN"
                                name="note_taker_gender"
                                onChange={(e) => handleNoteTakerChange(e)}
                                fullWidth
                                required
                                inputProps={{ "aria-label": "My Select" }}
                                InputLabelProps={
                                  protocol &&
                                  protocol.note_taker &&
                                  protocol.note_taker.note_taker_gender && {
                                    shrink: true,
                                  }
                                }
                              >
                                <MenuItem value={"noChoice"}>
                                  Keine Angabe
                                </MenuItem>
                                <MenuItem value={"male"}>Herr</MenuItem>
                                <MenuItem value={"female"}>Frau</MenuItem>
                                <MenuItem value={"divers"}>Divers</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <div className="col-md-2 " style={{ padding: "5px" }}>
                            <TextField
                              onClick={(e) => {
                                handleInputClick(e);
                                handleClickTextfield(
                                  "title",
                                  protocol?.note_taker?.note_taker_title,
                                  0,
                                  null,
                                  "TITEL WÄHLEN",
                                  null,
                                  "note_taker_title"
                                );
                              }}
                              InputLabelProps={{
                                style: {
                                  textTransform: "uppercase",
                                  padding: "0 5px 0 0",
                                  backgroundColor: "white",
                                },
                                shrink:
                                  (shrinkLabel.note_taker_title &&
                                    protocol &&
                                    protocol.note_taker &&
                                    protocol.note_taker.note_taker_title) ||
                                  (!shrinkLabel.note_taker_title &&
                                    protocol &&
                                    protocol.note_taker &&
                                    protocol.note_taker.note_taker_title) ||
                                  (shrinkLabel.note_taker_title &&
                                    protocol &&
                                    protocol.note_taker &&
                                    (protocol.note_taker.note_taker_title ===
                                      null ||
                                      protocol.note_taker.note_taker_title ===
                                        "")),
                              }}
                              name="title"
                              id="title"
                              type="text"
                              label="TITEL WÄHLEN"
                              variant="outlined"
                              fullWidth
                              value={formatTitle(
                                protocol?.note_taker?.note_taker_title
                              )}
                              onChange={(e) => handleNoteTakerChange(e)}
                            />
                          </div>
                          <div className="col-md-4 " style={{ padding: "5px" }}>
                            <TextField
                              onClick={() => handleInputClick}
                              inputRef={(el) => (inputRefs.current[15] = el)}
                              InputLabelProps={{
                                style: {
                                  textTransform: "uppercase",
                                  padding: "0 5px 0 0",
                                  backgroundColor: "white",
                                },
                                shrink:
                                  (shrinkLabel.note_taker_first_name &&
                                    protocol &&
                                    protocol.note_taker &&
                                    protocol.note_taker
                                      .note_taker_first_name) ||
                                  (!shrinkLabel.note_taker_first_name &&
                                    protocol &&
                                    protocol.note_taker &&
                                    protocol.note_taker
                                      .note_taker_first_name) ||
                                  (shrinkLabel.note_taker_first_name &&
                                    protocol &&
                                    protocol.note_taker &&
                                    (protocol.note_taker
                                      .note_taker_first_name === null ||
                                      protocol.note_taker
                                        .note_taker_first_name === "")),
                              }}
                              name="note_taker_first_name"
                              id="note_taker_first_name"
                              value={
                                protocol?.note_taker?.note_taker_first_name ||
                                ""
                              }
                              type="text"
                              label="VORNAME"
                              required
                              variant="outlined"
                              fullWidth
                              onChange={(e) => handleNoteTakerChange(e)}
                            />
                          </div>
                          <div className="col-md-5 " style={{ padding: "5px" }}>
                            <TextField
                              onClick={() => handleInputClick}
                              inputRef={(el) => (inputRefs.current[17] = el)}
                              InputLabelProps={{
                                style: {
                                  textTransform: "uppercase",
                                  padding: "0 5px 0 0",
                                  backgroundColor: "white",
                                },
                                shrink:
                                  (shrinkLabel.note_taker_last_name &&
                                    protocol &&
                                    protocol.note_taker &&
                                    protocol.note_taker.note_taker_last_name) ||
                                  (!shrinkLabel.note_taker_last_name &&
                                    protocol &&
                                    protocol.note_taker &&
                                    protocol.note_taker.note_taker_last_name) ||
                                  (shrinkLabel.note_taker_last_name &&
                                    protocol &&
                                    protocol.note_taker &&
                                    (protocol.note_taker
                                      .note_taker_last_name === null ||
                                      protocol.note_taker
                                        .note_taker_last_name === "")),
                              }}
                              name="note_taker_last_name"
                              id="note_taker_last_name"
                              value={
                                protocol?.note_taker?.note_taker_last_name ==
                                null
                                  ? ""
                                  : protocol?.note_taker?.note_taker_last_name
                              }
                              type="text"
                              required
                              label="NACHNAME"
                              variant="outlined"
                              fullWidth
                              onChange={(e) => handleNoteTakerChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </>
          )
          :(
            <>
              <div className="SonCard protocol-kontrol p-0">
                <div className="wohnungsdaten withoutBorderRadius">
                  <div className="first_wohnungsdaten w-100 withoutBorderRadius">
                    <div className="padding-center-withoutLeftandRght w-100 h-full">
                        <div className="d-flex align-items-center">
                          <img
                            className="WohnungsdatenIcon"
                            src={WohnungsdatenUserIcon}
                            alt="eigentumer Icon"
                          />
                          <h3>Protokollant</h3>
                        </div>
                    </div>
                  </div>
                </div>
                  <div className="after-cards">
                    <div className="textfieldsdateplzObOw">
                      <div style={{ padding: "10px 20px" }}>
                        <img
                          className="line-Image mt-1 pr-2"
                          src={Line}
                          alt="notvalidateSclusselOvwerview Icon"
                          style={{ float: "left" }}
                        />
                        <div className="row mt-2 mb-2">
                          <div
                            className="col-md-1 "
                            style={{ padding: "5px 5px 5px 0px" }}
                          >
                            <TextField
                              InputLabelProps={{
                                style: {
                                  textTransform: "uppercase",
                                  padding: "0 5px 0 0",
                                  backgroundColor: "white",
                                },
                                shrink: true,
                              }}
                              name="note_taker_gender"
                              id="note_taker_gender"
                              type="text"
                              label="ANREDE"
                              variant="outlined"
                              fullWidth
                              value={protocol?.note_taker?.note_taker_gender === "male"? "Herr"
                              : protocol?.note_taker?.note_taker_gender === "female"? "Frau"
                              : protocol?.note_taker?.note_taker_gender === "divers"? "Divers"
                              :"Keine Angabe"
                              }
                              InputProps={{ readOnly: true,}}
                            />
                          </div>
                          <div className="col-md-2 " style={{ padding: "5px" }}>
                            <TextField
                              InputLabelProps={{
                                style: {
                                  textTransform: "uppercase",
                                  padding: "0 5px 0 0",
                                  backgroundColor: "white",
                                },
                                shrink: true,
                              }}
                              name="title"
                              id="title"
                              type="text"
                              label="TITEL WÄHLEN"
                              variant="outlined"
                              fullWidth
                              value={protocol?.note_taker?.note_taker_title ||"Keine Angabe"}
                              InputProps={{ readOnly: true,}}
                            />
                          </div>
                          <div className="col-md-4 " style={{ padding: "5px" }}>
                            <TextField
                              InputLabelProps={{
                                style: {
                                  textTransform: "uppercase",
                                  padding: "0 5px 0 0",
                                  backgroundColor: "white",
                                },
                                shrink: true,
                              }}
                              name="note_taker_first_name"
                              id="note_taker_first_name"
                              value={
                                protocol?.note_taker?.note_taker_first_name ||
                                ""
                              }
                              type="text"
                              label="VORNAME"
                              required
                              variant="outlined"
                              fullWidth
                              InputProps={{ readOnly: true,}}
                            />
                          </div>
                          <div className="col-md-5 " style={{ padding: "5px" }}>
                            <TextField
                              InputLabelProps={{
                                style: {
                                  textTransform: "uppercase",
                                  padding: "0 5px 0 0",
                                  backgroundColor: "white",
                                },
                                shrink: true,
                              }}
                              name="note_taker_last_name"
                              id="note_taker_last_name"
                              value={
                                protocol?.note_taker?.note_taker_last_name ==
                                null
                                  ? ""
                                  : protocol?.note_taker?.note_taker_last_name
                              }
                              type="text"
                              required
                              label="NACHNAME"
                              variant="outlined"
                              fullWidth
                              InputProps={{ readOnly: true,}}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </>
          )
        }

          {window.location.pathname.startsWith("/app/show_protocolcontrol") ? (
            <>
              <div className="SonCard mt-3 mb-3">
                <div
                  className="neuer  withoutBorderRadius"
                  style={{
                    background:
                      "linear-gradient(95deg, rgb(98, 171, 18) 0%, rgb(98, 171, 18) 50%, rgb(151, 193, 88) 100%)",
                  }}
                >
                  <img
                    className="NeuerIcon"
                    src={ContractIcon}
                    alt="Abschlussbemerkung Icon"
                  />
                  <h3 style={{ textTransform: "none" }}> Unterschriften </h3>
                </div>
                <div className="card-shadow">
                  <div className={`VerteilerContainer ${protocolToShow?.landlord?.signature_landlord
                          ?.signature_landlord != true && "Missing"}`}>
                    <div className="formAbschlussOvwerview Padding-NewForm-signature ">
                      <div className="sub-formverteilerOvwerview">
                        <div className="first-part-formverteilerOvwerview signature-Block">
                          <div className="LigneWohnungsdatenOvwerview">
                            <span>Wohnungsgeber</span>
                          </div>
                          <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                            <div style={{ width: "100%" }}>
                              <span style={{ fontSize: "700" }}>
                                {protocolToShow?.landlord
                                  ?.landlord_salutation == null &&
                                  "Anrede fehlt "}
                                {protocolToShow?.landlord
                                  ?.landlord_salutation == "noChoice" &&
                                  "Keine Angabe "}
                                {protocolToShow?.landlord
                                  ?.landlord_salutation == "male" && "Herr "}
                                {protocolToShow?.landlord
                                  ?.landlord_salutation == "female" && "Frau "}
                              </span>
                              <br />
                              <span style={{ fontSize: "700" }}>
                                {protocolToShow?.landlord
                                  ?.landlord_first_name == null &&
                                  protocolToShow?.landlord
                                    ?.landlord_last_name == null && (
                                    <span>Name und Vorname fehlt</span>
                                  )}
                                {(protocolToShow?.landlord
                                  ?.landlord_first_name == null ||
                                  protocolToShow?.landlord
                                    ?.landlord_first_name == "") &&
                                  protocolToShow?.landlord
                                    ?.landlord_last_name != null && (
                                    <>
                                      {protocolToShow?.landlord
                                        ?.landlord_first_name
                                        ? protocolToShow?.landlord
                                            ?.landlord_first_name
                                        : " Vorname fehlt "}
                                    </>
                                  )}
                                {protocolToShow?.landlord
                                  ?.landlord_first_name != null &&
                                  (protocolToShow?.landlord
                                    ?.landlord_last_name == null ||
                                    protocolToShow?.landlord
                                      ?.landlord_last_name == "") && (
                                    <>
                                      {protocolToShow?.landlord
                                        ?.landlord_last_name
                                        ? protocolToShow?.landlord
                                            ?.landlord_last_name
                                        : " Nachname fehlt "}
                                    </>
                                  )}
                                {protocolToShow?.landlord
                                  ?.landlord_first_name != null &&
                                  protocolToShow?.landlord
                                    ?.landlord_last_name != null && (
                                    <>
                                      {
                                        protocolToShow?.landlord
                                          ?.landlord_first_name
                                      }{" "}
                                      {
                                        protocolToShow?.landlord
                                          ?.landlord_last_name
                                      }
                                    </>
                                  )}
                              </span>
                            </div>
                          </div>
                        </div>
                        {protocolToShow?.landlord?.signature_landlord
                          ?.signature_landlord === true ? (
                          <>
                            <div className="second-part-formverteilerOvwerview signature-Block" >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  border:"1px solid rgb(169, 169, 169)",
                                  padding:"20px 10px"
                                }}
                              >
                                <img
                                  src={
                                    protocolToShow?.landlord?.signature_landlord
                                      ?.signature_landlord_image_remote_url
                                  }
                                  alt={
                                    protocolToShow?.landlord?.signature_landlord
                                      ?.signature_landlord_image_name
                                  }
                                  height="125px"
                                  width="125px"
                                />
                              </div>
                              <div className=" text-align-end">
                              <span
                                  style={{ fontSize: "700", color: "#000000" }}
                                >
                                  {protocolToShow?.landlord?.signature_landlord
                                    ?.signature_landlord_time_stamp !== null
                                    ? moment
                                        .unix(
                                          protocolToShow?.landlord
                                            ?.signature_landlord
                                            ?.signature_landlord_time_stamp
                                            ?.seconds
                                        )
                                        .add(
                                          protocolToShow?.landlord
                                            ?.signature_landlord
                                            ?.signature_landlord_time_stamp
                                            ?.nanoseconds / 1000000
                                        )
                                        .format("[unterzeichnet am] DD.MM.YYYY, [um] HH:mm [Uhr]")
                                    : "Kein Angabe"}
                                </span>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="second-part-formverteilerOvwerview signature-Block">
                            <PhotoLibraryIcon
                              className="defaultImageIcon"
                              sx={{ width: 425, height: 200 }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <hr className="hrOvwerview" />
                  </div>

                  <div className={`VerteilerContainer ${protocolToShow?.renter?.signature_renter?.signature_renter != true && "Missing"}`}>
                    <div className="formAbschlussOvwerview Padding-NewForm-signature ">
                      <div className="sub-formverteilerOvwerview">
                        <div className="first-part-formverteilerOvwerview signature-Block">
                          <div className="LigneWohnungsdatenOvwerview">
                            <span>Mieter</span>
                          </div>
                          <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                            <div style={{ width: "100%" }}>
                              <span style={{ fontSize: "700" }}>
                                {protocolToShow?.renter?.renter_salutation ==
                                  null && "Anrede fehlt "}
                                {protocolToShow?.renter?.renter_salutation ==
                                  "noChoice" && "Keine Angabe "}
                                {protocolToShow?.renter?.renter_salutation ==
                                  "male" && "Herr "}
                                {protocolToShow?.renter?.renter_salutation ==
                                  "female" && "Frau "}
                              </span>
                              <br />
                              <span style={{ fontSize: "700" }}>
                                {protocolToShow?.renter?.renter_first_name ==
                                  null &&
                                  protocolToShow?.renter?.renter_last_name ==
                                    null && <span>Name und Vorname fehlt</span>}
                                {(protocolToShow?.renter?.renter_first_name ==
                                  null ||
                                  protocolToShow?.renter?.renter_first_name ==
                                    "") &&
                                  protocolToShow?.renter?.renter_last_name !=
                                    null && (
                                    <>
                                      {protocolToShow?.renter?.renter_first_name
                                        ? protocolToShow?.renter
                                            ?.renter_first_name
                                        : " Vorname fehlt "}
                                    </>
                                  )}
                                {protocolToShow?.renter?.renter_first_name !=
                                  null &&
                                  (protocolToShow?.renter?.renter_last_name ==
                                    null ||
                                    protocolToShow?.renter?.renter_last_name ==
                                      "") && (
                                    <>
                                      {protocolToShow?.renter?.renter_last_name
                                        ? protocolToShow?.renter
                                            ?.renter_last_name
                                        : " Nachname fehlt "}
                                    </>
                                  )}
                                {protocolToShow?.renter?.renter_first_name !=
                                  null &&
                                  protocolToShow?.renter?.renter_last_name !=
                                    null && (
                                    <>
                                      {
                                        protocolToShow?.renter
                                          ?.renter_first_name
                                      }{" "}
                                      {protocolToShow?.renter?.renter_last_name}
                                    </>
                                  )}
                              </span>
                            </div>
                          </div>
                          <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                          {protocolToShow?.renter?.signature_renter
                          ?.signature_renter === true ? (
                          <> </>
                        ) : (
                          protocolToShow?.renter?.signature_renter
                            ?.signature_renter === false && (
                            <div style={{ width: "100%" }}>
                              {protocolToShow?.renter?.signature_renter
                                ?.signature_renter_refuse_reason ? (
                                <span  className="bad-status ml-0"  >
                                  <img
                                    className="notvalidateSclusselOvwerviewIcon"
                                    src={NotValidIcon}
                                    alt="notvalidateSclusselOvwerview Icon"
                                    style={{
                                      width: "1rem",
                                      marginRight: "0.25rem",
                                    }}
                                  />
                                  {formatReason(
                                    protocolToShow?.renter?.signature_renter
                                      ?.signature_renter_refuse_reason
                                  )}
                                </span>
                              ) : (
                                <span className="bad-status ml-0" >
                                  <img
                                    className="notvalidateSclusselOvwerviewIcon"
                                    src={NotValidIcon}
                                    alt="notvalidateSclusselOvwerview Icon"
                                    style={{
                                      width: "1rem",
                                      marginRight: "0.25rem",
                                    }}
                                  />
                                  Keine Unterschrift
                                </span>
                              )}
                            </div>
                          )
                        )}
                          </div>
                        </div>
                        {protocolToShow?.renter?.signature_renter
                          ?.signature_renter === true ? (
                          <>
                            <div className="second-part-formverteilerOvwerview signature-Block">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  border:"1px solid rgb(169, 169, 169)",
                                  padding:"20px 10px"
                                }}
                              >
                                <img
                                  src={
                                    protocolToShow?.renter?.signature_renter
                                      ?.signature_renter_image_remote_url
                                  }
                                  alt={
                                    protocolToShow?.renter?.signature_renter
                                      ?.signature_renter_image_name
                                  }
                                  height="125px"
                                  width="125px"
                                />
                              </div>
                              <div className=" text-align-end">
                                <span
                                  style={{ fontSize: "700", color: "#000000" }}
                                >
                                  {protocolToShow?.renter?.signature_renter
                                    ?.signature_renter_time_stamp !== null
                                    ? moment
                                        .unix(
                                          protocolToShow?.renter
                                            ?.signature_renter
                                            ?.signature_renter_time_stamp
                                            ?.seconds
                                        )
                                        .add(
                                          protocolToShow?.renter
                                            ?.signature_renter
                                            ?.signature_renter_time_stamp
                                            ?.nanoseconds / 1000000
                                        )
                                        .format("[unterzeichnet am] DD.MM.YYYY, [um] HH:mm [Uhr]")
                                    : "Kein Angabe"}
                                </span></div>
                            </div>
                          </>
                        ) : (
                          <div className="second-part-formverteilerOvwerview signature-Block">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  border:"1px solid rgb(169, 169, 169)",
                                  padding:"20px 10px"
                                }}
                              >
                                <img
                                  src={CrossIcon}
                                  alt={"Missing signature"}
                                  height="125px"
                                  width="125px"
                                  style={{width:"auto"}}
                                />
                              </div>
                            </div>
                        )}
                      </div>
                    </div>
                    <hr className="hrOvwerview" />
                  </div>

                  {protocolToShow?.coRenter?.signature_coRenter?.signature ===
                  false ? null : (
                    <div className={`VerteilerContainer ${protocolToShow?.renter?.signature_renter?.signature_renter != true && "Missing"}`}>
                      <div className="formAbschlussOvwerview Padding-NewForm-signature ">
                        <div className="sub-formverteilerOvwerview">
                          <div className="first-part-formverteilerOvwerview signature-Block">
                            <div className="LigneWohnungsdatenOvwerview">
                              <span>Bewohner</span>
                            </div>
                            <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                              <div style={{ width: "100%" }}>
                                <span style={{ fontSize: "700" }}>
                                  {protocolToShow?.coRenter?.salutaion ==
                                    null && "Anrede fehlt "}
                                  {protocolToShow?.coRenter?.salutaion ==
                                    "noChoice" && "Keine Angabe "}
                                  {protocolToShow?.coRenter?.salutaion ==
                                    "male" && "Herr "}
                                  {protocolToShow?.coRenter?.salutaion ==
                                    "female" && "Frau "}
                                </span>
                                <br />
                                <span style={{ fontSize: "700" }}>
                                  {protocolToShow?.coRenter?.first_name ==
                                    null &&
                                    protocolToShow?.coRenter?.last_name ==
                                      null && (
                                      <span>Name und Vorname fehlt</span>
                                    )}
                                  {(protocolToShow?.coRenter?.first_name ==
                                    null ||
                                    protocolToShow?.coRenter?.first_name ==
                                      "") &&
                                    protocolToShow?.coRenter?.last_name !=
                                      null && (
                                      <>
                                        {protocolToShow?.coRenter?.first_name
                                          ? protocolToShow?.coRenter?.first_name
                                          : " Vorname fehlt "}
                                      </>
                                    )}
                                  {protocolToShow?.coRenter?.first_name !=
                                    null &&
                                    (protocolToShow?.coRenter?.last_name ==
                                      null ||
                                      protocolToShow?.coRenter?.last_name ==
                                        "") && (
                                      <>
                                        {protocolToShow?.coRenter?.last_name
                                          ? protocolToShow?.coRenter?.last_name
                                          : " Nachname fehlt "}
                                      </>
                                    )}
                                  {protocolToShow?.coRenter?.first_name !=
                                    null &&
                                    protocolToShow?.coRenter?.last_name !=
                                      null && (
                                      <>
                                        {protocolToShow?.coRenter?.first_name}{" "}
                                        {protocolToShow?.coRenter?.last_name}
                                      </>
                                    )}
                                </span>
                              </div>
                            </div>
                            {protocolToShow?.coRenter?.signature_coRenter
                            ?.signature === true && (
                            <>
                              <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                                {protocolToShow?.coRenter?.signature_coRenter
                                  ?.refuse_reason ? (
                                  <span className="bad-status ml-0" >
                                    <img
                                      className="notvalidateSclusselOvwerviewIcon"
                                      src={NotValidIcon}
                                      alt="notvalidateSclusselOvwerview Icon"
                                      style={{
                                        width: "1rem",
                                        marginRight: "0.25rem",
                                      }}
                                    />
                                    {formatReason(
                                      protocolToShow?.coRenter
                                        ?.signature_coRenter?.refuse_reason
                                    )}
                                  </span>
                                ) : (
                                  <>
                                  </>
                                )}
                              </div>
                            </>
                          )}
                          </div>
                          {protocolToShow?.coRenter?.signature_coRenter
                            ?.signature === true && (
                            <>
                              <div className="second-part-formverteilerOvwerview signature-Block">
                                {protocolToShow?.coRenter?.signature_coRenter
                                  ?.refuse_reason ? (
                                    <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      border:"1px solid rgb(169, 169, 169)",
                                      padding:"20px 10px"
                                    }}
                                  >
                                    <img
                                      src={CrossIcon}
                                      alt={"Missing signature"}
                                      height="125px"
                                      width="125px"
                                      style={{width:"auto"}}
                                    />
                                  </div>
                                ) : (
                                  <>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      border:"1px solid rgb(169, 169, 169)",
                                      padding:"20px 10px"
                                    }}
                                  >
                                    <img
                                      src={
                                        protocolToShow?.coRenter
                                          ?.signature_coRenter?.remote_url
                                      }
                                      alt={
                                        protocolToShow?.coRenter
                                          ?.signature_coRenter?.image_name
                                      }
                                      height="125px"
                                      width="125px"
                                    />
                                  </div>
                                  <div className=" text-align-end">
                                    <span
                                      style={{
                                        fontSize: "700",
                                        color: "#000000",
                                      }}
                                    >
                                      {protocolToShow?.coRenter
                                        ?.signature_coRenter?.time_stamp !==
                                      null
                                        ? moment
                                            .unix(
                                              protocolToShow?.coRenter
                                                ?.signature_coRenter?.time_stamp
                                                ?.seconds
                                            )
                                            .add(
                                              protocolToShow?.coRenter
                                                ?.signature_coRenter?.time_stamp
                                                ?.nanoseconds / 1000000
                                            )
                                            .format("[unterzeichnet am] DD.MM.YYYY, [um] HH:mm [Uhr]")
                                        : "Kein Angabe"}
                                    </span>
                                  </div>
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <hr className="hrOvwerview" />
                    </div>
                  )}

                  {protocolToShow?.protocol_type == "moveInAndExit" ? (
                    <div className={`VerteilerContainer ${protocolToShow?.previous_renter?.signature_previous_renter?.signature_previous_renter  != true && "Missing"}`}>
                      <div className="formAbschlussOvwerview Padding-NewForm-signature ">
                        <div className="sub-formverteilerOvwerview">
                          <div className="first-part-formverteilerOvwerview signature-Block">
                            <div className="LigneWohnungsdatenOvwerview">
                              <span className="uppercase mb-2">Vormieter</span>
                            </div>
                            <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                              <div style={{ width: "100%" }}>
                                <span style={{ fontSize: "700" }}>
                                  {protocolToShow?.previous_renter
                                    ?.previous_renter_salutation == null &&
                                    "Anrede fehlt "}
                                  {protocolToShow?.previous_renter
                                    ?.previous_renter_salutation ==
                                    "noChoice" && "Keine Angabe "}
                                  {protocolToShow?.previous_renter
                                    ?.previous_renter_salutation == "male" &&
                                    "Herr "}
                                  {protocolToShow?.previous_renter
                                    ?.previous_renter_salutation == "female" &&
                                    "Frau "}
                                </span>
                                <br />
                                <span style={{ fontSize: "700" }}>
                                  {protocolToShow?.previous_renter
                                    ?.previous_renter_first_name
                                    ? protocolToShow?.previous_renter
                                        ?.previous_renter_first_name + " "
                                    : "Vorname fehlt"}
                                  {protocolToShow?.previous_renter
                                    ?.previous_renter_last_name
                                    ? protocolToShow?.previous_renter
                                        ?.previous_renter_last_name
                                    : "Nachname fehlt"}
                                </span>
                              </div>
                            </div>
                            <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                            {protocolToShow?.previous_renter
                                  ?.signature_previous_renter
                                  ?.signature_previous_renter_refuse_reason ? (
                                  <span className="bad-status ml-0">
                                    <img
                                      className="notvalidateSclusselOvwerviewIcon"
                                      src={NotValidIcon}
                                      alt="notvalidateSclusselOvwerview Icon"
                                      style={{
                                        width: "1rem",
                                        marginRight: "0.25rem",
                                      }}
                                    />
                                    {formatReason(
                                      protocolToShow?.previous_renter
                                        ?.signature_previous_renter
                                        ?.signature_previous_renter_refuse_reason
                                    )}
                                  </span>
                                ) : (
                                  <span className="bad-status ml-0"   >
                                    <img
                                      className="notvalidateSclusselOvwerviewIcon"
                                      src={NotValidIcon}
                                      alt="notvalidateSclusselOvwerview Icon"
                                      style={{
                                        width: "1rem",
                                        marginRight: "0.25rem",
                                      }}
                                    />
                                    Verweigert Unterschrift
                                  </span>
                                )}
                                </div>
                          </div>
                          {protocolToShow?.previous_renter
                            ?.signature_previous_renter
                            ?.signature_previous_renter === true ? (
                            <>
                              <div className="second-part-formverteilerOvwerview signature-Block">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    border:"1px solid rgb(169, 169, 169)",
                                    padding:"20px 10px"
                                  }}
                                >
                                  <img
                                    src={
                                      protocolToShow?.previous_renter
                                        ?.signature_previous_renter
                                        ?.signature_previous_renter_image_remote_url
                                    }
                                    alt={
                                      protocolToShow?.previous_renter
                                        ?.signature_previous_renter
                                        ?.signature_previous_renter_image_name
                                    }
                                    height="125px"
                                    width="125px"
                                  />
                                </div>
                                <div className=" text-align-end">
                                <span
                                    style={{
                                      fontSize: "700",
                                      color: "#000000",
                                    }}
                                  >
                                    {protocolToShow?.previous_renter
                                      ?.signature_previous_renter
                                      ?.signature_previous_renter_time_stamp !==
                                    null
                                      ? moment
                                          .unix(
                                            protocolToShow?.previous_renter
                                              ?.signature_previous_renter
                                              ?.signature_previous_renter_time_stamp
                                              ?.seconds
                                          )
                                          .add(
                                            protocolToShow?.previous_renter
                                              ?.signature_previous_renter
                                              ?.signature_previous_renter_time_stamp
                                              ?.nanoseconds / 1000000
                                          )
                                          .format("[unterzeichnet am] DD.MM.YYYY, [um] HH:mm [Uhr]")
                                      : "Kein Angabe"}
                                  </span>
                                </div>
                              </div>
                            </>
                          ) : (
                            protocolToShow?.previous_renter
                              ?.signature_previous_renter
                              ?.signature_previous_renter === false ? (
                                <div className="second-part-formverteilerOvwerview signature-Block">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  border:"1px solid rgb(169, 169, 169)",
                                  padding:"20px 10px"
                                }}
                              >
                                <img
                                  src={CrossIcon}
                                  alt={"Missing signature"}
                                  height="125px"
                                  width="125px"
                                  style={{width:"auto"}}
                                />
                              </div>
                            </div>
                             
                            )
                            :(
                              <div className="second-part-formverteilerOvwerview signature-Block">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                   border:"1px solid rgb(169, 169, 169)",
                                   padding:"20px 10px"
                                }}
                              >
                                <img
                                  src={
                                    protocolToShow?.previous_renter
                                      ?.signature_previous_renter
                                      ?.signature_previous_renter_image_remote_url
                                  }
                                  alt={
                                    protocolToShow?.previous_renter
                                      ?.signature_previous_renter
                                      ?.signature_previous_renter_image_name
                                  }
                                  height="125px"
                                  width="125px"
                                />
                              </div>
                            </div>
                            )
                          )}
                        </div>
                      </div>
                      <hr className="hrOvwerview" />
                    </div>
                  ) : null}

                  {protocolToShow?.witness_signature?.signature_witness ===false ? null : (
                    <div className={`VerteilerContainer ${protocolToShow?.witness_signature?.signature_witness_refuse_reason && "Missing"}`}>
                      <div className="formAbschlussOvwerview Padding-NewForm-signature ">
                        <div className="sub-formverteilerOvwerview">
                          <div className="first-part-formverteilerOvwerview signature-Block">
                            <div className="LigneWohnungsdatenOvwerview">
                              <span>Zeuge</span>
                            </div>
                            <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                              <div style={{ width: "100%" }}>
                                <span style={{ fontSize: "700" }}>
                                  {protocolToShow?.witness_signature
                                    ?.signature_witness_name !== null
                                    ? protocolToShow?.witness_signature
                                        ?.signature_witness_name
                                    : protocolToShow?.witness_signature
                                        ?.signature_witness_name === null && (
                                        <span>Name fehlt</span>
                                      )}
                                </span>
                              </div>
                            </div>
                            <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                            <span
                                className="bad-status ml-0">
                                <img
                                  className="notvalidateSclusselOvwerviewIcon"
                                  src={NotValidIcon}
                                  alt="notvalidateSclusselOvwerview Icon"
                                  style={{
                                    width: "1rem",
                                    marginRight: "0.25rem",
                                  }}
                                />
                                {formatReason(
                                  protocolToShow?.witness_signature
                                    ?.signature_witness_refuse_reason
                                )}
                              </span>
                            </div>
                          </div>
                          {protocolToShow?.witness_signature
                            ?.signature_witness === true &&
                            (protocolToShow?.witness_signature
                              ?.signature_witness_refuse_reason ? (
                                <div className="second-part-formverteilerOvwerview signature-Block">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  border:"1px solid rgb(169, 169, 169)",
                                  padding:"20px 10px"
                                }}
                              >
                                <img
                                  src={CrossIcon}
                                  alt={"Missing signature"}
                                  height="125px"
                                  width="125px"
                                  style={{width:"auto"}}
                                />
                              </div>
                            </div>
                              
                            ) : (
                              <>
                                <div className="second-part-formverteilerOvwerview signature-Block">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      border:"1px solid rgb(169, 169, 169)",
                                      padding:"20px 10px"
                                    }}
                                  >
                                    <img
                                      src={
                                        protocolToShow?.witness_signature
                                          ?.signature_witness_image_remote_url
                                      }
                                      alt={
                                        protocolToShow?.witness_signature
                                          ?.signature_witness_image_name
                                      }
                                      height="125px"
                                      width="125px"
                                    />
                                    
                                  </div>
                                  <div className=" text-align-end">
                                  <span
                                      style={{
                                        fontSize: "700",
                                        color: "#000000",
                                      }}
                                    >
                                      {protocolToShow?.witness_signature
                                        ?.signature_witness_time_stamp !== null
                                        ? moment
                                            .unix(
                                              protocolToShow?.witness_signature
                                                ?.signature_witness_time_stamp
                                                ?.seconds
                                            )
                                            .add(
                                              protocolToShow?.witness_signature
                                                ?.signature_witness_time_stamp
                                                ?.nanoseconds / 1000000
                                            )
                                            .format("[unterzeichnet am] DD.MM.YYYY, [um] HH:mm [Uhr]")
                                        : "Keine Angabe"}
                                    </span>
                                  </div>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>
                      <hr className="hrOvwerview" />
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : null}

          {(window.location.pathname.startsWith("/app/protocolcontrol") ||
            window.location.pathname.startsWith(
              "/app/update_protocolcontrol"
            )) && (
            <div class="SonCard">
              <div
                class="after-cards"
                style={{ backgroundColor: "transparent !important" }}
              >
                <div className="OvwerviewGlobalButton Protocol-control-Button-save">
                    <button type="button" onClick={onSubmit}
                        className="Save-button" >
                    Speichern
                  </button>
                  <button type="button" className="Save-button upload-pdf-button" 
                    onClick={()=> handleDownloadPdf(protocolToShow.id)}
                  >
                    <PictureAsPdfIcon  style={{ marginRight: "8px",color:"#b30b00",fontSize:"2rem" }} />
                    Protokoll herunterladen
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {show && (
        <Dialog
          setShow={setShow}
          show={show}
          //selects={selects}
          // setSelects={setSelects}
          //setValue={setValue}
          placeholder={placeholder}
          index={index}
          type={type}
          id={type}
          value={value}
          level={level}
          handleInputChange={
            realname == "note_taker_title"
              ? handleNoteTakerChange
              : handleChangeComment
          }
          secondIndex={secondIndex}
          realname={realname}
          //multiline={true}
        />
      )}
      {alert}
    </>
  );
}

export default ProtokollOvwerView;
