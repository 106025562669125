import React, { useContext, useState, useEffect, createContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { auth } from "../config/firebase";
import {
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
  updatePassword,
} from "firebase/auth";
import swal from "sweetalert";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const location = useLocation();
  const history = useHistory();

  // Signup and login
  const signup = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };
  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  // Logout
  const logout = () => {
    localStorage.removeItem("currentuser");
    localStorage.removeItem("switchedcustomer");
    return signOut(auth);
  };

  // Reset password
  const resetPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  // Update email
  const updateUserEmail = (email) => {
    return updateEmail(auth.currentUser, email);
  };

  // Reauthenticate user
  const reauthenticate = (currentEmail, currentPassword) => {
    const credential = EmailAuthProvider.credential(currentEmail, currentPassword);
    return reauthenticateWithCredential(auth.currentUser, credential);
  };

  // Change password
  const changePassword = async (currentEmail, currentPassword, newPassword) => {
    try {
      await reauthenticate(currentEmail, currentPassword);
      await updatePassword(auth.currentUser, newPassword);
      return "Password updated!";
    } catch (error) {
      throw new Error("Error updating password");
    }
  };

  // UseEffect to manage auth state and token refresh
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Check if token is expired and handle token refresh
  useEffect(() => {
    const refreshToken = async () => {
      if (!auth.currentUser || location.pathname === "/signin" || location.pathname === "/signup") {
        return;
      }

      try {
        const refreshedToken = await auth.currentUser.getIdToken(true); // Force token refresh
        setToken(refreshedToken); // Store the new token
      } catch (error) {
        if (error.code === "auth/user-token-expired") {
          console.log("Token expired. Logging out...");
          swal({
            title: "ACHTUNG",
            icon: "info",
            text: "Sie werden von der APP abgemeldet. Bitte nutzen Sie Ihre neuen Zugangsdaten um sich wieder anzumelden.",
            button: "Verstanden",
          }).then(async () => {
            await logout();
            history.push("/signin");
          });
        } else {
          console.error("Error during token refresh:", error);
        }
      }
    };

    const tokenInterval = setInterval(() => {
      refreshToken();
    }, 300000); // Refresh token every 5 minutes (adjust as needed)

    return () => clearInterval(tokenInterval); // Clear the interval on unmount
  }, [location, auth.currentUser, history]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        login,
        signup,
        logout,
        resetPassword,
        updateUserEmail,
        updateUserPassword: updatePassword,
        changePassword,
        reauthenticate,
        token, // Providing the token as part of context if needed
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const authContext = useContext(AuthContext);
  return authContext;
};

export default AuthProvider;
