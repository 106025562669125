import React, { useEffect, useRef, useState } from "react";
import wifiImage from "../../assets/Images/dsl/wifi (2).png";
import phoneImage from "../../assets/Images/dsl/phone (2).png";
import TvImage from "../../assets/Images/dsl/tv.png";
import pdf from "../../assets/Images/dsl/pdf2.PNG";
import phoneIcon from "../../assets/Images/dsl/phone-icon.png";
import mailIcon from "../../assets/Images/dsl/mail-icon.png";
import InfoIcon from "@mui/icons-material/Info";
import dollor from "../../assets/Images/dsl/moneyflow-290H.png";
import bgImage from "../../assets/Images/dsl/headerbg.jpg";
import { userService } from "../../_services/user.service";
import { useAuth } from "../../context/AuthContext";
import { companyService } from "../../_services/company.service";

function DSLPage() {
  const [user, setUser] = useState({});
  const [company, setCompany] = useState({});

  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchinfouser_company = async () => {
      const userDoc = await userService.getUser(userService.getId());
      userDoc.forEach(async (doc) => {
        setUser(doc.data());
        if (doc.data().company_id) {
          let company = await companyService.getCompany(doc.data().company_id);
          if (company) {
            setCompany(company);
          }
        }
      });
    };
    fetchinfouser_company();
  }, [currentUser]);
  return (
    <div className="outer-container pt-0">
      <div className="card-style">
        <div className="SonCard" style={{ maxWidth: "1100px" }}>
          <div
            className="after-cards"
            style={{
              backgroundImage: `url(${bgImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              className="textfieldsdateplzObOwBgnone position-relative "
              style={{
                backgroundColor: "transparent !important",
                padding: "0px 60px",
              }}
            >
              <div
                className="einzug fm-class-title h-full w-100 p-0"
                style={{ padding: "0px !important" }}
              >
                <h2
                  style={{
                    marginBottom: "6.5rem",
                    textTransform: "none",
                    fontWeight: "bold"
                  }}
                >
                  Vodafone Kabelanschluss, TV & Internet für Ihre Mieter
                  Serviceangebot für Ihre Mieter
                </h2>
              </div>
              <div className="row ">
                <div class="col-sm-7" style={{
                  display: "flex",
                  alignItems: "center",
                  height: "450px"
                }}>
                  <div class="dsl-content ">
                    <p
                      style={{
                        fontSize: "1.2rem",
                        color: "#000",
                      }}
                    >
                      Bieten Sie Ihren Mietern über Ihre Hausverwaltung einen
                      erstklassigen Service zur Anmeldung des Kabelanschlusses
                      sowie attraktive TV-, Internet- und Telefonpakete.
                      Angesichts der neuen Regelung, dass die Kosten für den
                      Kabelanschluss nicht mehr über die Nebenkosten abgerechnet
                      werden können und jeder Mieter selbst einen Kabelvertrag
                      abschließen muss, ist diese Unterstützung besonders
                      wertvoll.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="after-cards" style={{ backgroundColor: "#CACACA" }}>
            <div style={{ padding: "0px  60px" }}>
              <div className="row ">
                <ul
                  className=" d-flex list-unstyled "
                  style={{ position: "relative", marginTop: "-80px" }}
                >
                  <li
                    style={{
                      borderRadius: "50%",
                      border: "7px solid #CACACA",
                      backgroundColor: "#fff",
                      width: "100px",
                      height: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <img
                      src={wifiImage}
                      alt="protocol"
                      className=""
                      style={{ width: "60px" }}
                    />
                  </li>
                  <li
                    style={{
                      borderRadius: "50%",
                      border: "7px solid #CACACA",
                      backgroundColor: "#fff",
                      marginLeft: "20px",
                      width: "100px",
                      height: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",

                    }}
                  >
                    {" "}
                    <img
                      src={TvImage}
                      alt="protocol"
                      className=""
                      style={{ width: "60px" }}
                    />
                  </li>
                  <li
                    style={{
                      borderRadius: "50%",
                      border: "7px solid #CACACA",
                      backgroundColor: "#fff",
                      marginLeft: "20px",
                      width: "100px",
                      height: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",

                    }}
                  >
                    {" "}
                    <img
                      src={phoneImage}
                      alt="protocol"
                      className=" "
                      style={{ width: "60px" }}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {company.company_account_type !== "basic" &&
            <div
              className="after-cards"
              style={{ borderBottom: "1px solid #CACACA" }}
            >
              <div
                className="textfieldsdateplzObOw"
                style={{ padding: "40px 60px 10px" }}
              >
                <div className="row   ">
                  <div class="col-sm-8">
                    <div
                      className="wohnungsdaten"
                      style={{ marginLeft: "-60px" }}
                    >
                      <div
                        className="first_wohnungsdaten pl-4 pr-2 borderRadius"
                        style={{ borderRadius: "0px 5px 5px 0px" }}
                      >
                        <div className="row fm-class-title  ml-1" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                          <div
                            style={{
                              fontSize: "1.8rem",
                              textTransform: "none",
                              fontWeight: "bold",
                            }}
                          >
                            Ihre vorteile
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="dsl-content  fm-class-title">
                      <h3
                        style={{
                          color: "#000",
                          padding: "20px 0 10px",
                          fontWeight: "bold",
                        }}
                      >
                        Provisionen
                      </h3>
                      <p
                        style={{
                          fontSize: "1.2rem",
                          color: "#000",
                        }}
                      >
                        Nicht nur Ihre Mieter profitieren von unserem Service –{" "}
                        <br />
                        Sie als Verwaltung erhalten zusätzlich für jeden
                        abgeschlossenen Vertrag attraktive Provisionen!
                      </p>
                    </div>
                  </div>
                  <div class="col-sm-4 d-flex justify-content-end">
                    <img
                      src={dollor}
                      alt="protocol"
                      className=""
                      style={{ height: "100%", objectFit: "cover" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
          <div
            className="after-cards"
            style={{ borderBottom: "1px solid #CACACA" }}
          >
            <div
              className="textfieldsdateplzObOw"
              style={{ padding: "40px 60px" }}
            >
              <div style={{ marginLeft: "-60px" }}>
                <div
                  className="first_wohnungsdaten pl-4 pr-2 borderRadius"
                  style={{ borderRadius: "0px 5px 5px 0px", width: "310px" }}
                >
                  <div className="row  ml-1 fm-class-title" style={{padding:"10px"}}>
                    <h3
                      style={{

                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                    >
                      Einfache abwicklung
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div class="col-sm-12 " style={{ width: "80%" }}>
                  <div class="dsl-content  fm-class-title ">
                    <h3
                      style={{
                        color: "#000",
                        padding: "30px 0 10px",
                        fontWeight: "bold",
                      }}
                    >
                      Anträge einreichen
                    </h3>
                    <p
                      style={{
                        fontSize: "1.2rem",
                        color: "#000",
                        paddingRight: "10px",
                      }}
                    >
                      Senden Sie ausgefüllte Anträge unkompliziert per E-Mail an
                      unseren Fachberater Karl Zabel{" "}
                      <a href="mailto:karl.zabel@x-cite.de">
                        <span
                          style={{
                            fontSize: "1.2rem",
                            color: "#000",
                            fontWeight: "600",
                          }}
                        >
                          (karl.zabel@x-cite.de)
                        </span>
                      </a>
                      . Wir erfassen die Aufträge umgehend in unserer
                      Vodafone-Schnittstelle und schreiben Ihnen die Provisionen
                      gemäß der unten-stehenden Übersicht gut. Wichtig: Derzeit
                      ist es noch nicht möglich, die Aufträge über unsere App
                      abzuschließen.
                    </p>
                    <p
                      style={{
                        fontSize: "1.2rem",
                        color: "#000",
                        fontWeight: "600",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "50px",
                        paddingRight: "10px",
                      }}
                    >
                      <img
                        src={mailIcon}
                        alt="protocol"
                        className=""
                        style={{ width: "50px", marginRight: "10px" }}
                      />
                      <a
                        href="mailto:karl.zabel@x-cite.de"
                        style={{ color: "#000" }}
                      >
                        E-Mail an: karl.zabel@x-cite.de{" "}
                      </a>
                    </p>
                    <p style={{ fontSize: "1.2rem", color: "#000" }}>
                      Telefonische Unterstützung: Für Rückfragen stehen wir
                      Ihnen auch telefonisch unter 069-943404-35 zur Verfügung.{" "}
                    </p>
                    <p
                      style={{
                        fontSize: "1.2rem",
                        color: "#000",
                        fontWeight: "600",
                        display: "flex",
                        alignItems: "center",
                        paddingRight: "10px",
                      }}
                    >
                      <img
                        src={phoneIcon}
                        alt="protocol"
                        className=""
                        style={{ width: "35px", marginRight: "10px" }}
                      />
                      <a href="tel:069-943404-35" style={{ color: "#000" }}>
                        Hotline: 069-943404-35
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="after-cards"
            style={{ borderBottom: "1px solid #CACACA" }}
          >
            <div
              className="textfieldsdateplzObOw"
              style={{ padding: "40px 60px" }}
            >
              <div style={{ marginLeft: "-60px" }}>
                <div
                  className="first_wohnungsdaten pl-4 pr-2 borderRadius"
                  style={{ borderRadius: "0px 5px 5px 0px", width: "535px" }}
                >
                  <div className="row ml-1 fm-class-title" style={{padding:"10px"}}>
                    <h3
                      style={{
                        textTransform: "none",
                        fontWeight: "bold",
                        fontSize:"1.7rem"
                      }}
                    >
                      Wichtige dokumente und informationen
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row mt-4 ">
                <div class="col-sm-12">
                  <div class="dsl-content  fm-class-title">
                    <h3
                      style={{
                        color: "#000",
                        padding: "20px 0",
                        fontWeight: "700",
                      }}
                    >
                      Auftragsformulare
                    </h3>
                    <ul className=" d-flex row w-100">
                      <li className="d-flex flex-column col-4 text-align-center">
                        <a
                          href="https://firebasestorage.googleapis.com/v0/b/xc-flutter.appspot.com/o/documents%2Fcontracts%2Finfo_docs%2FKabel%20Auftrag.pdf?alt=media&token=712417ac-c5e6-4dbb-be2c-72ea8b947087"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            display: "block",
                            margin: "0 auto",
                            textAlign: "center",
                            textDecoration: "none",
                            color: "#000",
                            fontSize: "1.2rem",
                            lineHeight: "25px",
                            cursor: "pointer"
                          }}
                        >
                          <img
                            src={pdf}
                            alt="protocol"
                            className="mb-3"
                            style={{
                              width: "100px",
                              margin: "0 auto",
                              cursor: "pointer"
                            }}
                          />
                          <div>Auftrag Kabelfernsehen,
                            <br /> Internet & Telefon
                          </div>
                        </a>
                      </li>

                      <li className=" d-flex flex-column col-4 text-align-center">
                        <a
                          href="https://firebasestorage.googleapis.com/v0/b/xc-flutter.appspot.com/o/documents%2Fcontracts%2Finfo_docs%2FDSL%20Auftrag.pdf?alt=media&token=9bae3d55-3845-4f7d-8d5d-9b8016fe9201"
                          download
                          target="_blank"
                          style={{
                            fontSize: "1.2rem",
                            color: "#000",
                            marginLeft: "5px",
                            lineHeight: "25px",
                          }}
                        >
                          <img
                            src={pdf}
                            alt="protocol"
                            className="mb-3"
                            style={{ width: "100px", margin: "0 auto" }}
                          />
                          <div>
                            Auftrag DSL
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row mt-4 ">
                <div class="col-sm-12">
                  <div class="dsl-content  fm-class-title">
                    <h3
                      style={{
                        color: "#000",
                        padding: "20px 0",
                        fontWeight: "700",
                      }}
                    >
                      {company.company_account_type !== "basic" ? "Preise & Provisionen" : "Preise"}
                    </h3>
                    <ul className=" d-flex row">
                      <li className=" d-flex flex-column   col-4 text-align-center">
                        <a
                          href="https://firebasestorage.googleapis.com/v0/b/xc-flutter.appspot.com/o/documents%2Fcontracts%2Finfo_docs%2FPreisliste%20Kabel.pdf?alt=media&token=83fc4c0b-e0ab-4210-aca8-a76da5f3cc7c"
                          download
                          target="_blank"
                          style={{
                            fontSize: "1.2rem",
                            color: "#000",
                            marginLeft: "5px",
                            lineHeight: "25px",
                          }}
                        >
                          <img
                            src={pdf}
                            alt="protocol"
                            className="mb-3"
                            style={{ width: "100px", margin: "0 auto" }}
                          />
                          <div>
                            Preisliste Kabel
                          </div>
                        </a>
                      </li>
                      <li className=" d-flex flex-column  col-4 text-align-center">
                        <a
                          href="https://firebasestorage.googleapis.com/v0/b/xc-flutter.appspot.com/o/documents%2Fcontracts%2Finfo_docs%2FPreisliste%20DSL.pdf?alt=media&token=fb7120a5-9969-4dc7-b380-af5ef68bef6c"
                          download
                          target="_blank"
                          style={{
                            fontSize: "1.2rem",
                            color: "#000",
                            marginLeft: "5px",
                            lineHeight: "25px",
                          }}
                        >
                          <img
                            src={pdf}
                            alt="protocol"
                            className=" mb-3"
                            style={{ width: "100px", margin: "0 auto" }}
                          />
                          <div>
                            Preisliste DSL
                          </div>
                        </a>
                      </li>
                      {company.company_account_type !== "basic" &&
                        <li className=" d-flex flex-column  col-4 text-align-center">

                          <a
                            href="https://firebasestorage.googleapis.com/v0/b/xc-flutter.appspot.com/o/documents%2Fcontracts%2Finfo_docs%2FProvisions%C3%BCbersicht_Makler_27_06_24.pdf?alt=media&token=ce605a3a-bf87-437a-86cb-a1dfc7619fc5"
                            download
                            target="_blank"
                            style={{
                              fontSize: "1.2rem",
                              color: "#000",
                              marginLeft: "5px",
                              lineHeight: "25px",
                            }}
                          >
                            <img
                              src={pdf}
                              alt="protocol"
                              className=" mb-3"
                              style={{ width: "100px", margin: "0 auto" }}
                            />
                            <div>
                              Provisionen
                              <br /> Kabel & DSL
                            </div>
                          </a>
                        </li>
                      }
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row mt-4 ">
                <div class="col-sm-12">
                  <div class="dsl-content  fm-class-title">
                    <h3
                      style={{
                        color: "#000",
                        padding: "20px 0",
                        fontWeight: "700",
                      }}
                    >
                      Informationen
                    </h3>
                    <ul className=" d-flex row">
                      <li className=" d-flex flex-column   col-4 text-align-center">
                        <a
                          href="https://www.vodafone.de/privat/internet/verfuegbarkeit-pruefen.html"
                          target="_blank"
                          style={{
                            fontSize: "1.2rem",
                            color: "#000",
                            marginLeft: "5px",
                            lineHeight: "25px",
                          }}
                        >
                          <img
                            src={pdf}
                            alt="protocol"
                            className="mb-3"
                            style={{ width: "100px", margin: "0 auto" }}
                          />
                          <div>
                            Verfügbarkeitsprüfung
                            <br /> für Adresse
                          </div>
                        </a>
                      </li>
                      <li className=" d-flex flex-column  col-4 text-align-center">
                        <a
                          href="https://firebasestorage.googleapis.com/v0/b/xc-flutter.appspot.com/o/documents%2Fcontracts%2Finfo_docs%2FInformation_Kabelanschluss_Mehrfamilienhaus.pdf?alt=media&token=5c2b3499-3ff4-4ec8-9227-8c84f571085e"
                          download
                          target="_blank"
                          style={{
                            fontSize: "1.2rem",
                            color: "#000",
                            marginLeft: "5px",
                            lineHeight: "25px",
                          }}
                        >
                          <img
                            src={pdf}
                            alt="protocol"
                            className="mb-3"
                            style={{ width: "100px", margin: "0 auto" }}
                          />
                          <div>
                            Kabelanschluss
                            <br /> Mehrfamilienhaus – <br />
                            neue Regelungen
                          </div>
                        </a>
                      </li>
                      <li className=" d-flex flex-column  col-4 text-align-center">
                        <a
                          href="https://firebasestorage.googleapis.com/v0/b/xc-flutter.appspot.com/o/documents%2Fcontracts%2Finfo_docs%2Fsenderuebersicht_online_tv_connect.pdf?alt=media&token=f07776b6-3497-4567-90f3-5e17cbbdc6d0"
                          download
                          target="_blank"
                          style={{
                            fontSize: "1.2rem",
                            color: "#000",
                            marginLeft: "5px",
                            lineHeight: "25px",
                          }}
                        >
                          <img
                            src={pdf}
                            alt="protocol"
                            className="mb-3"
                            style={{ width: "100px", margin: "0 auto" }}
                          />
                          <div>
                            Senderliste Kabel
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="after-cards"
            style={{ borderBottom: "1px solid #CACACA" }}
          >
            <div
              className="textfieldsdateplzObOw"
              style={{ padding: "60px 60px" }}
            >
              <div className="row  ">
                <div class="col-sm-1  d-flex align-items-center">
                  <InfoIcon style={{ color: "#009FE3", fontSize: "50px" }} />
                </div>
                <div class="col-sm-11">
                  <div class="dsl-content  ">
                    <p
                      style={{
                        fontSize: "1.2rem",
                        color: "#000",
                        marginBottom: "0px",
                      }}
                    >
                      Stellen Sie sicher, dass Ihre Mieter bestmöglich versorgt
                      sind und profitieren Sie gleichzeitig von unserem
                      Provisionssystem. Kontaktieren Sie uns für weitere
                      Informationen und eine partnerschaftliche Zusammenarbeit!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DSLPage;
