import { storage } from "../config/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db } from "../config/firebase";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";

export const uploadImageService = {
  uploadImageWithThumbnail,
  uploadImage,
  addThumb,
};

//function to resize image
async function resizeImage(file, maxWidth, quality) {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = () => {
      let width = img.width;
      let height = img.height;

      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }

      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      // Convert the resized image on the canvas to a data URL
      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        file.type,
        quality / 100
      );
    };

    img.src = URL.createObjectURL(file);
  });
}
//upload image with resized and thumbnail
async function uploadImage(
  type,
  file,
  thumbnailWidth,
  thumbnailHeight,
  maxWidth,
  quality
) {
  try {
    const thumbnailDataURL = await createThumbnail(
      file,
      thumbnailWidth,
      thumbnailHeight
    );

    // Resize the image to a maximum width of 1280 pixels
    const resizedImageBlob = await resizeImage(file, maxWidth, quality);
    // Upload the resized image to storage
    const resizedStorageRef = ref(storage, `${type}/${file.name}`);
    const resizedUploadTask = uploadBytesResumable(
      resizedStorageRef,
      resizedImageBlob
    );
    await resizedUploadTask;
    const resizedDownloadURL = await getDownloadURL(
      resizedUploadTask.snapshot.ref
    );

    // Resolve with the original download URL and resized download URL
    return {
      resizedDataURL: resizedDownloadURL,
      thumbnailDataURL: thumbnailDataURL,
    };
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
}
async function uploadImageWithThumbnail(
  type,
  file,
  thumbnailWidth,
  thumbnailHeight
) {
  try {
    const storageRef = ref(storage, `${type}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // You can track the upload progress here if needed.
        },
        (error) => {
          console.error("Error uploading image:", error);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            // Create thumbnail
            const thumbnailDataURL = await createThumbnail(
              file,
              thumbnailWidth,
              thumbnailHeight
            );
            // Create a resized image with the specified dimensions and quality
            const resizedDataURL = await resizeImage(file, 1280, 1280, 50);
            // Resolve with both download URL and thumbnail data URL
            resolve({ thumbnailDataURL, resizedDataURL });
          } catch (error) {
            console.error("Error getting download URL:", error);
            reject(error);
          }
        }
      );
    });
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error; // Rethrow the error so it can be caught by the caller if needed.
  }
}
//create the thumb
async function createThumbnail(file, width, height) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        const thumbnailDataURL = canvas.toDataURL("image/jpeg")?.split(",")[1];
        resolve(thumbnailDataURL);
      };
    };
    reader.readAsDataURL(file);
  });
}

// Add a new thumb
async function addThumb(data) {
  try {
    if (!data.id) {
      throw new Error(
        "Missing 'id' field in data. Cannot create a document without an ID."
      );
    }

    // Create a document reference with the provided ID
    const thumbDocRef = doc(db, "xc_base64_picture", data.id);

    const thumbWithTimestamps = {
      ...data,
      created_at: serverTimestamp(),
      updated_at: serverTimestamp(),
    };

    // Set the document with the provided ID
    await setDoc(thumbDocRef, thumbWithTimestamps);

    return thumbWithTimestamps; // Return the data
  } catch (error) {
    console.error("Error adding room item:", error);
    throw new Error("Error adding room item: " + error.message);
  }
}
