import React, { useState, useEffect } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { db } from "../../config/firebase";
import { getDocs, collection, query } from "firebase/firestore";

export default function DatenInfo() {


  const [privacy_text, setPrivacy_text] = useState("");

  useEffect(() => {
    const getLegalInfo = async () => {
      let privacyText = "";
      try {
        const privacyQuerry = query(collection(db, "xc_privacy"));
        const privacyDoc = await getDocs(privacyQuerry);

        privacyDoc.forEach((doc) => {
          const privacy_Text = doc.data().privacy_text;
          privacyText = privacy_Text;
        });
        setPrivacy_text(privacyText);
      } catch (error) {
        console.log(error);
      }
    };
    getLegalInfo();
  }, []);

  function cancel() {
    window.location.href = "/signup";
  }

  return (
    privacy_text &&
    <>
          <div className="layoutstepsbar " style={{ height:"70px"}}>
            <div className="row layoutcontent">
              <div className="col-md-4 pr-0 pl-0">
                <div className="einzug h-full">
                  <ArrowBackIosNewIcon
                    onClick={cancel}
                    sx={{ fontSize: 25, marginRight: "0.5rem",cursor:'pointer' }}
                  />
                  <h3 style={{textTransform:"uppercase"}}>
                    Datenschutzerklärung
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="outer-container paading">
          <div className="card-style">
            <div className="SonCard">
              <div className="card-shadow">
          <div className="after-cards">
            <div className="textfieldsdateplzObOw position-relative">
              <div dangerouslySetInnerHTML={{ __html: privacy_text }} />
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
