/**
 * Courses Routing File
 */

import Store from 'Stored';
import React from 'react'
import { FirebaseContextProvider } from "../../context/firebaseContext";
import { Route, Switch } from 'react-router-dom'
import {
	AsyncKautionComponent,
} from 'routes';
import { MyProvider } from "./kautionContext";
const Protokol = ({ match }) => (
	<Switch>
		<Store>
			
				<MyProvider>
				<Route path={`${match.url}`} component={AsyncKautionComponent}></Route>
				</MyProvider>
			
		</Store>

	</Switch>
)
export default Protokol;