import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDQ338Jj3RRBq1MDgfRRmyBNYx8N466ZWo",
  authDomain: "flutter-staging-eb0a9.firebaseapp.com",
  databaseURL: 
  "https://flutter-staging-eb0a9-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "flutter-staging-eb0a9",
  storageBucket: "flutter-staging-eb0a9.firebasestorage.app",
  messagingSenderId: "133374976813",
  appId: "1:133374976813:web:436d996c905f1dbd7ee9a4",
  measurementId: "G-5QYJZPC9C3"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const storage = getStorage(app);

export default auth;
