import React, { useState, useEffect } from "react";
import moment from "moment";
import keyIcon from "../../assets/Icons/key-icon.svg";
import ViewContractIcon from "../../assets/Icons/viewcontract-icon.svg";
import ValidIcon from "../../assets/Icons/valid-icon.svg";
import RaumeIcon from "../../assets/Icons/raume-icon.svg";
import VerteilerIcon from "../../assets/Icons/verteiler-icon.svg";
import ContractIcon from "../../assets/Icons/contract-icon.svg";
import CalenderIcon from "../../assets/Icons/calander-icon.svg";
import CommentIcon from "../../assets/Icons/CommentIcon.svg";
import WohnungsdatenUserIcon from "../../assets/Icons/user-icon.svg";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import MinimizeOutlinedIcon from "@mui/icons-material/MinimizeOutlined";
import MeasuremnetIcon from "../../assets/Icons/measurement.svg";
import NotValidIcon from "../../assets/Icons/notvalid_icon.svg";
import CheckIcon from "@mui/icons-material/Check";
import Mangel from "../../assets/Icons/mangel.png";
import DefectTyp from "../../assets/Icons/DeffectTyp.png";
import Line from "../../assets/Icons/Line.png";
import Defect from "../../assets/Icons/deffectName.png";
import _ from "lodash";
import { grey } from "@mui/material/colors";
import { useLocation } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ZumIcon from "../../assets/Icons/zum-icon.svg";
import { Checkbox, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { userService } from "../../_services/user.service";
import { companyService } from "../../_services/company.service";
import { protocolService } from "_services/protocol.service";
import Config from "@mui/icons-material/Construction";
import { makeStyles, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
const useStyles = makeStyles(() => ({
  imageContainer: {
    position: "relative",
  },
  imageText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "#fff",
    padding: "10px",
    width: "100%",
    textAlign: "justify",
    wordBreak: "break-word",
    hyphens: "auto",
  },
}));
function ProtokollOvwerView() {
  const history = useHistory();
  const classes = useStyles();
  const imagesPerPage = 6;

  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const defffectParam = urlParams.get("deffect");
  const hasDefect = defffectParam ? defffectParam === "true" : false;
  //const protocolToShow = location.state;
  const [showImage, setShowImage] = useState(false);
  const [idImageShow, setIdImageShow] = useState(false);
  const [protocol_pattern, setProtocolPattern] = useState([]);
  const [protocolToShow, setProtocolToShow] = useState();
  const [activeIndexMangel, setActiveIndexMangel] = useState(0);
  const [clickedOnBullet, setClickedOnBullet] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [CurrentPageByRoom, setCurrentPageByRoom] = useState([]);
  const [comment, setComment] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pagesByRoom, setPagesByRoom] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [company, setCompany] = useState({});
  const label = { inputProps: { "aria-label": "Checkbox" } };
  function cancel() {
    history.push("/app/overview");
  }
  // get company info
  async function getCompany() {
    const userDoc = await userService.getUser(userService.getId());
    userDoc.forEach(async (doc) => {
      const userObject = doc.data();
      if (userObject.company_id) {
        const companyInfo = await companyService.getCompany(
          userObject.company_id
        );
        setCompany(companyInfo);
      }
    });
  }

  useEffect(() => {
    getCompany();
  }, []);
  const toggleAgbPopup = (company_agb) => {
    swal({
      title: "",
      text: company_agb,
      button: "OK",
    });
  };
  const toggleImage = (index) => {
    setIdImageShow(index);
    setShowImage(!showImage);
  };
  const next = (index, type = null) => {
    if (animating || clickedOnBullet) return;
    const nextIndex =
      activeIndex === protocolToShow.protocol_rooms[index].room_image.length - 1
        ? 0
        : activeIndex + 1;
    if (type == null) setActiveIndex(nextIndex);
    else setActiveIndexMangel(nextIndex);
  };
  const previous = (index, type = null) => {
    if (animating) return;
    const prevIndex =
      activeIndex === 0
        ? protocolToShow.protocol_rooms[index].room_image.length - 1
        : activeIndex - 1;
    if (type == null) setActiveIndex(prevIndex);
    else setActiveIndexMangel(prevIndex);
  };
  const goToIndexMangel = (newIndex) => {
    if (animating) return;
    setClickedOnBullet(true);
    setActiveIndexMangel(newIndex);
  };
  const handleExiting = () => {
    setAnimating(true);
  };
  // Function to update selected index to true
  const handleCurrentPageByRoom = (page, i) => {
    setCurrentPage(page)
    // Updating the array based on condition index === i
    setCurrentPageByRoom(prev => {
      const updatedCurrentPageByRoom = CurrentPageByRoom.map((value, index) => {
        return index === i ? page : value;
      });
      return updatedCurrentPageByRoom;
    });
  }

  function formatTitle(title) {
    if (
      title == "noChoice" ||
      title == null ||
      title == "Bitte wählen" ||
      title == ""
    )
      return "";
    else {
      switch (title) {
        case "dr":
          return "Dr.";
        case "ing":
          return "Ing.";
        case "prof":
          return "Prof.";
        default:
          return title;
      }
    }
  }
  // Function to handle pagination for the selected room
  const paginate = (pageNumber, id) => {
    handleCurrentPageByRoom(pageNumber, id)


  };
  //calcul number of pages need to show images of one room 
  function calculatePages(roomImages) {
    const numImages = roomImages.room_image != null ? roomImages.room_image.length : 0
    var pages
    if (numImages > 0) {
      pages = Math.ceil(numImages / 6);
    }
    return numImages == 0 ? 0 : pages;
  }
  // Example assuming protocol.rooms.length gives you the desired length
  const initializeIndices = (lengthOfProtocolRooms) => {
    // Initialize initializedArray
    const initializedArray = Array.from({ length: lengthOfProtocolRooms }, (_, i) => {
      const pages = calculatePages(protocolToShow.protocol_rooms[i]);
      return pages;
    });
    const arrayofzero = Array.from({ length: lengthOfProtocolRooms }, (_, i) => 0);
    setCurrentPageByRoom(arrayofzero)
    setPagesByRoom(initializedArray)

  };
  useEffect(() => {
    if (protocolToShow && protocolToShow.protocol_rooms) {
      // Call the initialization function when component mounts or protocol changes
      const lengthOfProtocolRooms = protocolToShow.protocol_rooms.length;
      if (lengthOfProtocolRooms) { initializeIndices(lengthOfProtocolRooms); }
    }

  }, [protocolToShow]);
  function formatReason(reason) {
    if (reason == "notPresent") return "Nicht anwesend";
    else if (reason == "refuseToSign") return "Verweigert Unterschrift";
    else return "Keine Unterschrift";
  }

  // get company info of current user
  const getCompanyId = async () => {
    const userDoc = await userService.getUser(userService.getId());
    const userObject = userDoc.docs.map((doc) => doc.data());
    const companyId = userObject[0].company_id;
    getProtocolPattern(companyId);
  };
  // get protocol 
  const getProtocolToShow = async (state) => {
    await protocolService.getProtocol(state.id_doc).then(result => {
      if (result) {
        setProtocolToShow(result.data())
      }
    });
  };
  const getProtocolPattern = async (companyId) => {
    await companyService.getProtocolPattern(companyId).then((result) => {
      if (result?.length > 0) {
        setProtocolPattern(result);
      }
    });
  };
  const getState = async () => {
    const storedProtocol = localStorage.getItem("state") ? JSON.parse(localStorage.getItem("state")) : false
    if (storedProtocol) {
      try {
          getProtocolToShow(storedProtocol);
      } catch (e) {
          console.error("Error parsing stored protocol:", e);
      }
  } else {
      console.warn("No protocol found in localStorage.");
  }
  }
  // useEffect(() => {
  //   getProtocolToShow();
  //   getCompanyId();
  // }, [location.state]);
  useEffect(() => {
    if(hasDefect) {
      getState()
    }
      else {getProtocolToShow(location.state.state);
      }
    getCompanyId();
  }, [hasDefect]);
  function formatType(type) {
    switch (type) {
      case "hot_water":
        return "Warmwasser";
      case "":
        return "Keine Angabe";
      case "ventilation":
        return "Lüften";
      case "heating":
        return "Heizung";
      default:
        return type;
    }
  }
  const handleDisplayPatternSection = (section_name) => {
    // Find the index of the section_name
    const sectionIndex = protocol_pattern.findIndex(
      (section) => section.key_section_name === section_name
    );

    // Iterate through protocol_pattern starting from the next index after sectionIndex
    if (sectionIndex !== -1 && sectionIndex < protocol_pattern.length - 1) {
      for (let i = sectionIndex + 1; i < protocol_pattern.length; i++) {
        const section = protocol_pattern[i];
        const hasItemsWithB = section.ref_id && section.ref_id.includes("B");
        if (hasItemsWithB) {
          return (
            <div>
              <div
                key={section.id}
                className="formAbschlussOvwerview  mt-3"
                id={`section_${section.id}`}
              >
                <div className="LigneWohnungsdatenOvwerview">
                  <span>{section.title}</span>
                </div>
                <p>{section.content}</p>
              </div>
              <hr className="hrOvwerview" />
            </div>
          );
        } else return null;
      }
    }
  };
  return (
    <>

      <div className="layoutstepsbar" style={{ height: "70px" }}>
        <div className="row layoutcontent full-height mr-0 ml-0">
          <div className="einzug width">
            <img
              className="RaumDetailsIcon"
              src={ZumIcon}
              alt="Übergabeprotokoll Icon"
            />
            <h3>
              Übergabeprotokoll zum{" "}
              {protocolToShow?.protocol_type == "move" ? "Auszug"
                : protocolToShow?.protocol_type == "moveIn" ? "Einzug"
                : protocolToShow?.protocol_type == "vacancy_inspection" ? "Angaben zur Wohnung"
                : "Ein- und Auszug"}{" "}
            </h3>
          </div>
          <div className="Weiter align-end-button">
            <button
              onClick={() => cancel()}
              className="WeiterButton"
              type="submit"
            >
              Zurück
              <ArrowForwardIosIcon
                className="icon-registrieren"
                sx={{ fontSize: 16 }}
              />
            </button>
          </div>
        </div>
      </div>
      <div className="outer-container paading">
        <div className="card-style">
          <div className="SonCard">

            <div className="neuer withoutBorderRadius">
              <img className="NeuerIcon" src={ValidIcon} alt="abschluss Icon" />
              <h3 className="Header">{protocolToShow?.protocol_type == null &&
                            "Kein Angabe"}
                          {protocolToShow?.protocol_type == "move" && "Einzug"}
                          {protocolToShow?.protocol_type == "moveIn" && "Auszug"}
                          {protocolToShow?.protocol_type == "moveInAndExit" &&
                            "Ein- und Auszug"}
                          {protocolToShow?.protocol_type == "vacancy_inspection" && "Angaben zur Wohnung"}
                            Protokolltyp </h3>
            </div>
            <div className="card-shadow" style={{ marginBottom: "2rem" }}>
              <div className="after-cards protocol-kontrol" >
                <div className="formAbschlussOvwerview">
                  <div className="LigneSchlüssel Adresse-RenviernungsdatumOvwerview w-100">
                    <div className="float-left protocol-Type LigneWohnungsdatenOvwerview">
                      <span>Protokollant:</span>
                      <span className="Header-content">
                          {protocolToShow?.note_taker?.note_taker_gender ==
                            null && "Anrede fehlt "}
                          {protocolToShow?.note_taker?.note_taker_gender ==
                            "noChoice" && "Keine Angabe "}
                          {protocolToShow?.note_taker?.note_taker_gender ==
                            "male" && "Herr "}
                          {protocolToShow?.note_taker?.note_taker_gender ==
                            "female" && "Frau "}
                          {protocolToShow?.note_taker?.note_taker_title ==
                            "noChoice" && "Keine Angabe "}
                          {protocolToShow?.note_taker?.note_taker_title == "dr" &&
                            "Dr. "}
                          {protocolToShow?.note_taker?.note_taker_title ==
                            "prof" && "Prof. "}
                          {protocolToShow?.note_taker?.note_taker_title == "ing" && "Ing. "}
                          {protocolToShow?.note_taker?.note_taker_first_name ==
                            null &&
                            protocolToShow?.note_taker?.note_taker_last_name ==
                            null && <span>Name und Vorname fehlt</span>}
                          {(protocolToShow?.note_taker?.note_taker_first_name ==
                            null ||
                            protocolToShow?.note_taker?.note_taker_first_name ==
                            "") &&
                            protocolToShow?.note_taker?.note_taker_last_name !=
                            null && (
                              <>
                                {protocolToShow?.note_taker?.note_taker_first_name
                                  ? protocolToShow?.note_taker
                                    ?.note_taker_first_name
                                  : " Vorname fehlt "}
                              </>
                            )}
                          {protocolToShow?.note_taker?.note_taker_first_name !=
                            null &&
                            (protocolToShow?.note_taker?.note_taker_last_name ==
                              null ||
                              protocolToShow?.note_taker?.note_taker_last_name ==
                              "") && (
                              <>
                                {protocolToShow?.note_taker?.note_taker_last_name
                                  ? protocolToShow?.note_taker
                                    ?.note_taker_last_name
                                  : " Nachname fehlt "}
                              </>
                            )}
                          {protocolToShow?.note_taker?.note_taker_first_name !=
                            null &&
                            protocolToShow?.note_taker?.note_taker_last_name !=
                            null && (
                              <>
                                {
                                  protocolToShow?.note_taker
                                    ?.note_taker_first_name
                                }{" "}
                                {protocolToShow?.note_taker?.note_taker_last_name}
                              </>
                            )}
                        </span>
                    </div>
                    <div className="float-right">
                      <span>Datum der Übergabe</span>
                      <div className="text-style">
                      <img
                        className="auftrageCalendarIcon"
                        src={CalenderIcon}
                        alt="Auftrage Calendar Icon"
                        style={{marginRight:"5%", verticalAlign: "text-bottom"}}
                        
                      />
                        <span>
                          {protocolToShow?.date_of_hand_over
                            ? protocolToShow?.date_of_hand_over
                            : "Angabe fehlt"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="hrOvwerview" />
                <div className="formAbschlussOvwerview">
                  <div className="LigneWohnungsdatenOvwerview">
                    <span> Wohnungsdaten</span>
                  </div>
                  <div className="LigneSchlüssel Adresse-RenviernungsdatumOvwerview w-full">
                    <div>
                      <span>Adresse</span>
                      <div className=" text-style">
                        <span>
                          {protocolToShow?.object?.address?.object_street
                            ? protocolToShow?.object?.address?.object_street
                            : "Straße fehlt"}
                          ,{" "}
                          {protocolToShow?.object?.address?.object_house_nbr
                            ? protocolToShow?.object?.address?.object_house_nbr
                            : "Hausnummer  fehlt"}
                        </span>
                        <br />
                        <span>
                          {protocolToShow?.object?.address?.object_plz
                            ? protocolToShow?.object?.address?.object_plz
                            : "PLZ fehlt"}
                          ,{" "}
                          {protocolToShow?.object?.address?.object_city
                            ? protocolToShow?.object?.address?.object_city
                            : "Ort fehlt"}
                        </span>
                      </div>
                    </div>
                    <div >
                          <span>Renovierungsdatum</span>
                          <div className=" text-style mb-2">
                            <span>
                              {protocolToShow?.object?.object_date_of_renovation
                                ? protocolToShow?.object?.object_date_of_renovation
                                : "Angabe fehlt"}
                            </span>
                          </div>
                      {(protocolToShow?.object?.address.object_address_note || protocolToShow?.object?.address?.object_position) &&
                        <>
                          <span>Adresszusatz/Lage oder Wohnungsnummer</span>
                          <div className="text-style">
                            <span>
                              {protocolToShow?.object?.address.object_address_note
                                ? protocolToShow?.object?.address.object_address_note
                                : "Angabe fehlt"}
                              ,{" "}
                              {protocolToShow?.object?.address?.object_position
                                ? protocolToShow?.object?.address?.object_position
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>
                {protocolToShow?.protocol_type === "vacancy_inspection" && <>
                <hr className="hrOvwerview" />
                {protocol_pattern &&
                  protocol_pattern.length > 0 &&
                  protocol_pattern.find(
                    (key) => key.key_section_name === "owner"
                  ) &&
                  handleDisplayPatternSection("owner")}
                 <hr className="hrOvwerview" />
                 <div className="formAbschlussOvwerview">
                  <div className="LigneWohnungsdatenOvwerview">
                    <span>Eigentümer</span>
                  </div>
                  {protocolToShow?.owner_is_landlord == true ? (
                    <>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span className="Header-content" style={{ textTransform:"capitalize",}}>
                          {protocolToShow?.landlord?.landlord_company_name
                            ? protocolToShow?.landlord?.landlord_company_name
                            : "Unternehmensname fehlt"}
                        </span>
                      </div>
                      <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                        <div>
                          <span>
                            {protocolToShow?.landlord?.landlord_salutation ==
                              null &&
                              protocolToShow?.landlord?.landlord_salutation
                              ? protocolToShow?.landlord?.landlord_salutation
                              : "Anrede fehlt "}
                            {protocolToShow?.landlord?.landlord_salutation ==
                              "noChoice" && "Keine Angabe "}
                            {protocolToShow?.landlord?.landlord_salutation ==
                              "male" && "Herr "}
                            {protocolToShow?.landlord?.landlord_salutation ==
                              "female" && "Frau "}
                            {formatTitle(protocolToShow?.landlord?.landlord_title)}
                            {protocolToShow?.landlord?.landlord_first_name ==
                              null &&
                              protocolToShow?.landlord?.landlord_last_name ==
                              null && <span>Name und Vorname fehlt</span>}
                            {(protocolToShow?.landlord?.landlord_first_name ==
                              null ||
                              protocolToShow?.landlord?.landlord_first_name ==
                              "") &&
                              protocolToShow?.landlord?.landlord_last_name !=
                              null &&
                              " Vorname fehlt "}
                            {protocolToShow?.landlord?.landlord_first_name !=
                              null &&
                              (protocolToShow?.landlord?.landlord_last_name ==
                                null ||
                                protocolToShow?.landlord?.landlord_last_name ==
                                "") &&
                              " Nachname fehlt "}
                            {protocolToShow?.landlord?.landlord_first_name !=
                              null &&
                              protocolToShow?.landlord?.landlord_last_name !=
                              null && (
                                <>
                                  {protocolToShow?.landlord?.landlord_first_name}{" "}
                                  {protocolToShow?.landlord?.landlord_last_name}
                                </>
                              )}
                          </span>
                          <br />
                          <span>
                            {protocolToShow?.landlord?.address?.landlord_street
                              ? protocolToShow?.landlord?.address?.landlord_street
                              : "Straße fehlt"}
                            ,{" "}
                            {protocolToShow?.landlord?.address
                              ?.landlord_house_number
                              ? protocolToShow?.landlord?.address
                                ?.landlord_house_number
                              : "Hausnummer  fehlt"}
                          </span>
                          <br />
                          <span>
                            {protocolToShow?.landlord?.address?.landlord_plz
                              ? protocolToShow?.landlord?.address?.landlord_plz
                              : "PLZ fehlt"}
                            ,{" "}
                            {protocolToShow?.landlord?.address?.landlord_city
                              ? protocolToShow?.landlord?.address?.landlord_city
                              : "Ort fehlt"}
                          </span>
                        </div>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                      <span style={{ fontSize: "16px" }}>Art des Wohnungsgebers</span>
                        <div className="mb-2 text-style">
                          {(protocolToShow?.landlord?.landlord_type == null ||
                            protocolToShow?.landlord?.landlord_type == "") && (
                              <span>Angabe fehlt</span>
                            )}
                          {protocolToShow?.landlord?.landlord_type ==
                            "noChoice" && (
                              <span style={{ color: "#000000" }}>Keine Angabe</span>
                            )}
                          {protocolToShow?.landlord?.landlord_type ==
                            "company" && (
                              <span style={{ color: "#000000" }}>Gesellschaft</span>
                            )}
                          {protocolToShow?.landlord?.landlord_type ==
                            "private" && (
                              <span style={{ color: "#000000" }}>Privat</span>
                            )}
                        </div>
                      </div>         
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                          <span>E-mail:</span>
                          <div className="mb-2 text-style">
                            <span>
                              {" "}
                              {protocolToShow?.landlord?.landlord_email
                                ? protocolToShow?.landlord?.landlord_email
                                : "Angabe fehlt"}
                            </span>
                          </div>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                          <span >Festnetz:</span>
                          <div className="mb-2 text-style">
                            <span>
                              {" "}
                              {protocolToShow?.landlord?.landlord_phone
                                ? protocolToShow?.landlord?.landlord_phone
                                : "Angabe fehlt"}
                            </span>
                          </div>                       
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                          <span>Mobilnummer:</span>
                          <div className="mb-2 text-style">
                            <span>
                              {" "}
                              {protocolToShow?.landlord?.landlord_mobile
                                ? protocolToShow?.landlord?.landlord_mobile
                                : "Angabe fehlt"}
                            </span>
                          </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span className="Header-content">
                          {protocolToShow?.owner?.owner_company_name
                            ? protocolToShow?.owner?.owner_company_name
                            : "Unternehmensname fehlt"}
                        </span>
                      </div>
                      <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                        <div>
                          <span>
                            {protocolToShow?.owner?.owner_salutation == null &&
                              "Anrede fehlt "}
                            {protocolToShow?.owner?.owner_salutation ==
                              "noChoice" && "Keine Angabe "}
                            {protocolToShow?.owner?.owner_salutation == "male" &&
                              "Herr "}
                            {protocolToShow?.owner?.owner_salutation ==
                              "female" && "Frau "}
                            {protocolToShow?.owner?.owner_salutation ==
                              "association" && "WEG "}
                            {formatTitle(protocolToShow?.owner?.owner_title)}
                            {protocolToShow?.owner?.owner_first_name == null &&
                              protocolToShow?.owner?.owner_last_name == null && (
                                <span>Name und Vorname fehlt</span>
                              )}
                            {(protocolToShow?.owner?.owner_first_name == null ||
                              protocolToShow?.owner?.owner_first_name == "") &&
                              protocolToShow?.owner?.owner_last_name != null &&
                              " Vorname fehlt "}
                            {protocolToShow?.owner?.owner_first_name != null &&
                              (protocolToShow?.owner?.owner_last_name == null ||
                                protocolToShow?.owner?.owner_last_name == "") &&
                              " Nachname fehlt "}
                            {protocolToShow?.owner?.owner_first_name != null &&
                              protocolToShow?.owner?.owner_last_name != null && (
                                <>
                                  {protocolToShow?.owner?.owner_first_name}{" "}
                                  {protocolToShow?.owner?.owner_last_name}
                                </>
                              )}
                          </span>
                          <br />
                          <span>
                            {protocolToShow?.owner?.address?.owner_street
                              ? protocolToShow?.owner?.address?.owner_street
                              : "Straße fehlt"}
                            ,
                            {protocolToShow?.owner?.address?.owner_house_number
                              ? protocolToShow?.owner?.address?.owner_house_number
                              : "Hausnummer  fehlt"}
                          </span>
                          <br />
                          <span>
                            {protocolToShow?.owner?.address?.owner_plz
                              ? protocolToShow?.owner?.address?.owner_plz
                              : "PLZ fehlt"}
                            ,{" "}
                            {protocolToShow?.owner?.address?.owner_city
                              ? protocolToShow?.owner?.address?.owner_city
                              : "Ort fehlt"}
                          </span>
                        </div>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                      <span  style={{ fontSize: "16px" }}>Art des Eigentümers</span>
                        <div className="mb-2 text-style">
                          {(protocolToShow?.owner?.owner_Type == null ||
                            protocolToShow?.landlord?.landlord_type == "") && (
                            <span>Angabe fehlt</span>
                          )}
                          {protocolToShow?.owner?.owner_Type == "noChoice" && (
                            <span style={{ color: "#000000" }}>Keine Angabe</span>
                          )}
                          {protocolToShow?.owner?.owner_Type == "company" && (
                            <span style={{ color: "#000000" }}>Gesellschaft</span>
                          )}
                          {protocolToShow?.owner?.owner_Type == "private" && (
                            <span style={{ color: "#000000" }}>Privat</span>
                          )}
                        </div>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span style={{ fontSize: "16px" }}>E-mail:</span>
                        <div className="mb-2 text-style">
                            <span>
                              {" "}
                              {protocolToShow?.owner?.owner_email
                                ? protocolToShow?.owner?.owner_email
                                : "Angabe fehlt"}
                            </span>
                          </div>                    
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                          <span  style={{ fontSize: "16px" }}>Festnetz:</span>
                          <div className="mb-2 text-style">
                            <span>
                              {" "}
                              {protocolToShow?.owner?.owner_phone
                                ? protocolToShow?.owner?.owner_phone
                                : "Angabe fehlt"}
                            </span>
                          </div>                                              
                      </div>
                       <div className="LigneAdresse-RenviernungsdatumOvwerview">                      
                          <span  style={{ fontSize: "16px" }}>Mobilnummer:</span>
                          <div className="mb-2 text-style">
                            <span>
                              {" "}
                              {protocolToShow?.owner?.owner_mobile
                                ? protocolToShow?.owner?.owner_mobile
                                : "Angabe fehlt"}
                            </span>
                          </div>                        
                      </div>                     
                    </>
                  )}
                </div>
                <hr className="hrOvwerview" />
                <div className="formAbschlussOvwerview">
                  <div className="LigneWohnungsdatenOvwerview">
                    <span className="uppercase">Wohnungsabnehmer</span>
                  </div>
                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                    <span className="Header-content" style={{ textTransform:"capitalize",}}>
                      {protocolToShow?.landlord?.landlord_company_name
                        ? protocolToShow?.landlord?.landlord_company_name
                        : "Unternehmensname fehlt"}
                    </span>
                  </div>
                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                    <div className="mb-2 text-style">
                      <span>
                        {protocolToShow?.landlord?.landlord_salutation == null &&
                          "Anrede fehlt "}
                        {protocolToShow?.landlord?.landlord_salutation ==
                          "noChoice" && "Keine Angabe "}
                        {protocolToShow?.landlord?.landlord_salutation ==
                          "male" && "Herr "}
                        {protocolToShow?.landlord?.landlord_salutation ==
                          "female" && "Frau "}
                        {formatTitle(protocolToShow?.landlord?.landlord_title)}
                        {protocolToShow?.landlord?.landlord_first_name == null &&
                          protocolToShow?.landlord?.landlord_last_name ==
                          null && <span>Name und Vorname fehlt</span>}
                        {(protocolToShow?.landlord?.landlord_first_name == null ||
                          protocolToShow?.landlord?.landlord_first_name == "") &&
                          protocolToShow?.landlord?.landlord_last_name !=
                          null && (
                            <>
                              {protocolToShow?.landlord?.landlord_first_name
                                ? protocolToShow?.landlord?.landlord_first_name
                                : " Vorname fehlt "}
                            </>
                          )}
                        {protocolToShow?.landlord?.landlord_first_name != null &&
                          (protocolToShow?.landlord?.landlord_last_name == null ||
                            protocolToShow?.landlord?.landlord_last_name ==
                            "") && (
                            <>
                              {protocolToShow?.landlord?.landlord_last_name
                                ? protocolToShow?.landlord?.landlord_last_name
                                : " Nachname fehlt "}
                            </>
                          )}
                        {protocolToShow?.landlord?.landlord_first_name != null &&
                          protocolToShow?.landlord?.landlord_last_name !=
                          null && (
                            <>
                              {protocolToShow?.landlord?.landlord_first_name}{" "}
                              {protocolToShow?.landlord?.landlord_last_name}
                            </>
                          )}
                      </span>
                      <span>
                        {protocolToShow?.landlord?.address?.landlord_street
                          ? protocolToShow?.landlord?.address?.landlord_street
                          : "Straße fehlt"}
                        ,{" "}
                        {protocolToShow?.landlord?.address?.landlord_house_number
                          ? protocolToShow?.landlord?.address
                            ?.landlord_house_number
                          : "Hausnummer fehlt"}
                      </span>
                      <span>
                        {protocolToShow?.landlord?.address?.landlord_plz
                          ? protocolToShow?.landlord?.address?.landlord_plz
                          : "PLZ fehlt"}
                        ,{" "}
                        {protocolToShow?.landlord?.address?.landlord_city
                          ? protocolToShow?.landlord?.address?.landlord_city
                          : "Ort fehlt"}
                      </span>
                    </div>
                  </div>
                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                  <span style={{ fontSize: "16px" }}>Art des Eigentümers</span>
                    <div className="mb-2 text-style">
                      {(protocolToShow?.landlord?.landlord_type == null ||
                        protocolToShow?.landlord?.landlord_type == "") && (
                          <span>Angabe fehlt</span>
                        )}
                      {protocolToShow?.landlord?.landlord_type == "noChoice" && (
                        <span style={{ color: "#000000" }}>Keine Angabe</span>
                      )}
                      {protocolToShow?.landlord?.landlord_type == "company" && (
                        <span style={{ color: "#000000" }}>Gesellschaft</span>
                      )}
                      {protocolToShow?.landlord?.landlord_type == "private" && (
                        <span style={{ color: "#000000" }}>Privat</span>
                      )}
                    </div>
                  </div>
                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                    <span  style={{ fontSize: "16px" }}>E-mail:</span>
                    <div className="mb-2 text-style">
                        <span>
                          {" "}
                          {protocolToShow?.landlord?.landlord_email
                            ? protocolToShow?.landlord?.landlord_email
                            : "Angabe fehlt"}
                        </span>
                      </div>
                  </div>
                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                      <span  style={{ fontSize: "16px" }}>Festnetz:</span>
                    <div className="mb-2 text-style">
                        <span>
                          {" "}
                          {protocolToShow?.landlord?.landlord_phone
                            ? protocolToShow?.landlord?.landlord_phone
                            : "Angabe fehlt"}
                       </span>
                    </div>                    
                  </div>
                  <div className="LigneAdresse-RenviernungsdatumOvwerview">                 
                      <span  style={{ fontSize: "16px" }}>Mobilnummer:</span>
                     <div className="mb-2 text-style">
                        <span>
                          {" "}
                          {protocolToShow?.landlord?.landlord_mobile
                            ? protocolToShow?.landlord?.landlord_mobile
                            : "Angabe fehlt"}
                        </span>
                    </div>
                  </div>
                </div>
                <hr className="hrOvwerview" />
                {/* landlord  landlord*/}
                {protocol_pattern &&
                  protocol_pattern.length > 0 &&
                  protocol_pattern.find(
                    (key) => key.key_section_name === "landlord"
                  ) &&
                  handleDisplayPatternSection("landlord")}
                  {/* mieter  renter*/}
                    <div className="formAbschlussOvwerview">
                    <div className="LigneWohnungsdatenOvwerview">
                      <span>Mieter</span>
                    </div>
                    <div className="LigneAdresse-RenviernungsdatumOvwerview">
                      <span style={{ fontWeight: "bold", color: "#000000" }}>
                        {protocolToShow?.renter?.renter_salutation == null &&
                          "Anrede fehlt "}
                        {protocolToShow?.renter?.renter_salutation ==
                          "noChoice" && "Keine Angabe "}
                        {protocolToShow?.renter?.renter_salutation == "male" &&
                          "Herr "}
                        {protocolToShow?.renter?.renter_salutation == "female" &&
                          "Frau "}
                        {formatTitle(protocolToShow?.renter?.renter_title)}
                        {protocolToShow?.renter?.renter_first_name == null &&
                          protocolToShow?.renter?.renter_last_name == null && (
                            <span>Name und Vorname fehlt</span>
                          )}
                        {(protocolToShow?.renter?.renter_first_name == null ||
                          protocolToShow?.renter?.renter_first_name == "") &&
                          protocolToShow?.renter?.renter_last_name != null && (
                            <>
                              {protocolToShow?.renter?.renter_first_name
                                ? protocolToShow?.renter?.renter_first_name
                                : " Vorname fehlt "}
                            </>
                          )}
                        {protocolToShow?.renter?.renter_first_name != null &&
                          (protocolToShow?.renter?.renter_last_name == null ||
                            protocolToShow?.renter?.renter_last_name == "") && (
                            <>
                              {protocolToShow?.renter?.renter_last_name
                                ? protocolToShow?.renter?.renter_last_name
                                : " Nachname fehlt "}
                            </>
                          )}
                        {protocolToShow?.renter?.renter_first_name != null &&
                          protocolToShow?.renter?.renter_last_name != null && (
                            <>
                              {protocolToShow?.renter?.renter_first_name}{" "}
                              {protocolToShow?.renter?.renter_last_name}
                            </>
                          )}
                      </span>
                      {protocolToShow?.renter?.renter_date_of_birth &&
                        <span style={{ fontSize: "16px" }}>Geburtsdatum</span>
                      }
                    </div>
                    <div class="LigneDataAdresse-RenviernungsdatumOvwerview">
                      {protocolToShow?.renter?.renter_date_of_birth &&
                        <div className="mb-2 text-style">
                          <div className="LigneAdresse-RenviernungsdatumOvwerview">
                            <span style={{ color: "#000000" }}>
                              {protocolToShow?.renter?.renter_date_of_birth
                                ? protocolToShow?.renter?.renter_date_of_birth
                                : "Angabe fehlt"}
                            </span>
                          </div>
                        </div>
                      }
                    </div>
                    <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span  style={{ fontSize: "16px" }}>E-mail:</span>
                      <div className="mb-2 text-style">
                          <span>
                            {" "}
                            {protocolToShow?.renter?.renter_email
                              ? protocolToShow?.renter?.renter_email
                              : "Angabe fehlt"}
                          </span>
                       </div>
                    </div>
                    <div className="LigneAdresse-RenviernungsdatumOvwerview">
                      <span  style={{ fontSize: "16px" }}>Festnetz:</span>
                      <div className="mb-2 text-style">
                          <span>
                            {" "}
                            {protocolToShow?.renter?.renter_phone
                              ? protocolToShow?.renter?.renter_phone
                              : "Angabe fehlt"}
                          </span>
                        </div>
                    </div>
                    <div className="LigneAdresse-RenviernungsdatumOvwerview">
                       <span  style={{ fontSize: "16px" }}>Mobilnummer:</span>
                       <div className="mb-2 text-style">
                          <span>
                            {" "}
                            {protocolToShow?.renter?.renter_mobile
                              ? protocolToShow?.renter?.renter_mobile
                              : "Angabe fehlt"}
                          </span>
                        </div>
                    </div>
                  </div>
                  <hr className="hrOvwerview" />
                  {/* Vorherige Adresse */}
                  {protocolToShow?.renter?.address?.renter_previous_new_adress && (
                  <div className="aftercardOvwerview pt-0 pb-0">
                    <div className="formAbschlussOvwerview">
                      <div className="LigneWohnungsdatenOvwerview">
                        <span>Vorherige Adresse</span>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span style={{ color: "#000000" }}>
                          {protocolToShow?.renter?.address?.renter_srteet
                            ? protocolToShow?.renter?.address?.renter_srteet
                            : "Straße fehlt"}
                          ,{" "}
                          {protocolToShow?.renter?.address?.renter_house_number
                            ? protocolToShow?.renter?.address?.renter_house_number
                            : "Hausnummer  fehlt"}
                        </span>
                        <div className="mb-2 text-style">
                          <span>
                            {protocolToShow?.renter?.address?.renter_plz
                              ? protocolToShow?.renter?.address?.renter_plz
                              : "PLZ fehlt"}
                            ,{" "}
                            {protocolToShow?.renter?.address?.renter_city
                              ? protocolToShow?.renter?.address?.renter_city
                              : "Ort fehlt"}
                          </span>
                        </div>
                      </div>
                      {protocolToShow?.renter?.address?.renter_address_note &&
                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                          <span  style={{ fontSize: "16px" }}>Adresszusatz</span>
                          <div className="mb-2 text-style">
                            <span style={{ fontWeight: "bold" }}>
                              {protocolToShow?.renter?.address?.renter_address_note
                                ? protocolToShow?.renter?.address
                                  ?.renter_address_note
                                : "Angabe fehlt"}
                            </span>
                          </div>

                      </div>}
                    </div>
                    <hr className="hrOvwerview" />
                  </div>
                )}
                   {/* Weitere Mieter corenter */}             
                {protocolToShow?.has_corenter && (
                  <>
                    <div className="aftercardOvwerview pt-0 pb-0">
                      <div className="formAbschlussOvwerview">
                        <div className="LigneWohnungsdatenOvwerview">
                          <span >Weitere Mieter</span>
                        </div>
                        <div className="LigneAdresse-RenviernungsdatumOvwerview">
                          <span style={{ fontWeight: "bold", color: "#000000" }}>
                            {protocolToShow?.coRenter?.salutaion == null &&
                              "Anrede fehlt "}
                            {protocolToShow?.coRenter?.salutaion == "noChoice" &&
                              "Keine Angabe "}
                            {protocolToShow?.coRenter?.salutaion == "male" &&
                              "Herr "}
                            {protocolToShow?.coRenter?.salutaion == "female" &&
                              "Frau "}
                            {formatTitle(protocolToShow?.coRenter?.title)}
                            {(protocolToShow?.coRenter?.first_name == null ||
                              protocolToShow?.coRenter?.first_name == "") &&
                              protocolToShow?.coRenter?.last_name != null && (
                                <>
                                  {protocolToShow?.coRenter?.first_name
                                    ? protocolToShow?.coRenter?.first_name
                                    : " Vorname fehlt "}
                                </>
                              )}
                            {protocolToShow?.coRenter?.first_name != null &&
                              (protocolToShow?.coRenter?.last_name == null ||
                                protocolToShow?.coRenter?.last_name == "") && (
                                <>
                                  {protocolToShow?.coRenter?.last_name
                                    ? protocolToShow?.coRenter?.last_name
                                    : " Nachname fehlt "}
                                </>
                              )}
                            {protocolToShow?.coRenter?.first_name != null &&
                              protocolToShow?.coRenter?.last_name != null && (
                                <>
                                  {protocolToShow?.coRenter?.first_name}{" "}
                                  {protocolToShow?.coRenter?.last_name}
                                </>
                              )}
                          </span>
                        </div>
                        <div className="LigneAdresse-RenviernungsdatumOvwerview">
                          <span  style={{ fontSize: "16px" }}>E-mail:</span>
                          <div className="mb-2 text-style">
                            <span>
                            {" "}
                            {protocolToShow?.coRenter?.email
                              ? protocolToShow?.coRenter?.email
                              : "Angabe fehlt"}
                            </span>
                          </div>
                        </div>
                        <div className="LigneAdresse-RenviernungsdatumOvwerview">
                            <span  style={{ fontSize: "16px" }}>Festnetz:</span>
                            <div className="mb-2 text-style">
                              <span>
                                {" "}
                                {protocolToShow?.coRenter?.phone
                                ? protocolToShow?.coRenter?.phone
                                : "Angabe fehlt"}
                              </span>
                            </div>
                         </div>
                        <div className="LigneAdresse-RenviernungsdatumOvwerview">
                             <span  style={{ fontSize: "16px" }}>Mobilnummer:</span>
                             <div className="mb-2 text-style">
                               <span>
                                 {" "}
                                 {protocolToShow?.coRenter?.mobile
                                 ? protocolToShow?.coRenter?.mobile
                                 : "Angabe fehlt"}
                               </span>
                             </div>
                        </div>
                      </div>
                      <hr className="hrOvwerview" />
                      {protocolToShow?.roommates &&
                       protocolToShow?.roommates.length > 0 && (
                      <>
                      {protocolToShow?.roommates.map((element, index) => {
                        return (
                          <div className="aftercardOvwerview pt-0 pb-0">
                            <div className="formAbschlussOvwerview">
                              <div className="LigneWohnungsdatenOvwerview">
                                <span className="uppercase">{index + 1}. Bewohner</span>
                              </div>
                              <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                <span
                                  style={{ fontWeight: "bold", color: "#000000" }}
                                >
                                  {element.first_name} {element.last_name}
                                </span>
                              </div>
                            </div>
                            <hr className="hrOvwerview" />
                          </div>
                        );
                      })}
                    </>
                       )}
                  {protocolToShow?.protocol_type == "moveInAndExit" && (
                  <>
                    <div className="aftercardOvwerview pt-0 pb-0">
                      <div className="formAbschlussOvwerview">
                        <div className="LigneWohnungsdatenOvwerview">
                          <span>Vormieter</span>
                        </div>
                        <div className="LigneAdresse-RenviernungsdatumOvwerview">
                          <span style={{ fontWeight: "bold", color: "#000000" }} className="mb-2">
                            {protocolToShow?.previous_renter
                              ?.previous_renter_salutation == null &&
                              "Anrede fehlt "}
                            {protocolToShow?.previous_renter
                              ?.previous_renter_salutation == "noChoice" &&
                              "Keine Angabe "}
                            {protocolToShow?.previous_renter
                              ?.previous_renter_salutation == "male" && "Herr "}
                            {protocolToShow?.previous_renter
                              ?.previous_renter_salutation == "female" && "Frau "}
                            {formatTitle(protocolToShow?.previous_renter
                              ?.previous_renter_title)}
                            {protocolToShow?.previous_renter
                              ?.previous_renter_first_name
                              ? protocolToShow?.previous_renter
                                ?.previous_renter_first_name + " "
                              : "Vorname fehlt"}
                            {protocolToShow?.previous_renter
                              ?.previous_renter_last_name
                              ? protocolToShow?.previous_renter
                                ?.previous_renter_last_name
                              : "Nachname fehlt"}
                          </span>
                        </div>
                        <div className="LigneAdresse-RenviernungsdatumOvwerview">
                          <span style={{ fontSize: "16px" }}>E-mail:</span>
                          <div className="mb-2 text-style">
                              <span>
                                {protocolToShow?.previous_renter
                                  ?.previous_renter_email
                                  ? protocolToShow?.previous_renter
                                    ?.previous_renter_email
                                  : "Angabe fehlt"}
                              </span>
                            </div>
                        </div>
                        <div className="LigneAdresse-RenviernungsdatumOvwerview">
                            <span  style={{ fontSize: "16px" }}>Festnetz:</span>
                          <div className="mb-2 text-style">
                              <span>
                                {" "}
                                {protocolToShow?.previous_renter
                                  ?.previous_renter_phone_number
                                  ? protocolToShow?.previous_renter
                                    ?.previous_renter_phone_number
                                  : "Angabe fehlt"}
                              </span>
                            </div>                         
                        </div>
                        <div className="LigneAdresse-RenviernungsdatumOvwerview">                       
                          <span  style={{ fontSize: "16px" }}>Mobilnummer:</span>
                          <div className="mb-2 text-style">
                              <span>
                                {" "}
                                {protocolToShow?.previous_renter
                                  ?.previous_renter_mobile_number
                                  ? protocolToShow?.previous_renter
                                    ?.previous_renter_mobile_number
                                  : "Angabe fehlt"}
                              </span>
                            </div>
                        </div>
                      </div>
                      <hr className="hrOvwerview" />
                    </div>
                    {protocolToShow?.previous_renter?.address
                      ?.previous_renter_new_adress && (
                        <div className="aftercardOvwerview pt-0 pb-0">
                          <div className="formAbschlussOvwerview">
                            <div className="LigneWohnungsdatenOvwerview">
                              <span >Verzugs-Adresse</span>
                            </div>
                            <div className="LigneAdresse-RenviernungsdatumOvwerview">
                              <span style={{ color: "#000000" }}>
                                {protocolToShow?.previous_renter.address
                                  .previous_renter_srteet
                                  ? protocolToShow?.previous_renter.address
                                    ?.previous_renter_srteet
                                  : "Straße fehlt"}
                                ,{" "}
                                {protocolToShow?.previous_renter?.address
                                  ?.previous_renter_housnumber
                                  ? protocolToShow?.previous_renter?.address
                                    ?.previous_renter_housnumber
                                  : "Hausnummer  fehlt"}
                              </span>
                              <span  style={{ fontSize: "16px" }}>Adresszusatz</span>
                            </div>
                            <div className="LigneAdresse-RenviernungsdatumOvwerview">
                              <div className="mb-2 text-style">
                                <span>
                                  {protocolToShow?.previous_renter?.address
                                    ?.previous_renter_plz
                                    ? protocolToShow?.previous_renter?.address
                                      ?.previous_renter_plz
                                    : "PLZ fehlt"}
                                  ,{" "}
                                  {protocolToShow?.previous_renter?.address
                                    ?.previous_renter_city
                                    ? protocolToShow?.previous_renter?.address
                                      ?.previous_renter_city
                                    : "Ort fehlt"}
                                </span>
                              </div>
                              {protocolToShow?.previous_renter?.address
                                ?.previous_renter_address_note &&
                                <div className="mb-2 text-style">
                                  <span style={{ fontWeight: "bold" }}>
                                    {protocolToShow?.previous_renter?.address
                                      ?.previous_renter_address_note
                                      ? protocolToShow?.previous_renter?.address
                                        ?.previous_renter_address_note
                                      : "Angabe fehlt"}
                                  </span>
                                </div>
                              }
                            </div>
                          </div>
                          <hr className="hrOvwerview" />
                        </div>
                      )}
                  </>
                )}
                {protocol_pattern &&
                  protocol_pattern.length > 0 &&
                  protocol_pattern.find(
                    (key) => key.key_section_name === "renter"
                  ) &&
                  handleDisplayPatternSection("renter")}
                    </div>
                  </>
                )}
                </>}
              </div>
              
            </div>
          </div>
          {/* section Measurement of inspection vancancy  */}
          {protocolToShow?.protocol_type == "vacancy_inspection" &&<div className="SonCard document-measurement">
            <div className="wohnungsdaten ">
              <div className="first_wohnungsdaten w-100">
                <div className="row padding-center-middle">
                  <img
                    className="WohnungsdatenIcon icon"
                    src={MeasuremnetIcon}
                    alt="Key Icon"
                  />
                  <h3>Maßnahmen</h3>
                </div>
              </div>
            </div>
            <div className="after-cards p-0">
            {protocolToShow?.insepction_measures?.map((measure, index) => (
                <div className="formAbschlussOvwerview ">
                  <div className="block ">
                    <div className="left-content-block">
                      <div className="topTitle">
                        <h3 style={{ textTransform: "initial" }}>
                          {formatType(measure.type)}
                        </h3>
                        <p>
                          {measure.type === "hot_water" ? (
                            "Alle Zapfstellen gespült"
                          ) : measure.type === "ventilation" ? (
                            "Alle Fenster für 5min geöffnet"
                          ) : (
                            <>
                              Sind alle Heizkörperthermostate gemäß Vorgabe
                              eingestellt? (Falls keine Vorgabe <br />
                              existiert wird zur Vermeidung von Schimmel die
                              Stellung 2 empfohlen)
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="right-content-block">
                    <div className="topTitle Label-Satuts">
                    {measure.status &&<CheckIcon
                        lassName="icon-AngabeButton"
                        sx={{ fontSize: 40 }}
                        />} 
                        <h3 style={{ textTransform: "uppercase" }}>{measure.status ?"JA":"NEIN"}</h3>
                    </div>
                    </div>
                  </div>
                  <div className="row w-full m-0">
                    <TextField
                      InputLabelProps={{
                        style: {
                          textTransform: "uppercase",
                          padding: "0 5px 0 0",
                          backgroundColor: "white",
                          shrink: true,
                        },
                      }}
                      name="measure_note"
                      value={measure?.note}
                      id="measure_note"
                      type="text"
                      label={"Bemerkung"}
                      placeholder={"BEMERKUNG"}
                      variant="outlined"
                      rows="3"
                      multiline={true}
                      fullWidth
                      InputProps={{ readOnly: true,}}
                    />
                  </div>
                </div>
              ))}
              <div className="formAbschlussOvwerview">
                <div className="Title">
                  <p>
                    Allgemeine Bemerkung zur Begehung, Auffälligkeiten oder
                    Mängel
                  </p>
                </div>
                <div className="row w-full  margin-button">
                  <TextField
                    InputLabelProps={{
                      style: {
                        textTransform: "uppercase",
                        padding: "0 5px 0 0",
                        backgroundColor: "white",
                        shrink: true,
                      },
                    }}
                    name="general_insecption_comments"
                    value={protocolToShow?.general_insecption_comments}
                    id="general_insecption_comments"
                    type="text"
                    label={"Bemerkung"}
                    placeholder={"BEMERKUNG"}
                    variant="outlined"
                    rows="3"
                    multiline={true}
                    fullWidth
                    InputProps={{ readOnly: true,}}
                  />
                </div>
                <div className="w-100">
                  {protocolToShow?.images?.map((Rimage, rimg) => (
                    <>
                      <div className="row deffect-filter-comment-container" key={rimg}>
                        <div className="col-md-2 pl-0 pr-0" key={rimg}>
                          <div className="image--container image-right">
                            {showImage && Rimage?.id == idImageShow && (
                              <div
                                className="image-overlay"
                                onClick={() =>
                                  toggleImage(protocolToShow.images[rimg]?.id)
                                }
                              >
                                <div className={classes.imageContainer}>
                                    <img
                                      src={protocolToShow.images[rimg]?.image_remote_url}
                                      alt={protocolToShow.images[rimg]?.image_name}
                                      className="image"
                                    />
                                  
                                </div>
                              </div>
                            )}
                            {!showImage && (
                              <div
                                className="icon overview"
                                onClick={() => toggleImage(Rimage?.id)}
                              >
                                <i className="fas fa-search-plus"></i>
                              </div>
                            )}
                            <div className="defect-contain ">
                                <img
                                  src={Rimage?.image_remote_url}
                                  alt={Rimage?.image_name}
                                  className="w-full"
                                  style={{
                                    width: "100%",
                                    height: "90px !important",
                                  }}
                                />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-10 pr-0 w-100 full-height-size">
                          <TextField
                            InputLabelProps={{
                              style: {
                                textTransform: "uppercase",
                                padding: "0 5px 0 0",
                                backgroundColor: "white",
                                shrink: true,
                              },
                            }}
                            name="controle_comment"
                            value={
                              protocolToShow?.images[rimg]?.comment ||
                              "KEIN KOMMENTAR"
                            }
                            id="image_description"
                            type="text"
                            label={"Kommentar"}
                            placeholder="Kommentar"
                            variant="outlined"
                            rows="3"
                            multiline={true}
                            fullWidth
                          />
                        </div>
                      </div>
                    </>
                  ))}
                  
                </div>
              </div>
            </div>
          </div>}
          {/* section protokollant of insepection vacancy */}
          {protocolToShow?.protocol_type == "vacancy_inspection" &&<div className="SonCard document-measurement">
            <div className="card-shadow">
            <div className="wohnungsdaten withoutBorderRadius">
            <div className="first_wohnungsdaten w-100 withoutBorderRadius">
                    <div className="padding-center-withoutLeftandRght w-100 h-full">
                      <div className="float-left">
                        <div className="d-flex align-items-center">
                          <img
                            className="WohnungsdatenIcon"
                            src={WohnungsdatenUserIcon}
                            alt="eigentumer Icon"
                          />
                          <h3>Protokollant</h3>
                        </div>
                      </div>
                    </div>
            </div>
                </div>
             <div className="after-cards">
                    <div className="textfieldsdateplzObOw">
                      <div style={{ padding: "10px 20px" }}>
                        <img
                          className="line-Image mt-1 pr-2"
                          src={Line}
                          alt="notvalidateSclusselOvwerview Icon"
                          style={{ float: "left" }}
                        />
                        <div className="row mt-2 mb-2">
                          <div className="col-md-1 " style={{ padding: "5px 5px 5px 0px" }} >
                            <TextField
                              InputLabelProps={{
                                style: {
                                  textTransform: "uppercase",
                                  padding: "0 5px 0 0",
                                  backgroundColor: "white",
                                },
                                shrink: true,
                              }}
                              name="note_taker_gender"
                              id="note_taker_gender"
                              type="text"
                              label="Anrede*"
                              placeholder="ANREDE WÄHLEN *"
                              variant="outlined"
                              fullWidth
                              value={protocolToShow?.note_taker?.note_taker_gender}
                              InputProps={{ readOnly: true }}
                            />
                          </div>
                          <div className="col-md-2 " style={{ padding: "5px" }}>
                            <TextField
                              InputLabelProps={{
                                style: {
                                  textTransform: "uppercase",
                                  padding: "0 5px 0 0",
                                  backgroundColor: "white",
                                },
                                shrink: true,
                              }}
                              name="title"
                              id="title"
                              type="text"
                              label="TITEL WÄHLEN"
                              variant="outlined"
                              fullWidth
                              value={formatTitle(
                                protocolToShow?.note_taker?.note_taker_title
                              )}
                              InputProps={{ readOnly: true }}
                            />
                          </div>
                          <div className="col-md-4 " style={{ padding: "5px" }}>
                            <TextField
                              InputLabelProps={{
                                style: {
                                  textTransform: "uppercase",
                                  padding: "0 5px 0 0",
                                  backgroundColor: "white",
                                },
                                shrink:true,
                              }}
                              name="note_taker_first_name"
                              id="note_taker_first_name"
                              value={
                                protocolToShow?.note_taker?.note_taker_first_name ||
                                ""
                              }
                              type="text"
                              label="VORNAME"
                              required
                              variant="outlined"
                              fullWidth
                              InputProps={{ readOnly: true }}
                            />
                          </div>
                          <div className="col-md-5 " style={{ padding: "5px" }}>
                            <TextField
                              InputLabelProps={{
                                style: {
                                  textTransform: "uppercase",
                                  padding: "0 5px 0 0",
                                  backgroundColor: "white",
                                },
                                shrink:true,
                              }}
                              name="note_taker_last_name"
                              id="note_taker_last_name"
                              value={
                                protocolToShow?.note_taker?.note_taker_last_name ==
                                null
                                  ? ""
                                  : protocolToShow?.note_taker?.note_taker_last_name
                              }
                              type="text"
                              required
                              label="NACHNAME"
                              variant="outlined"
                              fullWidth
                              InputProps={{ readOnly: true }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
          </div>}
          {/* section rooms */}
          {protocolToShow?.protocol_type != "vacancy_inspection" && <div className="SonCard mt-3 protocol-kontrol Rooms pt-0 pb-0" style={{backgroundColor:"#fff"}}>
            <div className="card-shadow">
              <div className="neuer withoutBorderRadius">
                <img className="NeuerIcon" src={RaumeIcon} alt="Räume Icon" />
                <h3 style={{ textTransform: "uppercase" }}> Räume und Zimmer </h3>
              </div>

              {protocolToShow?.protocol_rooms &&
                protocolToShow?.protocol_rooms.length > 0 ? (
                <>
                  {protocolToShow?.protocol_rooms.map((room, index) => (
                    <>
                    {/* Header Room */}
                      <div className="VerteilerContainer">
                        <div>
                        <img
                           className="line-Image"
                           src={Line}
                           alt="notvalidateSclusselOvwerview Icon"
                           style={{float:"left"}}
                           />
                          <div className="LigneWohnungsdatenOvwerview float-left">
                             <span>
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "livingRoom" && (
                                      <>Wohnzimmer</>
                                    )}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "bedroom" && <>Schlafzimmer</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "kitchen" && <>Küche</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "bathroom" && <>Badezimmer</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "basement" && <>Keller</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "corridor" && <>Flur</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "secondbedroom" && (
                                      <>Zweites Schlafzimmer</>
                                    )}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "secondbathroom" && (
                                      <>Zweites Badezimmer</>
                                    )}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "attic" && <>Dachboden</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "garage" && <>Garage</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "balcony" && <>Balkon</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "shed" && <>Schuppen</>}
                                    {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "guest_room" && <>Gästezimmer</>}
                                    {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "guest_toilet" && <>Gäste-WC</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "garden" && <>Garten</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "nursery" && <>Kinderzimmer</>}
                                  {protocolToShow?.protocol_rooms[index]
                                    ?.room_type == "other" && (
                                      <>
                                        {
                                          protocolToShow?.protocol_rooms[index]
                                            ?.room_name
                                        }
                                      </>
                                    )}
                        </span>
                        <span className="RoomNameComment">
                          Es fehlen diverse Teile.
                        </span>
                        </div>
                        <div className="LigneZustandPROTOKOLLTYPE float-right">
                                {protocolToShow?.protocol_rooms[index]
                                  .room_status == null && (
                                    <>
                                      <div className="LigneRaumeundZimmerAddIcon">
                                        <MinimizeOutlinedIcon
                                          sx={{
                                            fontSize: "2rem",
                                            paddingBottom: "12px",
                                            color: "rgb(141 133 133)",
                                          }}
                                        />

                                        <span
                                          style={{
                                            color: "rgb(141 133 133)",
                                            fontSize: "1.3rem",
                                          }}
                                        >
                                          Zustand: Angabe fehlt
                                        </span>
                                      </div>
                                    </>
                                  )}

                                {protocolToShow?.protocol_rooms[index]
                                  .room_status == 0 && (
                                    <>
                                      <CheckIcon
                                        className="icon-AngabeButton"
                                        sx={{ fontSize: 40, color: "#62AB12" }}
                                      />
                                      <span
                                        style={{
                                          color: "#62AB12",
                                          fontSize: "1.3rem",
                                        }}
                                      >
                                        Zustand: IN ORDNUNG
                                      </span>
                                    </>
                                  )}

                                {protocolToShow?.protocol_rooms[index]
                                  .room_status == 1 && (
                                    <>
                                      
                                      <img
                                            className="PenIcon"
                                            src={Mangel}
                                            alt="Baskets Icon"
                                            style={{
                                              filter: "none",
                                              marginRight: "10px",
                                            }}
                                      />

                                      <span
                                        style={{
                                          color: "#D00000",
                                          fontSize: "1.3rem",
                                        }}
                                      >
                                        Zustand: Mangel vorhanden
                                      </span>
                                    </>
                                  )}
                        </div>

                        </div>
                      </div>
                      {(protocolToShow?.protocol_rooms[index] ?.room_generale_description != null || 
                        protocolToShow.protocol_rooms[index].room_image &&
                        protocolToShow.protocol_rooms[index].room_image
                          .length > 0
                      ) &&
                      <div className="RoomGeneralContant">
                      {protocolToShow.protocol_rooms[index]
                                .room_generale_description != null && (
                                  <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                                    <div className="description-General" style={{ width: "100%" }}>
                                      <span className="description-General-Room">
                                        {
                                          protocolToShow.protocol_rooms[index]
                                            .room_generale_description
                                        }
                                      </span>
                                    </div>
                                  </div>
                                )}
                      {protocolToShow.protocol_rooms[index].room_image &&
                                protocolToShow.protocol_rooms[index].room_image.length > 0 
                                ?
                                <div className="row">
                                {
                                  protocolToShow.protocol_rooms[index].room_image?.filter(
                                  (Rimage) => Rimage?.deffect_id == null  
                                ).map((imageSet, rimg) => (
                                  
                                      <div
                                        className="col-md-4"
                                        style={{
                                          //display: "flex",
                                          //justifyContent: "center",
                                          marginBottom: "30px",
                                        }}
                                      >
                                  <div
                                    className="image--container image-right"
                                    style={{ flexDirection: "column" }}
                                    key={rimg}
                                  >
                                    {showImage &&
                                      protocolToShow?.protocol_rooms[index]
                                        ?.room_image[rimg]?.id ==
                                      idImageShow && (
                                        <div
                                          className="image-overlay"
                                          onClick={() =>
                                            toggleImage(
                                              protocolToShow?.protocol_rooms[
                                                index
                                              ]?.room_image[rimg]?.id
                                            )
                                          }
                                        >
                                          <div
                                            className={
                                              classes.imageContainer
                                            }
                                          >
                                            <img
                                              src={
                                                protocolToShow?.protocol_rooms[
                                                  index
                                                ]?.room_image[rimg]
                                                  ?.room_image_remote_url
                                              }
                                              alt={
                                                protocolToShow?.protocol_rooms[
                                                  index
                                                ]?.room_image[rimg]
                                                  ?.room_image_name
                                              }
                                              className="image"
                                            />
                                          </div>
                                        </div>
                                      )}
                                    {!showImage && (
                                      <div
                                        className="icon overview"
                                        onClick={() =>
                                          toggleImage(
                                            protocolToShow?.protocol_rooms[
                                              index
                                            ]?.room_image[rimg]?.id
                                          )
                                        }
                                      >
                                        {/* Add your icon here */}
                                        <i className="fas fa-search-plus"></i>
                                      </div>
                                    )}
                                    <img
                                      src={
                                        protocolToShow?.protocol_rooms[index]
                                          ?.room_image[rimg]
                                          ?.room_image_remote_url
                                      }
                                      alt={
                                        protocolToShow?.protocol_rooms[index]
                                          ?.room_image[rimg]
                                          ?.room_image_name
                                      }
                                      className="image-Room"
                                    />
                                    {protocolToShow?.protocol_rooms[index]
                                      ?.room_image[rimg]
                                      ?.image_description && (
                                        <>
                                        <div className="deffect-filter-comment-title">Kommentar</div>
                                        <div className="description-contain">
                                          <span
                                            style={{
                                              //backgroundColor: "#fff",
                                              color: "rgba(0, 0, 0, 1)",
                                            }}
                                          >
                                            {
                                              protocolToShow?.protocol_rooms[
                                                index
                                              ]?.room_image[rimg]
                                                ?.image_description
                                            }
                                          </span>
                                        </div>
                                        </>
                                      )}
                                  </div>
                                      </div>
                                  
                                ))}
                                </div>
                                :null
                                }
                     
                       </div>
                      }
                              {/* Defect detail View */}
                              {protocolToShow.protocol_rooms[index].room_deffect &&
                             protocolToShow.protocol_rooms[index].room_deffect.length > 0 ? (
                            <>
                              {protocolToShow.protocol_rooms[index].room_deffect.map((deffect, i) => (
                                <>
                                  {protocolToShow.protocol_rooms[index].room_status == 1 && (
                                      <div 
                                        style={{ backgroundColor: "#fff", width: "100%", }}
                                      >
                                        <div className="sub-formverteilerOvwerview">
                                          {/* Defects details  */}
                                          <div className="first-part-formverteilerOvwerview w-100 pt-4 pb-4">
                                          <div className="row m-0">
                                          <img
                                              className="line-Image"
                                              src={Line}
                                              alt="notvalidateSclusselOvwerview Icon"
                                            />
                                            <div className="LigneZustandPROTOKOLLTYPE"
                                               style={{marginTop:"14px"}}
                                            >
                                              <img
                                                className="notvalidateSclusselOvwerviewIcon"
                                                src={Mangel}
                                                alt="notvalidateSclusselOvwerview Icon"
                                                style={{height: "1.3rem",
                                                  padding: "0rem",
                                                  marginRight: "0rem",
                                                  marginTop: "0",
                                                  filter: "invert(50%) sepia(99%) saturate(9999%) hue-rotate(390deg) brightness(95%) contrast(119%)",
                                                  }}
                                              />
                                              <h2
                                                style={{
                                                  fontSize: "1.3rem",
                                                  marginBottom: "0px",
                                                }}
                                              >
                                                Mangel {i + 1} von {protocolToShow.protocol_rooms[index].room_deffect.length}
                                              </h2>
                                            </div>
                                          </div>
                                            <div className="row w-100">
                                              <div className="col-md-6">
                                                <div>
                                                <img
                                                    src={Defect}
                                                    alt="notvalidateSclusselOvwerview Icon"
                                                    style={{float:"left",padding:"10px"}}
                                                   />
                                                  <div className="mangel-deffect mangel-deffect-title">
                                                    <span className="deffect-Label">
                                                      Objekt
                                                    </span>
                                                    <span className="deffect-value">
                                                      {protocolToShow.protocol_rooms[
                                                        index
                                                      ].room_deffect[i]
                                                        .deffect_name != null
                                                        ? protocolToShow.protocol_rooms[
                                                          index
                                                        ].room_deffect[i]
                                                          .deffect_name
                                                        : "keine angabe"}
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="float-left"
                                                     style={{paddingLeft: "20px",
                                                      clear:"inline-start" }}
                                                >
                                                  <img
                                                      className=""
                                                      src={Line}
                                                      alt="notvalidateSclusselOvwerview Icon"
                                                      style={{float:"inline-start"}}
                                                  />
                                                  <div className=" d-flex align-items-center">
                                                  <img
                                                      style={{height: "3rem",width: "3rem",}}
                                                      className="PenIcon"
                                                      src={DefectTyp}
                                                      alt="Baskets Icon"
                                                   />
                                                   <div className=" mangel-deffect mangel-deffect-title">
                                                    <span className="deffect-Label">
                                                      Art des Mangels
                                                    </span>
                                                    <span className="deffect-value ">
                                                      {protocolToShow.protocol_rooms[
                                                        index
                                                      ].room_deffect[i]
                                                        .deffect_type != null
                                                        ? protocolToShow.protocol_rooms[
                                                          index
                                                        ].room_deffect[i]
                                                          .deffect_type
                                                        : "keine angabe"}
                                                    </span>
                                                  </div>
                                                  </div>
                                                  
                                                </div>
                                              </div>
                                              <div
                                                className="col-md-6"
                                                style={{
                                                  backgroundColor: "#f3f3f3",
                                                }}
                                              >
                                                <div  style={{padding:"30px"}}>
                                                  <div className=" float-left">
                                                    <Config
                                                      className=" float-left"
                                                      sx={{
                                                        fontSize: 33,
                                                        color: "#009fe3",
                                                      }}
                                                    />
                                                    <div className="mangel-deffect mangel-deffect-title">
                                                      <span className="deffect-Label">
                                                        Maßnahme
                                                      </span>
                                                      <span className="deffect-value">
                                                        {protocolToShow.protocol_rooms[
                                                          index
                                                        ].room_deffect[i]
                                                          .room_deffect_who_fix !=
                                                          null
                                                          ? protocolToShow
                                                            .protocol_rooms[
                                                            index
                                                          ].room_deffect[i]
                                                            .room_deffect_who_fix == 'accepted' ? 'KEINE BEHEBUNG NÖTIG':
                                                            protocolToShow
                                                            .protocol_rooms[
                                                            index
                                                          ].room_deffect[i]
                                                            .room_deffect_who_fix
                                                          : "keine angabe"}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="float-right">                                                    <div className="mangel-deffect mangel-deffect-title">
                                                      <span className="deffect-Label">
                                                       Zu beseitigen bis
                                                      </span>
                                                      <div className="d-flex align-items-center">
                                                      <img
                                                              className="auftrageCalendarIcon"
                                                              src={CalenderIcon}
                                                              alt="Auftrage Calendar Icon"
                                                            />
                                                      <span className="deffect-value">
                                                        {protocolToShow.protocol_rooms[
                                                          index
                                                        ].room_deffect[i]
                                                          .room_deffect_date_of_fix
                                                          ? protocolToShow.protocol_rooms[
                                                            index
                                                          ].room_deffect[i]
                                                            .room_deffect_date_of_fix
                                                          : "Angabe fehlt"}

                                                      </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                          </div>
                                          {/* Images Defects */}
                                          <div className="first-part-formverteilerOvwerview w-100">
                                          
                                          <div className="row">
                                          {
                                            protocolToShow.protocol_rooms[index]?.room_image?.map(
                                              (Rimage, rimg) => {
                                                if (
                                                  protocolToShow?.protocol_rooms[
                                                    index
                                                  ]?.room_image[rimg]
                                                    ?.deffect_id != null &&
                                                  protocolToShow?.protocol_rooms[
                                                    index
                                                  ]?.room_image[rimg]
                                                    ?.deffect_id ==
                                                  protocolToShow?.protocol_rooms[
                                                    index
                                                  ]?.room_deffect[i]?.id &&
                                                  protocolToShow?.protocol_rooms[
                                                    index
                                                  ]?.room_image[rimg]
                                                    ?.room_image_remote_url !=
                                                  null
                                                ) {
                                                  return (
                                                    <div className="col-md-4">
                                                    <div
                                                      className="image--container image-right room-deffect-contain"
                                                      style={{flexDirection:"column"}}
                                                      key={rimg}
                                                    >
                                                      {showImage && protocolToShow ?.protocol_rooms[ index ]?.room_image[rimg] ?.id == idImageShow && (
                                                          <div className="image-overlay"
                                                               onClick={() => toggleImage( protocolToShow
                                                                         ?.protocol_rooms[ index ]?.room_image[ rimg ]?.id ) }
                                                          >
                                                            <img
                                                              src={
                                                                protocolToShow
                                                                  ?.protocol_rooms[
                                                                  index
                                                                ]?.room_image[
                                                                  rimg
                                                                ]
                                                                  ?.room_image_remote_url
                                                              }
                                                              alt={
                                                                protocolToShow
                                                                  ?.protocol_rooms[
                                                                  index
                                                                ]?.room_image[
                                                                  rimg
                                                                ]
                                                                  ?.room_image_name
                                                              }
                                                              className="image"
                                                            />
                                                          </div>
                                                        )}
                                                      {!showImage && (
                                                        <div
                                                          className="icon overview"
                                                          onClick={() =>
                                                            toggleImage(
                                                              protocolToShow
                                                                ?.protocol_rooms[
                                                                index
                                                              ]?.room_image[
                                                                rimg
                                                              ]?.id
                                                            )
                                                          }
                                                        >
                                                          <i className="fas fa-search-plus"></i>
                                                        </div>
                                                      )}
                                                      <div className="defect-contain">
                                                        <img
                                                          src={
                                                            protocolToShow
                                                              ?.protocol_rooms[
                                                              index
                                                            ]?.room_image[rimg]
                                                              ?.room_image_remote_url
                                                          }
                                                          alt={
                                                            protocolToShow
                                                              ?.protocol_rooms[
                                                              index
                                                            ]?.room_image[rimg]
                                                              ?.room_image_name
                                                          }
                                                          style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            objectFit:
                                                              "cover",
                                                          }}
                                                        />
                                                      </div>
                                                        <div className="deffect-filter-comment-title">Kommentar</div>
                                                          <div className="description-contain deffect">
                                                            <span style={{ backgroundColor: "#fff", color:  "rgba(0, 0, 0, 1)", }} >
                                                              {
                                                                protocolToShow
                                                                  ?.protocol_rooms[index]?.room_image[ rimg
                                                                ] ?.image_description 
                                                                ?
                                                                protocolToShow
                                                                ?.protocol_rooms[index]?.room_image[ rimg
                                                              ] ?.image_description 
                                                                : "keine kommentar"
                                                              }
                                                            </span>
                                                          </div>
                                                    </div>
                                                    </div>
                                                  );
                                                }
                                              }
                                            )
                                          }</div>
                                          </div>
                                          
                                          
                                        </div>
                                      </div>
                                    )}{" "}
                                </>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                    </>
                  ))}
                </>
              ) : (
                <></>
              )}
              {protocol_pattern &&
                protocol_pattern.length > 0 &&
                protocol_pattern.find(
                  (key) => key.key_section_name === "rooms"
                ) &&
                handleDisplayPatternSection("rooms")}
            </div>
          </div>}
          {/* section counter */}
          {protocolToShow?.protocol_type != "vacancy_inspection" && <div className="SonCard mt-3 protocol-kontrol pt-0 pb-0">
            <div className="card-shadow">
              <div className="neuer withoutBorderRadius">
                <img
                  className="NeuerIcon"
                  src={VerteilerIcon}
                  alt="Zähler Icon"
                />
                <h3 style={{ textTransform: "none" }}> Zähler / Verteiler </h3>
              </div>
              {protocolToShow?.protocol_counter &&
                protocolToShow?.protocol_counter.length > 0 ? (
                <>
                  {protocolToShow?.protocol_counter.map((counter, j) => (
                    <>
                    <div className="VerteilerContainer">
                    <img
                           className="line-Image"
                           src={Line}
                           alt="notvalidateSclusselOvwerview Icon"
                           style={{float:"left"}}
                           />
                    <div className="LigneWohnungsdatenOvwerview">
                                <span style={{ textTransform: "uppercase", fontSize: "16px" }}>
                                  {protocolToShow?.protocol_counter[j]
                                    ?.counter_type == "electricity" &&
                                    (protocolToShow?.protocol_counter[j]
                                      ?.counter_is_double == "doubleCounter" ? (
                                      <>Zweitarif Stromzähler</>
                                    ) : (
                                      <>Eintarif Stromzähler</>
                                    ))}
                                  {protocolToShow?.protocol_counter[j]
                                    ?.counter_type == "gas" && <>GASZÄHLER</>}
                                  {protocolToShow?.protocol_counter[j]
                                    ?.counter_type == "watter" && (
                                      <>Wasserzähler</>
                                    )}
                                  {protocolToShow?.protocol_counter[j]
                                    ?.counter_type == "oil" && <>Heizöl</>}
                                  {protocolToShow?.protocol_counter[j]
                                    ?.counter_type == "hotness" && (
                                      <>Heizkostenverteiler</>
                                    )}
                                  {protocolToShow?.protocol_counter[j]
                                    ?.counter_type == "electronicHot" && (
                                      <>Elektronischer Heizkostenverteiler</>
                                    )}
                                  {protocolToShow?.protocol_counter[j]
                                    ?.counter_type == "smokeDetector" && (
                                      <>Rauchmelder</>
                                    )}
                                </span>
                              </div>

                    </div>
                    <div className="formAbschlussOvwerview">
                          <div className="sub-formverteilerOvwerview  " style={{padding: " 20px 50px"}}>
                            <div className="first-part-formverteilerOvwerview p-0">
                              {/* Fixed for all type of counter */}
                            <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                <span className="uppercase" style={{ fontSize: "16px" }}>Installationsort</span>
                              </div>
                              <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                                <div
                                  style={{ width: "100%", fontWeight: "bold" }}
                                >
                                  <span>
                                    {protocolToShow?.protocol_counter[j]
                                      ?.counter_location
                                      ? protocolToShow?.protocol_counter[j]
                                        ?.counter_location
                                      : "Angabe fehlt"}
                                  </span>
                                </div>
                              </div>
                            {/* Strom counter electricity */}
                              {protocolToShow?.protocol_counter[j]
                                ?.counter_type === "electricity"?(<>
                                 <div className="row">
                                  <div className="col-md-4">
                                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                    <span
                                      className="titles"
                                      style={{ textTransform: "uppercase", fontSize: "16px" }}
                                    >
                                      Zählernummer
                                    </span>
                                  </div>
                                  <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                                    <div
                                      style={{fontWeight: "bold" }}
                                    >
                                      <span>
                                        {protocolToShow?.protocol_counter[j]
                                          ?.counter_number
                                          ? protocolToShow?.protocol_counter[j]
                                            ?.counter_number
                                          : "Angabe fehlt"}
                                      </span>
                                    </div>
                                  </div>
                                  </div>
                                  {protocolToShow?.protocol_counter[j]
                                ?.counter_value_1 !== null &&
                                protocolToShow?.protocol_counter[j]
                                  ?.counter_value_2 !== null ? (
                                <>
                                <div className="col-md-4">
                                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                    {protocolToShow?.protocol_counter[j]
                                      ?.counter_is_double === "doubleCounter" &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_value_1 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type === "electricity" && (
                                        <span
                                          className="titles"
                                          style={{ textTransform: "uppercase", fontSize: "16px" }}
                                        >
                                          HT (Hochtarifzählerstand)
                                        </span>
                                      )}

                                    {protocolToShow?.protocol_counter[j]
                                      ?.counter_is_double == "doubleCounter" &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_value_2 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type === "electricity" && (
                                        <span
                                          className="titles"
                                          style={{ textTransform: "uppercase", fontSize: "16px" }}
                                        >
                                          NT (Niedertarifzählerstand)
                                        </span>
                                      )}

                                    {protocolToShow?.protocol_counter[j]
                                      ?.counter_is_double != "doubleCounter" &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_value_1 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type !== "electronicHot" && (
                                        <span
                                          className="titles"
                                          style={{ textTransform: "uppercase", fontSize: "16px" }}
                                        >
                                          Zählerstand
                                        </span>
                                      )}
                                  </div>
                                  <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                                    {protocolToShow?.protocol_counter[j]
                                      ?.counter_value_1 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type !== "electronicHot" ? (
                                      <div
                                        style={{
                                          width: "50%",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <span>
                                          {
                                            protocolToShow?.protocol_counter[j]
                                              ?.counter_value_1
                                          }
                                        </span>
                                      </div>
                                    ) : protocolToShow?.protocol_counter[j]
                                      ?.counter_value_1 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type ==
                                      "electronicHot" ? null 
                                      : (
                                      (
                                        protocolToShow?.protocol_counter[j]
                                          ?.counter_value_1 == null &&
                                        protocolToShow?.protocol_counter[j]
                                          ?.counter_type !== "electronicHot"
                                      )(
                                        <div
                                          style={{fontWeight: "bold",}}
                                        >
                                          <span>Angabe fehlt</span>
                                        </div>
                                      )
                                    )}

                                    {protocolToShow?.protocol_counter[j]
                                      ?.counter_value_2 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_is_double === "doubleCounter" &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type !== "electronicHot" ? (
                                      <div
                                        style={{fontWeight: "bold",}}
                                      >
                                        <span>
                                          {
                                            protocolToShow?.protocol_counter[j]
                                              ?.counter_value_2
                                          }
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                  </div>
                                </>
                              ) : protocolToShow?.protocol_counter[j]
                                ?.counter_value_1 !== null &&
                                protocolToShow?.protocol_counter[j]
                                  ?.counter_value_2 === null ? (
                                <>
                                <div className="col-md-4">
                                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                    <span
                                      className="titles"
                                      style={{ textTransform: "uppercase", fontSize: "16px" }}
                                    >
                                      Zählerstand
                                    </span>
                                  </div>
                                  <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                                    {protocolToShow?.protocol_counter[j]
                                      ?.counter_value_1 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type !== "electronicHot" ? (
                                      <div
                                        style={{
                                          width: "50%",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <span>
                                          {
                                            protocolToShow?.protocol_counter[j]
                                              ?.counter_value_1
                                          }
                                        </span>
                                      </div>
                                    ) : protocolToShow?.protocol_counter[j]
                                      ?.counter_value_1 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type ==
                                      "electronicHot" ? null : (
                                      (
                                        protocolToShow?.protocol_counter[j]
                                          ?.counter_value_1 == null &&
                                        protocolToShow?.protocol_counter[j]
                                          ?.counter_type !== "electronicHot"
                                      )(
                                        <div
                                          style={{fontWeight: "bold",}}
                                        >
                                          <span>Angabe fehlt</span>
                                        </div>
                                      )
                                    )}

                                    {protocolToShow?.protocol_counter[j]
                                      ?.counter_value_2 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type !== "electronicHot" ? (
                                      <div
                                        style={{fontWeight: "bold",}}
                                      >
                                        <span>
                                          {
                                            protocolToShow?.protocol_counter[j]
                                              ?.counter_value_2
                                          }
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                  </div>
                                </>
                              ) : (
                                protocolToShow?.protocol_counter[j]
                                  ?.counter_value_1 === null &&
                                protocolToShow?.protocol_counter[j]
                                  ?.counter_value_2 === null &&
                                null
                               )}
                                  <div className="col-md-4">
                                  {protocolToShow?.protocol_counter[j]
                                ?.market_location_number ? (
                                <>
                                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                    <span>Marktlokationsnummer</span>
                                  </div>
                                  <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                                    <div
                                      style={{
                                        width: "100%",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <span>
                                        {protocolToShow?.protocol_counter[j]
                                          ?.market_location_number
                                          ? protocolToShow?.protocol_counter[j]
                                            ?.market_location_number
                                          : "Angabe fehlt"}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                                    
                                  </div>
                                 </div>
                                </>) : null }
                            {/*  other type of counter except somedector, electronicHot and strom */}
                              {(protocolToShow?.protocol_counter[j]
                                ?.counter_type !== "smokeDetector" && protocolToShow?.protocol_counter[j]
                                ?.counter_type !== "electronicHot" && protocolToShow?.protocol_counter[j]
                                ?.counter_type !== "electricity") ? (
                                <>
                                  <div className="row">
                                  {protocolToShow?.protocol_counter[j]
                                ?.counter_value_1 !== null &&
                                protocolToShow?.protocol_counter[j]
                                  ?.counter_value_2 !== null ? (
                                <>
                                <div className="col-md-4">
                                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                    {protocolToShow?.protocol_counter[j]
                                      ?.counter_is_double === "doubleCounter" &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_value_1 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type === "electricity" && (
                                        <span
                                          className="titles"
                                          style={{ textTransform: "uppercase", fontSize: "16px" }}
                                        >
                                          HT (Hochtarifzählerstand)
                                        </span>
                                      )}

                                    {protocolToShow?.protocol_counter[j]
                                      ?.counter_is_double == "doubleCounter" &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_value_2 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type === "electricity" && (
                                        <span
                                          className="titles"
                                          style={{ textTransform: "uppercase", fontSize: "16px" }}
                                        >
                                          NT (Niedertarifzählerstand)
                                        </span>
                                      )}

                                    {protocolToShow?.protocol_counter[j]
                                      ?.counter_is_double != "doubleCounter" &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_value_1 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type !== "electronicHot" && (
                                        <span
                                          className="titles"
                                          style={{ textTransform: "uppercase", fontSize: "16px" }}
                                        >
                                          Zählerstand
                                        </span>
                                      )}
                                  </div>
                                  <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                                    {protocolToShow?.protocol_counter[j]
                                      ?.counter_value_1 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type !== "electronicHot" ? (
                                      <div
                                        style={{fontWeight: "bold",}}
                                      >
                                        <span>
                                          {
                                            protocolToShow?.protocol_counter[j]
                                              ?.counter_value_1
                                          }
                                        </span>
                                      </div>
                                    ) : protocolToShow?.protocol_counter[j]
                                      ?.counter_value_1 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type ==
                                      "electronicHot" ? null 
                                      : (
                                      (
                                        protocolToShow?.protocol_counter[j]
                                          ?.counter_value_1 == null &&
                                        protocolToShow?.protocol_counter[j]
                                          ?.counter_type !== "electronicHot"
                                      )(
                                        <div
                                          style={{fontWeight: "bold",}}
                                        >
                                          <span>Angabe fehlt</span>
                                        </div>
                                      )
                                    )}

                                    {protocolToShow?.protocol_counter[j]
                                      ?.counter_value_2 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_is_double === "doubleCounter" &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type !== "electronicHot" ? (
                                      <div
                                        style={{fontWeight: "bold",}}
                                      >
                                        <span>
                                          {
                                            protocolToShow?.protocol_counter[j]
                                              ?.counter_value_2
                                          }
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                  </div>
                                </>
                              ) : protocolToShow?.protocol_counter[j]
                                ?.counter_value_1 !== null &&
                                protocolToShow?.protocol_counter[j]
                                  ?.counter_value_2 === null ? (
                                <>
                                <div className="col-md-4">
                                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                    <span
                                      className="titles"
                                      style={{ textTransform: "uppercase", fontSize: "16px" }}
                                    >
                                      Zählerstand
                                    </span>
                                  </div>
                                  <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                                    {protocolToShow?.protocol_counter[j]
                                      ?.counter_value_1 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type !== "electronicHot" ? (
                                      <div
                                        style={{fontWeight: "bold",}}
                                      >
                                        <span>
                                          {
                                            protocolToShow?.protocol_counter[j]
                                              ?.counter_value_1
                                          }
                                        </span>
                                      </div>
                                    ) : protocolToShow?.protocol_counter[j]
                                      ?.counter_value_1 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type ==
                                      "electronicHot" ? null : (
                                      (
                                        protocolToShow?.protocol_counter[j]
                                          ?.counter_value_1 == null &&
                                        protocolToShow?.protocol_counter[j]
                                          ?.counter_type !== "electronicHot"
                                      )(
                                        <div
                                          style={{fontWeight: "bold", }}
                                        >
                                          <span>Angabe fehlt</span>
                                        </div>
                                      )
                                    )}

                                    {protocolToShow?.protocol_counter[j]
                                      ?.counter_value_2 !== null &&
                                      protocolToShow?.protocol_counter[j]
                                        ?.counter_type !== "electronicHot" ? (
                                      <div
                                        style={{
                                          width: "50%",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <span>
                                          {
                                            protocolToShow?.protocol_counter[j]
                                              ?.counter_value_2
                                          }
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                  </div>
                                </>
                              ) : (
                                protocolToShow?.protocol_counter[j]
                                  ?.counter_value_1 === null &&
                                protocolToShow?.protocol_counter[j]
                                  ?.counter_value_2 === null &&
                                null
                               )}
                                  
                                  <div className=" col-md-4">
                                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                    <span
                                      className="titles"
                                      style={{ textTransform: "uppercase", fontSize: "16px" }}
                                    >
                                      Zählernummer
                                    </span>
                                  </div>
                                  <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                                    <div
                                      style={{ fontWeight: "bold" }}
                                    >
                                      <span>
                                        {protocolToShow?.protocol_counter[j]
                                          ?.counter_number
                                          ? protocolToShow?.protocol_counter[j]
                                            ?.counter_number
                                          : "Angabe fehlt"}
                                      </span>
                                    </div>
                                  </div>
                                  </div>
                                  </div>
                                </>
                              ) : null}
                              {/* counter smokeDector */}
                              
                              {protocolToShow?.protocol_counter[j]
                                ?.counter_type === "smokeDetector" ? (
                                <>
                                <div className="row">
                                <div className="col-md-4">
                                <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                    <span
                                      className="titles"
                                      style={{ textTransform: "uppercase", fontSize: "16px" }}
                                    >
                                      Zählernummer
                                    </span>
                                  </div>
                                  <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                                    <div
                                      style={{  fontWeight: "bold" }}
                                    >
                                      <span>
                                        {protocolToShow?.protocol_counter[j]
                                          ?.counter_number
                                          ? protocolToShow?.protocol_counter[j]
                                            ?.counter_number
                                          : "Angabe fehlt"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                  <div className="col-md-4">
                                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                    <span className="titles">funktionstest</span>
                                  </div>
                                  <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                                    <div style={{ width: "100%" }}>
                                      <span style={{ fontWeight: "bold" }}>
                                        {protocolToShow?.protocol_counter[j]
                                          ?.counter_is_double == null && (
                                            <>Angabe fehlt</>
                                          )}
                                        {protocolToShow?.protocol_counter[j]
                                          ?.counter_is_double == "inOrder" && (
                                            <>In ordnung</>
                                          )}
                                        {protocolToShow?.protocol_counter[j]
                                          ?.counter_is_double == "defective" && (
                                            <>Mangelhaft</>
                                          )}
                                      </span>
                                    </div>
                                  </div>
                                  </div></div>
                                </>
                              ) : null}
                              {/* counter electronicHot */}

                              {protocolToShow?.protocol_counter[j]
                                ?.counter_type === "electronicHot" ? (
                                <>
                                <div className=" row">
                                  <div className=" col-md-4">
                                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                    <span
                                      className="titles"
                                      style={{ textTransform: "uppercase", fontSize: "16px" }}
                                    >
                                      Zählernummer
                                    </span>
                                  </div>
                                  <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                                    <div
                                      style={{  fontWeight: "bold" }}
                                    >
                                      <span>
                                        {protocolToShow?.protocol_counter[j]
                                          ?.counter_number
                                          ? protocolToShow?.protocol_counter[j]
                                            ?.counter_number
                                          : "Angabe fehlt"}
                                      </span>
                                    </div>
                                  </div>
                                  </div>
                                  <div className="col-md-8">
                                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                    <div >
                                      <div className="LigneDataAngabefhltOvwerview">
                                        <span
                                          className="titles"
                                          style={{
                                            textTransform: "none",
                                            color: "#888",
                                          }}
                                        >
                                          Anzeige 1
                                        </span>
                                        <span
                                          className="titles"
                                          style={{
                                            textTransform: "none",
                                            color: "#888",
                                          }}
                                        >
                                          Anzeige 2
                                        </span>
                                        <span
                                          className="titles"
                                          style={{
                                            textTransform: "none",
                                            color: "#888",
                                          }}
                                        >
                                          Anzeige 3
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="LigneAdresse-RenviernungsdatumOvwerview">
                                    <div>
                                      <div className="LigneDataAngabefhltOvwerview">
                                        <span style={{ fontWeight: "bold" }}>
                                          {protocolToShow?.protocol_counter[j]
                                            ?.counter_current_consumption
                                            ? protocolToShow?.protocol_counter[j]
                                              ?.counter_current_consumption
                                            : "Angabe fehlt"}
                                        </span>
                                        <span style={{ fontWeight: "bold" }}>
                                          {protocolToShow?.protocol_counter[j]
                                            ?.counter_value_1
                                            ? protocolToShow?.protocol_counter[j]
                                              ?.counter_value_1
                                            : "Angabe fehlt"}
                                        </span>
                                        <span style={{ fontWeight: "bold" }}>
                                          {protocolToShow?.protocol_counter[j]
                                            ?.counter_value_2
                                            ? protocolToShow?.protocol_counter[j]
                                              ?.counter_value_2
                                            : "Angabe fehlt"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                    </div>

                                </div>
                                  
                                </>
                              ) : null}
                            </div>
                            {/* Images of counter  */}

                            <div className="image-grid  d-flex  justify-content-end">
                              {protocolToShow?.protocol_counter[j]
                                ?.counter_type == "electronicHot" ? (
                                <>
                                  {protocolToShow?.protocol_counter[j]?.image
                                    ?.counter_image_remote_url != null ? (
                                    <div className="image--container ">
                                      {showImage &&
                                        protocolToShow?.protocol_counter[j]?.image
                                          ?.id == idImageShow && (
                                          <div
                                            className="image-overlay"
                                            onClick={() =>
                                              toggleImage(
                                                protocolToShow?.protocol_counter[
                                                  j
                                                ]?.image?.id
                                              )
                                            }
                                          >
                                            <img
                                              src={
                                                protocolToShow?.protocol_counter[
                                                  j
                                                ]?.image?.counter_image_remote_url
                                              }
                                              alt={
                                                protocolToShow?.protocol_counter[
                                                  j
                                                ]?.image?.counter_image_name
                                              }
                                              className="image"
                                            />
                                          </div>
                                        )}
                                      {!showImage && (
                                        <div
                                          className="icon overview"
                                          onClick={() =>
                                            toggleImage(
                                              protocolToShow?.protocol_counter[j]
                                                ?.image?.id
                                            )
                                          }
                                        >
                                          {/* Add your icon here */}
                                          <i className="fas fa-search-plus"></i>
                                        </div>
                                      )}
                                      <img
                                        src={
                                          protocolToShow?.protocol_counter[j]
                                            ?.image?.counter_image_remote_url
                                        }
                                        alt={
                                          protocolToShow?.protocol_counter[j]
                                            ?.image?.counter_image_name
                                        }
                                        height="125px"
                                        width="125px"
                                      />
                                    </div>
                                  ) : (null)}
                                  {protocolToShow?.protocol_counter[j]?.image1
                                    ?.counter_image_remote_url != null ? (
                                    <div className="image--container">
                                      {showImage &&
                                        protocolToShow?.protocol_counter[j]
                                          ?.image1?.id == idImageShow && (
                                          <div
                                            className="image-overlay"
                                            onClick={() =>
                                              toggleImage(
                                                protocolToShow?.protocol_counter[
                                                  j
                                                ]?.image1?.id
                                              )
                                            }
                                          >
                                            <img
                                              src={
                                                protocolToShow?.protocol_counter[
                                                  j
                                                ]?.image1
                                                  ?.counter_image_remote_url
                                              }
                                              alt={
                                                protocolToShow?.protocol_counter[
                                                  j
                                                ]?.image1?.counter_image_name
                                              }
                                              className="image"
                                            />
                                          </div>
                                        )}
                                      {!showImage && (
                                        <div
                                          className="icon overview"
                                          onClick={() =>
                                            toggleImage(
                                              protocolToShow?.protocol_counter[j]
                                                ?.image1?.id
                                            )
                                          }
                                        >
                                          {/* Add your icon here */}
                                          <i className="fas fa-search-plus"></i>
                                        </div>
                                      )}
                                      <img
                                        src={
                                          protocolToShow?.protocol_counter[j]
                                            ?.image1?.counter_image_remote_url
                                        }
                                        alt={
                                          protocolToShow?.protocol_counter[j]
                                            ?.image1?.counter_image_name
                                        }
                                        height="125px"
                                        width="125px"
                                      />
                                    </div>
                                  ) : (null)}

                                  {protocolToShow?.protocol_counter[j]?.image2
                                    ?.counter_image_remote_url != null ? (
                                    <div className="image--container">
                                      {showImage &&
                                        protocolToShow?.protocol_counter[j]
                                          ?.image2?.id == idImageShow && (
                                          <div
                                            className="image-overlay"
                                            onClick={() =>
                                              toggleImage(
                                                protocolToShow?.protocol_counter[
                                                  j
                                                ]?.image2?.id
                                              )
                                            }
                                          >
                                            <img
                                              src={
                                                protocolToShow?.protocol_counter[
                                                  j
                                                ]?.image2
                                                  ?.counter_image_remote_url
                                              }
                                              alt={
                                                protocolToShow?.protocol_counter[
                                                  j
                                                ]?.image2?.counter_image_name
                                              }
                                              className="image"
                                            />
                                          </div>
                                        )}
                                      {!showImage && (
                                        <div
                                          className="icon overview"
                                          onClick={() =>
                                            toggleImage(
                                              protocolToShow?.protocol_counter[j]
                                                ?.image2?.id
                                            )
                                          }
                                        >
                                          {/* Add your icon here */}
                                          <i className="fas fa-search-plus"></i>
                                        </div>
                                      )}
                                      <img
                                        src={
                                          protocolToShow?.protocol_counter[j]
                                            ?.image2?.counter_image_remote_url
                                        }
                                        alt={
                                          protocolToShow?.protocol_counter[j]
                                            ?.image2?.counter_image_name
                                        }
                                        height="125px"
                                        width="125px"
                                      />
                                    </div>
                                  ) : (null)}
                                </>
                              ) : (
                                protocolToShow?.protocol_counter[j]
                                  ?.counter_type == "electronicHot" &&
                                protocolToShow?.protocol_counter[j]?.image
                                  ?.counter_image_remote_url == null &&
                                protocolToShow?.protocol_counter[j]?.image1
                                  ?.counter_image_remote_url == null &&
                                protocolToShow?.protocol_counter[j]?.image2
                                  ?.counter_image_remote_url == null && <></>
                              )}

                              {protocolToShow.protocol_counter[j].counter_type !==
                                "electronicHot" &&
                                protocolToShow.protocol_counter[j]?.image
                                  ?.counter_image_remote_url != null ? (
                                <div className="image--container">
                                  {showImage &&
                                    protocolToShow?.protocol_counter[j]?.image
                                      ?.id == idImageShow && (
                                      <div
                                        className="image-overlay"
                                        onClick={() =>
                                          toggleImage(
                                            protocolToShow?.protocol_counter[j]
                                              ?.image?.id
                                          )
                                        }
                                      >
                                        <img
                                          src={
                                            protocolToShow?.protocol_counter[j]
                                              ?.image?.counter_image_remote_url
                                          }
                                          alt={
                                            protocolToShow?.protocol_counter[j]
                                              ?.image?.counter_image_name
                                          }
                                          className="image"
                                        />
                                      </div>
                                    )}
                                  {!showImage && (
                                    <div
                                      className="icon overview"
                                      onClick={() =>
                                        toggleImage(
                                          protocolToShow?.protocol_counter[j]
                                            ?.image?.id
                                        )
                                      }
                                    >
                                      {/* Add your icon here */}
                                      <i className="fas fa-search-plus"></i>
                                    </div>
                                  )}
                                  <div class="image-container">
                                    <img
                                      src={
                                        protocolToShow?.protocol_counter[j]?.image
                                          ?.counter_image_remote_url
                                      }
                                      alt={
                                        protocolToShow?.protocol_counter[j]?.image
                                          ?.counter_image_name
                                      }
                                      className="image-object"
                                      style={{height:"150px",width:"225px"}}
                                    />
                                  </div>
                                </div>
                              ) : (null)}
                            </div>
                          </div>
                        </div>
                        <hr className="hrOvwerview" />
                    </>
                  ))}
                </>
              ) : (
                <></>
              )}
              {protocol_pattern &&
                protocol_pattern.length > 0 &&
                protocol_pattern.find(
                  (key) => key.key_section_name === "counters"
                ) &&
                handleDisplayPatternSection("counters")}
            </div>
          </div>}
          {/* section keys */}
          {protocolToShow?.protocol_type != "vacancy_inspection" && <div className="SonCard mt-3">
            <div className="card-shadow">
              <div className="neuer withoutBorderRadius">
                <img className="NeuerIcon" src={keyIcon} alt="Schlüssel Icon" />
                <h3 style={{ textTransform: "uppercase" }}> Schlüssel </h3>
              </div>
              {protocolToShow?.protocol_keys &&
                protocolToShow?.protocol_keys.length > 0 ? (
                <>
                  {protocolToShow?.protocol_keys.map((key, s) => (
                    <>
                      <div className="after-cards protocol-step_border">
                        <div
                          className="col-md-12  mt-2 formAbschlussOvwerview"
                          style={{ padding: "0" }}
                        >
                          <div
                            className="row col-md-12"
                            style={{
                              marginTop: "-0.5rem",
                              paddingTop: "0",
                              paddingRight: "0",
                              paddingLeft: "0",
                              marginLeft: "0.5rem",
                            }}
                          >
                            <div
                              className="col-md-4 mb-3"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {parseInt(
                                protocolToShow?.protocol_keys[s]?.key_number
                              ) >
                                protocolToShow?.protocol_keys[s]
                                  ?.number_keys_returned_in_main_protocol ? (
                                <img
                                  className="notvalidateSclusselOvwerviewIcon"
                                  src={NotValidIcon}
                                  alt="notvalidateSclusselOvwerview Icon"
                                  style={{ marginbottom: "0rem", marginRight: "1rem" }}
                                />
                              ) : (
                                <img
                                  className="validateSclusselOvwerviewIcon"
                                  src={ValidIcon}
                                  alt="validateSclusselOvwerview Icon"
                                  style={{ marginbottom: "0rem", marginRight: "1rem" }}
                                />
                              )}
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "3px",
                                  fontSize: "1.15rem",
                                }}
                              >
                                {protocolToShow?.protocol_keys[s]?.key_number &&
                                  protocolToShow?.protocol_keys[s]?.key_number
                                    .length > 0 ? (
                                  <span>
                                    {
                                      protocolToShow?.protocol_keys[s]
                                        ?.key_number
                                    }{" "}
                                    x{" "}
                                  </span>
                                ) : (
                                  <span>K.A x </span>
                                )}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "mainEntrance" && <span> Eingangstür, </span>}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "apartmentKey" && (
                                    <span> Wohnungsschlüssel, </span>
                                  )}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "basement" && <span> Keller, </span>}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "attic" && <span> Dachboden, </span>}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "garage" && <span> Garage, </span>}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "shed" && <span> Schuppen, </span>}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "outerGate" && <span> Aussentor, </span>}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "goal" && <span> Tor, </span>}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "gazebo" && <span> Gartenlaube, </span>}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "letterBox" && <span> Briefkasten, </span>}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "frontDoor" && <span> Haustür, </span>}
                                {protocolToShow?.protocol_keys[s]?.key_type ==
                                  "other" && (
                                    <span>
                                      {protocolToShow?.protocol_keys[s]?.key_name}{" "}
                                      ,{" "}
                                    </span>
                                  )}
                                <span
                                  style={
                                    parseInt(
                                      protocolToShow?.protocol_keys[s]
                                        ?.key_number
                                    ) >
                                      protocolToShow?.protocol_keys[s]
                                        ?.number_keys_returned_in_main_protocol
                                      ? { color: "#D00000" }
                                      : { color: "#62AB12" }
                                  }
                                >
                                  erhalten :{" "}
                                  {protocolToShow?.protocol_keys[s]
                                    ?.number_keys_returned_in_main_protocol
                                    ? protocolToShow?.protocol_keys[s]
                                      ?.number_keys_returned_in_main_protocol
                                    : "K.A"}{" "}
                                </span>
                              </p>
                            </div>
                            <div
                              className="row col-md-8"
                              style={{ paddingRight: "0.5rem" }}
                            >
                              <div
                                className="col-md-4 mb-3 flex-column justify-content-center"
                                style={{display: "flex",}}
                              >
                                <span
                                  style={{
                                    textTransform: "none",
                                    textAlign: "left",
                                  }}
                                >
                                  Übergabe
                                </span>
                                <div className=" d-flex align-items-center">
                                <img
                                  className="CalenderSclusselOvwerviewIcon"
                                  src={CalenderIcon}
                                  alt="validateSclusselOvwerview Icon"
                                  style={{filter: "brightness(0) saturate(100%) invert(33%) sepia(91%) saturate(10%) hue-rotate(96deg) brightness(94%) contrast(94%)"}}
                                />
                                <p className="mb-0">
                                  <span></span>
                                  {protocolToShow?.protocol_keys[s]
                                    ?.key_date_of_forwarding
                                    ? protocolToShow.protocol_keys[s]
                                      ?.key_date_of_forwarding
                                    : "K.A"}
                                </p></div>
                              </div>
                              <div
                                className="col-md-4 mb-3 flex-column justify-content-center"
                                style={{
                                  display: "flex",
                                }}
                              >
                                 <span>Information</span>
                                 <div className=" d-flex  align-items-center">
                                <span
                                  style={{
                                    marginTop: "0",
                                    marginBottom: "0",
                                    fontSize: "1.2rem",
                                  }}
                                >
                                  <span>
                                    {protocolToShow?.protocol_keys[s]
                                      ?.key_notice
                                      ? protocolToShow?.protocol_keys[s]
                                        ?.key_notice
                                      : "Kein Kommentar"}
                                  </span>
                                </span>
                                </div>
                              </div>
                              <div
                                className="col-md-4 mb-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {protocolToShow?.protocol_keys[s].image &&
                                  protocolToShow?.protocol_keys[s]?.image
                                    ?.key_image_remote_url != null ? (
                                  <div className="image--container ">
                                    {showImage &&
                                      protocolToShow?.protocol_keys[s]?.image
                                        ?.id == idImageShow && (
                                        <div
                                          className="image-overlay"
                                          onClick={() =>
                                            toggleImage(
                                              protocolToShow?.protocol_keys[s]
                                                ?.image?.id
                                            )
                                          }
                                        >
                                          <img
                                            src={
                                              protocolToShow?.protocol_keys[s]
                                                ?.image?.key_image_remote_url
                                            }
                                            alt={
                                              protocolToShow?.protocol_keys[s]
                                                ?.image?.key_image_name
                                            }
                                            className="image"
                                          />
                                        </div>
                                      )}
                                    {!showImage && (
                                      <div
                                        className="icon overview"
                                        onClick={() =>
                                          toggleImage(
                                            protocolToShow?.protocol_keys[s]
                                              ?.image?.id
                                          )
                                        }
                                      >
                                        <i className="fas fa-search-plus"></i>
                                      </div>
                                    )}
                                    <img
                                      src={
                                        protocolToShow?.protocol_keys[s]?.image
                                          ?.key_image_remote_url
                                      }
                                      alt={
                                        protocolToShow?.protocol_keys[s]?.image
                                          ?.key_image_name
                                      }
                                      style={{
                                        objectFit: "cover",
                                        width: "7.8rem",
                                        height: "9.4rem",
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="image-container">
                                    <PhotoLibraryIcon
                                      className="defaultImageIcon"
                                      sx={{
                                        width: "7.8rem",
                                        height: "9.4rem",
                                        marginLeft: "-4rem",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>

                          </div>
                        </div>
                        <hr className="hrOvwerview" />
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <></>
              )}
              {protocol_pattern &&
                protocol_pattern.length > 0 &&
                protocol_pattern.find((key) => key.key_section_name === "keys") &&
                handleDisplayPatternSection("keys")}
            </div>
          </div>}
          {/* section certification */}
          {protocolToShow?.protocol_type != "vacancy_inspection" && <div className="SonCard mt-3">
            <div className="card-shadow">
              <div className="wohnungsdaten withoutBorderRadius">
                <div className="first_wohnungsdaten withoutBorderRadius padding-left-right col-md-7" >
                  <div className="row padding-center">
                    <img
                      className="WohnungsdatenIcon"
                      src={ViewContractIcon}
                      alt="eigentumer Icon"
                    />
                    <h3>Wohnungsgeberbescheinigung</h3>
                  </div>
                </div>
                <div className="sub_wohnungsdaten col-md-5">
                  <div className="row  d-flex align-items-center h-100">
                    <div className="col-md-8">
                      <h3>Bescheinigung erstellen</h3>
                    </div>
                    <div className="col-md-4 d-flex justify-content-end align-items-center">
                      <Checkbox
                        className="checkbox"
                        checked={
                          protocolToShow?.protocol_transfer_certificate == true
                        }
                        {...label}
                        sx={{
                          color: grey[800],
                          "&.Mui-checked": {
                            color: grey[600],
                          },
                          "& .MuiSvgIcon-root": { fontSize: 28 },
                        }}
                      // onChange={() => handleCertificate()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {protocolToShow?.protocol_transfer_certificate == true && (
                <>
                  <div className="after-cards">
                    <div className="formAbschlussOvwerview">
                      <div className="LigneWohnungsdatenOvwerview">
                        <span className="uppercase">Adresse der Wohnung</span>
                      </div>
                      <div
                        className="LigneDataAdresse-RenviernungsdatumOvwerview"
                        style={{ marginBottom: "20px", width: "100%" }}
                      >
                        <div style={{ width: "50%" }}>
                          <span>
                            {protocolToShow?.object?.address?.object_street
                              ? protocolToShow?.object?.address?.object_street
                              : "Straße fehlt"}
                            ,{" "}
                            {protocolToShow?.object?.address?.object_house_nbr
                              ? protocolToShow?.object?.address?.object_house_nbr
                              : "Hausnummer  fehlt"}
                          </span>
                          <br />
                          <span>
                            {protocolToShow?.object?.address?.object_plz
                              ? protocolToShow?.object?.address?.object_plz
                              : "PLZ fehlt"}
                            ,{" "}
                            {protocolToShow?.object?.address?.object_city
                              ? protocolToShow?.object?.address?.object_city
                              : "Ort fehlt"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <hr className="hrOvwerview" />
                  </div>
                  <div className="aftercardOvwerview">
                    <div className="formAbschlussOvwerview">
                      <div className="LigneWohnungsdatenOvwerview">
                        <span className="uppercase">Eigentümer</span>
                      </div>
                      {protocolToShow.owner_is_landlord == true ? (
                        <>
                          <div className="LigneAdresse-RenviernungsdatumOvwerview">
                            <span></span>
                            <span className="uppercase" style={{ fontSize: "16px" }}>Art des Wohnungsgebers</span>
                          </div>
                          <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                            <div style={{ width: "50%" }} className="mb-2">
                              <span>
                                {protocolToShow?.landlord?.landlord_salutation ==
                                  null && "Anrede fehlt "}
                                {protocolToShow?.landlord?.landlord_salutation ==
                                  "noChoice" && "Keine Angabe "}
                                {protocolToShow?.landlord?.landlord_salutation ==
                                  "male" && "Herr "}
                                {protocolToShow?.landlord?.landlord_salutation ==
                                  "female" && "Frau "}
                                {formatTitle(protocolToShow?.landlord?.landlord_title)}
                                {protocolToShow?.landlord?.landlord_first_name ==
                                  null &&
                                  protocolToShow?.landlord?.landlord_last_name ==
                                  null && <span>Name und Vorname fehlt</span>}
                                {(protocolToShow?.landlord?.landlord_first_name ==
                                  null ||
                                  protocolToShow?.landlord?.landlord_first_name ==
                                  "") &&
                                  protocolToShow?.landlord?.landlord_last_name !=
                                  null && <>{" Vorname fehlt "}</>}
                                {protocolToShow?.landlord?.landlord_first_name !=
                                  null &&
                                  (protocolToShow?.landlord?.landlord_last_name ==
                                    null ||
                                    protocolToShow?.landlord
                                      ?.landlord_last_name == "") && (
                                    <>{" Nachname fehlt "}</>
                                  )}
                                {protocolToShow?.landlord?.landlord_first_name !=
                                  null &&
                                  protocolToShow?.landlord?.landlord_last_name !=
                                  null && (
                                    <>
                                      {
                                        protocolToShow?.landlord
                                          ?.landlord_first_name
                                      }{" "}
                                      {
                                        protocolToShow?.landlord
                                          ?.landlord_last_name
                                      }
                                    </>
                                  )}
                              </span>
                              <br />
                              <span>
                                {protocolToShow?.landlord?.address
                                  ?.landlord_street
                                  ? protocolToShow?.landlord?.address
                                    ?.landlord_street
                                  : "Straße fehlt"}
                                ,{" "}
                                {protocolToShow?.landlord?.address
                                  ?.landlord_house_number
                                  ? protocolToShow?.landlord?.address
                                    ?.landlord_house_number
                                  : "Hausnummer  fehlt"}
                              </span>
                              <br />
                              <span>
                                {protocolToShow?.landlord?.address?.landlord_plz
                                  ? protocolToShow?.landlord?.address
                                    ?.landlord_plz
                                  : "PLZ fehlt"}
                                ,{" "}
                                {protocolToShow?.landlord?.address?.landlord_city
                                  ? protocolToShow?.landlord?.address
                                    ?.landlord_city
                                  : "Ort fehlt"}
                              </span>
                            </div>
                            <div style={{ width: "50%" }} className="mb-2">
                              {(protocolToShow?.landlord?.landlord_type == null ||
                                protocolToShow?.landlord?.landlord_type ==
                                "") && <span>Angabe fehlt</span>}
                              {protocolToShow?.landlord?.landlord_type ==
                                "noChoice" && (
                                  <span style={{ color: "#000000" }}>
                                    Keine Angabe
                                  </span>
                                )}
                              {protocolToShow?.landlord?.landlord_type ==
                                "company" && (
                                  <span style={{ color: "#000000" }}>
                                    Gesellschaft
                                  </span>
                                )}
                              {protocolToShow?.landlord?.landlord_type ==
                                "private" && (
                                  <span style={{ color: "#000000" }}>Privat</span>
                                )}
                            </div>
                          </div>
                          <div className="LigneAdresse-RenviernungsdatumOvwerview">
                            <span className="uppercase" style={{ fontSize: "16px" }}>E-mail:</span>
                          </div>
                          <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                            <div style={{ width: "50%" }}>
                              <span>
                                {" "}
                                {protocolToShow?.landlord?.landlord_email
                                  ? protocolToShow?.landlord?.landlord_email
                                  : "Angabe fehlt"}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="LigneAdresse-RenviernungsdatumOvwerview">
                            <span
                              style={{ fontWeight: "bold", color: "#000000" }}
                            >
                              {protocolToShow?.owner?.owner_company_name
                                ? protocolToShow?.owner?.owner_company_name
                                : "Unternehmensname fehlt"}
                            </span>
                            <span className="uppercase" style={{ fontSize: "16px" }}>Art des Eigentümers</span>
                          </div>
                          <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                            <div style={{ width: "50%" }}>
                              <span>
                                {" "}
                                {protocolToShow?.owner?.owner_salutation ==
                                  null && "Anrede fehlt "}
                                {protocolToShow?.owner?.owner_salutation ==
                                  "noChoice" && "Keine Angabe "}
                                {protocolToShow?.owner?.owner_salutation ==
                                  "male" && "Herr "}
                                {protocolToShow?.owner?.owner_salutation ==
                                  "female" && "Frau "}
                                {protocolToShow?.owner?.owner_salutation ==
                                  "association" && "Weg "}
                                {formatTitle(protocolToShow?.owner?.owner_title)}
                                {protocolToShow?.owner?.owner_first_name == "" &&
                                  protocolToShow?.owner?.owner_last_name ==
                                  "" && (
                                    <span style={{ color: "#D00000" }}>
                                      Name und Vorname fehlt
                                    </span>
                                  )}
                                {(protocolToShow?.owner?.owner_first_name == "" ||
                                  protocolToShow?.owner?.owner_first_name ==
                                  "") &&
                                  protocolToShow?.owner?.owner_last_name !=
                                  "" && <>{" Vorname fehlt "}</>}
                                {protocolToShow?.owner?.owner_first_name != "" &&
                                  (protocolToShow?.owner?.owner_last_name == "" ||
                                    protocolToShow?.owner?.owner_last_name ==
                                    "") && <>{" Nachname fehlt "}</>}
                                {protocolToShow?.owner?.owner_first_name != "" &&
                                  protocolToShow?.owner?.owner_last_name !=
                                  "" && (
                                    <>
                                      {protocolToShow?.owner?.owner_first_name}{" "}
                                      {protocolToShow?.owner?.owner_last_name}
                                    </>
                                  )}
                              </span>
                              <br />
                              <span>
                                {protocolToShow?.owner?.address?.owner_street
                                  ? protocolToShow?.owner?.address?.owner_street
                                  : "Straße fehlt"}
                                ,{" "}
                                {protocolToShow?.owner?.address
                                  ?.owner_house_number
                                  ? protocolToShow?.owner?.address
                                    ?.owner_house_number
                                  : "Hausnummer fehlt"}
                              </span>
                              <br />
                              <span>
                                {protocolToShow?.owner?.address?.owner_plz
                                  ? protocolToShow?.owner?.address?.owner_plz
                                  : "PLZ fehlt"}
                                ,{" "}
                                {protocolToShow?.owner?.address?.owner_city
                                  ? protocolToShow?.owner?.address?.owner_city
                                  : "Ort fehlt"}
                              </span>
                            </div>
                            <div style={{ width: "50%" }}>
                              {protocolToShow?.owner?.owner_Type == null && (
                                <span>Angabe fehlt</span>
                              )}
                              {protocolToShow?.owner?.owner_Type ==
                                "noChoice" && (
                                  <span style={{ color: "#000000" }}>
                                    Keine Angabe
                                  </span>
                                )}
                              {protocolToShow?.owner?.owner_Type == "company" && (
                                <span style={{ color: "#000000" }}>
                                  Gesellschaft
                                </span>
                              )}
                              {protocolToShow?.owner?.owner_Type == "private" && (
                                <span style={{ color: "#000000" }}>Privat</span>
                              )}
                            </div>
                          </div>
                          <div className="LigneAdresse-RenviernungsdatumOvwerview">
                            <span className="uppercase" style={{ fontSize: "16px" }}>E-mail:</span>
                          </div>
                          <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                            <div style={{ width: "50%" }}>
                              <span>
                                {" "}
                                {protocolToShow?.owner?.owner_email
                                  ? protocolToShow?.owner?.owner_email
                                  : "Angabe fehlt"}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <hr className="hrOvwerview" />
                  </div>

                  <div className="aftercardOvwerview">
                    <div className="formAbschlussOvwerview">
                      <div className="LigneWohnungsdatenOvwerview">
                        <span className="uppercase">Mieter</span>
                      </div>
                      <div className="LigneAdresse-RenviernungsdatumOvwerview mb-2">
                        <span style={{ fontWeight: "bold", color: "rgba(0, 0, 0, 1)" }}>

                          {protocolToShow?.renter?.renter_salutation == null &&
                            "Anrede fehlt "}
                          {protocolToShow?.renter?.renter_salutation ==
                            "noChoice" && "Keine Angabe "}
                          {protocolToShow?.renter?.renter_salutation == "male" &&
                            "Herr "}
                          {protocolToShow?.renter?.renter_salutation ==
                            "female" && "Frau "}
                          {formatTitle(protocolToShow?.renter?.renter_title)}

                          {protocolToShow?.renter?.renter_first_name == null &&
                            protocolToShow?.renter?.renter_last_name == null && (
                              <span>Name und Vorname fehlt</span>
                            )}
                          {(protocolToShow?.renter?.renter_first_name == null ||
                            protocolToShow?.renter?.renter_first_name == "") &&
                            protocolToShow?.renter?.renter_last_name != null && (
                              <>{" Vorname fehlt "}</>
                            )}
                          {protocolToShow?.renter?.renter_first_name != null &&
                            (protocolToShow?.renter?.renter_last_name == null ||
                              protocolToShow?.renter?.renter_last_name == "") && (
                              <>{" Nachname fehlt "}</>
                            )}
                          {protocolToShow?.renter?.renter_first_name != null &&
                            protocolToShow?.renter?.renter_last_name != null && (
                              <>
                                {protocolToShow?.renter?.renter_first_name}{" "}
                                {protocolToShow?.renter?.renter_last_name}
                              </>
                            )}
                        </span>
                      </div>

                      <div className="LigneAdresse-RenviernungsdatumOvwerview">
                        <span className="uppercase" style={{ fontSize: "16px" }}>E-mail:</span>
                      </div>
                      <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                        <div style={{ width: "50%" }}>
                          <span>
                            {protocolToShow?.renter?.renter_email
                              ? protocolToShow?.renter?.renter_email
                              : "Angabe fehlt"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <hr className="hrOvwerview" />
                  </div>
                  {protocolToShow.has_corenter && (
                    <>
                      <div className="aftercardOvwerview">
                        <div className="formAbschlussOvwerview">
                          <div className="LigneWohnungsdatenOvwerview">
                            <span className="uppercase">Weitere Mieter</span>
                          </div>
                          <div className="LigneAdresse-RenviernungsdatumOvwerview mb-2">
                            <span style={{ fontWeight: "bold", color: "rgba(0, 0, 0, 1)" }}>
                              {protocolToShow?.coRenter?.salutaion == null &&
                                "Anrede fehlt "}
                              {protocolToShow?.coRenter?.salutaion ==
                                "noChoice" && "Keine Angabe "}
                              {protocolToShow?.coRenter?.salutaion == "male" &&
                                "Herr "}
                              {protocolToShow?.coRenter?.salutaion == "female" &&
                                "Frau "}
                              {formatTitle(protocolToShow?.coRenter?.title)}

                              {(protocolToShow?.coRenter?.first_name == null ||
                                protocolToShow?.coRenter?.first_name == "") &&
                                protocolToShow?.coRenter?.last_name != null && (
                                  <>{" Vorname fehlt "}</>
                                )}
                              {protocolToShow?.coRenter?.first_name != null &&
                                (protocolToShow?.coRenter?.last_name == null ||
                                  protocolToShow?.coRenter?.last_name == "") && (
                                  <>{" Nachname fehlt "}</>
                                )}
                              {protocolToShow?.coRenter?.first_name != null &&
                                protocolToShow?.coRenter?.last_name != null && (
                                  <>
                                    {protocolToShow?.coRenter?.first_name}{" "}
                                    {protocolToShow?.coRenter?.last_name}
                                  </>
                                )}
                            </span>
                          </div>
                        </div>
                        <hr className="hrOvwerview" />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>}
          {/* section description */}
          {protocolToShow?.protocol_type != "vacancy_inspection" && <div className="SonCard mt-3">
            <div className="card-shadow">
              <div className="wohnungsdaten withoutBorderRadius">
                <div className="first_wohnungsdaten withoutBorderRadius padding-left-right col-md-12">
                  <div className="row padding-center">
                    <img
                      className="WohnungsdatenIcon"
                      src={ViewContractIcon}
                      alt="eigentumer Icon"
                    />
                    <h3 className="uppercase">Abschlussbemerkung</h3>
                  </div>
                </div>
              </div>

              <div className="after-cards pt-0 pb-0">
                <div className="formAbschlussOvwerview " style={{padding: " 20px 50px"}}>
                  <div className="LigneSchlüssel Adresse-RenviernungsdatumOvwerview row w-full">
                    <div className="col-md-6 mt-1">
                      <span style={{ fontWeight: "bold", color: "rgb(95, 95, 95)" }} className="uppercase">
                        Bemerkung :
                      </span>
                      <div className="text-style">
                        <span>
                          {protocolToShow?.genereal_notice
                            ? protocolToShow?.genereal_notice
                            : "Angabe fehlt"}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 mt-1">
                      <span style={{ fontWeight: "bold", color: "rgb(95, 95, 95)" }} className="uppercase">
                        Interne bemerkungen :
                      </span>
                      <div className="text-style">
                        <span>
                          {protocolToShow?.internal_notice
                            ? protocolToShow?.internal_notice
                            : "Angabe fehlt"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>}
          {/* section signature of old protocol type */}
          {protocolToShow?.protocol_type != "vacancy_inspection" && <div className="SonCard mt-3 mb-3">
            <div className="card-shadow">
              <div
                className="neuer withoutBorderRadius"
                style={{
                  background:
                    "linear-gradient(95deg, rgb(98, 171, 18) 0%, rgb(98, 171, 18) 50%, rgb(151, 193, 88) 100%)",
                }}
              >
                <img
                  className="NeuerIcon"
                  src={ContractIcon}
                  alt="Abschlussbemerkung Icon"
                />
                <h3 className="uppercase"> Unterschriften </h3>
              </div>
              <div className="VerteilerContainer" style={{padding:"0 50px"}}>
                <div className="formAbschlussOvwerview">
                  <div className="sub-formverteilerOvwerview">
                    <div className="first-part-formverteilerOvwerview">
                      <div className="LigneWohnungsdatenOvwerview">

                        <span className="uppercase">Protokollant</span>
                      </div>
                      <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                        <div style={{ width: "100%" }}>
                          <span style={{ fontSize: "700" }}>
                            {protocolToShow?.note_taker?.note_taker_gender ==
                              null && "Anrede fehlt "}
                            {protocolToShow?.note_taker?.note_taker_gender ==
                              "noChoice" && "Keine Angabe "}
                            {protocolToShow?.note_taker?.note_taker_gender ==
                              "male" && "Herr "}
                            {protocolToShow?.note_taker?.note_taker_gender ==
                              "female" && "Frau "}
                          </span>
                          <br />
                          <span style={{ fontSize: "700" }}>
                            {protocolToShow?.note_taker?.note_taker_first_name ==
                              null &&
                              protocolToShow?.note_taker?.note_taker_last_name ==
                              null && <span>Name und Vorname fehlt</span>}
                            {(protocolToShow?.note_taker?.note_taker_first_name ==
                              null ||
                              protocolToShow?.note_taker?.note_taker_first_name ==
                              "") &&
                              protocolToShow?.note_taker?.note_taker_last_name !=
                              null && (
                                <>
                                  {protocolToShow?.note_taker?.note_taker_first_name
                                    ? protocolToShow?.note_taker?.note_taker_first_name
                                    : " Vorname fehlt "}
                                </>
                              )}
                            {protocolToShow?.note_taker?.note_taker_first_name !=
                              null &&
                              (protocolToShow?.note_taker?.note_taker_last_name ==
                                null ||
                                protocolToShow?.note_taker?.note_taker_last_name ==
                                "") && (
                                <>
                                  {protocolToShow?.note_taker?.note_taker_last_name
                                    ? protocolToShow?.note_taker?.note_taker_last_name
                                    : " Nachname fehlt "}
                                </>
                              )}
                            {protocolToShow?.note_taker?.note_taker_first_name !=
                              null &&
                              protocolToShow?.note_taker?.note_taker_last_name !=
                              null && (
                                <>
                                  {protocolToShow?.note_taker?.note_taker_first_name}{" "}
                                  {protocolToShow?.note_taker?.note_taker_last_name}
                                </>
                              )}
                          </span>
                        </div>
                      </div>
                    </div>
                    {protocolToShow?.landlord?.signature_landlord
                      ?.signature_landlord === true ? (
                      <>
                        <div className="second-part-formverteilerOvwerview" style={{border :" 1px solid rgb(169, 169, 169)" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={
                                protocolToShow?.landlord?.signature_landlord
                                  ?.signature_landlord_image_remote_url
                              }
                              alt={
                                protocolToShow?.landlord?.signature_landlord
                                  ?.signature_landlord_image_name
                              }
                              height="125px"
                              width="125px"
                            />
                            <span style={{ fontSize: "700", color: "#000000" }}>
                              {protocolToShow?.landlord?.signature_landlord
                                ?.signature_landlord_time_stamp !== null
                                ? moment
                                  .unix(
                                    protocolToShow?.landlord?.signature_landlord
                                      ?.signature_landlord_time_stamp?.seconds
                                  )
                                  .add(
                                    protocolToShow?.landlord?.signature_landlord
                                      ?.signature_landlord_time_stamp
                                      ?.nanoseconds / 1000000
                                  )
                                  .format("DD/MM/YYYY, HH:mm")
                                : "Kein Angabe"}
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="second-part-formverteilerOvwerview">
                        <PhotoLibraryIcon
                          className="defaultImageIcon"
                          sx={{ width: 425, height: 200 }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <hr className="hrOvwerview" />
              {/* 1 Mieter signature section */}
              <div className="VerteilerContainer" style={{padding:"0 50px"}}>
                <div className="formAbschlussOvwerview">
                  <div className="sub-formverteilerOvwerview">
                    <div className="first-part-formverteilerOvwerview">
                      <div className="LigneWohnungsdatenOvwerview">
                        {protocolToShow?.coRenter?.signature_coRenter?.signature ?
                          <span className="uppercase">1.Mieter</span>
                          :
                          <span className="uppercase">Mieter</span>
                        }
                      </div>
                      <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                        <div style={{ width: "100%" }}>
                          <span style={{ fontSize: "700" }}>
                            {protocolToShow?.renter?.renter_salutation == null &&
                              "Anrede fehlt "}
                            {protocolToShow?.renter?.renter_salutation ==
                              "noChoice" && "Keine Angabe "}
                            {protocolToShow?.renter?.renter_salutation ==
                              "male" && "Herr "}
                            {protocolToShow?.renter?.renter_salutation ==
                              "female" && "Frau "}
                          </span>
                          <br />
                          <span style={{ fontSize: "700" }}>
                            {protocolToShow?.renter?.renter_first_name == null &&
                              protocolToShow?.renter?.renter_last_name ==
                              null && <span>Name und Vorname fehlt</span>}
                            {(protocolToShow?.renter?.renter_first_name == null ||
                              protocolToShow?.renter?.renter_first_name == "") &&
                              protocolToShow?.renter?.renter_last_name !=
                              null && (
                                <>
                                  {protocolToShow?.renter?.renter_first_name
                                    ? protocolToShow?.renter?.renter_first_name
                                    : " Vorname fehlt "}
                                </>
                              )}
                            {protocolToShow?.renter?.renter_first_name != null &&
                              (protocolToShow?.renter?.renter_last_name == null ||
                                protocolToShow?.renter?.renter_last_name ==
                                "") && (
                                <>
                                  {protocolToShow?.renter?.renter_last_name
                                    ? protocolToShow?.renter?.renter_last_name
                                    : " Nachname fehlt "}
                                </>
                              )}
                            {protocolToShow?.renter?.renter_first_name != null &&
                              protocolToShow?.renter?.renter_last_name !=
                              null && (
                                <>
                                  {protocolToShow?.renter?.renter_first_name}{" "}
                                  {protocolToShow?.renter?.renter_last_name}
                                </>
                              )}
                          </span>
                        </div>
                      </div>
                    </div>
                    {protocolToShow?.renter?.signature_renter
                      ?.signature_renter === true ? (
                      <>
                        <div className="second-part-formverteilerOvwerview" style={{border :" 1px solid rgb(169, 169, 169)" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={
                                protocolToShow?.renter?.signature_renter
                                  ?.signature_renter_image_remote_url
                              }
                              alt={
                                protocolToShow?.renter?.signature_renter
                                  ?.signature_renter_image_name
                              }
                              height="125px"
                              width="125px"
                            />
                            <span style={{ fontSize: "700", color: "#000000" }}>
                              {protocolToShow?.renter?.signature_renter
                                ?.signature_renter_time_stamp !== null
                                ? moment
                                  .unix(
                                    protocolToShow?.renter?.signature_renter
                                      ?.signature_renter_time_stamp?.seconds
                                  )
                                  .add(
                                    protocolToShow?.renter?.signature_renter
                                      ?.signature_renter_time_stamp
                                      ?.nanoseconds / 1000000
                                  )
                                  .format("DD/MM/YYYY, HH:mm")
                                : "Kein Angabe"}
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      protocolToShow?.renter?.signature_renter
                        ?.signature_renter === false && (
                        <div className="second-part-formverteilerOvwerview" style={{border :" 1px solid rgb(169, 169, 169)" }}>
                          {protocolToShow?.renter?.signature_renter
                            ?.signature_renter_refuse_reason ? (
                            <span
                              className="bad-status"
                              style={{ marginLeft: "8rem" }}
                            >
                              <img
                                className="notvalidateSclusselOvwerviewIcon"
                                src={NotValidIcon}
                                alt="notvalidateSclusselOvwerview Icon"
                                style={{
                                  width: "1rem",
                                  marginRight: "0.25rem",
                                }}
                              />
                              {formatReason(
                                protocolToShow?.renter?.signature_renter
                                  ?.signature_renter_refuse_reason
                              )}
                            </span>
                          ) : (
                            <span
                              className="bad-status"
                              style={{ marginLeft: "8rem" }}
                            >
                              <img
                                className="notvalidateSclusselOvwerviewIcon"
                                src={NotValidIcon}
                                alt="notvalidateSclusselOvwerview Icon"
                                style={{
                                  width: "1rem",
                                  marginRight: "0.25rem",
                                }}
                              />
                              Keine Unterschrift
                            </span>
                          )}
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <hr className="hrOvwerview" />
              {/* 2 Mieter signature section */}
              {protocolToShow?.coRenter?.signature_coRenter?.signature ===
                false &&
                protocolToShow?.coRenter?.signature_coRenter?.refuse_signature ==
                false ? null : (
                  <>
                <div className="VerteilerContainer" style={{padding:"0 50px"}}>
                  <div className="formAbschlussOvwerview">
                    <div className="sub-formverteilerOvwerview">
                      <div className="first-part-formverteilerOvwerview">
                        <div className="LigneWohnungsdatenOvwerview">
                          <span className="uppercase">2.Mieter</span>
                        </div>
                        <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                          <div style={{ width: "100%" }}>
                            <span style={{ fontSize: "700" }}>
                              {protocolToShow?.coRenter?.salutaion == null &&
                                "Anrede fehlt "}
                              {protocolToShow?.coRenter?.salutaion ==
                                "noChoice" && "Keine Angabe "}
                              {protocolToShow?.coRenter?.salutaion == "male" &&
                                "Herr "}
                              {protocolToShow?.coRenter?.salutaion == "female" &&
                                "Frau "}
                            </span>
                            <br />
                            <span style={{ fontSize: "700" }}>
                              {protocolToShow?.coRenter?.first_name == null &&
                                protocolToShow?.coRenter?.last_name == null && (
                                  <span>Name und Vorname fehlt</span>
                                )}
                              {(protocolToShow?.coRenter?.first_name == null ||
                                protocolToShow?.coRenter?.first_name == "") &&
                                protocolToShow?.coRenter?.last_name != null && (
                                  <>
                                    {protocolToShow?.coRenter?.first_name
                                      ? protocolToShow?.coRenter?.first_name
                                      : " Vorname fehlt "}
                                  </>
                                )}
                              {protocolToShow?.coRenter?.first_name != null &&
                                (protocolToShow?.coRenter?.last_name == null ||
                                  protocolToShow?.coRenter?.last_name == "") && (
                                  <>
                                    {protocolToShow?.coRenter?.last_name
                                      ? protocolToShow?.coRenter?.last_name
                                      : " Nachname fehlt "}
                                  </>
                                )}
                              {protocolToShow?.coRenter?.first_name != null &&
                                protocolToShow?.coRenter?.last_name != null && (
                                  <>
                                    {protocolToShow?.coRenter?.first_name}{" "}
                                    {protocolToShow?.coRenter?.last_name}
                                  </>
                                )}
                            </span>
                          </div>
                        </div>
                      </div>
                      {protocolToShow?.coRenter?.signature_coRenter && (
                        <>
                          <div className="second-part-formverteilerOvwerview" style={{border :" 1px solid rgb(169, 169, 169)" }}>
                            {protocolToShow?.coRenter?.signature_coRenter
                              ?.refuse_reason ? (
                              <span
                                className="bad-status"
                                style={{ marginLeft: "8rem" }}
                              >
                                <img
                                  className="notvalidateSclusselOvwerviewIcon"
                                  src={NotValidIcon}
                                  alt="notvalidateSclusselOvwerview Icon"
                                  style={{
                                    width: "1rem",
                                    marginRight: "0.25rem",
                                  }}
                                />
                                {formatReason(
                                  protocolToShow?.coRenter?.signature_coRenter
                                    ?.refuse_reason
                                )}
                              </span>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={
                                    protocolToShow?.coRenter?.signature_coRenter
                                      ?.remote_url
                                  }
                                  alt={
                                    protocolToShow?.coRenter?.signature_coRenter
                                      ?.image_name
                                  }
                                  height="125px"
                                  width="125px"
                                />
                                <span
                                  style={{ fontSize: "700", color: "#000000" }}
                                >
                                  {protocolToShow?.coRenter?.signature_coRenter
                                    ?.time_stamp !== null
                                    ? moment
                                      .unix(
                                        protocolToShow?.coRenter
                                          ?.signature_coRenter?.time_stamp
                                          ?.seconds
                                      )
                                      .add(
                                        protocolToShow?.coRenter
                                          ?.signature_coRenter?.time_stamp
                                          ?.nanoseconds / 1000000
                                      )
                                      .format("DD/MM/YYYY, HH:mm")
                                    : "Kein Angabe"}
                                </span>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <hr className="hrOvwerview" />
                </>
              )}

              {protocolToShow?.protocol_type == "moveInAndExit" ? (
                <>
                <div className="VerteilerContainer" style={{padding:"0 50px"}}>
                  <div className="formAbschlussOvwerview">
                    <div className="sub-formverteilerOvwerview">
                      <div className="first-part-formverteilerOvwerview">
                        <div className="LigneWohnungsdatenOvwerview">
                          <span className="uppercase">Vormieter</span>
                        </div>
                        <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                          <div style={{ width: "100%" }}>
                            <span style={{ fontSize: "700" }}>
                              {protocolToShow?.previous_renter
                                ?.previous_renter_salutation == null &&
                                "Anrede fehlt "}
                              {protocolToShow?.previous_renter
                                ?.previous_renter_salutation == "noChoice" &&
                                "Keine Angabe "}
                              {protocolToShow?.previous_renter
                                ?.previous_renter_salutation == "male" && "Herr "}
                              {protocolToShow?.previous_renter
                                ?.previous_renter_salutation == "female" &&
                                "Frau "}
                            </span>
                            <br />
                            <span style={{ fontSize: "700" }}>
                              {protocolToShow?.previous_renter
                                ?.previous_renter_first_name
                                ? protocolToShow?.previous_renter
                                  ?.previous_renter_first_name + " "
                                : "Vorname fehlt"}
                              {protocolToShow?.previous_renter
                                ?.previous_renter_last_name
                                ? protocolToShow?.previous_renter
                                  ?.previous_renter_last_name
                                : "Nachname fehlt"}
                            </span>
                          </div>
                        </div>
                      </div>
                      {protocolToShow?.previous_renter?.signature_previous_renter
                        ?.signature_previous_renter === true ? (
                        <>
                          <div className="second-part-formverteilerOvwerview" style={{border :" 1px solid rgb(169, 169, 169)" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={
                                  protocolToShow?.previous_renter
                                    ?.signature_previous_renter
                                    ?.signature_previous_renter_image_remote_url
                                }
                                alt={
                                  protocolToShow?.previous_renter
                                    ?.signature_previous_renter
                                    ?.signature_previous_renter_image_name
                                }
                                height="125px"
                                width="125px"
                              />
                              <span style={{ fontSize: "700", color: "#000000" }}>
                                {protocolToShow?.previous_renter
                                  ?.signature_previous_renter
                                  ?.signature_previous_renter_time_stamp !== null
                                  ? moment
                                    .unix(
                                      protocolToShow?.previous_renter
                                        ?.signature_previous_renter
                                        ?.signature_previous_renter_time_stamp
                                        ?.seconds
                                    )
                                    .add(
                                      protocolToShow?.previous_renter
                                        ?.signature_previous_renter
                                        ?.signature_previous_renter_time_stamp
                                        ?.nanoseconds / 1000000
                                    )
                                    .format("DD/MM/YYYY, HH:mm")
                                  : "Kein Angabe"}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        protocolToShow?.previous_renter?.signature_previous_renter
                          ?.signature_previous_renter === false && (
                          <div className="second-part-formverteilerOvwerview" style={{border :" 1px solid rgb(169, 169, 169)" }}>
                            {protocolToShow?.previous_renter
                              ?.signature_previous_renter
                              ?.signature_previous_renter_refuse_reason ? (
                              <span
                                className="bad-status"
                                style={{ marginLeft: "8rem" }}
                              >
                                <img
                                  className="notvalidateSclusselOvwerviewIcon"
                                  src={NotValidIcon}
                                  alt="notvalidateSclusselOvwerview Icon"
                                  style={{
                                    width: "1rem",
                                    marginRight: "0.25rem",
                                  }}
                                />
                                {formatReason(
                                  protocolToShow?.previous_renter
                                    ?.signature_previous_renter
                                    ?.signature_previous_renter_refuse_reason
                                )}
                              </span>
                            ) : (
                              <span
                                className="bad-status"
                                style={{ marginLeft: "8rem" }}
                              >
                                {/* <CloseIcon sx={{ fontSize: 33 }} /> */}
                                <img
                                  className="notvalidateSclusselOvwerviewIcon"
                                  src={NotValidIcon}
                                  alt="notvalidateSclusselOvwerview Icon"
                                  style={{
                                    width: "1rem",
                                    marginRight: "0.25rem",
                                  }}
                                />
                                Keine Unterschrift
                              </span>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <hr className="hrOvwerview" />
                </>
              ) : null}
              {/* Zeuge signature section */}
              {protocolToShow?.witness_signature?.signature_witness === false &&
                protocolToShow?.witness_signature?.witness_refuse_signature ==
                false ? null : (
                  <>
                <div className="VerteilerContainer" style={{padding:"0 50px"}}>
                  <div className="formAbschlussOvwerview">
                    <div className="sub-formverteilerOvwerview">
                      <div className="first-part-formverteilerOvwerview">
                        <div className="LigneWohnungsdatenOvwerview">
                          <span className="uppercase">Zeuge</span>
                        </div>
                        <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                          <div style={{ width: "100%" }}>
                            <span style={{ fontSize: "700" }}>
                              {protocolToShow?.witness_signature
                                ?.signature_witness_name !== null
                                ? protocolToShow?.witness_signature
                                  ?.signature_witness_name
                                : protocolToShow?.witness_signature
                                  ?.signature_witness_name === null && (
                                  <span>Name fehlt</span>
                                )}
                            </span>
                          </div>
                        </div>
                      </div>
                      {protocolToShow?.witness_signature?.signature_witness &&
                        (protocolToShow?.witness_signature
                          ?.signature_witness_refuse_reason ? (
                          <span
                            className="bad-status"
                            style={{ marginLeft: "8rem" }}
                          >
                            <img
                              className="notvalidateSclusselOvwerviewIcon"
                              src={NotValidIcon}
                              alt="notvalidateSclusselOvwerview Icon"
                              style={{
                                width: "1rem",
                                marginRight: "0.25rem",
                              }}
                            />
                            {formatReason(
                              protocolToShow?.witness_signature
                                ?.signature_witness_refuse_reason
                            )}
                          </span>
                        ) : (
                          <>
                            <div className="second-part-formverteilerOvwerview">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={
                                    protocolToShow?.witness_signature
                                      ?.signature_witness_image_remote_url
                                  }
                                  alt={
                                    protocolToShow?.witness_signature
                                      ?.signature_witness_image_name
                                  }
                                  height="125px"
                                  width="125px"
                                />
                                <span
                                  style={{ fontSize: "700", color: "#000000" }}
                                >
                                  {protocolToShow?.witness_signature
                                    ?.signature_witness_time_stamp !== null
                                    ? moment
                                      .unix(
                                        protocolToShow?.witness_signature
                                          ?.signature_witness_time_stamp
                                          ?.seconds
                                      )
                                      .add(
                                        protocolToShow?.witness_signature
                                          ?.signature_witness_time_stamp
                                          ?.nanoseconds / 1000000
                                      )
                                      .format("DD/MM/YYYY, HH:mm")
                                    : "Keine Angabe"}
                                </span>
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                  </div>
                </div>
                <hr className="hrOvwerview" />
                </>
              )}
            </div>
            {protocol_pattern &&
              protocol_pattern.length > 0 &&
              protocol_pattern.find(
                (key) => key.key_section_name === "signatures"
              ) &&
              handleDisplayPatternSection("signatures")}
          </div>}
          {/* section signature of protocol inspection vacancy */}
          {protocolToShow?.protocol_type == "vacancy_inspection" && <div className="SonCard document-measurement">
                <div className="neuer  withoutBorderRadius" >
                  <img
                    className="NeuerIcon"
                    src={ContractIcon}
                    alt="Abschlussbemerkung Icon"
                  />
                  <h3 style={{ textTransform: "none" }}> Unterschriften </h3>
                </div>
                <div className="card-shadow">
                  <div className={`VerteilerContainer ${protocolToShow?.landlord?.signature_landlord
                          ?.signature_landlord != true && "Missing"}`}>
                    <div className="formAbschlussOvwerview Padding-NewForm-signature ">
                      <div className="sub-formverteilerOvwerview">
                        <div className="first-part-formverteilerOvwerview signature-Block">
                          <div className="LigneWohnungsdatenOvwerview">
                            <span>Protokollant</span>
                          </div>
                          <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                            <div className="Title-of-signature" style={{ width: "100%" }}>
                              <span style={{ fontSize: "700" }}>
                              {protocolToShow?.note_taker?.note_taker_gender ==
                              null && "Anrede fehlt "}
                            {protocolToShow?.note_taker?.note_taker_gender ==
                              "noChoice" && "Keine Angabe "}
                            {protocolToShow?.note_taker?.note_taker_gender ==
                              "male" && "Herr "}
                            {protocolToShow?.note_taker?.note_taker_gender ==
                              "female" && "Frau "}
                              </span>
                              <br />
                              <span style={{ fontSize: "700" }}>
                            {protocolToShow?.note_taker?.note_taker_first_name ==
                              null &&
                              protocolToShow?.note_taker?.note_taker_last_name ==
                              null && <span>Name und Vorname fehlt</span>}
                            {(protocolToShow?.note_taker?.note_taker_first_name ==
                              null ||
                              protocolToShow?.note_taker?.note_taker_first_name ==
                              "") &&
                              protocolToShow?.note_taker?.note_taker_last_name !=
                              null && (
                                <>
                                  {protocolToShow?.note_taker?.note_taker_first_name
                                    ? protocolToShow?.note_taker?.note_taker_first_name
                                    : " Vorname fehlt "}
                                </>
                              )}
                            {protocolToShow?.note_taker?.note_taker_first_name !=
                              null &&
                              (protocolToShow?.note_taker?.note_taker_last_name ==
                                null ||
                                protocolToShow?.note_taker?.note_taker_last_name ==
                                "") && (
                                <>
                                  {protocolToShow?.note_taker?.note_taker_last_name
                                    ? protocolToShow?.note_taker?.note_taker_last_name
                                    : " Nachname fehlt "}
                                </>
                              )}
                            {protocolToShow?.note_taker?.note_taker_first_name !=
                              null &&
                              protocolToShow?.note_taker?.note_taker_last_name !=
                              null && (
                                <>
                                  {protocolToShow?.note_taker?.note_taker_first_name}{" "}
                                  {protocolToShow?.note_taker?.note_taker_last_name}
                                </>
                              )}
                          </span>
                            </div>
                          </div>
                        </div>
                        {protocolToShow?.landlord?.signature_landlord
                          ?.signature_landlord === true ? (
                          <>
                            <div className="second-part-formverteilerOvwerview signature-Block" >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  border:"1px solid rgb(169, 169, 169)",
                                  padding:"20px 10px"
                                }}
                              >
                                <img
                                  src={
                                    protocolToShow?.landlord?.signature_landlord
                                      ?.signature_landlord_image_remote_url
                                  }
                                  alt={
                                    protocolToShow?.landlord?.signature_landlord
                                      ?.signature_landlord_image_name
                                  }
                                  height="125px"
                                  width="125px"
                                />
                              </div>
                              <div className=" text-align-end">
                              <span
                                  style={{ fontSize: "700", color: "#000000" }}
                                >
                                  {protocolToShow?.landlord?.signature_landlord
                                    ?.signature_landlord_time_stamp !== null
                                    ? moment
                                        .unix(
                                          protocolToShow?.landlord
                                            ?.signature_landlord
                                            ?.signature_landlord_time_stamp
                                            ?.seconds
                                        )
                                        .add(
                                          protocolToShow?.landlord
                                            ?.signature_landlord
                                            ?.signature_landlord_time_stamp
                                            ?.nanoseconds / 1000000
                                        )
                                        .format("[unterzeichnet am] DD.MM.YYYY, [um] HH:mm [Uhr]")
                                    : "Kein Angabe"}
                                </span>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="second-part-formverteilerOvwerview signature-Block">
                            <PhotoLibraryIcon
                              className="defaultImageIcon"
                              sx={{ width: 425, height: 200 }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
          </div>}
        </div>
      </div>
    </>
  );
}

export default ProtokollOvwerView;
