import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
import SidebarContent from './Components/SidebarContent';
import { useHistory } from "react-router-dom";
import { companyService, userService } from '_services';
import logoXciteBurger from "../../assets/Icons/black-logo.svg";
import { useAuth } from "../../context/AuthContext";
const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		backgroundColor: '#272e3d',
		borderRight: '0',
		overflowY: 'hidden'
	},
	drawerHeader: {
		display: 'flex',
		borderBottom: '1px solid rgba(255, 255, 255, 0.15);',
		alignItems: 'center',
		padding: theme.spacing(1, 2),
		...theme.mixins.toolbar,
		justifyContent: 'flex-start',
	},
	dFlex: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	smallBtn: {
		padding: '2px 5px',
		fontSize: '0.8rem',
	}
}));

export default function Sidebar(props) {
	const history = useHistory();
	const classes = useStyles();
	const { closeSidebar } = props;
	const [company, setCompany] = useState({});
	const [user, setUser] = useState({});
	const { currentUser, logout } = useAuth();
	// get Company data
	useEffect(() => {
		const fetchinfouser_company = async () => {
		  const userDoc = await userService.getUser(userService.getId());
		  userDoc.forEach(async (doc) => {
			setUser(doc.data());
			if (doc.data().company_id) {
			  let company = await companyService.getCompany(doc.data().company_id);
			  if (company) {
				setCompany(company);
			  }
			}
		  });
		};
		fetchinfouser_company();
	  }, [currentUser]);



	return (
		<div>
			<div className="sidebar-wrap ">
				<div className={classes.drawerHeader} style={{ width: "100%", borderBottom:"none" }}>
					<Box className="site-logo" display="flex" alignItems="center" justifyContent='center' flexDirection='column' width={"100%"}>
					<label className="custom-file-upload fas">
					<div className="img-wrap">
                           <img
                               alt="photo-user"
                               src={
                                  company?.company_logo !== null && company?.company_logo !== ""
                                 ? company?.company_logo
                                 : logoXciteBurger
                                  }
                            />
                    </div>
					</label>
					<div className="userNameBurgerBar">
                           {currentUser
                            ? user?.first_name + " " + user?.last_name
                            : "User name"}
                        </div>
					</Box>
				</div>
				<Scrollbars
					className="hulk-scroll"
					autoHide
					autoHideDuration={160}
				>
					<SidebarContent closeSidebar={closeSidebar}></SidebarContent>
				</Scrollbars>

			</div>
		</div>
	);
}