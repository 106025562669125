/**
 * Courses Routing File
 */

import Store from 'Stored';
import React from 'react'
import { FirebaseContextProvider } from "../../context/firebaseContext";
import { Route, Switch } from 'react-router-dom'
import ProtectedRoute from '../ProtectedRoutes.js'
import {
	AsyncExportRealEstateComponent,
	AsyncImportRealEstateComponent,
	AsyncPropertiesComponent,
	AsyncCreatePropertyComponent,
	AsyncCreateAppartementComponent,
	AsynclistAppartementComponent,
	AsyncDashboardComponent
} from 'routes';

const Properties = ({ match }) => (
	<Switch>
		<Store>
			
			     <Route path={`${match.url}/all`} component={AsyncPropertiesComponent}></Route>
				<Route path={`${match.url}/import`} component={AsyncImportRealEstateComponent} route={"realestate_import"} ></Route>
				<Route path={`${match.url}/export`} component={AsyncExportRealEstateComponent} route={"realestate_export"}></Route>
				<Route path={`${match.url}/createProperty`} component={AsyncCreatePropertyComponent}></Route>
				<Route path={`${match.url}/updateRealestate/:id`} component={AsyncCreatePropertyComponent}></Route>
				<Route path={`${match.url}/createApartment/:id`} component={AsyncCreateAppartementComponent}></Route>
				<Route path={`${match.url}/updateApartment/:id`} component={AsyncCreateAppartementComponent}></Route>
				 
				<Route path={`${match.url}/appartements/:id`} component={AsynclistAppartementComponent}></Route>
				<Route path={`${match.url}/realestate_anzeigen`} component={AsyncDashboardComponent}></Route>
				
			
			
		</Store>

	</Switch>
)
export default Properties;