/**
 * Nav List Item
 */
import React, { useState, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/styles";
import warningIcon from "../../../assets/Icons/warningIcon.png";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { DateFormatter } from "../../../helpers/DateFormatter";

import clsx from "clsx";
import {
  List,
  ListItem,
  Collapse,
  Icon,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
} from "@material-ui/core";
import { serverTimestamp, setDoc, doc } from "firebase/firestore";
import Moment from "moment";
import swal from "sweetalert";
import { v4 as uuidv4 } from "uuid";
import { db } from "../../../config/firebase";
import { companyService, userService } from "_services";
import { protocolService } from "_services/protocol.service";
import { contractService } from "_services/contract.service";
import { kautionService } from "_services/kaution.service";
import { useAuth } from "context/AuthContext";
import { useHistory } from "react-router-dom";
import { BorderLeft } from "@mui/icons-material";
import { realEstateService } from "_services/real_estate.service";
import PremiumGold from "../../../assets/Icons/premiumIcon.svg";
//import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";

const styles = (theme) => ({
  textWhite: {
    color: `${theme.palette.common.white} !important`,
    "& span ,& a": {
      color: theme.palette.common.white,
      fontSize: "1.04rem ",
    },
  },
  ListItem: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    margin: " 2px 0",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.36)",
    },
  },
  desc: {
    color: "#969fa4",
    "& span": {
      fontSize: "0.75rem",
      color: "#969fa4",
    },
  },
  iconWrap: {
    fontFamily: "Material Icons Outlined",
    fontSize: "20px !important",
    display: "flex",
    alignItems: "center",
  },
  font9: {
    fontFamily: "Material Icons Outlined",
    fontSize: "9px !important",
  },
  truncate: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  SubmenuOpen: {
    backgroundColor: "rgba(0, 0, 0, 0.2) !important",
    "&.clicked": {
      backgroundColor: "rgba(0, 0, 0, 0.3) !important",
    },
    "& .sub-menu": {
      listStyle: "none",
      padding: "0px !important",

      "& li.MuiListItem-root": {
        padding: "0px !important",
        listStyle: "none",
        "& a": {
          padding: "5px 0px 5px 30px !important",
        },
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.15)",
        },
      },
    },
  },
  menuOpen: {
    marginBottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    borderBottom: "0 !important",

    "& .sub-menu": {
      listStyle: "none",
      "& div.MuiListItem-root": {
        marginTop: 0,
        marginBottom: 0,
        borderRadius: 0,
        padding: "0px 10px 0px 10px",
        "& div.child-list ": {
          borderLeft: "1px solid rgba(255,255,255,0.4) !important",
          marginLeft: "36px",
          "& div.second-div ": {
            padding: "5px 12px",
          },
        },
        "& a": {
          borderLeft: "none !important",
        },
      },
      "& li": {
        "& .sub-menu": {
          padding: "0px !important",
          listStyle: "none",
          "& li.MuiListItem-root": {
            padding: "0px !important",
            listStyle: "none",
            "& a": {
              marginLeft: "46px",
              padding: "5px 0px 5px 30px !important",
            },
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.36) !important",
            },
          },
        },
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.25)",
        },
      },
      "& li.MuiListItem-root": {
        padding: "10px 16px",
        listStyle: "none",
        "& a": {
          marginLeft: "30px",
          padding: "5px 10px 5px 10px",
          borderLeft: "1px solid rgba(255,255,255,0.4) !important",
        },
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.25)",
        },
      },
    },
  },
  textLink: {
    padding: 0,
    "& a": {
      padding: "8px 20px 8px 16px",
      display: "block",
      "@media (max-width:1560px)": {
        padding: "10px 16px",
      },
    },
  },
  linkActive: {
    backgroundColor: "rgba(0, 0, 0, 0.2) !important",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.36) !!important",
    },
  },
  childlinkActive: {
    backgroundColor: "rgba(0, 0, 0, 0.2) !important",
  },
  childThirdlinkActive: {
    backgroundColor: "rgba(0, 0, 0, 0.26) !important",
  },
  w100: {
    width: "100%",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    width: "100%",
  },
});
const initialState = {
  alternative_billing: false,
  alternative_billing_adressNote: null,
  alternative_billing_location: null,
  alternative_billing_postal_code: null,
  alternative_billing_house_number: null,
  alternative_billing_street: null,
  alternative_billing_company_name: null,
  alternative_billing_last_name: null,
  alternative_billing_first_name: null,
  object_position: null,
  product_consumtion_price: null,

  alternative_billing_title: null,
  alternative_billing_gender: null,
  product_duration: "",
  address_note: null,
  product_updated_at: "",
  product_type: null,
  customer_location: null,
  postal_code: null,
  contractStatus: "active",
  contract_is_expired: false,
  id: null,
  created_at: null,
  created_by: null,
  created_remote: true,
  synched_remote: true,
  groupe_is_updated: false,
  idObject: null,
  idRealState: null,
  local_image_name: null,
  local_image_path: null,
  local_image_thumb: null,
  local_signature_name: null,
  local_signature_path: null,
  productIsExpired: false,
  pdf_created: false,
  product_id: null,
  idRealEstate: null,
  local_sepa_thumb: null,

  pdf_url: null,
  product_installation_position: "",
  customer_street: null,
  company_name: null,

  customer_mobile_phone: null,
  customer_email: null,
  customer_landline_phone: null,
  customer_birth_date: "",
  product_name: null,
  product_sealed: false,
  product_total_cost: null,

  product_renewal: null,
  customer_first_name: null,
  customer_last_name: null,
  customer_title: null,
  customer_gender: null,
  hasManyObject: false,
  product_counter_reading_2: null,
  sepa_check_box: false,
  sepa_iban: null,
  sepa_bic: null,
  sepa_account_holder: null,
  sepa_bank: null,
  product_counter_number: null,
  product_counter_reading: null,
  product_market_Location_number: null,
  product_counter_image_url: null,
  product_counter_type: null,
  product_move_date: null,
  product_move_date_supplier: null,
  product_the_earliest_possible_date: false,
  product_last_year_consumption: null,
  consumption: null,
  product_previous_customer_number: null,
  product_previous_supplier: null,
  product_new_move: false,
  product_change_supplier: false,

  realEstateObjectNumber: 0,
  send_email: null,
  company_id: null,
  send_pdf: false,
  persons_number: 0,
  sepa_signature_local_path: null,
  sepa_signature_name: null,
  sepa_signature_thumb: null,
  sepa_signature_time_stamp: null,
  sepa_signature_url: null,
  signature_thumb: null,
  signature_time_stamp: null,
  signature_url: null,
  id: null,
  customer_post_code: null,
  contract_leagl: false,
  updated_at: "",
  customer_house_number: null,
  realEstateCreated: false,
  product_base_price: null,
  updated_by: null,
};
const NavListItem = ({
  classes,
  menu,
  mobileToggleMenu,
  toggleThirdMenu,
  toggleMenu,
  toggleThirdMenuAndCloseSidebar,
  closeSidebar,
  toggleFourthMenu,
}) => {
  const [thirdMenuOpen, setThirdMenuOpen] = useState(false);
  const history = useHistory();
  const [user, setUser] = useState({});
  const [company, setCompany] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeThirdIndex, setActiveThirdIndex] = useState(null);
  const [type, setType] = useState("");
  const [path, setPath] = useState("");
  const [objectTosend, setObjectToSend] = useState(initialState);
  const [navigation, setNavigation] = useState(
    window.location.pathname.startsWith("/app/protocol/step") ||
      window.location.pathname.startsWith("/app/contract/selectProduct") ||
      window.location.pathname.startsWith("/app/kaution") ||
      window.location.pathname.startsWith("/app/protocol/rooms")
      ? false
      : true
  );
  //const [currentUrl, setCurrentUrl] = useState(window.location.pathname);
  const currentUrl = window.location.pathname;
  useEffect(() => {
    fetchUserInfoAndCompany();
  }, []);
  const fetchUserInfoAndCompany = async () => {
    try {
      const userDoc = await userService.getUser(userService.getId());
      userDoc.forEach(async (doc) => {
        setUser(doc.data());

        if (doc.data().company_id) {
          let company = await companyService.getCompany(doc.data().company_id);

          setCompany(company);
        }
      });
    } catch (error) {
      console.error("Error fetching user info and company:", error);
    }
  };
  // On Toggle  Menu/
  const onMobileToggleMenu = () => {
    mobileToggleMenu(false);
  };

  const toggleAndCloseSidebar = () => {
    toggleMenu();
    if (closeSidebar) {
      closeSidebar();
    }
  };
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [isTooltipHovered, setIsTooltipHovered] = useState(false);
  const tooltipTimer = useRef(null); // Use ref to manage the timer

  // Handle mouse enter on the ListItem
  const handleMouseEnter = (index) => {
    // Clear the timeout in case it was set before
    clearTimeout(tooltipTimer.current);
    setTooltipVisible(index);
  };

  // Handle mouse leave from the ListItem
  const handleMouseLeave = () => {
    // Delay hiding the tooltip if the mouse leaves the ListItem
    tooltipTimer.current = setTimeout(() => {
      if (!isTooltipHovered) {
        setTooltipVisible(false);
      }
    }, 500); // Delay of 500ms before hiding the tooltip
  };

  // Handle mouse enter on the Tooltip itself
  const handleTooltipEnter = () => {
    clearTimeout(tooltipTimer.current); // Clear timeout to keep it visible when hovering the tooltip
    setIsTooltipHovered(true);
  };

  // Handle mouse leave from the Tooltip itself
  const handleTooltipLeave = () => {
    setIsTooltipHovered(false);
    // Only hide the tooltip if the mouse is not over the ListItem or the tooltip
    tooltipTimer.current = setTimeout(() => {
      setTooltipVisible(false);
    }, 300); // Same delay before hiding the tooltip
  };
  const showInfoTip = () => {
    swal({
      text: "Diese Premium-Funktion steht unseren Energie-Rahmenvertragskunden zur Verfügung. Weitere Infos & Kontakt unter",
      content: (() => {
        const link = document.createElement("a");
        link.href = "https://www.x-cite.de";
        link.textContent = "X-Cite";
        link.target = "_blank"; // Opens link in new tab
        return link;
      })(),
    });
  };
  const SaveTheContract = async () => {
    const localStorageValue = JSON.parse(localStorage.getItem("globalState"));
    const updatedObject = fillObjectToSend(objectTosend, localStorageValue);
    await addToDB(updatedObject);
  };

  const fillObjectToSend = (object1, object2) => {
    Object.keys(object2).forEach((key) => {
      if (object1.hasOwnProperty(key)) {
        object1[key] = object2[key];
      }
    });

    return object1;
  };

  const addToDB = async (data) => {
    let id = data.id ? data.id : uuidv4();
    data.customer_birth_date = data.customer_birth_date
      ? Moment(data.customer_birth_date.toString()).format("DD.MM.YYYY")
      : null;
    data.product_last_year_consumption = data.product_last_year_consumption
      ? Moment(data.product_last_year_consumption.toString()).format(
          "DD.MM.YYYY"
        )
      : null;
    data.product_move_date = data.product_move_date
      ? Moment(data.product_move_date.toString()).format("DD.MM.YYYY")
      : null;

    try {
      var { property, index, realEstate } =
        await realEstateService.getRealEstateByAdress(
          data.postal_code,
          data.customer_street,
          data.customer_location,
          data.customer_house_number,
          data.object_position,
          company.id
        );
      if (property) {
        let counter = property.property_counters.find((c) => {
          return c.counterType == (data.product_type === "strom"
            ? "electricity"
            : data.product_type);
        });
        let { changeDetected, changedFields } = detectContractChanges(
          data,
          counter
        );
        if (changeDetected) {
          swal({
            title: "HINWEIS",
            text: " Sie haben Änderungen an dem Objekt vorgenommen. Sollen diese Änderungen in die Liegenschaft/Objekt übernommen werden? ",
            buttons: ["Abbrechen", "Ja"],
          }).then(async (choice) => {
            if (choice) {
              const updated_property = updatePropertyFromContract(
                changedFields,
                property,
                data,
                data.product_type
              );
              var realEstateToEdit = { ...realEstate };
              realEstateToEdit.created_at = DateFormatter.formatTimeStamp(
                realEstate.created_at
              );
              realEstateToEdit.updated_at = DateFormatter.initDate();
              realEstateToEdit.re_property = realEstateToEdit.re_property.map(
                (prop) => {
                  if (prop.id !== property.id) {
                    return {
                      ...prop,
                      updated_at: DateFormatter.formatTimeStamp(
                        prop.updated_at
                      ),
                      created_at: DateFormatter.formatTimeStamp(
                        prop.created_at
                      ),
                      property_counters: prop.property_counters?.map(
                        (counter) => ({
                          ...counter,
                          createdAt: DateFormatter.formatTimeStamp(
                            counter.createdAt
                          ),
                          updatedAt: DateFormatter.formatTimeStamp(
                            counter.updatedAt
                          ),
                        })
                      ),
                      property_keys: prop.property_keys?.map((key) => ({
                        ...key,
                        createdAt: DateFormatter.formatTimeStamp(key.createdAt),
                        updatedAt: DateFormatter.formatTimeStamp(key.updatedAt),
                      })),
                      property_rooms: prop.property_rooms?.map((room) => ({
                        ...room,
                        createdAt: DateFormatter.formatTimeStamp(
                          room.createdAt
                        ),
                        updatedAt: DateFormatter.formatTimeStamp(
                          room.updatedAt
                        ),
                      })),
                    };
                  } else {
                    return {
                      ...updated_property,
                      property_counters:
                        updated_property.property_counters?.map((counter) => ({
                          ...counter,
                          createdAt:
                            counter.counterType ==
                            (data.product_type === "strom"
                              ? "electricity"
                              : data.product_type)
                              ? counter.createdAt
                              : DateFormatter.formatTimeStamp(
                                  counter.createdAt
                                ),
                          updatedAt:
                            counter.counterType ==
                            (data.product_type === "strom"
                              ? "electricity"
                              : data.product_type)
                              ? counter.updatedAt
                              : DateFormatter.formatTimeStamp(
                                  counter.updatedAt
                                ),
                        })),
                      property_keys: prop.property_keys?.map((key) => ({
                        ...key,
                        createdAt: DateFormatter.formatTimeStamp(key.createdAt),
                        updatedAt: DateFormatter.formatTimeStamp(key.updatedAt),
                      })),
                      property_rooms: prop.property_rooms?.map((room) => ({
                        ...room,
                        createdAt: DateFormatter.formatTimeStamp(
                          room.createdAt
                        ),
                        updatedAt: DateFormatter.formatTimeStamp(
                          room.updatedAt
                        ),
                      })),
                    };
                  }
                }
              );
              await realEstateService.updateProperties(
                updated_property.realEstate,
                realEstateToEdit
              );
            }
            setDoc(doc(db, "xc_contract", id), {
              ...data,
              id: id,
              is_from_web: true,
              created_at: data.created_at
                ? new Date(data?.created_at)
                : serverTimestamp(),
              updated_at: serverTimestamp(),
              last_updated_at_web: serverTimestamp(),
              product_updated_at: data.product_updated_at
                ? new Date(data?.product_updated_at)
                : null,
            }).then((result) => {
              localStorage.removeItem("globalState");
              localStorage.removeItem("filters");
              localStorage.removeItem("visitedSteps");
              history.push(`${path}${type}`);
            });
          });
        }
        setDoc(doc(db, "xc_contract", id), {
          ...data,
          id: id,
          is_from_web: true,
          created_at: data.created_at
            ? new Date(data?.created_at)
            : serverTimestamp(),
          updated_at: serverTimestamp(),
          last_updated_at_web: serverTimestamp(),
          product_updated_at: data.product_updated_at
            ? new Date(data?.product_updated_at)
            : null,
        }).then((result) => {
          localStorage.removeItem("globalState");
          localStorage.removeItem("filters");
          localStorage.removeItem("visitedSteps");
          history.push(`${path}${type}`);
        });
      } else {
        setDoc(doc(db, "xc_contract", id), {
          ...data,
          id: id,
          is_from_web: true,
          created_at: data.created_at
            ? new Date(data?.created_at)
            : serverTimestamp(),
          updated_at: serverTimestamp(),
          last_updated_at_web: serverTimestamp(),
          product_updated_at: data.product_updated_at
            ? new Date(data?.product_updated_at)
            : null,
        }).then((result) => {
          localStorage.removeItem("globalState");
          localStorage.removeItem("filters");
          localStorage.removeItem("visitedSteps");
          history.push(`${path}${type}`);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const detectObjectChanges = async (protocolToSave) => {
    let changesDetected = false;
    const realEstateToTest = await realEstateService.getRealEstate(
      protocolToSave.object.real_state_id
    );

    const propertyToTest = realEstateToTest.data().re_property.find((p) => {
      return p.id == protocolToSave.object.object_id;
    });

    let protocol_object = {
      plz: protocolToSave.object.address.object_plz,
      street: protocolToSave.object.address.object_street,
      city: protocolToSave.object.address.object_city,
      hnr: protocolToSave.object.address.object_house_nbr,
      position: protocolToSave.object.address.object_position,
    };
    let property_object = {
      plz: propertyToTest.realEstatePlz,
      street: propertyToTest.realEstateStreet,
      city: propertyToTest.realEstateCity,
      hnr: propertyToTest.realEstateHouseNumber,
      position: propertyToTest.property_position,
    };
    let keysToCheck = ["plz", "street", "city", "hnr", "position"];
    let changes = compareObjects(protocol_object, property_object, keysToCheck);
    if (changes.length > 0) {
      changesDetected = true;
    }
    if (changesDetected) {
      return null;
    } else {
      return realEstateToTest.data();
    }
  };

  const saveProtocol = async (protocolToSave) => {
    protocolToSave = protocolService.initProtocolObject(
      protocolToSave,
      user.company_id
    );

    try {
      var realEstateToTest = null;
      if (protocolToSave.object.real_state_id) {
        realEstateToTest = await detectObjectChanges(protocolToSave);
      }
      if (realEstateToTest != null) {
        var realEstate = realEstateToTest;
        var property = realEstateToTest.re_property.find((p) => {
          return p.id == protocolToSave.object.object_id;
        });
      } else {
        var { property, index, realEstate } =
          await realEstateService.getRealEstateByAdress(
            protocolToSave.object.address.object_plz,
            protocolToSave.object.address.object_street,
            protocolToSave.object.address.object_city,
            protocolToSave.object.address.object_house_nbr,
            protocolToSave.object.address.object_position,
            user.company_id
          );
      }

      if (property && protocolToSave.protocol_type === "vacancy_inspection") {
        const changes = detectPropertyChanges(protocolToSave, property);
        if (changes) {
          swal({
            title: "HINWEIS",
            text: " Sie haben Änderungen an dem Objekt vorgenommen. Sollen diese Änderungen in die Liegenschaft/Objekt übernommen werden? ",
            buttons: ["Abbrechen", "Ja"],
          }).then(async (choice) => {
            if (choice) {
              const updated_property = prepareProperty(
                protocolToSave,
                property,
                user.firebase_auth_id
              );
              var realEstateToEdit = { ...realEstate };
              realEstateToEdit.created_at = DateFormatter.formatTimeStamp(
                realEstate.created_at
              );
              realEstateToEdit.updated_at = DateFormatter.initDate();
              realEstateToEdit.re_property = realEstateToEdit.re_property.map(
                (prop) => {
                  if (prop.id !== property.id) {
                    return {
                      ...prop,
                      updated_at: DateFormatter.formatTimeStamp(
                        prop.updated_at
                      ),
                      created_at: DateFormatter.formatTimeStamp(
                        prop.created_at
                      ),
                      property_counters: prop.property_counters?.map(
                        (counter) => ({
                          ...counter,
                          createdAt: DateFormatter.formatTimeStamp(
                            counter.createdAt
                          ),
                          updatedAt: DateFormatter.formatTimeStamp(
                            counter.updatedAt
                          ),
                        })
                      ),
                      property_keys: prop.property_keys?.map((key) => ({
                        ...key,
                        createdAt: DateFormatter.formatTimeStamp(key.createdAt),
                        updatedAt: DateFormatter.formatTimeStamp(key.updatedAt),
                      })),
                      property_rooms: prop.property_rooms?.map((room) => ({
                        ...room,
                        createdAt: DateFormatter.formatTimeStamp(
                          room.createdAt
                        ),
                        updatedAt: DateFormatter.formatTimeStamp(
                          room.updatedAt
                        ),
                      })),
                    };
                  } else {
                    return { ...updated_property };
                  }
                }
              );
              await realEstateService.updateProperties(
                updated_property.realEstate,
                realEstateToEdit
              );
            }
            protocolService.createProtocol(protocolToSave).then((result) => {
              swal({
                title: "HINWEIS",
                text: "Ihr Dokument wurde gespeichert und kann zur Weiterverarbeitung in der App aufgerufen werden. ",
                button: "OK",
              }).then(async (willDelete) => {
                // Mark the callback function as async
                if (willDelete) {
                  localStorage.removeItem("Protocol");
                  localStorage.removeItem("showCounters");
                  localStorage.removeItem("SuggestedTariffgas");
                  localStorage.removeItem("SuggestedTariffstrom");
                  localStorage.removeItem("SuggestedTariff");
                  localStorage.removeItem("filters");
                  window.location.href = `${path}`;
                }
              });
            });
          });
        } else {
          protocolService.createProtocol(protocolToSave).then((result) => {
            swal({
              title: "HINWEIS",
              text: "Ihr Dokument wurde gespeichert und kann zur Weiterverarbeitung in der App aufgerufen werden. ",
              button: "OK",
            }).then(async (willDelete) => {
              // Mark the callback function as async
              if (willDelete) {
                localStorage.removeItem("Protocol");
                localStorage.removeItem("showCounters");
                localStorage.removeItem("SuggestedTariffgas");
                localStorage.removeItem("SuggestedTariffstrom");
                localStorage.removeItem("SuggestedTariff");
                localStorage.removeItem("filters");
                window.location.href = `${path}`;
              }
            });
          });
        }
      } else {
        protocolService.createProtocol(protocolToSave).then((result) => {
          swal({
            title: "HINWEIS",
            text: "Ihr Dokument wurde gespeichert und kann zur Weiterverarbeitung in der App aufgerufen werden. ",
            button: "OK",
          }).then(async (willDelete) => {
            // Mark the callback function as async
            if (willDelete) {
              localStorage.removeItem("Protocol");
              localStorage.removeItem("showCounters");
              localStorage.removeItem("SuggestedTariffgas");
              localStorage.removeItem("SuggestedTariffstrom");
              localStorage.removeItem("SuggestedTariff");
              localStorage.removeItem("filters");
              window.location.href = `${path}`;
            }
          });
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const saveKaution = async (kautionToSave) => {
    kautionToSave = kautionService.initKaution(kautionToSave, user.company_id);
    try {
      var { property, index, realEstate } =
        await realEstateService.getRealEstateByAdress(
          kautionToSave.object.address.plz,
          kautionToSave.object.address.street,
          kautionToSave.object.address.city,
          kautionToSave.object.address.house_number,
          kautionToSave.object.address.position,
          company.id
        );
      if (property) {
        let changeDetected = detectKautionChanges(kautionToSave, property);
        if (changeDetected) {
          swal({
            title: "HINWEIS",
            text: " Sie haben Änderungen an dem Objekt vorgenommen. Sollen diese Änderungen in die Liegenschaft/Objekt übernommen werden? ",
            buttons: ["Abbrechen", "Ja"],
          }).then(async (choice) => {
            if (choice) {
              const updated_property = updatePropertyFromLandlord(
                property,
                kautionToSave.landlord
              );
              var realEstateToEdit = { ...realEstate };
              realEstateToEdit.created_at = DateFormatter.formatTimeStamp(
                realEstate.created_at
              );
              realEstateToEdit.updated_at = DateFormatter.initDate();
              realEstateToEdit.re_property = realEstateToEdit.re_property.map(
                (prop) => {
                  if (prop.id !== property.id) {
                    return {
                      ...prop,
                      updated_at: DateFormatter.formatTimeStamp(
                        prop.updated_at
                      ),
                      created_at: DateFormatter.formatTimeStamp(
                        prop.created_at
                      ),
                      property_counters: prop.property_counters?.map(
                        (counter) => ({
                          ...counter,
                          createdAt: DateFormatter.formatTimeStamp(
                            counter.createdAt
                          ),
                          updatedAt: DateFormatter.formatTimeStamp(
                            counter.updatedAt
                          ),
                        })
                      ),
                      property_keys: prop.property_keys?.map((key) => ({
                        ...key,
                        createdAt: DateFormatter.formatTimeStamp(key.createdAt),
                        updatedAt: DateFormatter.formatTimeStamp(key.updatedAt),
                      })),
                      property_rooms: prop.property_rooms?.map((room) => ({
                        ...room,
                        createdAt: DateFormatter.formatTimeStamp(
                          room.createdAt
                        ),
                        updatedAt: DateFormatter.formatTimeStamp(
                          room.updatedAt
                        ),
                      })),
                    };
                  } else {
                    return {
                      ...updated_property,
                      updated_at: DateFormatter.initDate(),
                      created_at: DateFormatter.formatTimeStamp(
                        updated_property.created_at
                      ),
                      property_counters:
                        updated_property.property_counters?.map((counter) => ({
                          ...counter,
                          createdAt: DateFormatter.formatTimeStamp(
                            counter.createdAt
                          ),
                          updatedAt: DateFormatter.formatTimeStamp(
                            counter.updatedAt
                          ),
                        })),
                      property_keys: updated_property.property_keys?.map(
                        (key) => ({
                          ...key,
                          createdAt: DateFormatter.formatTimeStamp(
                            key.createdAt
                          ),
                          updatedAt: DateFormatter.formatTimeStamp(
                            key.updatedAt
                          ),
                        })
                      ),
                      property_rooms: updated_property.property_rooms?.map(
                        (room) => ({
                          ...room,
                          createdAt: DateFormatter.formatTimeStamp(
                            room.createdAt
                          ),
                          updatedAt: DateFormatter.formatTimeStamp(
                            room.updatedAt
                          ),
                        })
                      ),
                    };
                  }
                }
              );
              await realEstateService.updateProperties(
                updated_property.realEstate,
                realEstateToEdit
              );
            }
            kautionService.createKaution(kautionToSave).then((result) => {
              swal({
                title: "HINWEIS",
                text: "Ihr Dokument wurde gespeichert und kann zur Weiterverarbeitung in der App aufgerufen werden. ",
                button: "OK",
              }).then(async (willDelete) => {
                localStorage.removeItem("Kaution");
                localStorage.removeItem("showCounters");
                localStorage.removeItem("filters");
                // Navigate to the desired path
                window.location.href = path;
              });
            });
          });
        } else {
          kautionService.createKaution(kautionToSave).then((result) => {
            swal({
              title: "HINWEIS",
              text: "Ihr Dokument wurde gespeichert und kann zur Weiterverarbeitung in der App aufgerufen werden. ",
              button: "OK",
            }).then(async (willDelete) => {
              localStorage.removeItem("Kaution");
              localStorage.removeItem("showCounters");
              localStorage.removeItem("filters");
              // Navigate to the desired path
              window.location.href = path;
            });
          });
        }
      } else {
        kautionService.createKaution(kautionToSave).then((result) => {
          swal({
            title: "HINWEIS",
            text: "Ihr Dokument wurde gespeichert und kann zur Weiterverarbeitung in der App aufgerufen werden. ",
            button: "OK",
          }).then(async (willDelete) => {
            localStorage.removeItem("Kaution");
            localStorage.removeItem("showCounters");
            localStorage.removeItem("filters");
            // Navigate to the desired path
            window.location.href = path;
          });
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  function updatePropertyFromLandlord(property, landlord) {
    if (!landlord) return property;

    property.owner_company_type = landlord.type || null;
    property.owner_company_Name = landlord.company_name || null;
    property.onwer_email = landlord.email || null;
    property.onwer_first_name = landlord.first_name || null;
    property.onwer_last_name = landlord.last_name || null;
    property.onwer_phone_number = landlord.phone || null;
    property.onwer_mobile_number = landlord.mobile || null;
    property.onwer_company_gender = landlord.salutation || null;
    property.onwer_company_title = landlord.title || null;

    if (landlord.address) {
      property.onwer_adress_note = landlord.address.address_note || null;
      property.onwer_city = landlord.address.city || null;
      property.onwer_adress_house_Number =
        landlord.address.house_number || null;
      property.onwer_adress_plz = landlord.address.plz || null;
      property.onwer_adress_street = landlord.address.street || null;
    }

    return property;
  }

  function compareObjects(obj1, obj2, keysToCheck) {
    let changes = [];

    for (let key of keysToCheck) {
      const value1 = obj1[key];
      const value2 = obj2[key];

      // Treat "" and null as equal
      const areValuesEqual =
        value1 === value2 ||
        (value1 === "" && value2 === null) ||
        (value1 === null && value2 === "");

      if (!areValuesEqual) {
        changes.push(`Field '${key}' changed from '${value1}' to '${value2}'`);
        //console.log(`Field '${key}' changed from '${value1}' to '${value2}'`);
      }
    }
    return changes;
  }

  function compareArrays(arr1, arr2, keysToCheck) {
    let changesDetected = false;
    let maxLength = Math.max(arr1.length, arr2.length);
    for (let i = 0; i < maxLength; i++) {
      if (!arr1[i] || !arr2[i]) {
        changesDetected = true;
        continue;
      }
      let obj = {};
      if (arr1[i].hasOwnProperty("key_type")) {
        obj = {
          key_type: arr2[i].keysType,
          key_name: arr2[i].keysName,
          //key_number: arr2[i].keysNumber
        };
      } else if (arr1[i].hasOwnProperty("room_name")) {
        obj = {
          room_name: arr2[i].roomName,
          room_type: arr2[i].roomType,
          room_generale_description: arr2[i].roomDescription,
        };
      } else {
        obj = {
          counter_name: arr2[i].counterName,
          counter_number: arr2[i].counterNumber,
          counter_type: arr2[i].counterType,
        };
      }
      let changes = compareObjects(arr1[i], obj, keysToCheck);
      if (changes.length > 0) {
        changesDetected = true;
      }
    }
    return changesDetected;
  }

  function detectPropertyChanges(protocol, property) {
    const keysToCheck = {
      property_counters: ["counter_name", "counter_number", "counter_type"],
      property_keys: ["key_type", "key_name"], //"key_number"],
      property_rooms: ["room_name", "room_type", "room_generale_description"],
    };

    let changeDetected = false;
    for (let key in keysToCheck) {
      let protocolKey = key.replace("property_", "protocol_");
      if (
        Array.isArray(protocol[protocolKey]) &&
        Array.isArray(property[key])
      ) {
        if (
          compareArrays(protocol[protocolKey], property[key], keysToCheck[key])
        ) {
          changeDetected = true;
        }
      } else if (
        typeof protocol[protocolKey] === "object" &&
        typeof property[key] === "object"
      ) {
        let changes = compareObjects(
          protocol[protocolKey],
          property[key],
          keysToCheck[key]
        );
        if (changes.length > 0) {
          changeDetected = true;
        }
      }
    }
    if (protocol.owner) {
      const ownerKeys = [
        "owner_Type",
        "owner_company_name",
        "owner_first_name",
        "owner_last_name",
        "owner_phone",
      ];
      let ownerChanges = compareObjects(
        protocol.owner,
        {
          owner_Type: property.owner_company_type,
          owner_company_name: property.owner_company_Name,
          owner_first_name: property.onwer_first_name,
          owner_last_name: property.onwer_last_name,
          owner_phone: property.onwer_phone_number,
        },
        ownerKeys
      );

      if (ownerChanges.length > 0) {
        changeDetected = true;
      }
    }

    return changeDetected;
  }

  function detectKautionChanges(kaution, property) {
    let changeDetected = false;

    if (kaution.landlord) {
      const ownerKeys = [
        "type",
        "company_name",
        "first_name",
        "last_name",
        "phone",
        "mobile",
        "email",
        "salutation",
        "title",
      ];

      let ownerChanges = compareObjects(
        kaution.landlord,
        {
          type: property.owner_company_type,
          company_name: property.owner_company_Name,
          first_name: property.onwer_first_name,
          last_name: property.onwer_last_name,
          phone: property.onwer_phone_number,
          mobile: property.onwer_mobile_number,
          email: property.onwer_email,
          salutation: property.onwer_company_gender,
          title: property.onwer_company_title,
        },
        ownerKeys
      );

      if (ownerChanges.length > 0) {
        changeDetected = true;
      }
    }

    return changeDetected;
  }

  function prepareProperty(protocol, property, user_id) {
    let property_rooms = [];
    let property_counters = [];
    let property_keys = [];

    let updatedProperty = {
      ...property,
      id: protocol.object.object_id,
      updated_at: DateFormatter.initDate(),
      created_at: DateFormatter.formatTimeStamp(property.created_at),
    };

    if (protocol.owner) {
      updatedProperty.re_owner_type = protocol.owner.owner_Type;
      updatedProperty.re_owner_company_name = protocol.owner.owner_company_name;
      updatedProperty.re_onwer_email = protocol.owner.owner_email;
      updatedProperty.re_onwer_first_name = protocol.owner.owner_first_name;
      updatedProperty.re_onwer_last_name = protocol.owner.owner_last_name;
      updatedProperty.re_onwer_phone_number = protocol.owner.owner_phone;
      updatedProperty.re_onwer_mobile_number = protocol.owner.owner_mobile;
      updatedProperty.re_onwer_gender = protocol.owner.owner_salutation;
      updatedProperty.re_onwer_title = protocol.owner.owner_title;
      updatedProperty.re_onwer_adress_note =
        protocol.owner.address.owner_address_note;
      updatedProperty.re_onwer_city = protocol.owner.address.owner_city;
      updatedProperty.re_onwer_adress_house_number =
        protocol.owner.address.owner_house_number;
      updatedProperty.re_onwer_adress_plz = protocol.owner.address.owner_plz;
      updatedProperty.re_onwer_adress_street =
        protocol.owner.address.owner_street;
    }
    protocol.protocol_rooms.forEach((room) => {
      property_rooms.push({
        id: room.property_room_ref_id ? room.property_room_ref_id : uuidv4(),
        realEstate: property.realEstate,
        roomDescription: room.room_generale_description || "",
        roomName: room.room_name,
        roomType: room.room_type,
        updatedAt: DateFormatter.initDate(),
        createdAt: room.room_date_creation,
        reProperty: property.id,
        createdBy: user_id,
      });
    });

    protocol.protocol_keys.forEach((key) => {
      property_keys.push({
        id: key.property_key_ref_id || uuidv4(),
        keysType: key.key_type,
        keysName: key.key_name,
        keysNumber:
          key.key_number && !isNaN(parseInt(key.key_number, 10))
            ? parseInt(key.key_number, 10)
            : 0,
        reProperty: property.id,
        realEstate: property.realEstate,
        updatedAt: DateFormatter.initDate(),
        createdAt: key.created_at,
        createdBy: user_id,
      });
    });

    protocol.protocol_counter.forEach((counter) => {
      property_counters.push({
        id: counter.property_counter_ref_id || uuidv4(),
        is_double: counter.counter_is_double,
        counterName: counter.counter_name,
        counterNumber: counter.counter_number,
        market_Location_number: counter.market_location_number,
        counterLocation: counter.counter_location,
        counterType: counter.counter_type,
        reProperty: property.id,
        updatedAt: DateFormatter.initDate(),
        createdAt: counter.counter_created_at,
        realEstate: property.realEstate,
        createdBy: user_id,
      });
    });

    updatedProperty.property_rooms = property_rooms;
    updatedProperty.property_keys = property_keys;
    updatedProperty.property_counters = property_counters;

    return updatedProperty;
  }
  function detectContractChanges(contract, counter) {
    let changeDetected = false;
    let changedFields = [];

    if (
      contract.product_market_Location_number !== counter.market_Location_number
    ) {
      changeDetected = true;
      changedFields.push("market_Location_number");
    }
    if (contract.product_counter_number !== counter.counterNumber) {
      changeDetected = true;
      changedFields.push("counterNumber");
    }
    if (contract.product_counter_type !== counter.is_double) {
      changeDetected = true;
      changedFields.push("is_double");
    }
    if (contract.product_installation_position !== counter.counterLocation) {
      changeDetected = true;
      changedFields.push("counterLocation");
    }
    return { changeDetected, changedFields };
  }
  function updatePropertyFromContract(changedFields, property, contract, type) {
    return {
      ...property,
      property_counters: property.property_counters.map((counter) => {
        if (counter.counterType === type) {
          let updatedCounter = {
            ...counter,
            updatedAt: DateFormatter.initDate(),
            createdAt: DateFormatter.formatTimeStamp(counter.createdAt),
          };

          changedFields.forEach((field) => {
            switch (field) {
              case "market_Location_number":
                updatedCounter[field] = contract.product_market_Location_number;
                break;
              case "counterNumber":
                updatedCounter[field] = contract.product_counter_number;
                break;
              case "is_double":
                updatedCounter[field] = contract.product_counter_type;
                break;
              case "counterLocation":
                updatedCounter[field] = contract.product_installation_position;
                break;
              default:
                break;
            }
          });

          return updatedCounter;
        }
        return counter;
      }),
      updated_at: DateFormatter.initDate(),
      created_at: DateFormatter.formatTimeStamp(property.created_at),
    };
  }
  const handleConfirm = async () => {
    setShowAlert(false);

    switch (true) {
      case currentUrl.startsWith("/app/contract/selectProduct") ||
        currentUrl.startsWith("/app/protocol/step") ||
        currentUrl.startsWith("/app/protocol/rooms") ||
        currentUrl.startsWith("/app/kaution"):
        if (currentUrl.startsWith("/app/contract/selectProduct")) {
          const searchParams = new URLSearchParams(window.location.search);

          let currentType = searchParams.get("type");
          let globalState = JSON.parse(localStorage.getItem("globalState"));
          let contract = {};
          if (globalState.id) {
            contract = (
              await contractService.getContract(globalState.id)
            ).data();
            if (contract?.pdf_created === true) {
              localStorage.removeItem("filters");
              localStorage.removeItem("globalState");
              history.push("/app/dashboard");
            } else {
              swal({
                title: "HINWEIS",
                text: "Ihr Dokument wurde gespeichert und kann zur Weiterverarbeitung in der App aufgerufen werden. ",
                button: "OK",
              }).then((willDelete) => {
                if (
                  (currentType === "strom" || currentType === "gas") &&
                  type !== currentType
                ) {
                  SaveTheContract();
                } else {
                  history.push`${path}`;
                }
              });
            }
          } else {
            swal({
              title: "HINWEIS",
              text: "Ihr Dokument wurde gespeichert und kann zur Weiterverarbeitung in der App aufgerufen werden. ",
              button: "OK",
            }).then((willDelete) => {
              if (
                (currentType === "strom" || currentType === "gas") &&
                type !== currentType
              ) {
                SaveTheContract();
              } else {
                history.push(`${path}`);
              }
            });
          }
        }
        if (currentUrl.startsWith("/app/protocol/step")) {
          let protocolToSave = JSON.parse(localStorage.getItem("Protocol"));
          let protocolToverify = (
            await protocolService.getProtocol(protocolToSave.id)
          ).data();
          if (protocolToverify && protocolToverify.closed === true) {
            localStorage.removeItem("Protocol");
            localStorage.removeItem("SuggestedTariffgas");
            localStorage.removeItem("SuggestedTariffstrom");
            localStorage.removeItem("SuggestedTariff");
            localStorage.removeItem("showCounters");
            localStorage.removeItem("filters");
            window.location.href = path;
          } else {
            try {
              let protocol = JSON.parse(localStorage.getItem("Protocol"));
              saveProtocol(protocol);
              localStorage.removeItem("Protocol");
              localStorage.removeItem("SuggestedTariffgas");
              localStorage.removeItem("SuggestedTariffstrom");
              localStorage.removeItem("SuggestedTariff");
              localStorage.removeItem("showCounters");
              localStorage.removeItem("filters");
              //window.location.href = path;
            } catch (error) {
              console.log(error);
            }
          }
        }
        if (currentUrl.startsWith("/app/protocol/rooms")) {
          let protocolToSave = JSON.parse(localStorage.getItem("Protocol"));
          let protocolToverify = (
            await protocolService.getProtocol(protocolToSave.id)
          ).data();
          if (protocolToverify && protocolToverify.closed === true) {
            localStorage.removeItem("Protocol");
            localStorage.removeItem("SuggestedTariffgas");
            localStorage.removeItem("SuggestedTariffstrom");
            localStorage.removeItem("SuggestedTariff");
            localStorage.removeItem("showCounters");
            localStorage.removeItem("filters");
            window.location.href = path;
          } else {
            try {
              let protocol = JSON.parse(localStorage.getItem("Protocol"));
              saveProtocol(protocol);
              localStorage.removeItem("Protocol");
              localStorage.removeItem("SuggestedTariffgas");
              localStorage.removeItem("SuggestedTariffstrom");
              localStorage.removeItem("SuggestedTariff");
              localStorage.removeItem("showCounters");
              localStorage.removeItem("filters");
              //window.location.href = path;
            } catch (error) {
              console.log(error);
            }
          }
        }
        if (currentUrl.startsWith("/app/kaution")) {
          let kautionToSave = JSON.parse(localStorage.getItem("Kaution"));
          let kautionToVerify = (
            await kautionService.getKaution(kautionToSave.id)
          ).data();
          if (kautionToVerify && kautionToVerify.closed === true) {
            /* localStorage.removeItem("Kaution");
            localStorage.removeItem("showCounters");
            window.location.href = path; */
          } else {
            try {
              let kaution = JSON.parse(localStorage.getItem("Kaution"));
              saveKaution(kaution);
            } catch (error) {
              console.log("Error saving Kaution:", error);
            }
          }
        }
        break;
    }
  };

  const handleCancel = () => {
    localStorage.removeItem("Protocol");
    localStorage.removeItem("filters");
    localStorage.removeItem("activeSteps");
    localStorage.removeItem("ObjectID");
    localStorage.removeItem("SuggestedTariff");
    localStorage.removeItem("showCounters");
    localStorage.removeItem("SuggestedTariffgas");
    localStorage.removeItem("SuggestedTariffstrom");
    localStorage.removeItem("Kaution");
    localStorage.removeItem("globalState");
    localStorage.removeItem("visitedSteps");
    setShowAlert(false);
    window.location.href = path;
  };

  const handleThirdButton = async () => {
    if (
      window.location.pathname.startsWith("/app/contract/selectProduct") ===
      true
    ) {
      let globalState = JSON.parse(localStorage.getItem("globalState"));
      let contract = {};
      if (globalState.id) {
        contract = (await contractService.getContract(globalState.id)).data();
        if (contract?.pdf_created === true) {
          localStorage.removeItem("globalState");
          localStorage.removeItem("filters");
          window.location.href = path;
        } else {
          contractService
            .deleteContract(contract.id)
            .then((result) => {
              localStorage.removeItem("globalState");
              localStorage.removeItem("filters");
            })
            .then((result) => {
              setShowAlert(false);
              history.push(`${path}`, { from: window.location.pathname });
            });
        }
      } else {
        localStorage.removeItem("globalState");
        localStorage.removeItem("filters");
        history.push(`${path}`, { from: window.location.pathname });
        setShowAlert(false);
      }
    }

    if (window.location.pathname.startsWith("/app/protocol/step")) {
      let protocolToSave = JSON.parse(localStorage.getItem("Protocol"));
      let protocolToverify = (
        await protocolService.getProtocol(protocolToSave.id)
      ).data();
      if (protocolToverify && protocolToverify.closed === true) {
        localStorage.removeItem("Protocol");
        localStorage.removeItem("SuggestedTariff");
        localStorage.removeItem("showCounters");
        localStorage.removeItem("SuggestedTariffgas");
        localStorage.removeItem("SuggestedTariffstrom");
        localStorage.removeItem("filters");
        //window.location.href = path;
        history.push(`${path}`, { from: window.location.pathname });
        setShowAlert(false);
      } else {
        protocolService.deleteProtocol(protocolToSave.id).then((result) => {
          if (result.success) {
            localStorage.removeItem("Protocol");
            localStorage.removeItem("showCounters");
            localStorage.removeItem("SuggestedTariffgas");
            localStorage.removeItem("SuggestedTariffstrom");
            localStorage.removeItem("SuggestedTariff");
            localStorage.removeItem("filters");
            history.push(`${path}`, { from: window.location.pathname });
            setShowAlert(false);
          }
        });
      }
    }
    if (window.location.pathname.startsWith("/app/protocol/rooms")) {
      let protocolToSave = JSON.parse(localStorage.getItem("Protocol"));
      let protocolToverify = (
        await protocolService.getProtocol(protocolToSave.id)
      ).data();
      if (protocolToverify && protocolToverify.closed === true) {
        localStorage.removeItem("Protocol");
        localStorage.removeItem("SuggestedTariff");
        localStorage.removeItem("showCounters");
        localStorage.removeItem("SuggestedTariffgas");
        localStorage.removeItem("SuggestedTariffstrom");
        localStorage.removeItem("filters");
        //window.location.href = path;
        history.push(`${path}`, { from: window.location.pathname });
        setShowAlert(false);
      } else {
        protocolService.deleteProtocol(protocolToSave.id).then((result) => {
          if (result.success) {
            localStorage.removeItem("Protocol");
            localStorage.removeItem("showCounters");
            localStorage.removeItem("SuggestedTariffgas");
            localStorage.removeItem("SuggestedTariffstrom");
            localStorage.removeItem("SuggestedTariff");
            localStorage.removeItem("filters");
            //window.location.href = path;
            history.push(`${path}`, { from: window.location.pathname });
            setShowAlert(false);
          }
        });
      }
    }

    if (window.location.pathname.startsWith("/app/kaution")) {
      let kautionToSave = JSON.parse(localStorage.getItem("Kaution"));
      let kautionToverify = (
        await kautionService.getKaution(kautionToSave.id)
      ).data();
      if (kautionToverify && kautionToverify.closed === true) {
        localStorage.removeItem("Kaution");
        localStorage.removeItem("filters");
        history.push(`${path}`, { from: window.location.pathname });
        setShowAlert(false);
      } else {
        kautionService.deleteKaution(kautionToSave.id).then((result) => {
          if (result.success) {
            localStorage.removeItem("Kaution");
            localStorage.removeItem("filters");
            history.push(`${path}`, { from: window.location.pathname });
            setShowAlert(false);
          }
        });
      }
    }
  };

  const handleClose = () => {
    setShowAlert(false);
  };

  const handleLinkOn = (pathUrl, index = null, childroute = null) => {
    const localStorageValue = JSON.parse(localStorage.getItem("Protocol"));
    const globalstate = JSON.parse(localStorage.getItem("globalState"));
    const kautionstate = JSON.parse(localStorage.getItem("Kaution"));
    let showAlert = false;
    if (childroute == "thirdchildroutes") {
      toggleFourthMenu(index);
      setActiveThirdIndex(`T${index}`);
    } else if (childroute == "secondchildroutes") {
      setActiveIndex(`S${index}`);
      if (pathUrl == "") {
        return swal({
          title: "HINWEIS",
          text: "Diese Funktionen befinden sich in der Entwicklungsphase und werden demnächst für Sie freigeschaltet. Wir bitten um etwas Geduld.",
          button: "OK",
        });
      } else toggleThirdMenuAndCloseSidebar(index);
    }
    if (currentUrl.startsWith("/app/contract/selectProduct")) {
      if (globalstate && !globalstate.product_name) {
        localStorage.removeItem("globalState");
        localStorage.removeItem("filters");

        setShowAlert(false);
        setPath(pathUrl);

        history.push(pathUrl);
      } else if (globalstate && globalstate.product_name) {
        setShowAlert(true);
        setPath(pathUrl);
      }
    } else if (
      currentUrl.startsWith("/app/protocol/step") &&
      localStorageValue != null &&
      localStorageValue.protocol_type !== ""
    ) {
      setShowAlert(true);
      setPath(pathUrl);
    } else if (
      currentUrl.startsWith("/app/protocol/rooms") &&
      localStorageValue != null &&
      localStorageValue.protocol_type !== ""
    ) {
      setShowAlert(true);
      setPath(pathUrl);
    } else if (
      currentUrl.startsWith("/app/kaution") &&
      kautionstate != null &&
      kautionstate.kaution_calculator != null &&
      kautionstate.kaution_calculator.deposit_amount != null &&
      kautionstate.kaution_calculator.deposit_amount !== ""
    ) {
      setShowAlert(true);
      setPath(pathUrl);
    } else {
      setShowAlert(false);
      localStorage.removeItem("globalState");
      localStorage.removeItem("visitedSteps");
      localStorage.removeItem("Protocol");
      localStorage.removeItem("ObjectID");
      localStorage.removeItem("SuggestedTariffgas");
      localStorage.removeItem("SuggestedTariff");
      localStorage.removeItem("SuggestedTariffstrom");
      localStorage.removeItem("showCounters");
      localStorage.removeItem("Kaution");
      localStorage.removeItem("filters");

      setPath(pathUrl);
      history.push(`${pathUrl}`);
    }
  };

  // Get Active Item
  /* 	const getActiveItem = () => {
    let matchingMenuItem = null;
    this.routePaths.map((item, index) => {
      if (item.type !== "groupHeader") {
        if (item.path === location.pathname) {
          matchingMenuItem = item;
        }
      }
      return null;
    });
    return matchingMenuItem;
  } */

  /* 	const handleNavMenuClick = (item) => {
    const { thirdMenuOpen } = this.state;
    setThirdMenuOpen(!thirdMenuOpen)
    setActiveItem(item)
  	
  } */
  const handleLogout = () => {
    try {
      localStorage.removeItem("currentuser");
      localStorage.removeItem("switchedcustomer");
      localStorage.removeItem("protocol");
      localStorage.removeItem("globalState");
      localStorage.removeItem("activeSteps");
      localStorage.removeItem("visitedSteps");
      localStorage.removeItem("VISITEDcounter");
      localStorage.removeItem("filters");
      window.location.href = "/signin";
    } catch (error) {
      console.log("Failed to log out", error);
    }
  };
  return (
    <>
      {menu.child_routes !== null && menu.fullPageMenu === false && (
        <>
          {menu.menu_title == "App Notifikation" ? (
            (user?.role === "admin" || user?.role === "tester") && (
              <>
                <li>
                  <ListItem
                    disableRipple
                    button
                    component="div"
                    onClick={() => toggleMenu()}
                    className={clsx(
                      classes.textWhite,
                      classes.ListItem,
                      {
                        "active-menu": menu.isMenuOpen,
                        [classes.menuOpen]: menu.isMenuOpen,
                      },
                      "list-item"
                    )}
                  >
                    <div className={classes.w100}>
                      <div className={classes.flexCenter}>
                        <Box component="span" className={classes.iconWrap}>
                          {menu.image && (
                            <img
                              src={require(`assets/Icons/${menu.image}`)}
                              alt="Icon"
                              className={`${
                                menu.style && menu.style
                              } icon-side`}
                            />
                          )}
                          {menu.icon && menu.icon}
                        </Box>
                        <ListItemText
                          primary={menu.menu_title}
                          style={{ paddingLeft: 12 }}
                        />
                        {menu.isMenuOpen ? (
                          <Icon style={{ fontSize: 20, width: 15 }}>
                            arrow_drop_up
                          </Icon>
                        ) : (
                          <Icon style={{ fontSize: 20, width: 15 }}>
                            arrow_drop_down
                          </Icon>
                        )}
                      </div>
                    </div>
                  </ListItem>
                  <Collapse
                    in={menu.isMenuOpen}
                    timeout="auto"
                    unmountOnExit
                    className={clsx(classes.textWhite, {
                      [classes.menuOpen]: menu.isMenuOpen,
                    })}
                  >
                    <List component="ul" className="sub-menu">
                      {menu.child_routes.map((subMenu, index) => {
                        if (
                          subMenu.third_child_routes !== null &&
                          subMenu.fullPageMenu === false
                        ) {
                          return (
                            <li key={index}>
                              <ListItem
                                disableRipple
                                button
                                component="div"
                                onClick={() => {
                                  toggleThirdMenu(index);
                                  setActiveIndex(`S${index}`);
                                }}
                                className={clsx(
                                  classes.textWhite,
                                  {
                                    [classes.menuOpen]: subMenu.isMenuOpen,
                                  },
                                  "list-item"
                                )}
                              >
                                <div className={classes.w100}>
                                  <div className={classes.flexCenter}>
                                    <ListItemText
                                      primary={subMenu.menu_title}
                                      style={{ paddingLeft: 20 }}
                                    />
                                    {subMenu.isMenuOpen ? (
                                      <Icon style={{ fontSize: 20, width: 15 }}>
                                        arrow_drop_up
                                      </Icon>
                                    ) : (
                                      <Icon style={{ fontSize: 20, width: 15 }}>
                                        arrow_drop_down
                                      </Icon>
                                    )}
                                  </div>
                                </div>
                              </ListItem>
                              <Collapse
                                in={subMenu.isMenuOpen}
                                timeout="auto"
                                unmountOnExit
                                className={clsx(classes.textWhite, {
                                  [classes.menuOpen]: subMenu.isMenuOpen,
                                })}
                              >
                                <List component="ul" className="sub-menu">
                                  {subMenu.third_child_routes.map(
                                    (thirdMenu, fourthindex) => (
                                      <ListItem
                                        key={fourthindex}
                                        disableRipple
                                        button
                                        component="li"
                                        onClick={() =>
                                          handleLinkOn(
                                            thirdMenu.path,
                                            fourthindex,
                                            "thirdchildroutes"
                                          )
                                        }
                                        className={clsx(
                                          classes.textWhite,
                                          activeThirdIndex ===
                                            `T${fourthindex}` && "clicked",
                                          {
                                            [classes.menuOpen]:
                                              subMenu.isMenuOpen,
                                          },
                                          "list-item"
                                        )}
                                      >
                                        <a
                                          className={`link ${classes.flexCenter}`}
                                        >
                                          {thirdMenu.menu_title}
                                        </a>
                                      </ListItem>
                                    )
                                  )}
                                </List>
                              </Collapse>
                            </li>
                          );
                        }

                        return (
                          <ListItem
                            key={index}
                            disableRipple
                            button
                            component="li"
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            onClick={() =>
                              handleLinkOn(
                                subMenu.path === "/app/user"
                                  ? `${subMenu.path}/${userService?.getId()}`
                                  : subMenu.path === "/app/company" ||
                                    subMenu.path === "/app/smtp"
                                  ? `${subMenu.path}/${user?.company_id}`
                                  : subMenu.path,
                                index,
                                "secondchildroutes"
                              )
                            }
                            className={clsx(
                              classes.childList,
                              activeIndex === `S${index}` &&
                                classes.childlinkActive
                            )}
                          >
                            <a className={`link ${classes.flexCenter}`}>
                              {subMenu.menu_title}
                            </a>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </li>
                {menu.dashed && <div className="dashed-line"></div>}
                {showAlert && (
                  <Dialog
                    open={showAlert}
                    onClose={handleClose}
                    fullWidth
                    maxWidth="sm"
                  >
                    <DialogContent>
                      <Box textAlign="center">
                        <img src={warningIcon} alt="warning icon" />
                        <h4
                          style={{
                            fontSize: "1.875em",
                            fontWeight: "600",
                            textAlign: "center",
                            color: "#888",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                          }}
                        >
                          ACHTUNG
                        </h4>
                      </Box>
                      <div
                        style={{
                          fontSize: "1.5em",
                          fontWeight: "300",
                          lineHeight: "normal",
                          textAlign: "center",
                          color: "#888",
                          marginTop: "1rem",
                          marginBottom: "1rem",
                        }}
                      >
                        Sie sind im Begriff das aktuelle Formular zu verlassen.
                        Wie möchten Sie vorgehen?
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <button
                        onClick={handleCancel}
                        className="button-cancel w-full custom-icon d-flex align-items-center justify-content-center h-full"
                      >
                        Ohne speichern verlassen
                      </button>
                      <button
                        onClick={handleThirdButton}
                        className="w-full custom-icon d-flex align-items-stretch button-cancelandsave"
                      >
                        Löschen und Schließen
                      </button>
                      <button
                        onClick={handleConfirm}
                        className="button-save w-full custom-icon d-flex align-items-stretch"
                      >
                        Speichern und Schließen
                      </button>
                    </DialogActions>
                    <button
                      onClick={handleClose}
                      className="close-button"
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        border: "none",
                        background: "none",
                      }}
                    >
                      <HighlightOffIcon style={{ fontSize: 25 }} />
                    </button>
                  </Dialog>
                )}
              </>
            )
          ) : (
            <></>
          )}
          {menu.menu_title == "Auftragsübersicht" && (
            <>
              <li>
                <ListItem
                  disableRipple
                  button
                  component="div"
                  onClick={() => toggleMenu()}
                  className={clsx(
                    classes.textWhite,
                    classes.ListItem,
                    {
                      "active-menu": menu.isMenuOpen,
                      [classes.menuOpen]: menu.isMenuOpen,
                    },
                    "list-item"
                  )}
                >
                  <div className={classes.w100}>
                    <div className={classes.flexCenter}>
                      <Box component="span" className={classes.iconWrap}>
                        {menu.image && (
                          <img
                            src={require(`assets/Icons/${menu.image}`)}
                            alt="Icon"
                            className={`${menu.style && menu.style} icon-side`}
                          />
                        )}
                        {menu.icon && menu.icon}
                      </Box>
                      <ListItemText
                        primary={menu.menu_title}
                        style={{ paddingLeft: 20 }}
                      />
                      {menu.isMenuOpen ? (
                        <Icon style={{ fontSize: 20, width: 15 }}>
                          arrow_drop_up
                        </Icon>
                      ) : (
                        <Icon style={{ fontSize: 20, width: 15 }}>
                          arrow_drop_down
                        </Icon>
                      )}
                    </div>
                  </div>
                </ListItem>
                <Collapse
                  in={menu.isMenuOpen}
                  timeout="auto"
                  unmountOnExit
                  className={clsx(classes.textWhite, {
                    [classes.menuOpen]: menu.isMenuOpen,
                  })}
                >
                  <List component="ul" className="sub-menu">
                    {menu.child_routes.map((subMenu, index) => {
                      if (subMenu.path == "/app/archive/list") {
                        if (
                          user?.role === "admin" ||
                          user?.role === "tester" ||
                          company.company_account_type == "premium"
                        ) {
                          return (
                            <ListItem
                              key={index}
                              disableRipple
                              button
                              component="li"
                              style={{ paddingTop: 0, paddingBottom: 0 }}
                              onClick={() =>
                                handleLinkOn(
                                  subMenu.path,
                                  index,
                                  "secondchildroutes"
                                )
                              }
                              className={clsx(
                                classes.childList,
                                activeIndex === `S${index}` &&
                                  classes.childlinkActive
                              )}
                            >
                              <a className={`link ${classes.flexCenter}`}>
                                {subMenu.menu_title}
                              </a>
                            </ListItem>
                          );
                        } else if (company.company_account_type == "basic") {
                          return (
                            <div
                              className="TolTip-Reference"
                              onMouseEnter={() =>
                                handleMouseEnter(subMenu.menu_title)
                              }
                              onMouseLeave={handleMouseLeave}
                            >
                              <ListItem
                                key={index}
                                disableRipple
                                button
                                component="li"
                                style={{
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                  opacity: 0.5,
                                  pointerEvents: "auto",
                                  cursor: "not-allowed",
                                }}
                                className={clsx(
                                  classes.childList,
                                  activeIndex === `S${index}` &&
                                    classes.childlinkActive
                                )}
                              >
                                <a
                                  className={`link ${classes.flexCenter} `}
                                  style={{ justifyContent: "space-between" }}
                                >
                                  {subMenu.menu_title}
                                  <img
                                    src={PremiumGold}
                                    className="icon-premuim sub-menu"
                                    alt="Premium Account Icon"
                                  />
                                </a>
                              </ListItem>
                              {/* Custom Tooltip */}
                              {tooltipVisible === subMenu.menu_title && (
                                <div
                                  className="ToolTip-Div"
                                  onMouseEnter={handleTooltipEnter}
                                  onMouseLeave={handleTooltipLeave}
                                >
                                  <span className="TooltipText">
                                    Diese Premium-Funktion steht unseren
                                    Energie-Rahmenvertragskunden zur Verfügung.
                                    Weitere Infos & Kontakt unter{" "}
                                    <a
                                      href="https://www.x-cite.de"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{
                                        textDecoration: "underline",
                                        color: "inherit",
                                      }}
                                    >
                                      X-Cite
                                    </a>
                                  </span>
                                </div>
                              )}
                            </div>
                          );
                        } else {
                          return <></>;
                        }
                      }
                      return (
                        <ListItem
                          key={index}
                          disableRipple
                          button
                          component="li"
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          onClick={() =>
                            handleLinkOn(
                              subMenu.path,
                              index,
                              "secondchildroutes"
                            )
                          }
                          className={clsx(
                            classes.childList,
                            activeIndex === `S${index}` &&
                              classes.childlinkActive
                          )}
                        >
                          <a className={`link ${classes.flexCenter}`}>
                            {subMenu.menu_title}
                          </a>
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </li>
              {menu.dashed && <div className="dashed-line"></div>}
              {showAlert && (
                <Dialog
                  open={showAlert}
                  onClose={handleClose}
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogContent>
                    <Box textAlign="center">
                      <img src={warningIcon} alt="warning icon" />
                      <h4
                        style={{
                          fontSize: "1.875em",
                          fontWeight: "600",
                          textAlign: "center",
                          color: "#888",
                          marginTop: "1rem",
                          marginBottom: "1rem",
                        }}
                      >
                        ACHTUNG
                      </h4>
                    </Box>
                    <div
                      style={{
                        fontSize: "1.5em",
                        fontWeight: "300",
                        lineHeight: "normal",
                        textAlign: "center",
                        color: "#888",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                      }}
                    >
                      Sie sind im Begriff das aktuelle Formular zu verlassen.
                      Wie möchten Sie vorgehen?
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <button
                      onClick={handleCancel}
                      className="button-cancel w-full custom-icon d-flex align-items-center justify-content-center h-full"
                    >
                      Ohne speichern verlassen
                    </button>
                    <button
                      onClick={handleThirdButton}
                      className="w-full custom-icon d-flex align-items-stretch button-cancelandsave"
                    >
                      Löschen und Schließen
                    </button>
                    <button
                      onClick={handleConfirm}
                      className="button-save w-full custom-icon d-flex align-items-stretch"
                    >
                      Speichern und Schließen
                    </button>
                  </DialogActions>
                  <button
                    onClick={handleClose}
                    className="close-button"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      border: "none",
                      background: "none",
                    }}
                  >
                    <HighlightOffIcon style={{ fontSize: 25 }} />
                  </button>
                </Dialog>
              )}
            </>
          )}
          {menu.menu_title == "Support" && (
            <>
              {company.company_account_type == "premium" ? (
                <>
                  <li>
                    <ListItem
                      disableRipple
                      button
                      component="div"
                      onClick={() => toggleMenu()}
                      className={clsx(
                        classes.textWhite,
                        classes.ListItem,
                        {
                          "active-menu": menu.isMenuOpen,
                          [classes.menuOpen]: menu.isMenuOpen,
                        },
                        "list-item"
                      )}
                    >
                      <div className={classes.w100}>
                        <div className={classes.flexCenter}>
                          <Box component="span" className={classes.iconWrap}>
                            {menu.image && (
                              <img
                                src={require(`assets/Icons/${menu.image}`)}
                                alt="Icon"
                                className={`${
                                  menu.style && menu.style
                                } icon-side`}
                              />
                            )}
                            {menu.icon && menu.icon}
                          </Box>
                          <ListItemText
                            primary={menu.menu_title}
                            style={{ paddingLeft: 20 }}
                          />
                          {menu.isMenuOpen ? (
                            <Icon style={{ fontSize: 20, width: 15 }}>
                              arrow_drop_up
                            </Icon>
                          ) : (
                            <Icon style={{ fontSize: 20, width: 15 }}>
                              arrow_drop_down
                            </Icon>
                          )}
                        </div>
                      </div>
                    </ListItem>
                    <Collapse
                      in={menu.isMenuOpen}
                      timeout="auto"
                      unmountOnExit
                      className={clsx(classes.textWhite, {
                        [classes.menuOpen]: menu.isMenuOpen,
                      })}
                    >
                      <List component="ul" className="sub-menu">
                        {menu.child_routes.map((subMenu, index) => {
                          if (subMenu.path == "/app/support/switch_user") {
                            if (
                              user?.role === "admin" ||
                              user?.role === "tester"
                            ) {
                              return (
                                <ListItem
                                  key={index}
                                  disableRipple
                                  button
                                  component="li"
                                  style={{ paddingTop: 0, paddingBottom: 0 }}
                                  onClick={() =>
                                    handleLinkOn(
                                      subMenu.path === "/app/user"
                                        ? `${
                                            subMenu.path
                                          }/${userService?.getId()}`
                                        : subMenu.path === "/app/company" ||
                                          subMenu.path === "/app/smtp"
                                        ? `${subMenu.path}/${user?.company_id}`
                                        : subMenu.path,
                                      index,
                                      "secondchildroutes"
                                    )
                                  }
                                  className={clsx(
                                    classes.childList,
                                    activeIndex === `S${index}` &&
                                      classes.childlinkActive
                                  )}
                                >
                                  <a className={`link ${classes.flexCenter}`}>
                                    {subMenu.menu_title}
                                  </a>
                                </ListItem>
                              );
                            } else {
                              return <></>;
                            }
                          }
                          return (
                            <ListItem
                              key={index}
                              disableRipple
                              button
                              component="li"
                              style={{ paddingTop: 0, paddingBottom: 0 }}
                              onClick={() =>
                                handleLinkOn(
                                  subMenu.path === "/app/user"
                                    ? `${subMenu.path}/${userService?.getId()}`
                                    : subMenu.path === "/app/company" ||
                                      subMenu.path === "/app/smtp"
                                    ? `${subMenu.path}/${user?.company_id}`
                                    : subMenu.path,
                                  index,
                                  "secondchildroutes"
                                )
                              }
                              className={clsx(
                                classes.childList,
                                activeIndex === `S${index}` &&
                                  classes.childlinkActive
                              )}
                            >
                              <a className={`link ${classes.flexCenter}`}>
                                {subMenu.menu_title}
                              </a>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Collapse>
                  </li>
                  {menu.dashed && <div className="dashed-line"></div>}
                  {showAlert && (
                    <Dialog
                      open={showAlert}
                      onClose={handleClose}
                      fullWidth
                      maxWidth="sm"
                    >
                      <DialogContent>
                        <Box textAlign="center">
                          <img src={warningIcon} alt="warning icon" />
                          <h4
                            style={{
                              fontSize: "1.875em",
                              fontWeight: "600",
                              textAlign: "center",
                              color: "#888",
                              marginTop: "1rem",
                              marginBottom: "1rem",
                            }}
                          >
                            ACHTUNG
                          </h4>
                        </Box>
                        <div
                          style={{
                            fontSize: "1.5em",
                            fontWeight: "300",
                            lineHeight: "normal",
                            textAlign: "center",
                            color: "#888",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                          }}
                        >
                          Sie sind im Begriff das aktuelle Formular zu
                          verlassen. Wie möchten Sie vorgehen?
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <button
                          onClick={handleCancel}
                          className="button-cancel w-full custom-icon d-flex align-items-center justify-content-center h-full"
                        >
                          Ohne speichern verlassen
                        </button>
                        <button
                          onClick={handleThirdButton}
                          className="w-full custom-icon d-flex align-items-stretch button-cancelandsave"
                        >
                          Löschen und Schließen
                        </button>
                        <button
                          onClick={handleConfirm}
                          className="button-save w-full custom-icon d-flex align-items-stretch"
                        >
                          Speichern und Schließen
                        </button>
                      </DialogActions>
                      <button
                        onClick={handleClose}
                        className="close-button"
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          border: "none",
                          background: "none",
                        }}
                      >
                        <HighlightOffIcon style={{ fontSize: 25 }} />
                      </button>
                    </Dialog>
                  )}
                </>
              ) : company?.company_account_type === "basic" ? (
                <div
                  className="TolTip-Reference"
                  onMouseEnter={() => handleMouseEnter(menu.menu_title)}
                  onMouseLeave={handleMouseLeave}
                >
                  <ListItem
                    disableRipple
                    button
                    component="li"
                    className={clsx(
                      classes.textWhite,
                      classes.textLink,
                      classes.ListItem,
                      {
                        [classes.linkActive]: menu.isMenuOpen,
                      },
                      "list-item"
                    )}
                    style={{
                      paddingTop: 0,
                      paddingBottom: 0,
                      opacity: 0.5,
                      pointerEvents: "auto",
                      cursor: "not-allowed",
                    }}
                  >
                    <div className={classes.w100}>
                      <a>
                        <Box className={classes.flexCenter}>
                          <Box component="span" className={classes.iconWrap}>
                            {menu.image && (
                              <img
                                src={require(`assets/Icons/${menu.image}`)}
                                alt="Icon"
                                className={`${
                                  menu.style && menu.style
                                } icon-side`}
                              />
                            )}
                          </Box>
                          <ListItemText
                            primary={menu.menu_title}
                            style={{ paddingLeft: 20 }}
                          />
                          {menu.icon && menu.icon}
                          {menu.image && (
                            <img
                              src={PremiumGold}
                              className="icon-premuim"
                              alt="Premium Account Icon"
                            />
                          )}
                          {menu.icon && menu.icon}
                        </Box>
                      </a>
                    </div>
                  </ListItem>
                  {/* Custom Tooltip */}
                  {tooltipVisible === menu.menu_title && (
                    <div
                      className="ToolTip-Div"
                      onMouseEnter={handleTooltipEnter}
                      onMouseLeave={handleTooltipLeave}
                    >
                      <span className="TooltipText">
                        Diese Premium-Funktion steht unseren
                        Energie-Rahmenvertragskunden zur Verfügung. Weitere
                        Infos & Kontakt unter{" "}
                        <a
                          href="https://www.x-cite.de"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: "underline",
                            color: "inherit",
                          }}
                        >
                          X-Cite
                        </a>
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}
            </>
          )}
          {menu.menu_title !== "App Notifikation" &&
            menu.menu_title !== "Auftragsübersicht" &&
            menu.menu_title !== "Support" && (
              <>
                <li>
                  <ListItem
                    disableRipple
                    button
                    component="div"
                    onClick={() => toggleMenu()}
                    className={clsx(
                      classes.textWhite,
                      classes.ListItem,
                      {
                        "active-menu": menu.isMenuOpen,
                        [classes.menuOpen]: menu.isMenuOpen,
                      },
                      "list-item"
                    )}
                  >
                    <div className={classes.w100}>
                      <div className={classes.flexCenter}>
                        <Box component="span" className={classes.iconWrap}>
                          {menu.image && (
                            <img
                              src={require(`assets/Icons/${menu.image}`)}
                              alt="Icon"
                              className={`${
                                menu.style && menu.style
                              } icon-side`}
                            />
                          )}
                          {menu.icon && menu.icon}
                        </Box>
                        <ListItemText
                          primary={menu.menu_title}
                          style={{ paddingLeft: 20 }}
                        />
                        {menu.isMenuOpen ? (
                          <Icon style={{ fontSize: 20, width: 15 }}>
                            arrow_drop_up
                          </Icon>
                        ) : (
                          <Icon style={{ fontSize: 20, width: 15 }}>
                            arrow_drop_down
                          </Icon>
                        )}
                      </div>
                    </div>
                  </ListItem>
                  <Collapse
                    in={menu.isMenuOpen}
                    timeout="auto"
                    unmountOnExit
                    className={clsx(classes.textWhite, {
                      [classes.menuOpen]: menu.isMenuOpen,
                    })}
                  >
                    <List component="ul" className="sub-menu">
                      {menu.child_routes.map((subMenu, index) => {
                        if (subMenu.path == "/app/administration/tickets") {
                          if (user?.role === "admin") {
                            return (
                              <ListItem
                                key={index}
                                disableRipple
                                button
                                component="li"
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                onClick={() =>
                                  handleLinkOn(
                                    subMenu.path === "/app/user"
                                      ? `${
                                          subMenu.path
                                        }/${userService?.getId()}`
                                      : subMenu.path === "/app/company" ||
                                        subMenu.path === "/app/smtp"
                                      ? `${subMenu.path}/${user?.company_id}`
                                      : subMenu.path,
                                    index,
                                    "secondchildroutes"
                                  )
                                }
                                className={clsx(
                                  classes.childList,
                                  activeIndex === `S${index}` &&
                                    classes.childlinkActive
                                )}
                              >
                                <a className={`link ${classes.flexCenter}`}>
                                  {subMenu.menu_title}
                                </a>
                              </ListItem>
                            );
                          } else {
                            return <></>;
                          }
                        }
                        if (subMenu.menu_title == "Unternehmen") {
                          if (
                            user?.role === "makler" ||
                            user?.role === "admin" ||
                            user?.role === "tester"
                          ) {
                            return (
                              <ListItem
                                key={index}
                                disableRipple
                                button
                                component="li"
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                onClick={() =>
                                  handleLinkOn(
                                    subMenu.path === "/app/user"
                                      ? `${
                                          subMenu.path
                                        }/${userService?.getId()}`
                                      : subMenu.path === "/app/company" ||
                                        subMenu.path === "/app/smtp"
                                      ? `${subMenu.path}/${user?.company_id}`
                                      : subMenu.path,
                                    index,
                                    "secondchildroutes"
                                  )
                                }
                                className={clsx(
                                  classes.childList,
                                  activeIndex === `S${index}` &&
                                    classes.childlinkActive
                                )}
                              >
                                <a className={`link ${classes.flexCenter}`}>
                                  {subMenu.menu_title}
                                </a>
                              </ListItem>
                            );
                          } else {
                            return <></>;
                          }
                        }
                        if (
                          subMenu.menu_title == "Produktgruppen" ||
                          subMenu.menu_title == "Emaileinstellungen" ||
                          subMenu.menu_title == "Rechtevergabe" ||
                          subMenu.path == "/app/realestate/import" ||
                          subMenu.path == "/app/realestate/export"
                        ) {
                          if (
                            (user?.role === "admin" ||
                              user?.role === "tester" ||
                              user?.role === "makler") &&
                            (company?.company_account_type === "premium" ||
                              company?.company_account_type === "power")
                          ) {
                            return (
                              <ListItem
                                key={index}
                                disableRipple
                                button
                                component="li"
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                onClick={() =>
                                  handleLinkOn(
                                    subMenu.path === "/app/user"
                                      ? `${
                                          subMenu.path
                                        }/${userService?.getId()}`
                                      : subMenu.path === "/app/company" ||
                                        subMenu.path === "/app/smtp"
                                      ? `${subMenu.path}/${user?.company_id}`
                                      : subMenu.path,
                                    index,
                                    "secondchildroutes"
                                  )
                                }
                                className={clsx(
                                  classes.childList,
                                  activeIndex === `S${index}` &&
                                    classes.childlinkActive
                                )}
                              >
                                <a className={`link ${classes.flexCenter}`}>
                                  {subMenu.menu_title}
                                </a>
                              </ListItem>
                            );
                          } else if (company.company_account_type == "basic") {
                            return (
                              <div
                                className="TolTip-Reference"
                                onMouseEnter={() =>
                                  handleMouseEnter(subMenu.menu_title)
                                }
                                onMouseLeave={handleMouseLeave}
                              >
                                <ListItem
                                  key={index}
                                  disableRipple
                                  button
                                  component="li"
                                  style={{
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    opacity: 0.5,
                                    pointerEvents: "auto",
                                    cursor: "not-allowed",
                                  }}
                                  className={clsx(
                                    classes.childList,
                                    activeIndex === `S${index}` &&
                                      classes.childlinkActive
                                  )}
                                >
                                  <a
                                    className={`link ${classes.flexCenter}`}
                                    style={{ justifyContent: "space-between" }}
                                  >
                                    {subMenu.menu_title}
                                    <img
                                      src={PremiumGold}
                                      className="icon-premuim sub-menu"
                                      alt="Premium Account Icon"
                                    />
                                  </a>
                                </ListItem>
                                {/* Custom Tooltip */}
                                {tooltipVisible === subMenu.menu_title && (
                                  <div
                                    className="ToolTip-Div"
                                    onMouseEnter={handleTooltipEnter}
                                    onMouseLeave={handleTooltipLeave}
                                  >
                                    <span className="TooltipText">
                                      Diese Premium-Funktion steht unseren
                                      Energie-Rahmenvertragskunden zur
                                      Verfügung. Weitere Infos & Kontakt unter{" "}
                                      <a
                                        href="https://www.x-cite.de"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          textDecoration: "underline",
                                          color: "inherit",
                                        }}
                                      >
                                        X-Cite
                                      </a>
                                    </span>
                                  </div>
                                )}
                              </div>
                            );
                          } else {
                            return <></>;
                          }
                        }
                        if (
                          subMenu.third_child_routes !== null &&
                          subMenu.fullPageMenu === false
                        ) {
                          if (subMenu.menu_title == "Produkt Importieren") {
                            if (user?.role == "admin")
                              return (
                                <li key={index}>
                                  <ListItem
                                    disableRipple
                                    button
                                    component="div"
                                    onClick={() => {
                                      toggleThirdMenu(index);
                                      setActiveIndex(`S${index}`);
                                    }}
                                    className={clsx(
                                      classes.textWhite,
                                      classes.childList,
                                      {
                                        [classes.SubmenuOpen]:
                                          subMenu.isMenuOpen,
                                      },
                                      "list-item",
                                      activeIndex === `S${index}` &&
                                        classes.childlinkActive
                                    )}
                                  >
                                    <div
                                      className={clsx(
                                        classes.w100,
                                        "child-list"
                                      )}
                                    >
                                      <div
                                        className={clsx(
                                          classes.flexCenter,
                                          classes.childList,
                                          "second-div"
                                        )}
                                      >
                                        <ListItemText
                                          primary={subMenu.menu_title}
                                        />
                                        {subMenu.isMenuOpen ? (
                                          <Icon
                                            style={{ fontSize: 20, width: 15 }}
                                          >
                                            arrow_drop_up
                                          </Icon>
                                        ) : (
                                          <Icon
                                            style={{ fontSize: 20, width: 15 }}
                                          >
                                            arrow_drop_down
                                          </Icon>
                                        )}
                                      </div>
                                    </div>
                                  </ListItem>
                                  <Collapse
                                    in={subMenu.isMenuOpen}
                                    timeout="auto"
                                    unmountOnExit
                                    className={clsx(classes.textWhite, {
                                      //[classes.SubmenuOpen]: subMenu.isMenuOpen,
                                    })}
                                  >
                                    <List component="ul" className="sub-menu">
                                      {subMenu.third_child_routes.map(
                                        (thirdMenu, fourthindex) => (
                                          <ListItem
                                            key={fourthindex}
                                            disableRipple
                                            button
                                            component="li"
                                            onClick={() =>
                                              handleLinkOn(
                                                thirdMenu.path,
                                                fourthindex,
                                                "thirdchildroutes"
                                              )
                                            }
                                            className={clsx(
                                              classes.textWhite,
                                              {
                                                [classes.SubmenuOpen]:
                                                  subMenu.isMenuOpen,
                                              },
                                              "list-item",
                                              activeThirdIndex ===
                                                `T${fourthindex}` && "clicked"
                                            )}
                                          >
                                            <a
                                              className={`link ${classes.flexCenter}`}
                                            >
                                              {thirdMenu.menu_title}
                                            </a>
                                          </ListItem>
                                        )
                                      )}
                                    </List>
                                  </Collapse>
                                </li>
                              );
                            else return <></>;
                          }
                          if (subMenu.menu_title == "Mitarbeiter") {
                            if (
                              (user?.role === "admin" ||
                                user?.role === "tester" ||
                                user?.role === "makler") &&
                              (company?.company_account_type === "premium" ||
                                company?.company_account_type === "power")
                            ) {
                              return (
                                <li key={index}>
                                  <ListItem
                                    disableRipple
                                    button
                                    component="div"
                                    onClick={() => {
                                      toggleThirdMenu(index);
                                      setActiveIndex(`S${index}`);
                                    }}
                                    className={clsx(
                                      classes.textWhite,
                                      {
                                        [classes.SubmenuOpen]:
                                          subMenu.isMenuOpen,
                                      },
                                      "list-item"
                                    )}
                                  >
                                    <div
                                      className={clsx(
                                        classes.w100,
                                        "child-list"
                                      )}
                                    >
                                      <div
                                        className={clsx(
                                          classes.flexCenter,
                                          "second-div"
                                        )}
                                      >
                                        <ListItemText
                                          primary={subMenu.menu_title}
                                        />
                                        {subMenu.isMenuOpen ? (
                                          <Icon
                                            style={{ fontSize: 20, width: 15 }}
                                          >
                                            arrow_drop_up
                                          </Icon>
                                        ) : (
                                          <Icon
                                            style={{ fontSize: 20, width: 15 }}
                                          >
                                            arrow_drop_down
                                          </Icon>
                                        )}
                                      </div>
                                    </div>
                                  </ListItem>
                                  <Collapse
                                    in={subMenu.isMenuOpen}
                                    timeout="auto"
                                    unmountOnExit
                                    className={clsx(classes.textWhite, {
                                      // [classes.SubmenuOpen]: subMenu.isMenuOpen,
                                    })}
                                  >
                                    <List component="ul" className="sub-menu">
                                      {subMenu.third_child_routes.map(
                                        (thirdMenu, fourthindex) => (
                                          <ListItem
                                            key={fourthindex}
                                            disableRipple
                                            button
                                            component="li"
                                            onClick={() =>
                                              handleLinkOn(
                                                thirdMenu.path,
                                                fourthindex,
                                                "thirdchildroutes"
                                              )
                                            }
                                            className={clsx(
                                              classes.textWhite,
                                              {
                                                [classes.SubmenuOpen]:
                                                  subMenu.isMenuOpen,
                                              },
                                              "list-item",
                                              activeThirdIndex ===
                                                `T${fourthindex}` && "clicked"
                                            )}
                                          >
                                            <a
                                              className={`link ${classes.flexCenter}`}
                                            >
                                              {thirdMenu.menu_title}
                                            </a>
                                          </ListItem>
                                        )
                                      )}
                                    </List>
                                  </Collapse>
                                </li>
                              );
                            } else if (
                              company?.company_account_type === "basic"
                            ) {
                              return (
                                <div
                                  className="TolTip-Reference"
                                  onMouseEnter={() =>
                                    handleMouseEnter(subMenu.menu_title)
                                  }
                                  onMouseLeave={handleMouseLeave}
                                >
                                  <ListItem
                                    key={index}
                                    disableRipple
                                    button
                                    component="li"
                                    style={{
                                      paddingTop: 0,
                                      paddingBottom: 0,
                                      opacity: 0.5,
                                      pointerEvents: "auto",
                                      cursor: "not-allowed",
                                    }}
                                    className={clsx(
                                      classes.childList,
                                      activeIndex === `S${index}` &&
                                        classes.childlinkActive
                                    )}
                                  >
                                    <a
                                      className={`link ${classes.flexCenter}`}
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {subMenu.menu_title}
                                      <img
                                        src={PremiumGold}
                                        className="icon-premuim sub-menu"
                                        alt="Premium Account Icon"
                                      />
                                    </a>
                                  </ListItem>
                                  {/* Custom Tooltip */}
                                  {tooltipVisible === subMenu.menu_title && (
                                    <div
                                      className="ToolTip-Div"
                                      onMouseEnter={handleTooltipEnter}
                                      onMouseLeave={handleTooltipLeave}
                                    >
                                      <span className="TooltipText">
                                        Diese Premium-Funktion steht unseren
                                        Energie-Rahmenvertragskunden zur
                                        Verfügung. Weitere Infos & Kontakt unter{" "}
                                        <a
                                          href="https://www.x-cite.de"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            textDecoration: "underline",
                                            color: "inherit",
                                          }}
                                        >
                                          X-Cite
                                        </a>
                                      </span>
                                    </div>
                                  )}
                                </div>
                              );
                            } else return <></>;
                          }
                          return (
                            <li key={index}>
                              <ListItem
                                disableRipple
                                button
                                component="div"
                                onClick={() => toggleThirdMenu(index)}
                                className={clsx(
                                  classes.textWhite,
                                  classes.ListItem,
                                  {
                                    [classes.menuOpen]: subMenu.isMenuOpen,
                                  },
                                  "list-item"
                                )}
                              >
                                <div className={classes.w100}>
                                  <div className={classes.flexCenter}>
                                    <ListItemText
                                      primary={subMenu.menu_title}
                                      style={{ paddingLeft: 20 }}
                                    />
                                    {subMenu.isMenuOpen ? (
                                      <Icon style={{ fontSize: 20, width: 15 }}>
                                        arrow_drop_up
                                      </Icon>
                                    ) : (
                                      <Icon style={{ fontSize: 20, width: 15 }}>
                                        arrow_drop_down
                                      </Icon>
                                    )}
                                  </div>
                                </div>
                              </ListItem>
                              <Collapse
                                in={subMenu.isMenuOpen}
                                timeout="auto"
                                unmountOnExit
                                className={clsx(classes.textWhite, {
                                  [classes.menuOpen]: subMenu.isMenuOpen,
                                })}
                              >
                                <List component="ul" className="sub-menu">
                                  {subMenu.third_child_routes.map(
                                    (thirdMenu, fourthindex) => (
                                      <ListItem
                                        key={fourthindex}
                                        disableRipple
                                        button
                                        component="li"
                                        onClick={() =>
                                          handleLinkOn(
                                            thirdMenu.path,
                                            fourthindex,
                                            "thirdchildroutes"
                                          )
                                        }
                                        className={clsx(
                                          classes.textWhite,
                                          classes.ListItem,
                                          {
                                            [classes.menuOpen]:
                                              subMenu.isMenuOpen,
                                          },
                                          "list-item",
                                          activeThirdIndex ===
                                            `T${fourthindex}` && "clicked"
                                        )}
                                      >
                                        <a
                                          className={`link ${classes.flexCenter}`}
                                        >
                                          {thirdMenu.menu_title}
                                        </a>
                                      </ListItem>
                                    )
                                  )}
                                </List>
                              </Collapse>
                            </li>
                          );
                        }

                        return (
                          <ListItem
                            key={index}
                            disableRipple
                            button
                            component="li"
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            onClick={() =>
                              handleLinkOn(
                                subMenu.path === "/app/user"
                                  ? `${subMenu.path}/${userService?.getId()}`
                                  : subMenu.path === "/app/company" ||
                                    subMenu.path === "/app/smtp"
                                  ? `${subMenu.path}/${user?.company_id}`
                                  : subMenu.path,
                                index,
                                "secondchildroutes"
                              )
                            }
                            className={clsx(
                              classes.childList,
                              activeIndex === `S${index}` &&
                                classes.childlinkActive
                            )}
                          >
                            <a className={`link ${classes.flexCenter}`}>
                              {subMenu.menu_title}
                            </a>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </li>
                {menu.dashed && <div className="dashed-line"></div>}
                {showAlert && (
                  <Dialog
                    open={showAlert}
                    onClose={handleClose}
                    fullWidth
                    maxWidth="sm"
                  >
                    <DialogContent>
                      <Box textAlign="center">
                        <img src={warningIcon} alt="warning icon" />
                        <h4
                          style={{
                            fontSize: "1.875em",
                            fontWeight: "600",
                            textAlign: "center",
                            color: "#888",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                          }}
                        >
                          ACHTUNG
                        </h4>
                      </Box>
                      <div
                        style={{
                          fontSize: "1.5em",
                          fontWeight: "300",
                          lineHeight: "normal",
                          textAlign: "center",
                          color: "#888",
                          marginTop: "1rem",
                          marginBottom: "1rem",
                        }}
                      >
                        Sie sind im Begriff das aktuelle Formular zu verlassen.
                        Wie möchten Sie vorgehen?
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <button
                        onClick={handleCancel}
                        className="button-cancel w-full custom-icon d-flex align-items-center justify-content-center h-full"
                      >
                        Ohne speichern verlassen
                      </button>
                      <button
                        onClick={handleThirdButton}
                        className="w-full custom-icon d-flex align-items-stretch button-cancelandsave"
                      >
                        Löschen und Schließen
                      </button>
                      <button
                        onClick={handleConfirm}
                        className="button-save w-full custom-icon d-flex align-items-stretch"
                      >
                        Speichern und Schließen
                      </button>
                    </DialogActions>
                    <button
                      onClick={handleClose}
                      className="close-button"
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        border: "none",
                        background: "none",
                      }}
                    >
                      <HighlightOffIcon style={{ fontSize: 25 }} />
                    </button>
                  </Dialog>
                )}
              </>
            )}
        </>
      )}
      {menu.child_routes === null && (
        <>
          {menu.path == "/app/protocol-pattern" ? (
            (user?.role === "admin" ||
              user?.role === "tester" ||
              user?.role === "makler") &&
            (company?.company_account_type === "premium" ||
              company?.company_account_type === "power") ? (
              <ListItem
                disableRipple
                button
                component="li"
                className={clsx(
                  classes.textWhite,
                  classes.textLink,
                  classes.ListItem,
                  {
                    [classes.linkActive]: menu.isMenuOpen,
                  },
                  "list-item"
                )}
                onClick={() => toggleAndCloseSidebar()}
              >
                <div
                  className={classes.w100}
                  onClick={
                    menu.menu_title === "Ausloggen"
                      ? handleLogout
                      : () => handleLinkOn(menu.path)
                  }
                >
                  <a>
                    <Box className={classes.flexCenter}>
                      <Box component="span" className={classes.iconWrap}>
                        {menu.image && (
                          <img
                            src={require(`assets/Icons/${menu.image}`)}
                            alt="Icon"
                            className={`${menu.style && menu.style} icon-side`}
                          />
                        )}
                        {menu.icon && menu.icon}
                      </Box>
                      <ListItemText
                        primary={menu.menu_title}
                        style={{ paddingLeft: 20 }}
                      />
                    </Box>
                  </a>
                </div>
              </ListItem>
            ) : company?.company_account_type === "basic" ? (
              <div
                className="TolTip-Reference"
                onMouseEnter={() => handleMouseEnter(menu.menu_title)}
                onMouseLeave={handleMouseLeave}
              >
                <ListItem
                  disableRipple
                  button
                  component="li"
                  className={clsx(
                    classes.textWhite,
                    classes.textLink,
                    classes.ListItem,
                    {
                      [classes.linkActive]: menu.isMenuOpen,
                    },
                    "list-item"
                  )}
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    opacity: 0.5,
                    pointerEvents: "auto",
                    cursor: "not-allowed",
                  }}
                >
                  <div className={classes.w100}>
                    <a>
                      <Box className={classes.flexCenter}>
                        <Box component="span" className={classes.iconWrap}>
                          {menu.image && (
                            <img
                              src={require(`assets/Icons/${menu.image}`)}
                              alt="Icon"
                              className={`${
                                menu.style && menu.style
                              } icon-side`}
                            />
                          )}
                        </Box>
                        <ListItemText
                          primary={menu.menu_title}
                          style={{ paddingLeft: 20 }}
                        />
                        {menu.icon && menu.icon}
                        {menu.image && (
                          <img
                            src={PremiumGold}
                            className="icon-premuim"
                            alt="Premium Account Icon"
                          />
                        )}
                        {menu.icon && menu.icon}
                      </Box>
                    </a>
                  </div>
                </ListItem>
                {/* Custom Tooltip */}
                {tooltipVisible === menu.menu_title && (
                  <div
                    className="ToolTip-Div"
                    onMouseEnter={handleTooltipEnter}
                    onMouseLeave={handleTooltipLeave}
                  >
                    <span className="TooltipText">
                      Diese Premium-Funktion steht unseren
                      Energie-Rahmenvertragskunden zur Verfügung. Weitere Infos
                      & Kontakt unter{" "}
                      <a
                        href="https://www.x-cite.de"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "underline",
                          color: "inherit",
                        }}
                      >
                        X-Cite
                      </a>
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
          {menu.path == "/app/promocode/overview" ? (
            (user?.role === "admin" ||
              (user?.role != "admin" &&
                company?.company_account_type === "basic")) && (
              <ListItem
                disableRipple
                button
                component="li"
                className={clsx(
                  classes.textWhite,
                  classes.textLink,
                  classes.ListItem,
                  {
                    [classes.linkActive]: menu.isMenuOpen,
                  },
                  "list-item"
                )}
                onClick={() => toggleAndCloseSidebar()}
              >
                <div
                  className={classes.w100}
                  onClick={
                    menu.menu_title === "Ausloggen"
                      ? handleLogout
                      : () => handleLinkOn(menu.path)
                  }
                >
                  <a>
                    <Box className={classes.flexCenter}>
                      <Box component="span" className={classes.iconWrap}>
                        {menu.image && (
                          <img
                            src={require(`assets/Icons/${menu.image}`)}
                            alt="Icon"
                            className={`${menu.style && menu.style} icon-side`}
                          />
                        )}
                        {menu.icon && menu.icon}
                      </Box>
                      <ListItemText
                        primary={menu.menu_title}
                        style={{ paddingLeft: 20 }}
                      />
                    </Box>
                    {menu.desc && (
                      <Box
                        fontSize="body1.fontSize"
                        className={`desc-wrap ${classes.truncate} ${classes.desc}`}
                        display="block"
                      >
                        {menu.content}
                      </Box>
                    )}
                  </a>
                </div>
              </ListItem>
            )
          ) : (
            <></>
          )}
          {menu.path !== "/app/protocol-pattern" &&
            menu.path !== "/app/promocode/overview" && (
              <ListItem
                disableRipple
                button
                component="li"
                className={clsx(
                  classes.textWhite,
                  classes.textLink,
                  classes.ListItem,
                  {
                    [classes.linkActive]: menu.isMenuOpen,
                  },
                  "list-item"
                )}
                onClick={() => toggleAndCloseSidebar()}
              >
                <div
                  className={classes.w100}
                  onClick={
                    menu.menu_title === "Ausloggen"
                      ? handleLogout
                      : () => handleLinkOn(menu.path)
                  }
                >
                  <a>
                    <Box className={classes.flexCenter}>
                      <Box component="span" className={classes.iconWrap}>
                        {menu.image && (
                          <img
                            src={require(`assets/Icons/${menu.image}`)}
                            alt="Icon"
                            className={`${menu.style && menu.style} icon-side`}
                          />
                        )}
                        {menu.icon && menu.icon}
                      </Box>
                      <ListItemText
                        primary={menu.menu_title}
                        style={{ paddingLeft: 20 }}
                      />
                    </Box>
                    {menu.desc && (
                      <Box
                        fontSize="body1.fontSize"
                        className={`desc-wrap ${classes.truncate} ${classes.desc}`}
                        display="block"
                      >
                        {menu.content}
                      </Box>
                    )}
                  </a>
                </div>
              </ListItem>
            )}
          {menu.dashed && <div className="dashed-line"></div>}
          {showAlert && (
            <Dialog
              open={showAlert}
              onClose={handleClose}
              fullWidth
              maxWidth="sm"
            >
              <DialogContent>
                <Box textAlign="center">
                  <img src={warningIcon} alt="warning icon" />
                  <h4
                    style={{
                      fontSize: "1.875em",
                      fontWeight: "600",
                      textAlign: "center",
                      color: "#888",
                      marginTop: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    ACHTUNG
                  </h4>
                </Box>
                <div
                  style={{
                    fontSize: "1.5em",
                    fontWeight: "300",
                    lineHeight: "normal",
                    textAlign: "center",
                    color: "#888",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  Sie sind im Begriff das aktuelle Formular zu verlassen. Wie
                  möchten Sie vorgehen?
                </div>
              </DialogContent>
              <DialogActions>
                <button
                  onClick={handleCancel}
                  className="button-cancel w-full custom-icon d-flex align-items-center justify-content-center h-full"
                >
                  Ohne speichern verlassen
                </button>
                <button
                  onClick={handleThirdButton}
                  className="w-full custom-icon d-flex align-items-stretch button-cancelandsave"
                >
                  Löschen und Schließen
                </button>
                <button
                  onClick={handleConfirm}
                  className="button-save w-full custom-icon d-flex align-items-stretch"
                >
                  Speichern und Schließen
                </button>
              </DialogActions>
              <button
                onClick={handleClose}
                className="close-button"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  border: "none",
                  background: "none",
                }}
              >
                <HighlightOffIcon style={{ fontSize: 25 }} />
              </button>
            </Dialog>
          )}
        </>
      )}
    </>
  );
};

export default withStyles(styles, { withTheme: true })(NavListItem);
