import React, { useRef, useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import logoXcite from "../../assets/Icons/black-logo.svg";
import Alert from "@mui/material/Alert";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import passwordIcon from "../../assets/Icons/password-icon.svg";
import IconButton from "@mui/material/IconButton";
import { InputAdornment } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import auth from "../../config/firebase";
import { db } from "../../config/firebase";
import { confirmPasswordReset } from "firebase/auth";
import { useHistory } from "react-router-dom";
import { verifyPasswordResetCode, applyActionCode } from "firebase/auth";
import {
  updateDoc,
  getDocs,
  collection,
  where,
  query,
} from "firebase/firestore";
import { CircularProgress } from "@mui/material";
export default function ConfirmPassword() {
  const initialValues = { password: "", confirm_password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mode = searchParams.get("mode");
  const oobCode = searchParams.get("oobCode");
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };
  const history = useHistory();
  const passwordRef = useRef();
  const confirm_passwordRef = useRef();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      let errors = validate(formValues).errors;
      let isValid = validate(formValues).isValid;

      if (!isValid) {
        setFormErrors(errors);
      } else {
        setFormErrors({});
        setError("");
        setLoading(true);
        await confirmPasswordReset(auth, oobCode, formValues.password).then(
          (results) => {
            setMessage("Ihr Passwort wurde erfolgreich geändert!");
            setIsSubmit(true);
            setLoading(false);
          }
        );
        history.push("/signin");
      }
    } catch (err) {
      setError(err);
    }
  }

  const validate = (values) => {
    const errors = {};
    let isValid = true;
    if (!values.password) {
      isValid = false;
      errors.password = "Ungültige Passwort";
    } else if (values.password.length < 6) {
      isValid = false;
      errors.password = "Das Passwort muss mindestens 6 Zeichen lang sein!";
    } else if (values.password.length > 12) {
      isValid = false;
      errors.password = "Das Passwort darf nicht länger als 12 Zeichen sein!";
    } else if (values.confirm_password != values.password) {
      isValid = false;
      errors.confirm_password = "Erforderlich";
    }
    return { errors, isValid };
  };

  const verifyEmailAccount = async (oobCode) => {
    await applyActionCode(auth, oobCode)
      .then(async () => {
        const user = auth.currentUser;
        if (user) {
          await auth.currentUser.reload();
        }
        if (user?.emailVerified) {
          const Userquery = query(
            collection(db, "xc_user"),
            where("email", "==", user?.email)
          );
          const userDoc = await getDocs(Userquery);
          if (userDoc.empty) {
            console.log("No document found with this email");
            setError(
              "Bei der Bestätigung Ihrer Anmeldung ist ein Fehler aufgetaucht. Bitte kontaktieren Sie den X-CITE Kundendienst!"
            );

            return;
          }
          const docRef = userDoc.docs[0].ref;
          //console.log(userDoc.docs[0].data(), ' data')
          // Update the document
          await updateDoc(docRef, { is_verified: true });
          setMessage("Sie können sich jetzt mit Ihrem neuen Konto anmelden");
        } else {
          setMessage("Sie können sich jetzt mit Ihrem neuen Konto anmelden");
        }
        setLoading(false);
      })
      .catch((error) => {
        // Handle errors here
        const user = auth?.currentUser;
        if (user?.emailVerified) {
          setError(
            "Der von Ihnen benutzte Bestätigungslink ist nicht mehr gültig!"
          );
        } else if (error.code === "auth/invalid-action-code") {
          setError(
            "Der von Ihnen benutzte Bestätigungslink ist nicht mehr gültig!"
          );
        } else {
          setError(
            "Bei der Bestätigung Ihrer Anmeldung ist ein Fehler aufgetaucht. Bitte kontaktieren Sie den X-CITE Kundendienst!"
          );
        }
        setLoading(false);
      });
  };
  const handleRedirection = async () => {
    history.push("/signin");
  };

  useEffect(() => {
    if (mode === "verifyEmail" && oobCode) {
      setLoading(true);
      verifyEmailAccount(oobCode);
    }
  }, []);

  return (
    <>
      <div className="bg-color-home">
        {mode != "verifyEmail" ? (
          <Container className="padding-fpw">
            <div className="card pt-2 pb-2">
              <div className="card-contain padding">
                <span className="logo">
                  <img src={logoXcite} alt="logo" />
                </span>
                {Object.keys(formErrors).length === 0 && isSubmit ? (
                  <span className="success">
                    {message && <Alert severity="success">{message}</Alert>}
                  </span>
                ) : (
                  <span className="error">
                    {" "}
                    {error && <Alert severity="error">{error}</Alert>}
                  </span>
                )}

                <Row className="mt-4 mb-4">
                  <Col md={12}>
                    <form>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <div className="input-field">
                          <img
                            className="passwordIcon"
                            src={passwordIcon}
                            alt="user Icon"
                          />
                          <TextField
                            name="password"
                            id="password"
                            type={showPassword ? "text" : "password"}
                            inputRef={passwordRef}
                            label="PASSWORT"
                            placeholder="PASSWORT *"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            required
                            error={error}
                            value={formValues.password}
                            onChange={handleChange}
                            InputProps={{
                              style: { paddingLeft: "35px" },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityOffOutlinedIcon />
                                    ) : (
                                      <VisibilityOutlinedIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                            className="input-field-PP"
                          />
                          <span className="error">{formErrors.password}</span>
                        </div>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <div className="input-field">
                          <img
                            className="passwordIcon"
                            src={passwordIcon}
                            alt="user Icon"
                          />
                          <TextField
                            name="confirm_password"
                            id="confirm_password"
                            type={showConfirmPassword ? "text" : "password"}
                            inputRef={confirm_passwordRef}
                            label="BESTÄTIGE DAS PASSWORT"
                            placeholder="BESTÄTIGE DAS PASSWORT *"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            required
                            error={error}
                            value={formValues.confirm_password}
                            onChange={handleChange}
                            InputProps={{
                              style: { paddingLeft: "35px" },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownConfirmPassword}
                                    edge="end"
                                  >
                                    {showConfirmPassword ? (
                                      <VisibilityOffOutlinedIcon />
                                    ) : (
                                      <VisibilityOutlinedIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                            className="input-field-PP"
                          />
                          <span className="error">
                            {formErrors.confirm_password}
                          </span>
                        </div>
                      </Box>
                    </form>
                  </Col>

                  <Col md={12} className="mt-2 mb-1">
                    <div className="Einloggen">
                      <button
                        disabled={loading}
                        className="LoginButton"
                        onClick={(e) => handleSubmit(e)}
                      >
                        ZURÜCKSETZEN
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        ) : (
          <Container className="padding-fpw">
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "100vh",
                }}
              >
                <CircularProgress sx={{ color: "white" }} />
              </Box>
            ) : (
              <div className="card pt-2 pb-2">
                <div className="card-contain padding">
                  <Row className="mt-4 mb-4">
                    <Col md={12}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        {message !== "" ? (
                          <span className="success">
                            <Alert severity="success">{message}</Alert>
                          </span>
                        ) : error !== "" ? (
                          <span>
                            <Alert severity="error">{error}</Alert>
                          </span>
                        ) : (
                          <></>
                        )}
                      </Box>
                    </Col>
                  </Row>
                </div>
                <button
                  className="RedirectLoginButton"
                  onClick={() => handleRedirection()}
                >
                  <span style={{ textTransform: "uppercase" }}>
                    Zurück zum Login
                  </span>
                </button>
              </div>
            )}
          </Container>
        )}
      </div>
    </>
  );
}
